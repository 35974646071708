<template>
    <b-form-group
        id="province-select-group"
        :label="this.getProvinceString()"
        label-for="province-select"
    >
        <b-form-select
            id="province-select"
            v-model="selected"
            :disabled="this.disabled"
        >
            :state="state">
            <option :value="0">{{ noSelectionDescriptor }}</option>
            <option v-for="prov in provinces" :value="prov.id" :key="prov.id">
                <template>
                    {{ prov.description }}
                </template>
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import RegionsService from '../services/regions.service';

export default {
    name: 'Provinces',
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        noSelectionDescriptor: {
            type: String,
            required: false,
            default: 'All',
        },
        state: {
            type: Boolean,
            required: false,
    },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
    },
        },
    },
    data() {
        return {
            provinces: [],
        };
    },
    mounted() {
        this.getProvinces();
    },
    methods: {
        getProvinces() {
            RegionsService.getProvinces()
                .then((response) => {
                this.provinces = response.data;
                })
                .catch((error) => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'RegionsService.getProvinces failed',
                    variant: 'danger',
                        solid: true,
                    });
                });
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
    },
};
</script>
<style scoped>
</style>