import { render, staticRenderFns } from "./ServantsComponent.vue?vue&type=template&id=49b9d6e5&"
import script from "./ServantsComponent.vue?vue&type=script&lang=js&"
export * from "./ServantsComponent.vue?vue&type=script&lang=js&"
import style0 from "./ServantsComponent.vue?vue&type=style&index=0&id=49b9d6e5&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports