<template>
    <b-modal id="api-login-delete-modal">
        <template #modal-title>
            <h3>Delete Api Login</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>You are about to delete API Login {{apiLogin.name}}</p>
                    <p>Do you want to proceed?</p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a @click="deleteApiLogin()" class="btn btn-danger">Delete</a>
        </template>
    </b-modal>
</template>

<script>
import AuthService from '../../services/auth.service';

export default {
    name: 'DeleteApiLoginModal',
    props: {
        apiLogin: {
            type: Object,
            required: true
        },
    },
    methods: {
        deleteApiLogin() {
            AuthService.deleteApiLogin(this.apiLogin.id).then(
                (response) => {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });
                    this.$emit('hide');
                }
            );
        },
    },
};
</script>