<template>
    <div>
        <div class="m-3">
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group
                        id="description-input-group"
                        label="Description"
                        label-for="description"
                    >
                        <b-form-input
                            id="description"
                            :state="descriptionState"
                            v-model="manualAddModel.description"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="descriptionState == false" class="error error-margins">
                        Description is required
                    </div>
                </div>
                <div class="col">
                    <b-form-group
                        id="address-input-group"
                        label="Address"
                        label-for="address"
                    >
                        <b-form-input
                            id="address"
                            :state="addressState"
                            v-model="manualAddModel.address"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="addressState == false" class="error error-margins">
                        Address is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group
                        id="city-input-group"
                        label="City"
                        label-for="city"
                    >
                        <b-form-input
                            id="city"
                            :state="cityState"
                            v-model="manualAddModel.city"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="cityState == false" class="error error-margins">
                        City is required
                    </div>
                </div>
                <div class="col mt-3">
                    <v-autocomplete
                        :items="provinces"
                        v-model="manualAddModel.province"
                        item-text="abbr"
                        item-value="id"
                        :label="this.getProvinceString()"
                        return-object
                        :error="!provincesState"
                        :class="{ error: provincesState == false }"
                    >
                    </v-autocomplete>
                    <div v-if="provincesState == false" class="error error-margins">
                        Province is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group
                        id="postal-code-input-group"
                        :label="this.getPostalCodeString()"
                        label-for="postal-code"
                    >
                        <b-form-input
                            id="postal-code"
                            :state="postalCodeState"
                            v-model="manualAddModel.postal_code"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="postalCodeState == false" class="error error-margins">
                        Postal Code is required
                    </div>
                </div>
                <div class="col">
                    <b-form-group
                        id="number-input-group"
                        label="Number"
                        label-for="number"
                    >
                        <b-form-input
                            id="number"
                            :state="numberState"
                            v-model="manualAddModel.number"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="numberState == false" class="error error-margins">
                        Store Number is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group
                        id="shipto-input-group"
                        label="Details"
                        label-for="shipto"
                    >
                        <b-form-input
                            id="city"
                            v-model="manualAddModel.ship_to"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col mt-3">
                    <v-autocomplete
                        :items="regions"
                        v-model="manualAddModel.region"
                        item-text="fullDescription"
                        item-value="id"
                        label="Region: "
                        return-object
                        :error="!regionsState"
                        :class="{ error: regionsState == false }"
                    >
                    </v-autocomplete>
                    <div v-if="regionsState == false" class="error error-margins">
                        Region is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    Tax
                    <div class="tax-group">
                        <UpdateTaxListValue
                            ref="updateTaxListValue"
                            :values="manualAddModel.effective_dates"
                            :checkValid="checkValid"
                        />
                    </div>
                </div>
            </div>
            <CustomFieldsForm
                ref="customFieldsForm"
                :typeId="CUSTOM_FIELD_TYPE_ENUM.Store"
            />
            <b-button variant="primary" @click="addNewManual" class="right mb-1">
                Add
            </b-button>
        </div>
    </div>
</template>

<script>
import { RegionsService, StoresService } from '../../services';
import CustomFieldsForm from '../../views/customfields/CustomFieldsForm.vue';
import CUSTOM_FIELD_TYPE_ENUM from '../../models/CustomFieldTypeEnum';
import UpdateTaxListValue from './UpdateTaxListValue.vue';

export default {
    name: 'AddStoreComponent',
    components: {
        CustomFieldsForm,
        UpdateTaxListValue
    },
    data() {
        return {
            success: false,
            manualAddModel: {
                description: '',
                address: '',
                region: null,
                number: '',
                city: '',
                province: null,
                postal_code: '',
                ship_to: '',
                effective_dates: [],
            },
            checkValid: false,
            regions: [],
            provinces: [],
            minDate: new Date(),
            CUSTOM_FIELD_TYPE_ENUM,
        };
    },
    computed: {
        descriptionState() {
            if (!this.checkValid) return null;
            return this.manualAddModel.description ? true : false;
        },
        addressState() {
            if (!this.checkValid) return null;
            return this.manualAddModel.address ? true : false;
        },
        cityState() {
            if (!this.checkValid) return null;
            return this.manualAddModel.city ? true : false;
        },
        provincesState() {
            if (!this.checkValid) return null;
            if (!this.manualAddModel.province) return false;
            return this.manualAddModel.province.id > 0 ? true : false;
        },
        postalCodeState() {
            if (!this.checkValid) return null;
            return this.manualAddModel.postal_code ? true : false;
        },
        numberState() {
            if (!this.checkValid) return null;
            return this.manualAddModel.number ? true : false;
        },
        regionsState() {
            if (!this.checkValid) return null;
            if (!this.manualAddModel.region) return false;
            return this.manualAddModel.region.id > 0 ? true : false;
        },
        isFormValid() {           
            return (
                this.descriptionState &&
                this.addressState &&
                this.cityState &&
                this.provincesState &&
                this.postalCodeState &&
                this.numberState &&
                this.regionsState &&
                this.$refs.updateTaxListValue.validateAll()
            );
        },
    },
    mounted() {
        this.getRegions();
        this.getProvinces();
    },
    methods: {
        addNewManual() {
            this.checkValid = true;
            if (this.isFormValid && this.$refs.customFieldsForm.isFormValid()) {
                this.manualAddModel.custom_field_values =
                    this.$refs.customFieldsForm.getValues();

                StoresService.addNew(this.manualAddModel)
                    .then((response) => {
                        if (response.data.success) {
                            this.manualAddModel = {
                                description: null,
                                address: null,
                                city: null,
                                province: null,
                                postal_code: null,
                                number: null,
                                ship_to: null,
                                region: null,
                            };
                            this.checkValid = false;

                            this.$root.$bvToast.toast('Stores Added', {
                                title: 'Success',
                                variant: 'success',
                                solid: true,
                            });
                            this.$emit('hide');
                        } else {
                            this.$root.$bvToast.toast(
                                'Highlighted Fields Required',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    solid: true,
                                }
                            );
                            this.checkValid = true;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 400) {
                            this.$root.$bvToast.toast(
                                'Highlighted Fields Required',
                                {
                                    title: 'Error',
                                    variant: 'danger',
                                    solid: true,
                                }
                            );
                            this.checkValid = true;
                        } else {
                            this.$root.$bvToast.toast('An Error has Occurred', {
                                title: 'Error',
                                variant: 'danger',
                                solid: true,
                            });
                            this.checkValid = true;
                        }
                    });
            }
        },
        getRegions() {
            RegionsService.get()
                .then((response) => {
                    this.regions = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'RegionsService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        getProvinces() {
            RegionsService.getProvinces()
                .then((response) => {
                    this.provinces = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'RegionsService.getProvinces failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        getProvinceString() {
            return (
                this.$store.state.auth.portal_configuration?.province ??
                'Province'
            );
        },
        getPostalCodeString() {
            return (
                this.$store.state.auth.portal_configuration?.postalcode ??
                'Postal Code'
            );
        },
    },
};
</script>

<style scoped>
.error {
    color: #dc3545;
}

.error-margins {
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}

.tax-group {
    margin-top: 0.5rem;
    padding: 1rem;
    border: 1px solid lightgray;
    border-radius: 0.25rem;
}
</style>