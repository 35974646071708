<template>
    <b-modal scrollable size="xl" id="codeactivity-http-modal">
        <template #modal-title>
            <h3>Http Details</h3>
        </template>
        <div class="modal-body">
            <h4 style="margin-bottom: 3%">Response Code: <b>{{items.responseCode}}</b></h4>
            <span><h5 class="textarea-title">Http Request</h5></span>
            <textarea v-model="items.request" style="width:100%; height:140px" readonly></textarea>
            <span><h5 class="textarea-title">Http Response</h5></span>
            <textarea v-model="items.response" style="width:100%; height:140px" readonly></textarea>
            <span><h5 class="textarea-title">Http Response Content</h5></span>
            <textarea v-model="items.responseContent" style="width:100%; height:140px" readonly></textarea>
        </div>
        <template #modal-footer="{cancel}">
            <a id="codeactivity-http-modal-close" @click="cancel()" class="btn">Close</a>
        </template>
    </b-modal>
</template>

<script>

export default {
    name:'CodeActivityHttpModal',
    props: {
        items: {
            request: '',
            response: '',
            responseCode: '',
            responseContent: ''
        },
    }
}
</script>

<style scoped>
.textarea-title {
  margin-top: 1%;
  margin-bottom: 1%;
}
</style>