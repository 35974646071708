<template>
    <div>
        <div>
            <h3 class="ml-3"><font-awesome-icon icon="paper-plane" class="nav-icon fa" />  Release <span v-if="firmware.hardware_type">- {{ firmware.hardware_type }} ({{firmware.version}})</span></h3>
            <h4 class="ml-3" v-if="firmware.release_completed"> 
                <font-awesome-icon icon="check" color='green' class="nav-icon fa" />
                Release Completed
            </h4>
            <b-button variant="outline-primary" class="my-3 float-right" @click="openAddPhaseModel()" :disabled="phases.length >= 4" v-if="security.phaseCreate && !firmware.release_completed">Add Phase</b-button>
            <br />
            <br />
            <br />
            <div class="phase-group">
                <div class="phase-container" v-for="(phase, index) in phases" :key="index">
                    <Phase
                    :ref="`phase_${phase.id}`"
                    :phase="phase" 
                    :firmware="firmware" 
                    :locked="index != phases.length - 1" 
                    @refresh="refresh" 
                    class="mb-4 phase" 
                    :class="(index % 2 == 0) ? 'odd-margin' : 'even-margin'"
                    />
                </div>
            </div>
            <SpinnerNoResults :isLoading="isLoading" :spinnerOnly="true"/>
            <h6 class="center mt-5" v-if="!isLoading && phases.length == 0">
                <font-awesome-icon icon="circle-exclamation" class="nav-icon fa"/>
                Release doesn't have any phases
            </h6>
            <b-button variant="outline-primary" class="my-3 float-right" @click="openCompleteReleaseModal()" v-if="security.phaseCreate && !firmware.release_completed">Complete Release</b-button>
        </div>
        <b-modal id="add-phase" ref="add-phase" hide-footer>
            <template #modal-title>
               Add Phase
            </template>

            <AddEditPhase :phase="selectedPhase" :firmware="firmware" :isEdit="false" @hide="hideAddPhaseModel"/>
        </b-modal>
        <b-modal id="complete-release" ref="complete-release" hide-footer>
            <template #modal-title>
               Complete Release
            </template>
            <p>Are you sure you want to complete release {{ firmware.hardware_type }} ({{firmware.version}})?</p>
            <p>All remaining kiosks will be promoted</p>
            <div class="float-right">
                <button variant="" class="mt-3 btn btn-primary" @click="completeRelease">
                    Complete
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import SoftwareService from "../../services/software.service"
import { Phase, AddEditPhase } from "../../components/firmware"
import { SpinnerNoResults } from "../../components"

export default {
    name: "KioskPromotion",
    components: {  
        Phase,
        AddEditPhase,
        SpinnerNoResults    
    },
    data() {
        return {
            isLoading: false,
            firmwareId: 0,
            firmware: {},
            phases: [],
            selectedPhase: {}
        };
    },
    computed: {
        security(){
            return this.$store.state.auth.security
        },
    },
    watch:{
        
    },
    created() {
    },
    mounted() {
        this.setUp();
    },
    methods: {
        setUp() {  
            this.firmwareId = +this.$route.params.id
            this.refresh()
        },
        refresh() {
            this.getFirmware()
            this.getPhases()
        },
        getFirmware(){
            SoftwareService.getFirmwareById(this.firmwareId).then(response => {
                if (response) {
                    this.firmware = response.data
                }
            })
        },
        getPhases() {
            this.phases = []
            this.isLoading = true
            return SoftwareService.getPhases(this.firmwareId).then(response => {
                if (response) {
                    this.phases = response.data
                    this.isLoading = false
                }
            })
        },
        openAddPhaseModel() {
            this.selectedPhase = { 
                id: 0,
                firmware_id: this.firmwareId,
                phase: this.phases.length + 1
                }
            this.$refs["add-phase"].show()
        },
        hideAddPhaseModel(phaseId, newPhase) {
            Promise.all([this.getPhases()]).then(() => {
                if (newPhase) {
                    this.$refs[`phase_${phaseId}`][0].openKioskPromotionModal()
                }

                this.$refs["add-phase"].hide()
            });
        },
        openCompleteReleaseModal(){
            this.$refs["complete-release"].show()
        },
        hideCompleteReleaseModal(){
            this.$refs["complete-release"].hide()
        },
        completeRelease() {
            SoftwareService.completeRelease(this.firmwareId).then(response => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success?  'success' : 'danger',
                        solid: true
                    })
                    this.hideCompleteReleaseModal();
                    this.getFirmware()
                }
            })
        }
    }
}
</script>
<style>
.center {
    text-align: center;
}
.phase-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.phase-container{
    width: 50%;
}
.even-margin {
    margin-left: 0.5rem
}
.odd-margin {
    margin-right: 0.5rem
}

@media only screen and (max-width: 600px) {
.phase-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.phase-container{
    width: 100%;
}
.even-margin {
    margin-left: 0rem;
}
.odd-margin {
    margin-right: 0rem
}

}
</style>