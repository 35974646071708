<template>
    <b-modal id="dlg-save" ref="dlg-save" hide-footer>
        <template #modal-title>
            {{ title }}
        </template> 
        <span>{{ message }}</span>
        <div class="d-block text-center">
                <b-form-input v-model="savename">                            
                </b-form-input>
        </div>
        <div class="float-right">
            <b-button class="mt-3 btn-margin-right " variant="danger" @click="$bvModal.hide('dlg-save')">Cancel</b-button>
            <b-button class="mt-3" variant="primary" @click="onSave">Save</b-button>
        </div>
    </b-modal>      
</template>

<script>
    export default {
        name: "SaveDialog",
        data() {
            return {
                title: '',
                message: '',
                savename: '',
                onSaveCb: () => {}
            }
        },
        methods: {
            show(args) {
                this.title = args.title
                this.message = args.message
                this.onSaveCb = args.onSave
                this.$refs["dlg-save"].show()
            },
            onSave() {
                if (this.savename.trim() != "") {
                    this.onSaveCb(this.savename)
                    this.$refs["dlg-save"].hide()
                }
            }
        }
    }
</script>

<style scoped>
.btn-margin-right {
    margin-right: 12px;
}
</style>