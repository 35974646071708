<template>
    <div>
        <h3>
            <font-awesome-icon icon="scale-balanced" class="nav-icon fa" />
            Commission
        </h3>

        <DynamicSearchComponent
            ref="CommissionFilterComponent"
            preferencesName="commission_search"
            :allCriteria="commissionCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadCommissionReport"
        />
        <Table
            id="commissionReportTable"
            :fields="fields"
            :items="commissionReport"
            sortBy="timestamp"
            :sortDesc="true"
            :searchPagination="searchPagination"
            @getItems="getCommissionReport"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(timestamp)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.timestamp)) }}
            </template>
            <template #cell(unit_price)="data">
                {{ formatPrice(data.item.unit_price) }}
            </template>
            <template #cell(commission)="data">
                {{ formatPrice(data.item.commission) }}
            </template>
            <template #custom-foot>
                <b-tr>
                    <b-th colspan="7"></b-th>
                    <b-th colspan="3" class="text-sm-right"
                        >Total Commission: $</b-th
                    >
                    <b-th>{{ totalCommission }}</b-th>
                </b-tr>
            </template>
        </Table>
        <ExportFileComponent
            :arguments="this.arguments.query"
            :disabled="false"
            page="commission"
            class="export"
        />
    </div>
</template>

<script>
import { ExportFileComponent, Table } from '../../components';
import { CommissionService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import DynamicSearchComponent from '../../components/DynamicSearchComponent.vue';
import { formatDate, formatPrice, convertUTCtoLocalDate } from '../../utils/utils';

export default {
    name: 'CommissionReport',
    components: {
        Table,
        DynamicSearchComponent,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'store_number',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'store_desc',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'transaction_id',
                    label: 'Transaction #',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: "vmid",
                    label: "VMID",
                    tdClass: "column",
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'region',
                    label: 'Region',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'timestamp',
                    label: 'Date/Time',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5
                },
                {
                    key: 'product_description',
                    label: 'Product',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7
                },
                {
                    key: 'unit_price',
                    label: 'Unit Price',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 8
                },
                {
                    key: 'commission',
                    label: 'Commission',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 9
                },
            ],
            commissionCriteria: [
                {
                    key: 'date_start',
                    name: 'Date Start',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
                {
                    key: 'date_end',
                    name: 'Date End',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
                {
                    key: 'store',
                    name: 'Store',
                    type: 'Text',
                    searchQueryName: 'store',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
            ],
            commissionReport: [],
            arguments: [],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            isLoading: false,
            sortOrder: -5,
            totalCommission: 0.0,
        };
    },
    methods: {
        getCommissionReport() {
            this.$refs.CommissionFilterComponent.reloadDynamicSearch();
        },
        reloadCommissionReport(args) {
            this.arguments = args;
            this.isLoading = true;
            CommissionService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.commissionReport = response.data.items;
                        this.totalCommission = response.data.total_commission;
                        this.searchPagination.totalRows =
                            this.commissionReport.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getCommissionReport()
        },
        formatDate,
        formatPrice,
        convertUTCtoLocalDate
    },
};
</script>
<style scoped>

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }

    ::v-deep .table.b-table.b-table-stacked-sm > tfoot {
        display: block !important;
    }
}

</style>