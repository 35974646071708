<template>
    <b-modal id="stores-delete-modal">
        <template #modal-title>
            <h3>Delete Store</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label
                            >You are about to delete all information associated
                            with this store.</label
                        >
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Do you want to proceed?</label>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <b-button
                id="stores-modal-delete-event"
                @click="deleteStore()"
                variant="danger"
            >
                Delete
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import StoresService from '../../services/stores.service';

export default {
    name: 'DeleteStoreModal',
    props: {
        storeId: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    emits: ['deleteComplete'],
    methods: {
        deleteStore() {
            StoresService.deleteStore(this.storeId).then((response) => {
                if (response) {
                    if (response.data.success) {
                        this.$root.$bvToast.toast('Store deleted', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                        this.$emit('hide');
                    } else {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        this.$emit('hide');
                    }
                }
            });
        },
    },
};
</script>