import axios from "axios";
import authHeader from "./auth.header";

class ProductsService {

    get() {
        return axios({
            method: 'get',
            url: '/api/products/list',
            headers: authHeader()
        })
    }

    getPrices() {
        return axios({
            method: 'get',
            url: '/api/products/list_prices',
            headers: authHeader()
        })
    }

    getPaged(startIndex, pageSize, sortOrder) {
        return axios({
            method: 'get',
            url: '/api/products/list_paged',
            headers: authHeader(),
            params: {
                startIndex, pageSize, sortOrder
            }
        })
    }

    getPricesPaged(productId, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'get',
            url: '/api/products/list_prices_paged',
            headers: authHeader(),
            params: {
                productId, startIndex, pageSize, sortOrder
            }
        })
    }

    getPriceDetails(priceId){
        return axios({
            method: 'get',
            url: 'api/products/get_price_details',
            headers: authHeader(),
            params: {
                priceId
            }
        })
    }

    getPriceDetailsByKioskId(kioskId){
        return axios({
            method: 'get',
            url: 'api/products/get_price_details_by_kioskid',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    deletePrice(id, effectiveDateId){
        return axios({
            method: 'delete',
            url: '/api/products/delete_price',
            headers: authHeader(),
            params: { priceId:  id, effectiveDateId: effectiveDateId }
        });
    }

    delete(id) {
        return axios({
            method: 'delete',
            url: '/api/products/delete',
            headers: authHeader(),
            params: { productId: id }
        });
    }

    add(productDetails) {
        return axios({
            method: 'post',
            url: '/api/products/add',
            headers: authHeader(),
            data: {
                id: 0,
                description: productDetails.description,
                receipt_message: productDetails.receipt_message,
                email_message: productDetails.email_message,
                message_start_date: productDetails.message_start_date,
                message_end_date: productDetails.message_end_date,
                receipt_heading: productDetails.receipt_heading,
                receipt_primary_message: productDetails.receipt_primary_message,
                receipt_secondary_message: productDetails.receipt_secondary_message,
                receipt_phone_number: productDetails.receipt_phone_number,
                billing_location: productDetails.billing_location,
                gst_reg: productDetails.gst_reg,
                custom_field_values: productDetails.custom_field_values
            }
        })
    }

    addPrice(priceDetails) {
        return axios({
            method: 'post',
            url: '/api/products/add_price',
            headers: authHeader(),
            data: priceDetails
        })
    }

    update(productDetails) {
        return axios({
            method: 'post',
            url: '/api/products/update',
            headers: authHeader(),
            data: {
                id: productDetails.id,
                description: productDetails.description,
                receipt_message: productDetails.receipt_message,
                email_message: productDetails.email_message,
                message_start_date: productDetails.message_start_date,
                message_end_date: productDetails.message_end_date,
                receipt_heading: productDetails.receipt_heading,
                receipt_primary_message: productDetails.receipt_primary_message,
                receipt_secondary_message: productDetails.receipt_secondary_message,
                receipt_phone_number: productDetails.receipt_phone_number,
                billing_location: productDetails.billing_location,
                gst_reg: productDetails.gst_reg,
                custom_field_values: productDetails.custom_field_values
            }
        })
    }

    updatePrice(priceDetails) {
        return axios({
            method: 'post',
            url: '/api/products/update_price',
            headers: authHeader(),
            data: priceDetails
        })
    }

    updateConfig(configItems, id) {
        return axios({
            method: 'post',
            url: '/api/products/update_config',
            headers: authHeader(),
            data: {
                config_items: configItems,
                product_id: id
            }
        })
    }

    getConfig(productId) {
        return axios({
            method: 'get',
            url: '/api/products/get_config',
            headers: authHeader(),
            params: {
                productId
            }
        })
    }

    downloadCsv() {     
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/products/download_csv',
            headers: authHeader(),
            params: {
            }
        })
    }

    downloadXlsx() {     
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/products/download_xlsx',
            headers: authHeader(),
            params: {
            }
        })
    }

    downloadPricesCsv(query) {     
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/products/download_prices_csv',
            headers: authHeader(),
            params: {
                productId: query.productId
            }
        })
    }

    downloadPricesXlsx(query) {     
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/products/download_prices_xlsx',
            headers: authHeader(),
            params: {
                productId: query.productId
            }
        })
    }
}

export default new ProductsService();