<template>
    <div>
        <div class="form mb-2">
            <div class="flex-group toggle-icon">
                <font-awesome-icon
                    :icon="collapse ? 'angle-right' : 'chevron-down'" 
                    class="nav-icon pointer mx-2" 
                    @click="toggleCollapse()" 
                    title="Toggle Collapse"
                    :class="{'hide': !hasChildren}"
                />
            </div>
            <div class="flex-group">
                {{node[nodeName]}}
            </div>
            <b-dropdown variant="link" toggle-class="text-decoration-none" dropright no-caret v-if="canCreate || canWrite || (canDelete && !hasChildren)">
                <template #button-content>
                    <font-awesome-icon icon="ellipsis" title="Options" class="ellipsis pointer"/>
                </template>
                <b-dropdown-item v-if="canWrite" @click="editNode(node)">
                    <div class="item">
                        <font-awesome-icon icon="pencil"/>
                        <div class="mx-2">
                            Edit
                        </div>
                    </div>
                </b-dropdown-item>
                <b-dropdown-item v-if="canCreate" @click="addChild(node)">
                    <div class="item">
                        <font-awesome-icon icon="plus"/>
                        <div class="mx-2">
                            Add Child
                        </div>
                    </div>
                </b-dropdown-item>
                <b-dropdown-item v-if="canDelete && !hasChildren" @click="deleteNode(node)">
                    <div class="item">
                        <font-awesome-icon icon="trash-can"/>
                        <div class="mx-2">
                            Delete
                        </div>
                    </div>
                </b-dropdown-item>
            </b-dropdown>
        </div>
        <div v-if="!collapse">
            <TreeViewNodeComponent 
                v-for="node in sortedChildren"
                :key="node.id"
                :ref="'node-' + node.id"
                :node="node"
                :nodeName="nodeName"
                :childrenName="childrenName"
                :canRead='canRead'
                :canWrite='canWrite'
                :canCreate='canCreate'
                :canDelete='canDelete'
                @editNode="editNode"
                @addChild="addChild"
                @deleteNode="deleteNode"
                class="ml-3"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: "TreeViewNodeComponent",
    components: {
    },
    props: {
        node: {
            type: Object,
            required: true
        },
        defaultCollapse: {
            type: Boolean,
            default: true
        },
        nodeName: {
            type: String,
            required: true
        },
        childrenName: {
            type: String,
            required: true
        },
        canRead: {
            type: Boolean,
            required: true
        },
        canWrite: {
            type: Boolean,
            required: true
        },
        canCreate: {
            type: Boolean,
            required: true
        },
        canDelete: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            collapse: true
        }
    },
    computed: {
        children() {
            return this.node[this.childrenName]
        },
        sortedChildren() {
            var localChildren = this.children
            return localChildren.sort((a, b ) => (a[this.nodeName] > b[this.nodeName]) ? 1 : ((b[this.nodeName] > a[this.nodeName]) ? -1 : 0))
        },
        hasChildren() {
            return this.children != ''
        }
    },
    mounted() {
        this.collapse = this.defaultCollapse
    },
    methods: {
        collapseAll() {
            this.collapse = true
            this.children.forEach(node => {
                var reference = this.$refs['node-' + node.id]

                if (reference && reference.length > 0) {
                    reference[0].collapseAll()
                }
            })
        },
        expandAll() {
            this.collapse = false
            this.children.forEach(node => {
                var reference = this.$refs['node-' + node.id]

                if (reference && reference.length > 0) {
                    reference[0].expandAll()
                }
            })
        },
        toggleCollapse() {
            this.collapse = !this.collapse
        },
        editNode(value) {
            this.$emit("editNode", value)
        },
        addChild(value) {
            this.$emit("addChild", value)
        },
        deleteNode(value) {
            this.$emit("deleteNode", value)
        },
    }
}
</script>
<style scoped>
.form {
    display: flex;
}
.node-input {
    width: 15%;
}
.hide {
    visibility: hidden;
}
.toggle-icon {
    width: 30px;
}
.pointer:hover {
    opacity: 60%;
}
.flex-group {
    display: flex;
    align-items: center;
}
.ellipsis {
    color: black;
}
::v-deep .dropdown-item {
    padding-right: 1rem;
    padding-left: 1rem;
}
.item {
    display: flex;
    align-items: center;
}
</style>