<template>
    <div>
        <div class="mt-3">
            <div class="row">
                <div class="col">
                    <b-form-file
                        v-model="storeCSV"
                        placeholder="Select a CSV or drop it here"
                        drop-placeholder="Drop CSV Here"
                        accept=".csv"
                        :state="csvState"
                    />
                    <div v-if="csvState == false" class="error">
                        CSV is required
                    </div>
                </div>
            </div>
            <div class="mt-3 button-group">
                <div class="button-group">
                    <a :href="bulkExampleFileLocation" download="ExampleStoreBulkEntry.csv" class="align-self-center">
                        Download Example CSV
                    </a>
                </div>
                <div>
                    <b-button variant="primary" @click="onSubmit">Import</b-button>
                </div>
            </div>
            <div class="mt-3">
                <div
                    v-for="(error, index) in storeErrors"
                    v-bind:key="index"
                >
                    <div style="color: red">{{ error }}</div>
                </div>
                <div v-if="success">
                    Store CSV imported successfully
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StoresService } from '../../services';
export default {
    name: 'BulkImportStores',
    components: {
    },
    data() {
        return {
            storeCSV: null,
            success: false,
            checkValid: false,
            storeErrors: []
        };
    },
    computed: {
        bulkExampleFileLocation() {
            if (this.$store.state.auth.portal.countryId == 1)
                return '/files/stores/ExampleStoreBulkEntry_Canada.csv';
            else if (this.$store.state.auth.portal.countryId == 2)
                return '/files/stores/ExampleStoreBulkEntry_USA.csv';
            return '/files/stores/ExampleStoreBulkEntry_Canada.csv';
        },
        csvState() {
            if (!this.checkValid) return null
            return this.storeCSV ? true : false
        },
        isFormValid() {
            return this.csvState
        }
    },
    mounted() {
    },
    methods: {
        onSubmit() {
            this.checkValid = true
            if (this.isFormValid) {
                var formData = new FormData();
                formData.append('StoreCSV', this.storeCSV);
                StoresService.addNewCsv(formData).then((response) => {
                    this.storeErrors = response.data.errors;
                    this.success = response.data.success;

                    if (this.success) {
                        this.$root.$bvToast.toast('Stores Added', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                        this.storeCSV = null;
                        this.$emit('hide')
                    } else {
                        this.$root.$bvToast.toast('Check Error Messages', {
                            title: 'Stores Not Added',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                });
            }
        },
    },
};
</script>

<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
}
.error {
    color: #dc3545;
}
</style>