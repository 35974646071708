<template>
    <div>
        <h3>
            <font-awesome-icon icon="file" class="nav-icon fa" /> File Archive Data
        </h3>
        <DynamicSearchComponent
            ref="fileArchiveSearchComponent"
            preferencesName="file_archive_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="getArchiveData"
        />
        <div class="pb-3">
            <Table
                id="archiveDataTable"
                :fields="fields"
                :items="archiveData"
                sortBy=""
                :sortDesc="false"
                :searchPagination="searchPagination"
                @getItems="getFileArchiveData"
            >
                <template #cell(actions)="row">
                    <template v-if="row.item.typeId != 4">
                        <font-awesome-icon
                            icon="download"
                            class="pointer"
                            @click="
                                downloadFile(row.item, row.index, $event.target)
                            "
                        />
                    </template>
                </template>
                <template #cell(archive_date)="data">
                    {{ formatDateOnly(data.item.archive_date) }}
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
import { FileArchiveService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../components/';
import { saveAs } from 'file-saver';
import { formatDateOnly } from '../../utils/utils';
export default {
    name: 'FileArchiveData',
    components: {
        Table,
        DynamicSearchComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                },
                {
                    key: 'file_name',
                    label: 'File Name',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'file_size',
                    label: 'File Size',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'archive_date',
                    label: 'Archive Date',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'recordtype',
                    name: 'Record Type',
                    type: 'RecordType',
                    searchQueryName: 'recordType',
                    searchQueryValueProperty: 'value',
                    required: true,
                },
                {
                    key: 'datestart',
                    name: 'Date Start',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: new Date().toISOString().slice(0, 10),
                    required: true,
                },
                {
                    key: 'dateend',
                    name: 'Date End',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: new Date().toISOString().slice(0, 10),
                    required: true,
                },
            ],
            archiveData: [],
            isLoading: false,
            message: '',
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
        };
    },
    mounted() {
        this.getFileArchiveData();
    },
    methods: {
        getFileArchiveData() {
            this.$refs.fileArchiveSearchComponent.reloadDynamicSearch();
        },
        getArchiveData(args) {
            this.isLoading = true;
            FileArchiveService.listArchiveData(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize
            ).then((response) => {
                if (response) {
                    this.archiveData = response.data.items;
                    this.searchPagination.totalRows =
                        this.archiveData.length > 0
                            ? response.data.total_rows
                            : 0;
                    this.isLoading = false;
                }
            });
            args.done();
        },
        downloadFile(file) {
            FileArchiveService.downloadFile(file.id, file.file_name).then(
                (response) => {
                    if (response) {
                        const blob = new Blob([response.data], {
                            type: response.headers['content-type'],
                        });
                        let fileName = response.headers['content-disposition']
                            .split('filename=')[1]
                            .split(';')[0];
                        fileName = fileName.replaceAll('"', '');
                        saveAs(blob, fileName);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        formatDateOnly
    },
};
</script>
<style scoped>
.pointer:hover {
    opacity: 60%;
}
</style>