<template>
    <b-form-group id="role-select-group" label="Role" label-for="role-select">
        <b-form-select
            id="role-select"
            v-model="selected"
            :disabled="this.disabled"
            :state="state"
        >
                <option></option>
                <option v-for="role in roles" :value="role" :key="role.description">
                {{ role.description.replace("_", " ") }}
                </option>
        </b-form-select>
    </b-form-group>
</template>

<script>

import RolesService from "../services/roles.service"

export default {
    name: "ClaimRoleComponent",
    props: {
        value: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        forceTechRoles: {
            type: Boolean,
            required: false,
        },
        state: {
            type: Boolean,
            required: false,
            default: null
        }
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        security() {
            return this.$store.state.auth.security
        },
        techRoles(){
            if (this.forceTechRoles) {
                return true
            }
            else {
                return this.security.technician
            }
        }
    },
    data() {
        return {
            roles: [],
        }
    },
    mounted() {
        this.getRoles()
    },
    methods: {
        getRoles() {
            RolesService.getRoles(this.techRoles).then((response) => {
                this.roles = response.data
            }).catch(error => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'RolesService.getRoles failed',
                    variant: 'danger',
                    solid: true
                })
            })
        }
    }
}
</script>

<style scoped>
</style>