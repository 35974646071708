import axios from "axios";
import authHeader from "./auth.header";

class IssuesService {

    get() {
        return axios({
            method: 'get',
            url: '/api/events/issues',
            headers: authHeader()
        })
    }
}

export default new IssuesService();