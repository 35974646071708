<template>
    <div>
        <h3>
            <font-awesome-icon icon="magnifying-glass" class="nav-icon fa" />
            Audit
        </h3>
        <DynamicSearchComponent
            ref="auditSearchComponent"
            preferencesName="audit_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <div class="pb-3">
            <Table
                id="auditTable"
                :fields="fields"
                :items="audits"
                sortBy="timestamp"
                :sortDesc="true"
                :searchPagination="searchPagination"
                @getItems="listAudits"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="data">
                    <font-awesome-icon
                        icon="info-circle"
                        class="nav-icon pointer action-btn"
                        @click="showAuditViewDiffModal(data.item)"
                    />
                </template>
                <template #cell(timestamp)="data">
                    {{ formatDate(convertUTCtoLocalDate(data.item.timestamp)) }}
                </template>
            </Table>
        </div>
        <b-modal
            id="audit-view-diff"
            ref="audit-view-diff-ref"
            hide-footer
            size="xl"
        >
            <template #modal-title> Audit - {{ selectedAudit.type }} </template>
            <AuditViewDiff
                :audit="selectedAudit"
                @hide="hideAuditViewDiffModal"
            />
        </b-modal>
    </div>
</template>

<script>
import { Table, DynamicSearchComponent } from '../../components';
import SearchPagination from '../../models/search.pagination';
import { AuditService } from '../../services';
import { formatDate, convertUTCtoLocalDate } from '../../utils/utils';
import AuditViewDiff from './AuditViewDiff.vue';

export default {
    name: 'Audit',
    components: {
        Table,
        DynamicSearchComponent,
        AuditViewDiff,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'info-icon'
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'operation',
                    label: 'Operation',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'changed_by',
                    label: 'Changed By',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'timestamp',
                    label: 'Changed On',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
            ],
            audits: [],
            selectedAudit: {},
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -4,
            searchCriteria: [
                {
                    key: 'audit_user',
                    name: 'User',
                    type: 'Users',
                    searchQueryName: 'userId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'audit_type',
                    name: 'Audit Type',
                    type: 'AuditType',
                    searchQueryName: 'auditTypeId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'audit_operation',
                    name: 'Audit Operation',
                    type: 'AuditOperation',
                    searchQueryName: 'auditOperationId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'date_start',
                    name: 'Start Date',
                    subtype: 'start-date',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
                {
                    key: 'date_end',
                    name: 'End Date',
                    subtype: 'end-date',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
            ],
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
    },
    created() {},
    mounted() {
        this.listAudits();
    },
    methods: {
        listAudits() {
            this.$refs.auditSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            AuditService.list(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.audits = response.data.items;
                        this.searchPagination.totalRows =
                            this.audits.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        showAuditViewDiffModal(audit) {
            this.selectedAudit = audit;
            this.$refs['audit-view-diff-ref'].show();
        },
        hideAuditViewDiffModal() {
            this.$refs['audit-view-diff-ref'].hide();
            this.listAudits();
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.listAudits()
        },
        formatDate,
        convertUTCtoLocalDate
    },
};
</script>
<style scoped>
::v-deep .info-icon {
    vertical-align: middle;
}
</style>