<template>
    <div>
        <b-dropdown split :text="exportOption" v-on:click="exportToFile(selectedExportType)" class="mt-2" variant="outline-primary">
            <b-dropdown-item
                v-for="(option, index) in exportOptions"
                :key="index"
                v-on:click="updateExport(option)"
            >
                {{ option.name }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>
<script>
import { PromosService, UserPreferencesService, } from "../services/"
import { saveAs } from 'file-saver'

export default {
    name: "PromoExportFileComponent",
    props: {
        disabled: {
            type: Boolean,
        },
        promoType: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            selectedExportType: "csv",
            exportOption: "Export redeemed to CSV",
            redeemedOnly: false,
        }
    },
    mounted() {
        this.getFilePreference();        
    },
    computed: {
        exportOptions() {
            return [
                { name: "Export redeemed to CSV", extension: "csv", redeemedOnly: true },
                { name: "Export all to CSV", extension: "csv", redeemedOnly: false },
                { name: "Export redeemed to XLSX", extension: "xlsx", redeemedOnly: true },
                { name: "Export all to XLSX", extension: "xlsx", redeemedOnly: false },
            ]
        }
    },
    methods: {
        updateExport(option) {
            this.selectedExportType = option.extension
            this.exportOption = option.name
            this.redeemedOnly = option.redeemedOnly
            UserPreferencesService.put(
            {
                type: "file_default",
                name: "File Search Preference",
                default: true,
                value: option.extension
            })
        },
        exportToFile(fileType) {
            if (fileType === "csv") {
                PromosService.downloadCsv(
                    this.promoType,
                    this.redeemedOnly,
                ).then((response) => {
                    if (response) {
                        const blob = new Blob([response.data], {type: response.headers['content-type']})
                        saveAs(blob, response.headers['content-disposition'].split('filename=')[1].split(';')[0])
                    }
                },
                (error) => {
                    console.log(error);
                })
            }
            else if (fileType === "xlsx") {
                PromosService.downloadXlsx(
                    this.promoType,
                    this.redeemedOnly,
                ).then((response) => {
                    if (response) {
                        const blob = new Blob([response.data], {type: response.headers['content-type']})
                        saveAs(blob, response.headers['content-disposition'].split('filename=')[1].split(';')[0])
                    }
                },
                (error) => {
                    console.log(error);
                })
            }
        },
        getFilePreference() {
            UserPreferencesService.list("file_default"
            ).then((response) => {
                if(response && response.data.length > 0){
                    this.selectedExportType = response.data[0].value;
                    this.exportOptions.forEach(option => {
                        if(option.extension == response.data[0].value) {
                            this.exportOption = option.name;
                            return;
                        }
                    });
                }
            },
            (error) => {
                console.log(error);
            })
        }
    },
};
</script>
<style scoped>
::v-deep .b-dropdown ul.dropdown-menu {
  max-height: 200px !important;
  overflow-y: auto !important;
  min-width: 100% !important;
  max-width: fit-content;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .b-dropdown > button:first-child {
        width: 60%
    }
}
</style>