<template>
    <div>
        <div>
            <div class="servant-group">
                <div class="servant-container" v-for="(servant, index) in servants" :key="index">
                    <Servant
                    :ref="`servant_${servant.id}`"
                    :servant="servant" 
                    :locked="index != servants.length - 1"
                    @refresh="getServants" 
                    class="mb-4" 
                    :class="(index % 2 == 0) ? 'odd-margin' : 'even-margin'"
                    />
                </div>
            </div>
            <SpinnerNoResults :isLoading="isLoading" :spinnerOnly="true"/>
            <h5 class="center" v-if="!isLoading && servants.length == 0">
                <font-awesome-icon icon="circle-exclamation" class="nav-icon fa"/>
                Kiosk doesn't have any servants
            </h5>
        </div>
    </div>
</template>

<script>

import Servant from "./Servant.vue"
import ServantsService from "../../../services/servants.service"
import { SpinnerNoResults } from "../.."

export default {
    name: 'Servants',
    components: {
        Servant,
        SpinnerNoResults,
    },
    props: {
        kioskId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            servants: []
        }
    },
    computed: {
		security(){
            return this.$store.state.auth.security
        },
    },
    mounted() {
        this.getServants()
    },
    methods: {
        getServants() {
            this.isLoading = true
            ServantsService.getServants(this.kioskId).then((response) => {
                if (response) {
                    this.servants = response.data
                    this.isLoading = false
                }
            })
        },
        hidePairModal() {
            this.$refs["pair-servant"].hide()
            this.getServants()
        },
    }
}
</script>

<style>
.center {
    text-align: center;
}
.servant-group{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.servant-container{
    width: 50%;
}
.even-margin {
    margin-left: 0.5rem
}
.odd-margin {
    margin-right: 0.5rem
}

@media only screen and (max-width: 600px) {
.servant-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.servant-container{
    width: 100%;
}
.even-margin {
    margin-left: 0rem;
}
.odd-margin {
    margin-right: 0rem
}

}
</style>