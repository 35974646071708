<template>
    <div class="table-container">
        <DynamicSearchComponent
            ref="issuesHistorySearchComponent"
            preferencesName="issues_history_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1
                    this.searchPagination.startIndex = 1
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <Table
            id="storeTable"
            :fields="fields"
            :items="events"
            sortBy="start_date"
            :sortDesc="true"
            :searchPagination="searchPagination"
            @getItems="getEventBlocks"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(start_date)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.start_date)) }}
            </template>
            <template #cell(end_date)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.end_date)) }}
            </template>
        </Table>
        <ExportFileComponent
            :arguments="this.arguments"
            class="export"
            :disabled="false"
            page="issuesHistory"
        />
    </div>
</template>

<script>
  
import KioskService from "../../services/kiosk.service"
import SearchPagination from "../../models/search.pagination"
import { ExportFileComponent, Table, DynamicSearchComponent } from "../../components/"
import { formatDate, convertUTCtoLocalDate } from '../../utils/utils'

export default {
    name: 'IssuesHistory',
    props: {
        kioskId: {
            type: String,
            required: true
        }
    },
    components:{
        ExportFileComponent,
        Table,
        DynamicSearchComponent
    },
    data() {
        return {
            events: [],
            loading: false,
            arguments: {},
            fields: [
                {
                    key: 'issue',
                    label: 'Issue',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1

                },
                {
                    key: 'value',
                    label: 'Value',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'servant_number',
                    label: 'Carousel',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'start_date',
                    label: 'Start Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'end_date',
                    label: 'End Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3 
                }
            ],
            searchCriteria: [
                {
                    key: 'issue_type',
                    name: 'Issue Type',
                    type: 'IssueType',
                    searchQueryName: 'issueTypeId',
                },
                {
                    key: 'date_start',
                    name: 'Date Start',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
                {
                    key: 'date_end',
                    name: 'Date End',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                },
            ],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -2
        }
    },
    mounted() {
        this.arguments = { kioskId: this.kioskId }
        this.getEventBlocks()
    },
    methods: {
        getEventBlocks() {
            this.$refs.issuesHistorySearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.arguments = args;
            this.arguments.kioskId = this.kioskId
            KioskService.searchIssuesHistory(
                this.kioskId,
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.events = response.data.items
                        this.searchPagination.totalRows =
                            this.events.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getEventBlocks()
        },
        formatDate,
        convertUTCtoLocalDate,
    }
}
</script>

<style scoped>
::v-deep .export > div {
    margin-top: 0rem !important;
}
@media only screen and (max-width: 575.5px) {
    
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>