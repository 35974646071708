<template>
    <div>
        <b-tabs lazy>
            <b-tab title="Bulk Import">
                <BulkImportUsers @changeComplete="changeComplete"/>
            </b-tab>
            <b-tab title="Manual Import">
                <RegisterUserModal @changeComplete="changeComplete"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

import { RegisterUserModal, BulkImportUsers } from './index'

export default {
    name: 'RegisterUserModalTabs',
    components: {
        RegisterUserModal,
        BulkImportUsers,
    },
    methods: {
        changeComplete() {
            this.$emit('changeComplete')
        }
    }
}
</script>

<style scoped>
</style>