<template>
    <div>
        <td class="inventory-column" v-for='col in this.carousel.columns' :key='col'>
            <div class="bay">
                <img v-if="getBayContents(col) == 0" :src="require('@/assets/kiosk/inventory/unknownTank.png')" />
                <img v-if="getBayContents(col) == 1" :src="require('@/assets/kiosk/inventory/emptyBay.png')" />
                <img v-if="getBayContents(col) == 2" :src="require('@/assets/kiosk/inventory/fullTank.png')" />
                <img v-if="getBayContents(col) == 3" :src="require('@/assets/kiosk/inventory/emptyTank.png')" />
                <div class="bay-no" :class="{ bayopen: getDoorOpen(col) }">{{getBay(col)}}</div>  
                <div v-if="getDoorOpen(col)" class="door-open"></div>              
            </div>
            <img v-if="col == getPos() && getBottomTier(tier)" class="position" :src="require('@/assets/kiosk/position.png')" />
        </td>
    </div>
</template>

<script>
export default {
    name: "CarouselTier",
    props: {
        carousel: {
            type: Object,
            required: true
        },
        tier: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            tierCount: this.carousel.tiers
        }
    },
    methods: {
        getPos() {
            return this.carousel.position + 1
        },
        getDoorOpen(col) {
            if (col != this.getPos()) {
                return false
            }
            for (var i = 0; i < this.carousel.doors.length; ++i) {
                let door = this.carousel.doors[i]
                if (door.tier == this.tier) {
                    return door.open
                }
            }
            return false
        },
        getBottomTier(tier) {
            return tier == this.carousel.tiers
        },
        getBay(column) {
            return (column * this.tierCount) + this.tier - this.tierCount
        },
        getBayContents(column) {
            var bay = this.getBay(column)
            for (var i = 0; i < this.carousel.inventory.length; ++i) {        
                var bayItem = this.carousel.inventory[i]
                if (bayItem.bay == bay) {                    
                    return bayItem.status
                }
            }
            return 0
        }
    }
}
</script>

<style scoped>
td.inventory-column {
    width: 36px;
    height: 55px;
    max-width: 55px;
    max-height: 55px;
    position: relative;
    border-width: 2px;
}

.inventory-column {
    color: black;
    font-weight: bold;
    text-align: center;
}

td.inventory-column img {
    width: 36px;
    height: 55px;
}

td.inventory-column .div {
    width: 36px;
    height: 55px;
    line-height: 55px;
    z-index: 1000;
    position: absolute;
    bottom: 15px;
    top: 0;
    left: 0;
}
.bay {
    position:relative;
	display:inline-block;
}
.bay-no {
   position: absolute; 
   top:15px;bottom:0;left:0; right:0;
   margin:auto; 
}
.bayopen {
   color: black;
}
.position {
    height:11px !important;
}
.door-open {
    background-color: rgba(255, 0, 0, 0.15);
    border-style: solid;
    border-color: red;
    border-width: 2px;
    position: absolute;
    top:0px;bottom:0;left:0; right:0;
}
</style>