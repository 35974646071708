<template>
    <div>
        <CurrentLevelsComponent :servantId="servantId" />
        <CarouselComponent :carousel="carousel" :isHost="false" v-if="carousel" />
        <div v-else>No carousel details available</div>
    </div>
</template>

<script>
import ServantsService from '../../../services/servants.service';
import CurrentLevelsComponent from '../CurrentLevelsComponent';
import CarouselComponent from '../CarouselComponent';

export default {
    name: 'ServantInventoryComponent',
    components: {
        CurrentLevelsComponent,
        CarouselComponent,
    },
    props: {
        servantId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            carousel: {},
        };
    },
    mounted() {
        this.getInventory();
    },
    methods: {
        getInventory() {
            this.isLoading = true;
            ServantsService.carousel(this.servantId).then((response) => {
                if (response) {
                    this.carousel = response.data;
                    this.isLoading = false;
                }
            });
        },
    },
};
</script>

<style scoped>
</style>