<template>
  <div>
    <span v-if="issues.length == 0">{{noResultsMessage}}</span>
    <Table
        id="issuesTable"
        :fields="fields"
        :items="issues"
        sortBy="timestamp"
        :sortDesc="false"
        :searchPagination="searchPagination"
        :hidePagination="true"
        :responsive="false"
        v-else
    >
        <template #cell(start_date)="data">
            {{ formatDate(convertUTCtoLocalDate(data.item.start_date)) }}
        </template>
        <template #cell(end_date)="data" v-if="!active">
            {{ formatDate(convertUTCtoLocalDate(data.item.end_date)) }}
        </template>
    </Table>
  </div>
</template>

<script>
import Table from '../../Table.vue';
import SearchPagination from '../../../models/search.pagination';
import { formatDate, convertUTCtoLocalDate } from '../../../utils/utils'
import ServantsService from "../../../services/servants.service"

export default {
    name: "ServantIssuesComponent",
    components: {
        Table,
    },
    props: {
        servantId: {
            type: Number,
            require: true
        },
        active: {
            type: Boolean,
            require: false,
            default: true
        }
    },
    computed: {
        noResultsMessage() {
            return this.active ? "No issues found " : "No issue history found "
        }
    },
    data() {
        return {
            fields: [
                {
                    key: 'issue',
                    label: 'Issue',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'value',
                    label: 'Value',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'servant_number',
                    label: 'Carousel',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'start_date',
                    label: 'Start Date',
                    tdClass: 'column',
                    sortable: false
                },
            ],
            issues: [],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
        };
    },
    mounted() {
        if (this.active) {
            this.getIssues()
        }
        else {
            this.getIssuesHistory()
        }
    },
    methods: {
        formatDate,
        convertUTCtoLocalDate,
        getIssues() {
            this.isLoading = true
            ServantsService.issues(this.servantId).then(
                (response) => {
                    if (response) {
                        this.issues = response.data
                        this.isLoading = false
                    }
                }
            )
        },
        getIssuesHistory() {
            this.fields.push({
                key: 'end_date',
                label: 'End Date',
                tdClass: 'column',
                sortable: false
            })

            this.isLoading = false
            ServantsService.issuesHistory(this.servantId).then(
                (response) => {
                    if (response) {
                        this.issues = response.data
                        this.isLoading = false
                    }
                }
            )
        }
    }
}
</script>
<style scoped>

</style>