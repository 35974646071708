<template>
    <div>
        <h3>
            <font-awesome-icon icon="terminal" class="nav-icon fa" /> Code
            Activity
        </h3>

        <DynamicSearchComponent
            ref="codeActivitySearchComponent"
            preferencesName="code_activity_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />

        <Table
            id="codeActivityTable"
            class="scollable-table"
            :fields="fields"
            :items="codeActivities"
            :sortDesc="true"
            :searchPagination="searchPagination"
            @getItems="getCodeActivities"
            @sortOrderChanged="sortOrderChanged"
            sortBy="activity_date"
        >
            <template #cell(actions)="row">
                <font-awesome-icon
                    icon="scroll"
                    class="nav-icon pointer action-btn"
                    @click="openHttpDetailsModal(row.item)"
                />
            </template>

            <template #cell(vmid)="data">
                <a
                    href="#"
                    @click="kioskLink(data.item.kiosk_id)"
                    class="underline"
                    >{{ data.item.vmid }}</a
                >
            </template>

            <template #cell(activity_date)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.activity_date)) }}
            </template>
        </Table>

        <CodeActivityHttpModal :items="httpModalItems" />
    </div>
</template>

<script>
import { Table } from '../../components';
import SearchPagination from '../../models/search.pagination';
import { DynamicSearchComponent } from '../../components';
import { CodeActivityService } from '../../services';
import { CodeActivityHttpModal } from '../../components/transactions';
import { formatDate, kioskLink, convertUTCtoLocalDate } from '../../utils/utils';

export default {
    name: 'CodeActivity',
    components: {
        Table,
        DynamicSearchComponent,
        CodeActivityHttpModal,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    sortable: false,
                },
                {
                    key: 'vendor',
                    label: 'Vendor',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1,
                },
                {
                    key: 'activity_date',
                    label: 'Activity Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2,
                },
                {
                    key: 'vmid',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3,
                },
                {
                    key: 'code',
                    label: 'Code',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4,
                },
                {
                    key: 'code_state',
                    label: 'State',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5,
                },
                {
                    key: 'activity_type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6,
                },
                {
                    key: 'http_response_code',
                    label: 'Http Response Code',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7,
                },
            ],
            searchCriteria: [
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                },
                {
                    key: 'code',
                    name: 'Code',
                    type: 'Text',
                    searchQueryName: 'code',
                },
                {
                    key: 'vendor',
                    name: 'Vendor',
                    type: 'CodeActivityVendor',
                    searchQueryName: 'vendor',
                },
                {
                    key: 'code_state',
                    name: 'State',
                    type: 'CodeActivityState',
                    searchQueryName: 'codeState',
                },
                {
                    key: 'activity_type',
                    name: 'Activity Type',
                    type: 'CodeActivityType',
                    searchQueryName: 'activityType',
                },
                {
                    key: 'date_start',
                    name: 'Date Start',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                    required: true,
                },
                {
                    key: 'date_end',
                    name: 'Date End',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                    required: true,
                },
            ],
            codeActivities: [],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -2,
            httpModalItems: {
                request: '',
                response: '',
                responseCode: '',
                responseContent: '',
            },
        };
    },
    mounted() {
        this.getCodeActivities();
    },
    methods: {
        getCodeActivities() {
            this.$refs.codeActivitySearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.isLoading = true;
            CodeActivityService.searchWithQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.codeActivities = response.data.items;
                        this.searchPagination.totalRows =
                            this.codeActivities.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                        args.done();
                    }
                })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder;
            this.getCodeActivities();
        },
        openHttpDetailsModal(item) {
            this.httpModalItems.request = item.request;
            this.httpModalItems.response = item.response;
            this.httpModalItems.responseCode = item.http_response_code;
            this.httpModalItems.responseContent = item.http_response_content;

            this.$bvModal.show('codeactivity-http-modal');
        },
        kioskLink,
        formatDate,
        convertUTCtoLocalDate
    },
};
</script>

<style scoped>
.underline {
    text-decoration: underline;
}
</style>