import axios from "axios";
import authHeader from "./auth.header";

class DriverCodesService {
    list(args, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/drivers/list',
            headers: authHeader(),
            data: {
                user_id: args.userId,
                user_type: args.userTypeId,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    getById(id) {
        return axios({
            method: 'get',
            url: '/api/drivers/getById',
            headers: authHeader(),
            params: {
                id
            }
        })
    }

    getByUserId(userId) {
        return axios({
            method: 'get',
            url: '/api/drivers/getByUserId',
            headers: authHeader(),
            params: {
                userId
            }
        })
    }

    add(name, email, userType, accessLevel) {
        return axios({
            method: 'post',
            url: '/api/drivers/add',
            headers: authHeader(),
            params: {
                name,
                email,
                userType,
                accessLevel
            }
        })
    }

    update(userId, name, email, disabled, userType, accessLevel) {
        return axios({
            method: 'post',
            url: '/api/drivers/update',
            headers: authHeader(),
            params: {
                userId,
                name,
                email,
                disabled,
                userType,
                accessLevel
            }
        })
    }

    getUserTypesForAccessCodes() {
        return axios({
            method: 'get',
            url: '/api/drivers/getUserTypesForAccessCodes',
            headers: authHeader()
        })
    }
}

export default new DriverCodesService();