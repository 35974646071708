<template>
    <div>
        <div class="table-container" :class="{ 'horizontal-scroll': horizontalScroll }">
            <b-table
                :id="id"
                striped
                hover
                :responsive="responsive && isMobile"
                :fields="fields"
                :items="items"
                :per-page="searchPagination.pageSize"
                :current-page="1"
                v-if="!hide"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :stacked="responsive && isMobile ? stacked : false"
                :sticky-header="verticalScroll"
                :busy="busy"
                :no-local-sorting="!localSorting"
                no-border-collapse
                :class="{'sticky-header' : stickyHeader}"
                ref="table"
                @sort-changed="sortOrderChanged"
            >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle" />
                    </div>
                </template>

                <template
                    v-for="(_, slotName) of $scopedSlots"
                    v-slot:[slotName]="scope"
                >
                    <slot :name="slotName" v-bind="scope" />
                </template>
            </b-table>
        </div>
        <Pagination
            :searchPagination="searchPagination"
            :currentNumRows="items.length"
            :tableControl="id"
            @refreshTable="getItems()"
            v-if="!hidePagination"
        />
    </div>
</template>

<script>
import Pagination from '../components/Pagination.vue';

export default {
    name: 'Table',
    components: { Pagination },
    props: {
        id: {
            type: String,
            required: true,
        },
        fields: {
            type: Array,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        sortBy: {
            type: String,
            required: true,
        },
        sortDesc: {
            type: Boolean,
            default: false,
        },
        stacked: {
            type: String,
            default: 'sm',
        },
        searchPagination: {
            type: Object,
            required: true,
        },
        responsive: {
            type: Boolean,
            default: true,
            required: false,
        },
        hidePagination: {
            type: Boolean,
            required: false,
        },
        horizontalScroll: {
            type: Boolean,
            default: false,
            required: false,
        },
        verticalScroll: {
            type: String,
            required: false,
        },
        busy: {
            type: Boolean,
            default: false,
            required: false,
        },
        localSorting: {
            type: Boolean,
            default: false,
            required: false,
        },
        stickyHeader: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.width <= 750
        }
    },
    methods: {
        getItems() {
            this.$emit('getItems');
        },
        sortOrderChanged(sortInfo) {
            var fieldInfo = this.fields.find((field) => field.key === sortInfo.sortBy)
            var sortOrder = fieldInfo.sortOrder ?? 1

            if (sortInfo.sortDesc) {
                sortOrder *= -1
            }

            this.$emit('sortOrderChanged', sortOrder);
        },
        refresh() {
            this.$refs.table.refresh()
        }
    },
};
</script>

<style scoped>
.horizontal-scroll {
    display: block;
    width: 100%;
    overflow-x: auto;
    margin-bottom: 1rem;
}

.table-container {
    margin-top: 30px;
}

::v-deep .column {
    cursor: default;
    vertical-align: middle;
    text-wrap: nowrap;
}

::v-deep .icon-column {
    width: 30px;
    cursor: pointer;
    vertical-align: middle;
}

::v-deep td.icon-column div {
    width: 100% !important;
}

::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
    position: sticky !important;
}

::v-deep .sticky-header > thead > tr > th {
    position: sticky !important;
    top: 0;
    background-color: white;
    z-index: 99;
}

@media only screen and (max-width: 750px) {
    ::v-deep .icon-column {
        width: 100%;
    }
}
</style>