<template>
    <div>
        <div class="ml-2 italics" v-if="linkButtons">
            <span class="pointer" @click="collapseAll()">Collapse All</span> | <span class="pointer" @click="expandAll()">Expand All</span>
        </div>
        <div class="ml-2" v-else>
            <b-button class="mr-2" variant="primary" @click="collapseAll()">Collapse All</b-button>
            <b-button variant="primary" @click="expandAll()">Expand All</b-button>
        </div>
        <hr/>
        <div class="mt-2">
            <TreeViewNodeComponent
                v-for="node in parents"
                :key="node.id"
                :ref="'node-' + node.id"
                :node="node"
                :defaultCollapse="defaultCollapse"
                :nodeName="nodeName"
                :childrenName="childrenName"
                :canRead='canRead'
                :canWrite='canWrite'
                :canCreate='canCreate'
                :canDelete='canDelete'
                @editNode="editNode"
                @addChild="addChild"
                @deleteNode="deleteNode"
            />
        </div>
        <hr/>
        <div v-if="showAddRoot && canCreate">
            <div v-if="linkButtons" class="ml-2 mt-2 pointer">
                <font-awesome-icon icon="plus"/>
                <span @click="addRoot()" class="ml-2 italics">{{addRootName}}</span>
            </div>
            <div v-else class="ml-2 mt-2 pointer">
                <b-button variant="primary" @click="addRoot()">
                    <font-awesome-icon icon="plus"/>
                    {{addRootName}}
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import TreeViewNodeComponent from './TreeViewNodeComponent.vue';

export default {
    name: "TreeViewComponent",
    components: {
        TreeViewNodeComponent
    },
     props: {
        parents: {
            type: Array,
            required: true
        },
        defaultCollapse: {
            type: Boolean,
            default: true
        },
        nodeName: {
            type: String,
            required: true
        },
        childrenName: {
            type: String,
            required: true
        },
        canRead: {
            type: Boolean,
            required: true
        },
        canWrite: {
            type: Boolean,
            required: true
        },
        canCreate: {
            type: Boolean,
            required: true
        },
        canDelete: {
            type: Boolean,
            required: true
        },
        showAddRoot: {
            type: Boolean,
            default: false
        },
        addRootName: {
            type: String,
            default: "Add Root"
        },
        linkButtons: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        }
    },
    computed: {
    },
    mounted() {
    },
    methods: {
        collapseAll() {
            this.parents.forEach(node => {
                var reference = this.$refs['node-' + node.id]

                if (reference && reference.length > 0) {
                    reference[0].collapseAll()
                }
            })
        },
        expandAll() {
            this.parents.forEach(node => {
                var reference = this.$refs['node-' + node.id]

                if (reference && reference.length > 0) {
                    reference[0].expandAll()
                }
            })
        },
        editNode(value) {
            this.$emit("editNode", value)
        },
        addChild(value) {
            this.$emit("addChild", value)
        },
        deleteNode(value) {
            this.$emit("deleteNode", value)
        },
        addRoot(value) {
            this.$emit("addRoot", value)
        },
    }
}
</script>
<style scoped>
.italics {
    font-style: italic;
}
.pointer:hover {
    opacity: 60%;
}
</style>