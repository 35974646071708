<template>
    <div>
        <h3>
            <font-awesome-icon icon="store" class="nav-icon fa" /> Stores
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf1d')" class="help-icon nav-icon fa" title="Sleegers User Guide - Stores"/>
        </h3>
        <div class="button-group" v-if="security.storeCreate">
            <div class="button-col">
                <b-button
                    @click="openAddModal()"
                    variant="primary"
                    class="button"
                    >
                    Add Stores
                </b-button>
            </div>    
            <div class="button-col">
                <b-button
                    @click="openAddQrCodeModal()"
                    variant="primary"
                    class="button button-index"
                    >
                    Add QR Codes
                </b-button>
                <b-button
                    @click="openAddBulkStoreTaxModal()"
                    variant="primary"
                    class="button button-index"
                    >
                    Add Bulk Store Tax
                </b-button>
            </div>
        </div>
        <DynamicSearchComponent
            ref="storeSearchComponent"
            preferencesName="store_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <Table
            id="storeTable"
            :fields="dynamicFields"
            :items="stores"
            sortBy="description"
            :sortDesc="false"
            :searchPagination="searchPagination"
            :horizontalScroll="true"
            @getItems="getStores"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(actions)="row">
                <div class="icons">
                    <font-awesome-icon
                        v-if="security.storeWrite"
                        icon="pen-to-square"
                        class="nav-icon pointer mx-2"
                        @click="openUpdateModal(row.item)"
                        title="Edit"
                    />
                    <font-awesome-icon
                        v-if="security.storeWrite"
                        icon="percent"
                        class="nav-icon pointer mx-2"
                        @click="openUpdateTaxModal(row.item)"
                        title="Tax"
                    />
                    <font-awesome-icon
                        v-if="security.storeWrite"
                        icon="gear"
                        class="nav-icon pointer mx-2"
                        @click="openConfigModal(row.item)"
                        title="Configuration"
                    />
                    <font-awesome-icon
                        v-if="security.storeDelete"
                        icon="trash-can"
                        class="nav-icon pointer mx-2"
                        @click="openDeleteModal(row.item)"
                        title="Delete"
                    />
                    <font-awesome-icon
                        v-if="row.item.qr_code_url != null"
                        icon="qrcode"
                        class="nav-icon pointer mx-2"
                        @click="openQrCodeModal(row.item)"
                        title="QR Code"
                    />
                </div>
            </template>
            <template #cell(region)="data">
                {{ data.item.region.description }}
            </template>
        </Table>

        <ExportFileComponent
            :arguments="this.arguments.query"
            :disabled="false"
            page="stores"
            class="export"
        />

        <b-modal id="stores-add-modal" size="lg" ref="stores-add-modal" hide-footer>
            <template #modal-title>Add Store</template>
            <AddStoreTabs @hide="hideAddModal" />
        </b-modal>
        <b-modal id="stores-update-modal" size="lg" ref="stores-update-modal" hide-footer>
            <template #modal-title>Update Store</template>
            <UpdateStoreModal :store="selectedStore" @hide="hideUpdateModal" />
        </b-modal>
        <b-modal id="stores-tax-update-modal" size="lg" ref="stores-tax-update-modal" hide-footer>
            <template #modal-title>Update Tax</template>
            <UpdateTaxModal :store="selectedStore" @hide="hideUpdateTaxModal" />
        </b-modal>
        <b-modal id="stores-config-modal" size="lg" ref="stores-config-modal" hide-footer>
            <template #modal-title>Update Configurations</template>
            <ConfigStoreModal
                :storeId="selectedStore.id"
                @hide="hideConfigModal"
            />
        </b-modal>
        <DeleteStoreModal :storeId="selectedStore.id" @hide="hideDeleteModal" />
        <QRCodeStoreModal :storeUrl="selectedStore.qr_code_url" />
        <b-modal id="stores-add-qr-code-modal" size="lg" ref="stores-add-qr-code-modal" hide-footer >
            <template #modal-title>Add QR Codes</template>
            <AddQRCodeTabs @hide="hideAddQrCodeModal" />
        </b-modal>
        <b-modal id="stores-add-bulk-tax-modal" size="lg" ref="stores-add-bulk-tax-modal" hide-footer>
            <template #modal-title>Upload Store Tax</template>
            <AddBulkStoreTaxComponent
                @hide="hideBulkTaxModal"
                :arguments="this.arguments.query"
            />
        </b-modal>
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import { Table, ExportFileComponent } from '../../components';
import { DynamicSearchComponent } from '../../components';
import {
    AddStoreTabs,
    UpdateStoreModal,
    ConfigStoreModal,
    DeleteStoreModal,
    QRCodeStoreModal,
    AddQRCodeTabs,
    AddBulkStoreTaxComponent,
    UpdateTaxModal,
} from '../../components/stores/';
import { StoresService, CustomFieldsService } from '../../services';
import CUSTOM_FIELD_TYPE_ENUM from '../../models/CustomFieldTypeEnum';
import { openHelpWindow } from '../../utils/utils'


export default {
    name: 'Stores',
    components: {
        Table,
        DynamicSearchComponent,
        ExportFileComponent,
        AddStoreTabs,
        UpdateStoreModal,
        ConfigStoreModal,
        DeleteStoreModal,
        QRCodeStoreModal,
        AddQRCodeTabs,
        AddBulkStoreTaxComponent,
        UpdateTaxModal,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1,
                },
                {
                    key: 'number',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2,
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'province.abbr',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'postal_code',
                    label: this.getPostalCodeString(),
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'region',
                    label: 'Region',
                },
                {
                    key: 'ship_to',
                    label: 'Details',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'tax_value',
                    label: 'Current Tax Percent (%)',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'description',
                    name: 'Description',
                    type: 'Text',
                    searchQueryName: 'description',
                },
                {
                    key: 'store_number',
                    name: 'Store Number',
                    type: 'Text',
                    searchQueryName: 'storeNumber',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
            ],
            stores: [],
            arguments: [],
            claims: [],
            security: this.$store.state.auth.security,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: 1,
            show: true,
            selectedStore: { type: Object },
            customColumns: [],
        };
    },
    computed: {
        dynamicFields() {
            return this.fields.concat(this.customColumns);
        }
    },
    mounted() {
        if (this.security.customFieldsRead) {
            this.getCustomColumns();
        }
    },
    methods: {
        openHelpWindow,
        getStores() {
            this.$refs.storeSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.arguments = args;
            StoresService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.stores = response.data.items;
                        this.searchPagination.totalRows =
                            this.stores.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder;
            this.getStores();
        },
        openAddModal() {
            this.$bvModal.show('stores-add-modal');
        },
        hideAddModal() {
            this.$bvModal.hide('stores-add-modal');
            this.getStores();
        },
        openUpdateModal(item) {
            this.selectedStore = item;
            this.$bvModal.show('stores-update-modal');
        },
        hideUpdateModal() {
            this.$bvModal.hide('stores-update-modal');
            this.getStores();
        },
        openUpdateTaxModal(item) {
            this.selectedStore = item;
            this.$bvModal.show('stores-tax-update-modal');
        },
        hideUpdateTaxModal() {
            this.$bvModal.hide('stores-tax-update-modal');
            this.getStores();
        },
        openConfigModal(item) {
            this.selectedStore = item;
            this.$bvModal.show('stores-config-modal');
        },
        hideConfigModal() {
            this.$bvModal.hide('stores-config-modal');
            this.getStores();
        },
        openDeleteModal(item) {
            this.selectedStore = item;
            this.$bvModal.show('stores-delete-modal');
        },
        hideDeleteModal() {
            this.$bvModal.hide('stores-delete-modal');
            this.getStores();
        },
        openQrCodeModal(item) {
            this.selectedStore = item;
            this.$bvModal.show('stores-qr-code-modal');
        },
        hideQrCodeModal() {
            this.$bvModal.hide('stores-qr-code-modal');
            this.getStores();
        },
        openAddQrCodeModal() {
            this.$bvModal.show('stores-add-qr-code-modal');
        },
        openAddBulkStoreTaxModal() {
            this.$bvModal.show('stores-add-bulk-tax-modal');
        },
        hideBulkTaxModal() {
            this.$bvModal.hide('stores-add-bulk-tax-modal');
            this.getStores();
        },
        hideAddQrCodeModal() {
            this.$bvModal.hide('stores-add-qr-code-modal');
            this.getStores();
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
        getPostalCodeString(){
            return this.$store.state.auth.portal_configuration?.postalcode ?? "Postal Code";
        },
        getCustomColumns() {
            CustomFieldsService.getByType(CUSTOM_FIELD_TYPE_ENUM.Store).then(
                (response) => {
                    if (response) {
                        var cols = response.data;

                        cols.forEach((col) => {
                            this.customColumns.push({
                                key: 'custom_values.' + col.field_name,
                                label: col.field_name,
                                tdClass: 'column',
                                sortable: false,
                            });
                        });
                    }
                }
            );
        },
    },
};
</script>

<style scoped>
::v-deep .action-column {
    min-width: 150px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 0.5rem;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

br {
    display: none;
}

@media only screen and (max-width: 575.5px) {
    .icons {
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button-col > .button-index {
        width: 100%;
    }

    .button-col > .button-index:first-child {
        margin-right: 0.5rem;
    }

    .button-col > .button-index:last-child {
        margin-left: 0.5rem;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }

    br {
        display: block;
    }
}
</style>