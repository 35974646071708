<template>
        <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="mfg-checkbox-group"
                class="chk-lbl"
                v-model="getSelectedCheckboxes"
                :aria-describedby="ariaDescribedby"
                name="checkbox"
                switches
            >
                <div v-for="(checkbox, index) in allCheckboxes" :key="checkbox.id" class="row row-cols-1 row-cols-md-2" name="checkboxes">
                    <div class="col mt-3">
                        <b-form-checkbox
                            :value="checkbox"
                            :id="'checkboxgroup-checkbox-' + index"
                            :name="'checkboxgroup-checkbox-' + index"
                        >
                            {{ checkbox.description }}
                        </b-form-checkbox>
                    </div>
                    <div class="col mt-3">
                        <div v-if="checkbox.options == null">
                            <b-form-input
                            :id="'checkbox_input-' + index"
                            :name="'checkbox_input-' + index"
                            :value="checkbox.value"
                            v-model="checkbox.value"
                            :disabled="!checkbox.isOveridden"
                            ></b-form-input>
                        </div>
                        <div v-else-if="checkbox.options.length > 0">
                            <b-form-select
                                :id="'checkbox-dropdown-' + index"
                                :name="'checkbox-dropdown-' + index"
                                v-model="checkbox.value"
                                :disabled="!checkbox.isOveridden"
                            >
                                <option v-for="option in checkbox.options" :value="option.item1" :key="option.item1">
                                    {{ option.item2 }}
                                </option>
                            </b-form-select>
                        </div>
                    </div>
                </div>
            </b-form-checkbox-group>
        </b-form-group>
</template>
<script>
export default {
    name: "ProductCheckboxGroupComponent",
    props: {
        allCheckboxes: {
            type: Array,
            required: true,
        },
        selectedCheckboxes: {
            type: Array,
            required: true,
        },
    },
    computed: {
        getSelectedCheckboxes: {
            get() {
                return this.selectedCheckboxes;
            },
            set(value) {
                this.$emit("setSelectedCheckboxes", value);
            },
        },
    }
};
</script>
<style scoped></style>