<template>
    <b-form-group id="transaction-type-select-group" label="Trans. Type" label-for="transaction-type">
        <b-form-select 
        id="transaction-type" 
        v-model="selected" 
        :disabled="this.disabled" 
        :state="state">
            <option :value="0" disabled>Select a transaction type</option>
            <option v-for="type in transactionTypes" :value="type.id" :key="type.id">
                {{ type.name }}
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import { TransactionsService } from '../services'

export default {
    name: "TransactionTypeSelect",
    props: {
        value: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        state: {
            type: Boolean,
            required: false
        },
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            transactionTypes: [],
            validTypes: [ "Purchase", "Exchange", "Purchase and Exchange" ]
        }
    },
    mounted() {
      this.getTypes()  
    },
    methods: {
        getTypes() {
            TransactionsService.getTransactionTypes()
                .then((response) => {
                    this.transactionTypes = response.data.filter(type => this.validTypes.includes(type.name))
                    this.transactionTypes.sort((a, b) => a.id - b.id)
                })
                .catch((error) => {
                    this.showErrorToast(
                        error,
                        'TransactionsService.getTransactionTypes failed'
                    );
                });
        }
    }
}
</script>
<style scoped>
</style>