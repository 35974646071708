<template>
    <div>
         <div class="row row-cols-1 g-4">
            <div class="col">
                <b-form-group id="user-type-select-group" label="User Type" label-for="user-type-select">
                    <b-form-select
                        id="user-type-select"
                        v-model="driverCode.userType"
                        :disabled="false">
                            <option v-for="userType in userTypes" :value="userType.id" :key="userType.id">
                            {{ userType.name }}
                            </option>
                    </b-form-select>
                </b-form-group>
                <b-form-group id="name-group" label="Name" label-for="name-input">
                    <b-form-input
                        id="name-input"
                        v-model="driverCode.name"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="email-group" label="Email" label-for="email-input">
                    <b-form-input
                        id="email-input"
                        v-model="driverCode.email"
                        required
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
        <b-button @click="addDriverCode" variant="outline-primary">Add</b-button>
    </div>
</template>

<script>
import DriverCodesService from "../../../services/drivercodes.service"

export default {
    name: "DriverCodeAdd",
    components: {
    },
    data() {
        return {
            driverCode: {
                userType: 0,
                name: "",
                email: ""
            },
            userTypes: []
        }
    },
    mounted() {
        this.getUserTypes()
    },
    methods: {
        getUserTypes() {
            DriverCodesService.getUserTypesForAccessCodes().then(
                (response)=> {
                    if (response) {
                        this.userTypes = response.data
                    }
                }
            )
        },
        addDriverCode() {
            DriverCodesService.add(this.driverCode.name, this.driverCode.email, this.driverCode.userType).then(
                (response)=> {
                    if(response.data.success) {
                        this.$emit("hide");
                    }
                    else {
                        this.$root.$bvToast.toast('Failed to add driver code', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true
                        })
                    }
                }
            );
        }
    }
}
</script>
<style scoped>

</style>