<template>
    <div>
        <LocationSelectComponent v-model="transactionCode.location" :state="locationState"/>
        <b-form-group id="expires-input-group" label="Expires" label-for="expires">
            <b-form-checkbox v-model="hideExpires" name="toggle-override" size="md" switch>
                Don't expire
            </b-form-checkbox>
        </b-form-group>
        <b-form-group id="expires-date-group" v-if="!hideExpires">
            <b-input-group>
                <b-form-input
                    id="expires-date-input"
                    v-model="transactionCode.expires"
                    placeholder="YYYY-MM-DD"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                    autocomplete="off"
                    :state="expiresState"
                    :disabled="true"
                    >
                </b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="expires-date"
                        v-model="transactionCode.expires"
                        button-only
                        right
                        :min="minDate" 
                        :max="maxDate"
                    >
                    </b-form-datepicker>
                </b-input-group-append>
            </b-input-group>   
        </b-form-group>
        <div v-if="expiresState == false" class="error">
            Expiry Date is required
        </div>
        <ProductsComponent v-model="transactionCode.productId" :hideOptionAll="true" :state="productIdState"/>
        <div v-if="productIdState == false" class="error">
            Product is required
        </div>
        <TransactionTypeSelectComponent v-model="transactionCode.transactionTypeId" :state="transactionTypeIdState"/>
        <div v-if="transactionTypeIdState == false" class="error">
            Transaction Type is required
        </div>
        <b-form-group id="quantity-input-group" label="Quantity" label-for="quantity">
            <b-form-spinbutton 
                id="quantity-input" 
                v-model="transactionCode.quantity" 
                inline
                :state="quantityState"
                >
            </b-form-spinbutton>
        </b-form-group>
        <div v-if="quantityState == false" class="error">
            Quantity must be greater than 0
        </div>
        <b-button @click="generateTransactionCode" variant="primary" class="right">Add</b-button>
    </div>
</template>
<script>

import PromosService from "../../services/promos.service"
import { ProductsComponent, TransactionTypeSelectComponent, LocationSelectComponent } from "../../components"

export default {
    name: "AddTransactionCode",
    components: {
        ProductsComponent,
        TransactionTypeSelectComponent, 
        LocationSelectComponent
    },
    data() {
        return {
            transactionCode:{
                location: {},
                expires: "",
                productId: 0,
                transactionTypeId: 0,
                quantity: 1
            },
            minDate: {},
            maxDate: {},
            checkValid: false, 
            hideExpires: true               
        };
    },
    computed: {
        locationState() {
            if (!this.checkValid) return null
            return Object.keys(this.transactionCode.location).length != 0
        },
        expiresState() {
            if (!this.checkValid) return null
            return this.transactionCode.expires != ""
        },
        productIdState() {
            if (!this.checkValid) return null
            return this.transactionCode.productId > 0
        },
        transactionTypeIdState() {
            if (!this.checkValid) return null
            return this.transactionCode.transactionTypeId > 0
        },
        quantityState() {
            if (!this.checkValid) return null
            return  this.transactionCode.quantity > 0
        },
        isFormValid() {
            if (!this.hideExpires) { 
                return this.locationState && this.expiresState && this.productIdState && this.transactionTypeIdState && this.quantityState
            }
            else {
                return this.locationState && this.productIdState && this.transactionTypeIdState && this.quantityState
            }
        }
    },
    created() {
        this.setUpDates();
    },
    methods: {
        setUpDates() {
            const now = new Date()

            this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            this.maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())

            var monthFromNow = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate())
            var year = monthFromNow.toLocaleString("default", { year: "numeric" });
            var month = monthFromNow.toLocaleString("default", { month: "2-digit" });
            var day = monthFromNow.toLocaleString("default", { day: "2-digit" });

            this.transactionCode.expires = `${year}-${month}-${day}`
        },
        generateTransactionCode() {
            this.checkValid = true
            if (this.isFormValid) {
                PromosService.generateTransactionCode(this.transactionCode).then((response) => {
                    if (response) {   
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.$emit("hide")
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>