<template>
    <div>
        <b-modal @submit.prevent="reloadUpdateProduct" @hide="$emit('changeComplete')" id="product-update-modal">
            <template #modal-title>
                <h3>Update Product</h3>
            </template>
            <div class="row row-cols-1"> 
                <div class="col">
                    <b-form-group id="description-input-group" label="Description" label-for="description">
                        <b-form-input 
                            id="description"
                            v-model="internalProductDetails.description"
                            :state="descriptionState"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="receipt-message-input-group" label="Receipt Message" label-for="receiptMessage">
                        <b-form-textarea
                            id="receiptMessage"
                            v-model="internalProductDetails.receipt_message"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="email-message-input-group" label="Email Message" label-for="emailMessage">
                        <b-form-textarea
                            id="emailMessage"
                            v-model="internalProductDetails.email_message"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <b-form-checkbox
                id="effective-date-time-input" 
                class="ml-3"
                v-model="flagEffectiveDateRange">
                    Message Effective Date Range
                </b-form-checkbox>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group v-if="flagEffectiveDateRange">
                        <label class="mt-3" for="example-datepicker">Message Effective Start Date</label>
                        <b-form-datepicker
                        id="message_effective_start_date"
                        v-model="internalProductDetails.message_start_date"
                        ></b-form-datepicker>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group v-if="flagEffectiveDateRange">
                        <label class="mt-3" for="example-datepicker">Message Effective End Date</label>
                        <b-form-datepicker
                        id="message_effective_end_date"
                        v-model="internalProductDetails.message_end_date"
                        ></b-form-datepicker>
                    </b-form-group>
                </div> 
            </div>
            <div class="row row-cols-1"> 
                <div class="col">
                    <b-form-group id="receipt-heading-input-group" label="Receipt Heading" label-for="receiptHeading">
                        <b-form-input 
                            id="receiptHeading"
                            v-model="internalProductDetails.receipt_heading"
                            :state="receiptHeadingState"
                            required
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="receipt-message-one-input-group" label="Receipt Message One" label-for="receiptMessageOne">
                        <b-form-textarea
                            id="receiptMessageOne"
                            v-model="internalProductDetails.receipt_primary_message"
                            :state="receiptPrimaryMessageState"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="receipt-message-two-input-group" label="Receipt Message Two" label-for="receiptMessageTwo">
                        <b-form-textarea
                            id="receiptMessageTwo"
                            v-model="internalProductDetails.receipt_secondary_message"
                            :state="receiptSecondaryMessageState"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="billing-location-input-group" label="Billing Location" label-for="billingLocation">
                        <b-form-textarea
                            id="billingLocation"
                            v-model="internalProductDetails.billing_location"
                            :state="billingLocationState"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group id="receipt-phone-input-group" label="Receipt Phone #" label-for="receiptPhone">
                        <b-form-input
                        id="receiptPhone"
                        v-model="internalProductDetails.receipt_phone_number"
                        :state="receiptPhoneState"
                        required
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col" v-if="showGSTReg">
                    <b-form-group id="gst-input-group" label="GST/HST Reg" label-for="gstReg">
                        <b-form-input
                        id="gstReg"
                        v-model="internalProductDetails.gst_reg"
                        ></b-form-input>
                    </b-form-group>
                </div> 
            </div>
            <CustomFieldsForm ref="customFieldsForm" :typeId="CUSTOM_FIELD_TYPE_ENUM.Product" :typeKeyId="internalProductDetails.id"/>
            <template #modal-footer>
                <a id="product-modal-update-product" @click="update()" class="btn btn-primary">Update</a>
            </template>
        </b-modal>
    </div>
</template>

<script>

import ProductsService from "../../../services/products.service"
import CustomFieldsForm from "../../../views/customfields/CustomFieldsForm.vue" 
import CUSTOM_FIELD_TYPE_ENUM from "../../../models/CustomFieldTypeEnum"

export default {
    name: "UpdateProductModal",
    components: {
        CustomFieldsForm
    },
    data() {
        return {
            isLoading: false,
            flagEffectiveDateRange: false,
            checkValid: false,
            phoneValid: null,
            CUSTOM_FIELD_TYPE_ENUM,
            internalProductDetails: {}
        }
    },
    props: {
        productDetails: {
            type: Object,
            required: true,
        },
        billingLocationLength: {
            type: Number,
            required: true
        }
    },
    watch: {
        productDetails() {
            this.internalProductDetails =  Object.assign({}, this.productDetails)

            this.flagEffectiveDateRange = (this.internalProductDetails.message_start_date != null || this.internalProductDetails.message_end_date != null)
        },
        flagEffectiveDateRange() {
            if (!this.flagEffectiveDateRange) {
                this.internalProductDetails.message_start_date = null
                this.internalProductDetails.message_end_date = null
            }
        }
    },
    emits: ['changeComplete'],
    computed: {
        descriptionState() {
            if(!this.checkValid) return null
            return this.internalProductDetails.description == '' ? false : true
        },
        receiptHeadingState() {
            if(!this.checkValid) return null
            return this.internalProductDetails.receipt_heading == ''? false : true
        },
        receiptPrimaryMessageState() {
            if(!this.checkValid) return null
            return this.internalProductDetails.receipt_primary_message == ''? false : true
        },
        receiptSecondaryMessageState() {
            if(!this.checkValid) return null
            return this.internalProductDetails.receipt_secondary_message == ''? false : true
        },
        receiptPhoneState() {
            if(!this.checkValid) return null
            return (this.internalProductDetails.receipt_phone_number == '' ? false : true) || this.phoneValid == true
        },
        billingLocationState() {
            if (!this.checkValid) return null
            if (this.internalProductDetails.billing_location === null) return true
            return (this.internalProductDetails.billing_location.length < this.billingLocationLength)
        },
        isFormValid() {
            return this.descriptionState && this.receiptHeadingState && this.receiptPrimaryMessageState 
                && this.receiptSecondaryMessageState && this.receiptPhoneState && this.billingLocationState
        }
    },
    methods: {
        reloadUpdateProduct(){
            this.$emit('reloadUpdateProduct');
        },
        update() { 
            this.phoneValid = this.validatePhone();
            if(!this.phoneValid) return;  
            this.phoneValid = null; 

            this.checkValid = true

            if (this.isFormValid && this.$refs.customFieldsForm.isFormValid()) {            
                this.internalProductDetails.custom_field_values = this.$refs.customFieldsForm.getValues()

                ProductsService.update(this.internalProductDetails).then((response) => {
                    if (response) {
                        this.isLoading = false;
                        if(response.data.success){
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Successful',
                                variant: 'success',
                                solid: true
                            })
                            this.checkValid = false;
                            this.$emit('changeComplete');
                            this.phoneValid = null;
                        }
                        else {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                                solid: true
                            })
                        }
                        this.checkValid = true
                    }
                });
            }
        },
        validatePhone() {
            if(this.internalProductDetails.receipt_phone_number == '') return true;
            const regex = new  RegExp('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$');
            if(!regex.test(this.internalProductDetails.receipt_phone_number)) {
                this.$root.$bvToast.toast('Please provide a valid phone format', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
                return false;
            }
            return true;
        },
        showGSTReg(){
            this.$store.state.auth.portal_configuration?.showgstreg == "1"
        }
    }
}
</script>

<style scoped>
.new-search {
    text-align: right;
    margin-right: 12px;
    position: relative;
    top: 38px;    
}
</style>