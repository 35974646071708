<template>
    <div>
        <div class="mt-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-2">
                        Applicable Product
                    </div>
                    <b-form-select
                        :state="productState"
                        v-model="manualSelectedProduct.id"
                        class="mb-3"
                    >
                        <option
                            v-for="prod in products"
                            :value="prod.id"
                            :key="prod.id"
                        >
                            {{ prod.description }}
                        </option>
                    </b-form-select>
                    <div v-if="productState == false" class="error">
                        Product is required
                    </div>
                </div>
                <div class="col-md-6">
                    <b-form-group
                            id="number-input-group"
                            label="Store Number"
                            label-for="number"
                        >
                        <b-form-input
                            id="number"
                            :state="numberState"
                            v-model="manualAddModel.number"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="numberState == false" class="error">
                        Store Number is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <b-form-group
                        id="url-input-group"
                        label="URL"
                        label-for="url"
                    >
                        <b-form-input
                            id="url"
                            :state="urlState"
                            v-model="manualAddModel.url"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="urlState == false" class="error">
                        URL is required
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div
                    v-for="(error, index) in qrcodeErrors"
                    v-bind:key="index"
                >
                    <div style="color: red">{{ error }}</div>
                </div>
                <div style="color: green" v-if="success">
                    QR Code CSV imported successfully
                </div>
            </div>
            <b-button @click="updateManual" variant="primary" class="right mb-1">
                Add
            </b-button>
        </div>
    </div>
</template>

<script>
import { StoresService, ProductsService } from '../../services';

export default {
    name: 'AddQRCodeComponent',
    data() {
        return {
            qrcodeErrors: [],
            success: false,
            manualAddModel: {
                number: null,
                url: null,
            },
            checkValid: false,
            products: [],
            manualSelectedProduct: {
                id: null,
            }
        };
    },
    computed: {
        productState() {
            if (!this.checkValid) return null;
            return this.manualSelectedProduct.id ? true : false;
        },
        numberState() {
            if (!this.checkValid) return null;
            return this.manualAddModel.number ? true : false;
        },
        urlState() {
            if (!this.checkValid) return null;
            return this.manualAddModel.url ? true : false;
        },
        isFormValid() {
            return this.productState && this.numberState && this.urlState
        }
    },
    mounted() {
        this.getProducts();
    },
    methods: {
        refresh() {
            this.checkValid = false
            this.qrcodeErrors = []
            this.manualAddModel = {
                number: null,
                url: null
            }
            this.manualSelectedProduct = {
                id: null
            }
        },
        updateManual() {
            this.checkValid = true
            if (this.isFormValid) {
                StoresService.updateUrl(
                    this.manualAddModel.number,
                    this.manualAddModel.url,
                    this.manualSelectedProduct.id
                )
                .then((response) => {
                    this.qrcodeErrors = response.data.errors;
                    this.success = response.data.success;

                    if (this.success) {
                        this.manualAddModel = {
                            number: null,
                            url: null,
                        };
                        this.manualSelectedProduct = {};
                        this.checkValid = false;

                        this.$root.$bvToast.toast('QR Code Added', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                        this.$emit('hide')

                    } else {
                        this.$root.$bvToast.toast('Check Error Messages', {
                            title: 'QR Codes Not Added',
                            variant: 'danger',
                            solid: true,
                        });
                        this.checkValid = true;
                    }
                })
                .catch(() => {
                    this.$root.$bvToast.toast('An Error has Occurred', {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                    this.checkValid = true;
                });
            }
        },
        getProducts() {
            ProductsService.get()
                .then((response) => {
                    this.products = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'ProductsService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>