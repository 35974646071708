function formatDate(date) {
    return new Date(Date.parse(date)).toLocaleString();
}

function formatDateOnly(date) {
    return new Date(Date.parse(date)).toLocaleDateString({ weekday: "short", year: "numeric", month: "2-digit", day: "numeric" });
}

function convertUTCtoLocalDate(date) {
    date = new Date(Date.parse(date))
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

function addTimezoneInformation(date){

    if (date === undefined)
        return undefined;

    date = new Date(Date.parse(date));

    const offset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(offset) / 60);
    const offsetMinutes = Math.abs(offset) % 60;

    const timezoneSign = offset >= 0 ? '+' : '-';
    const timezoneString = `${timezoneSign}${String(offsetHours).padStart(2, '0')}${String(offsetMinutes).padStart(2, '0')}`;

    const dateString = date.toISOString().split('Z')[0];

    return `${dateString}${timezoneString}`;
}

function formatPrice(price) {
    price = +price
    return price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

function getDateOnly(date) {
    date = new Date(date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    return `${year}-${month}-${day}`
}

function getTimeOnly(date) {
    date = new Date(date);
    var hours = date.getHours()
    var minutes = date.getMinutes()
    var seconds = date.getSeconds()

    return `${hours}:${minutes}:${seconds}`
}

function getTimeOnly12Hours(date) {
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

function generateDynamicFields(fields, columnName, hideColumn) {
    return fields.map(field => {
        if (field.key === columnName) {
            return hideColumn ? { ...field, thClass: 'd-none', tdClass: 'd-none' } : field
        }
        return field
    })
}

function generateFieldsByRole(fields, excludedColumns) {
    return fields.map(field => {
        if (excludedColumns.includes(field.columnId)) {
            return { ...field, thClass: 'd-none', tdClass: 'd-none' }
        }
        return field
    })
}

function getElapsedReadableTime(ms) {
    var d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = Math.trunc(s % 60);
    h = Math.floor(m / 60);
    m = Math.trunc(m % 60);
    d = Math.floor(h / 24);
    h = Math.trunc(h % 24);

    if (d) {
        return d + " days, " + h + " hours, " + m + " minutes, " + s + " seconds ago"
    }
    if (h) {
        return h + " hours, " + m + " minutes, " + s + " seconds ago"
    }
    return m + " minutes, " + s + " seconds ago"
}

function isDateTimeMin(date) {
    if (date === '0001-01-01T00:00:00') {
        return true
    }
    return false
}

function cleanVmid(vmid) {
    return vmid.replace(/[^0-9]/g, '')
}

function kioskLink(kioskId) {
    var portalId = this.$store.state.auth.portal.id;
    var routeData = this.$router.resolve({
        path: '/kiosk/',
        query: { kiosk_id: kioskId, portal_id: portalId },
    });
    window.open(routeData.href, 'kiosk_' + kioskId);
}

function servantLink(servantId) {
    var routeData = this.$router.resolve({
        path: '/servant/',
        query: { servant_id: servantId },
    });
    window.open(routeData.href, 'servant_' + servantId);
}

function transactionsVmidLink(dateStart, vmid) {
    var routeData = this.$router.resolve({
        path: '/transactions/',
        query: {
            dateStart: dateStart ? addTimezoneInformation(dateStart.toISOString().slice(0, 10)) : null,
            vmid: vmid
        }
    });
    window.open(routeData.href)
}

function transactionsIdLink(dateStart, transactionId) {
    var routeData = this.$router.resolve({
        path: '/transactions/',
        query: {
            dateStart: addTimezoneInformation(dateStart.toISOString().slice(0, 10)),
            transactionId: transactionId
        }
    });
    window.open(routeData.href)
}

function isMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
}

function openHelpWindow(anchor) {
    let location = '/files/help/Sleegers Kiosk Portal User Guide.html' 
    if (anchor !== undefined)
        location += anchor

    window.open(location, "help_guide")
}

export {
    formatDate, formatPrice, getDateOnly, getTimeOnly, generateDynamicFields, cleanVmid,
    generateFieldsByRole, getElapsedReadableTime, kioskLink, servantLink, transactionsVmidLink, transactionsIdLink, convertUTCtoLocalDate, formatDateOnly,
    getTimeOnly12Hours, isDateTimeMin, addTimezoneInformation, isMobile, openHelpWindow
}