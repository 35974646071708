import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils";

class AuditService {

    getTypes() {
        return axios({
            method: 'get',
            url: '/api/audit/get_types',
            headers: authHeader(),
        })
    }

    getOperations() {
        return axios({
            method: 'get',
            url: '/api/audit/get_operations',
            headers: authHeader(),
        })
    }

    list(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/audit/list',
            headers: authHeader(),
            data: {
                user_id: query.userId,
                type_id: query.auditTypeId,
                operation_id: query.auditOperationId,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder,
            }
        })
    }
}

export default new AuditService();