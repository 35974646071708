import axios from "axios";
import authHeader from "./auth.header";

class HardwareTypeService {

    get() {
        return axios({
            method: 'get',
            url: '/api/hardware_types/list',
            headers: authHeader()
        })
    }

    getKioskTypes() {
        return axios({
            method: 'get',
            url: '/api/hardware_types/list_kiosk_types',
            headers: authHeader()
        })
    }
}

export default new HardwareTypeService();