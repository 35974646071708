<template>
    <div>
        <div>
            <ul>
                <li><strong>Type:</strong> {{ audit.type }}</li>
                <li><strong>Operation:</strong> {{ audit.operation }}</li>
                <li><strong>Changed By:</strong> {{ audit.changed_by }}</li>
                <li><strong>Changed On:</strong> {{ formatDate(convertUTCtoLocalDate(audit.timestamp)) }}</li>
            </ul>
        </div>
        <hr class="hr"/>
        <div class="diff-group">
            <div class="old-value">
                <h5 class="center mb-3 diff">Old Value</h5>
                <div class="mx-3" v-if="audit.old_value">
                    <pre>{{formatJSON(audit.old_value)}}</pre>
                </div>
            </div>
            <div class="new-value">
                <h5 class="center mb-3 diff">New Value</h5>
                <div class="mx-3" v-if="audit.new_value">
                    <pre>{{formatJSON(audit.new_value)}}</pre>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatDate, convertUTCtoLocalDate } from '../../utils/utils'

export default {
    name: "AuditViewDiff",
    components: {
    },
    props: {
        audit: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            isLoading: false,
        };
    }, 
    computed: {
        security() {
            return this.$store.state.auth.security
        },
    },
    methods: {
        formatJSON(json){
            return JSON.stringify(JSON.parse(json), undefined, 2);
        },
        formatDate,
        convertUTCtoLocalDate
    }
}
</script>
<style>
ul {
    list-style-type: none;
    padding: 0; 
}
.diff-group {
    display: flex;
}
.center {
    text-align: center;
}
.old-value {
    box-sizing: content-box;
    width: 50%;
    border-right: 1px lightgray solid;
    min-height: 20rem;
}
.new-value {
    box-sizing: content-box;
    width: 50%;    
    min-height: 20rem;
}

.diff {
    margin-top: 0.25rem;
}

@media (max-width: 900px) {
.diff-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.old-value {
    width: 100%;
    border-right: 1px none;
    border-bottom: 1px lightgray solid;
}
.new-value {
    width: 100%;    
}

.hr {
    margin-bottom: 0;
}

.diff {
    margin-top: 1rem;
}

}
</style>