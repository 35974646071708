<template>
    <div>
        <div class="d-block">
            <p>You are about to delete a kiosk <b>{{kiosk.vmid}}</b></p>
            <p>Do you want to proceed?</p>
        </div>
        <div class="float-right">
            <button class="mt-3 mr-3 btn btn danger" @click="deleteKiosk()">
                Delete
            </button>
            <button variant="warning" class="mt-3 btn btn-primary" @click="hideModal">
                Close
            </button>
        </div>
    </div>
</template>

<script>
import KiosksService from "../../services/kiosks.service"
export default {
    name: 'DeleteKiosk',
    props: {
        kiosk: {
            type: Object,
            required: true
        },
    },
    methods: {
        deleteKiosk() {
            KiosksService.delete(this.kiosk.id)
            .then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? "Delete Successful" : "Error",
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })

                    this.$emit("hide")
                }
            })
        },
        hideModal() {
            this.$emit("hide")
        }
    }
}
</script>