<template>
    <div>
        <h3>
            <font-awesome-icon icon="money-bill-transfer" class="nav-icon fa" />
            Refunds
        </h3>
        <DynamicSearchComponent
            ref="refundSearchComponent"
            preferencesName="refund_search"
            :allCriteria="searchCriteria"
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <Table
            id="refund_searchTable"
            class="scrollable-table"
            :fields="dynamicFields"
            :items="refunds"
            sortBy="timestamp"
            :sortDesc="true"
            :searchPagination="searchPagination"
            :busy="isLoading"
            @getItems="getRefunds"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(actions)="data">
                <font-awesome-icon
                    :icon="!data.detailsShowing ? 'chevron-down' : 'chevron-up'"
                    class="pointer"
                    @click="data.toggleDetails"
                    title="Expand Info"
                />
            </template>
            <template #cell(transaction_id)="data">
                <a
                    href="#"
                    @click="
                        transactionsIdLink(
                            new Date(data.item.timestamp),
                            data.item.transaction_id
                        )
                    "
                    class="underline"
                    >{{ data.item.transaction_id }}</a
                >
            </template>
            <template #cell(vmid)="data">
                <a
                    href="#"
                    @click="kioskLink(data.item.kiosk_id)"
                    class="underline"
                    >{{ data.item.vmid }}</a
                >
            </template>
            <template #cell(amount)="data">
                {{ formatPrice(data.item.amount) }}
            </template>
            <template #cell(timestamp)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.timestamp)) }}
            </template>
            <template #row-details="data">
                <b-card>
                    <h5>Transaction</h5>
                    Order Number: {{ data.item.order_number }}
                    <br />
                    Store #: {{ data.item.store_id }}
                    <br />
                    Region: {{ data.item.region }}
                    <br />
                    Hardware Type: {{ data.item.hardware_type }}
                    <br />
                    Transaction Message:
                    {{
                        data.item.transaction_message !== ''
                            ? data.item.transaction_message
                            : 'N/A'
                    }}
                    <br />
                    Data: {{ data.item.data }}
                    <hr />
                    <h5>Payment</h5>
                    Card Type: {{ data.item.payment_type }}
                    <br />
                    Authorization Type: {{ data.item.authorization_type }}
                    <br />
                    Charged: {{ data.item.charged }}
                    <br />
                    Price: {{ formatPrice(data.item.price) }}
                    <br />
                    Total: {{ formatPrice(data.item.total) }}
                    <hr />
                    <h5>Issues</h5>
                    <span v-if="data.item.issues.length == 0">
                        No issues found
                    </span>
                    <div
                        v-for="(issue, index) in data.item.issues"
                        :key="index"
                    >
                        {{ index + 1 }}. {{ issue }}
                        <br />
                    </div>
                </b-card>
            </template>
        </Table>

        <ExportFileComponent
            :arguments="currentQuery"
            :disabled="false"
            page="refunds"
            class="export"
        />
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../components';
import TransactionsService from '../../services/transactions.service';
import {
    formatDate,
    formatPrice,
    kioskLink,
    transactionsIdLink,
    convertUTCtoLocalDate
} from '../../utils/utils';
import ExportFileComponent from '../../components/ExportFileComponent.vue';

export default {
    name: 'Refunds',
    components: {
        Table,
        DynamicSearchComponent,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'align-middle',
                },
                {
                    key: 'refund_id',
                    label: 'Refund Id',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1,
                },
                {
                    key: 'name',
                    label: 'User',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2,
                },
                {
                    key: 'transaction_id',
                    label: 'Transaction Id',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3,
                },
                {
                    key: 'vmid',
                    label: 'Vmid',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 8,
                },
                {
                    key: 'transaction_type',
                    label: 'Transaction Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7,
                },
                {
                    key: 'refund_type',
                    label: 'Refund Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4,
                },
                {
                    key: 'product_description',
                    label: 'Product',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 9,
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5,
                },
                {
                    key: 'timestamp',
                    label: 'Timestamp',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6,
                },
            ],
            refunds: [],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -6,
            isLoading: false,
            currentQuery: {},
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        roleId() {
            return this.$store.state.auth.user.role_id;
        },
        dynamicFields() {
            return this.fields;
        },
        searchCriteria() {
            let criteria = [
                {
                    key: 'refund_id',
                    name: 'Refund Id',
                    type: 'Text',
                    searchQueryName: 'refundId',
                },
                {
                    key: 'transaction_id',
                    name: 'Transaction Id',
                    type: 'Text',
                    searchQueryName: 'transactionId',
                },
                {
                    key: 'Transaction_type',
                    name: 'Transaction Type',
                    type: 'TransactionType',
                    searchQueryName: 'transactionType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'refund_type',
                    name: 'Refund Type',
                    type: 'RefundType',
                    searchQueryName: 'refundType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardware_type',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'date_start',
                    name: 'Start Date',
                    subtype: 'start-date',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(this.getDaysBack),
                    required: true,
                },
                {
                    key: 'date_end',
                    name: 'End Date',
                    type: 'Date',
                    subtype: 'end-date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                    required: true,
                },
                {
                    key: 'min_amount',
                    name: 'Min Amount',
                    type: 'Number',
                    searchQueryName: 'minAmount',
                    defaultValue: 0.0,
                },
                {
                    key: 'max_amount',
                    name: 'Max Amount',
                    type: 'Number',
                    searchQueryName: 'maxAmount',
                    defaultValue: 0.0,
                },
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'productId',
                    searchQueryValueProperty: 'id',
                },
            ];
            return criteria;
        },
        getDaysBack() {
            var daysBack = parseInt(
                this.$store.state.auth.portal_configuration.transdaysback,
                10
            );
            if (isNaN(daysBack)) {
                daysBack = 0;
            }
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - daysBack);
            return currentDate.toISOString();
        },
    },
    mounted() {
        this.getRefunds();
    },
    methods: {
        getRefunds() {
            this.$refs.refundSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.currentQuery = args.query;
            this.isLoading = true;
            TransactionsService.searchRefunds(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then(
                (response) => {
                    if (response) {
                        this.refunds = response.data.items;
                        this.searchPagination.totalRows =
                            this.refunds.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                        args.done();
                    }
                },
                (error) => {
                    if (error) {
                        this.isLoading = false;
                        args.done();
                    }
                }
            );
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder;
            this.getRefunds();
        },
        formatDate,
        convertUTCtoLocalDate,
        formatPrice,
        kioskLink,
        transactionsIdLink,
    },
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
.underline {
    text-decoration: underline;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>