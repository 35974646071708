<template>
    <div>
        <div class="row row-cols-1 g-4">
            <div class="col">
                <b-form-group
                    id="user-type-select-group"
                    label="User Type"
                    label-for="user-type-select"
                >
                    <b-form-select
                        id="user-type-select"
                        v-model="driverCode.userType"
                        :disabled="false"
                    >
                        <option
                            v-for="userType in userTypes"
                            :value="userType.id"
                            :key="userType.id"
                        >
                            {{ userType.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
                <b-form-group
                    id="name-group"
                    label="Name"
                    label-for="name-input"
                >
                    <b-form-input
                        id="name-input"
                        v-model="driverCode.name"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="email-group"
                    label="Email"
                    label-for="email-input"
                >
                    <b-form-input
                        id="email-input"
                        v-model="driverCode.email"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="access-level-group" label="Access Level" label-for="access-level-input">
                    <b-form-input
                        id="access-level-input"
                        v-model="driverCode.accessLevel"
                        :state="accessLevelState && accessLevelTechnicianState"
                        type="number"
                        :min="MIN_DRIVER_LEVEL"
                        :max="MAX_TECHNICIAN_LEVEL"
                        required
                    ></b-form-input>
                </b-form-group>
                <div v-if="checkValid && !accessLevelState" class="error mb-3">
                    Access Level must be between {{MIN_DRIVER_LEVEL}} and {{MAX_TECHNICIAN_LEVEL}} (inclusive)
                </div>
                <div v-if="checkValid && accessLevelTechnicianState === false" class="error mb-3">
                    A technician role is required to update an access level of {{MIN_TECHNICIAN_LEVEL}} or greater
                </div>
                <b-form-group
                    id="code-group"
                    label="Code"
                    label-for="code-input"
                >
                    <b-form-input
                        id="code-input"
                        :disabled="true"
                        v-model="driverCode.code"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
        <b-button @click="update" variant="outline-primary" class="mt-3"
            >Update</b-button
        >
    </div>
</template>

<script>
import { RolesService } from '../../../services';
import DriverCodesService from '../../../services/drivercodes.service';

export default {
    name: 'DriverCodeUpdate',
    props: {
        userId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            driverCode: {
                userType: 0,
                code: '',
                name: '',
                email: '',
                disabled: false,
                accessLevel: 1
            },
            userTypes: [],
            checkValid: false,
            isTechnician: false,
            originalAccessLevel: 1,
            MIN_DRIVER_LEVEL: 1,
            MIN_TECHNICIAN_LEVEL:30,
            MAX_TECHNICIAN_LEVEL: 99
        };
    },
    computed: {
        accessLevelState() {
            if(!this.checkValid) return null
            return this.driverCode.accessLevel >= this.MIN_DRIVER_LEVEL && this.driverCode.accessLevel <= this.MAX_TECHNICIAN_LEVEL
        },
        accessLevelTechnicianState() {
            if(!this.checkValid) return null
            if(!this.accessLevelState) return null
            if(this.originalAccessLevel == this.driverCode.accessLevel) return true
            if (this.originalAccessLevel >= this.MIN_TECHNICIAN_LEVEL || this.driverCode.accessLevel >= this.MIN_TECHNICIAN_LEVEL){
                return this.isTechnician
            }

            return true
        },
        isFormValid() {
            return this.accessLevelState && this.accessLevelTechnicianState
        }
    },
    mounted() {
        this.getUserTypes();
        this.getDriverCode();
        this.setIsTechnician();
    },
    methods: {
        getUserTypes() {
            DriverCodesService.getUserTypesForAccessCodes().then((response) => {
                if (response) {
                    this.userTypes = response.data;
                }
            });
        },
        getDriverCode() {
            DriverCodesService.getByUserId(this.userId).then((response) => {
                const driverCode = response.data.driverCode;
                if (driverCode) {
                    this.driverCode = driverCode;
                    this.originalAccessLevel = this.driverCode.accessLevel
                    this.driverCode.disabled =
                        driverCode.disabled != '0001-01-01T00:00:00';
                } else {
                    this.$root.$bvToast.toast("Can't find driver code", {
                        title: 'Error',
                        variant: 'danger',
                        solid: true,
                    });
                }
            });
        },
        update() {
            this.checkValid = true
            if (this.isFormValid) {
                DriverCodesService.update(
                    this.userId,
                    this.driverCode.name,
                    this.driverCode.email,
                    this.driverCode.disabled,
                    this.driverCode.userType,
                    this.driverCode.accessLevel
                ).then((response) => {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });

                    this.$emit('hide');
                });
            }
        },
        setIsTechnician() {
            RolesService.isTechnician().then((response) => {
                if(response) {
                    this.isTechnician = response.data
                }
            })
        }
    },
};
</script>
<style scoped>
.error {
    color: #dc3545;
}
</style>