<template>
    <div>
        <b-modal @show="reloadRegisterEvent" id="events-register-modal">
            <template #modal-title>
                <h3>Subscribe to Event</h3>
            </template>
            <div class="row row-cols-1 g-5 px-3"> 
                <v-autocomplete
                    v-model="registerEvent.userId"
                    :items="users"
                    item-text="username"
                    item-value="id"
                    label="Ex: username"
                >
                </v-autocomplete>
            </div>
            <h3>Event Groups</h3>
            <EventGroupNestedComponent 
                :eventGroups="eventGroups"
                :selected="selected"
                @setSelectedCheckboxes="setSelectedEventTypes"
            />
            <hr/>
            <div>
                <div class="blackout-container">
                    <div class="blackout-left">
                        <h3 class="mb-0">Blackout (Optional)</h3>
                    </div>
                    <div class="blackout-right">
                        <b-form-checkbox 
                            id="enable-blackout"
                            v-model="enableBlackout"
                            switch
                            class="right"
                            size="lg"
                        >
                        </b-form-checkbox>
                    </div>
                </div>
                Event notifications will not be sent during the black out period
                <div class="row row-cols-1 row-cols-md-2">
                    <div class="col">
                        <b-form-group label="Start Time" label-for="blackout-start-time-timepicker">
                            <b-form-timepicker
                                id="blackout-start-time-timepicker"
                                v-model="blackoutStartTime"
                                :disabled="!enableBlackout"
                            />
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group label="End Time" label-for="blackout-end-time-timepicker">
                            <b-form-timepicker
                                id="blackout-end-time-timepicker"
                                v-model="blackoutEndTime"
                                :disabled="!enableBlackout"
                            />
                        </b-form-group>
                    </div> 
                </div>
            </div>
            <div class="form-group message pt-2">
                <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                </div>
            </div>
            <template #modal-footer>
                <a id="events-modal-register-event" @click="register()" class="btn btn-primary">Save</a>
            </template>
        </b-modal>
    </div>
</template>

<script>

import { EventGroupNestedComponent }  from '../../components/'
import { UsersService } from '../../services'
import EventsService from "../../services/events.service"

export default {
    name: "RegisterEventModal",
    components: {
        EventGroupNestedComponent
    },
    data() {
        return {
            registerEvent: {
                userId: 0
            },
            eventGroups: [],
            selected: [],
            message: "",
            users: [],
            enableBlackout: false,
            blackoutStartTime: "00:00:00",
            blackoutEndTime: "00:00:00"
        }
    },
    computed: {
    },
    watch: {
        'registerEvent.userId'() {
            this.getUserInfo()
        },
    },
    mounted(){
        this.reloadRegisterEvent()        
    },
    emits: ['changeComplete'],
    methods: {
        reloadRegisterEvent() {
            this.clearForm()
            this.getGroups()
            this.getEmails()
        },
        register() {
            if(this.registerEvent.userId == 0) {
                this.message = "Please select a user";
                return;
            }

            EventsService.register(
                this.registerEvent.userId,
                this.selected,
                this.enableBlackout ? this.blackoutStartTime : undefined,
                this.enableBlackout ? this.blackoutEndTime : undefined,
            ).then((response) => {
                if (response) {
                    if(response.data.success) {

                        this.clearForm()
                        this.$root.$bvToast.toast(response.data.message, {
                            title: 'Success',
                            variant: 'success',
                            solid: true
                        })
                    }
                    else {
                        this.$root.$bvToast.toast(response.data.message, {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                        })
                    }
                    this.$emit('changeComplete');
                }
            });
        },
        onReset(event) {
            event.preventDefault()
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
        setSelectedEventTypes(value) {
            this.selected = value
        },
        getGroups(){
            EventsService.getGroups().then(
                (response) => {
                this.eventGroups = response.data;
            });
        },
        clearForm() {
            this.registerEvent.userId = 0
            this.eventGroups = []
            this.selected = []
            this.message = ""
            this.enableBlackout = false,
            this.blackoutStartTime = "00:00:00"
            this.blackoutEndTime = "00:00:00"
        },
        getEmails() {
            this.users = []
            UsersService.getEmails()
                .then((response) => {
                    this.users = response.data.filter((user) => !user.deleted);
                })
        },
        getUserInfo() {
            EventsService.getByUserId(this.registerEvent.userId).then(
                (response) => {
                    if (response.data)
                        this.selected = response.data.eventTypes.map(eventType => eventType.evType)
                    
                    this.enableBlackout = response.data.blackout_start_time != undefined && response.data.blackout_start_time != undefined

                    if (this.enableBlackout) {
                        this.blackoutStartTime = response.data.blackout_start_time
                        this.blackoutEndTime = response.data.blackout_end_time
                    }
            });
        },
    }
}
</script>

<style scoped>
.blackout-container {
    display: flex;
    justify-content: space-between;
}
.blackout-left {
    align-items: center;
}
.blackout-right {
    display: flex;
    align-items: center;
}
</style>