<template>
    <div>
        <h3>
            <font-awesome-icon icon="user" class="nav-icon fa" /> Users
            <!-- TODO: Add back user help guide with split HTML -->  
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf13')" class="help-icon nav-icon fa" title="Sleegers User Guide - Users"/>
        </h3>
        <div class="button-group mt-3">
            <div class="button-col">
                <button
                    class="btn btn-primary button"
                    @click="openRegisterModal"
                    v-if="security.userCreate"
                >
                    <span>Add</span>
                </button>
                <button
                    class="btn btn-primary button"
                    @click="showInviteModal"
                    v-if="security.userCreate"
                >
                    <span>Invite</span>
                </button>
            </div>
        </div>
        <DynamicSearchComponent
            ref="usersSearchComponent"
            preferencesName="users_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="getUsers"
        />
        <div class="pb-4">
            <Table
                id="usersTable"
                :fields="dynamicFields"
                :items="users"
                sortBy="created_on"
                :sortDesc="true"
                :horizontalScroll="true"
                :searchPagination="searchPagination"
                @getItems="reloadUsers"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="row">
                    <div class="icons">
                        <template v-if="row.item.typeId != 4">
                            <font-awesome-icon
                                icon="pencil"
                                @click="openUpdateModal(row.item)"
                                v-if="security.userWrite"
                                class="nav-icon pointer mx-2"
                                title="Edit"
                            />
                            <font-awesome-icon
                                icon="key"
                                @click="openUpdatePasswordModal(row.item)"
                                v-if="
                                    security.userWrite &&
                                    !isDriver(row.item.user_type_id) &&
                                    !isTechnician(row.item.user_type_id) &&
                                    !row.item.is_azure_sso
                                "
                                class="nav-icon pointer mx-2"
                                title="Password"
                            />
                            <font-awesome-icon
                                icon="bell"
                                @click="openEventModal(row.item)"
                                v-if="security.userWrite"
                                class="nav-icon pointer mx-2"
                                title="Events"
                            />
                            <font-awesome-icon
                                icon="id-badge"
                                @click="
                                    openUpdateAccessModal(
                                        row.item,
                                        row.index,
                                        $event.target
                                    )
                                "
                                v-if="security.userWrite && (security.regionsRead || security.storeRead)"
                                class="nav-icon pointer mx-2"
                                title="Access"
                            />
                            <font-awesome-icon
                                icon="trash-can"
                                @click="openDeleteModal(row.item)"
                                v-if="security.userDelete && row.item.id != currentUserId"
                                class="nav-icon pointer mx-2"
                                title="Delete"
                            />
                        </template>
                    </div>
                </template>
                <template #cell(username)="data">
                    <span :class="{ blur: data.item.user_type_id == 2 }">
                        {{ data.item.username }}
                    </span>
                </template>
                <template #cell(created_on)="data">
                    {{ formatDate(convertUTCtoLocalDate(data.item.created_on)) }}
                </template>
                <template #cell(last_activity_date)="data">
                    {{
                        data.item.last_activity_date != '0001-01-01T00:00:00'
                            ? formatDate(convertUTCtoLocalDate(data.item.last_activity_date))
                            : ''
                    }}
                </template>
                <template #cell(last_password_change_date)="data">
                    {{
                        data.item.last_password_change_date !=
                        '0001-01-01T00:00:00'
                            ? formatDate(convertUTCtoLocalDate(data.item.last_password_change_date))
                            : ''
                    }}
                </template>
                <template #cell(has_two_fa_enabled)="data">
                    <font-awesome-icon
                        icon="lock"
                        v-if="data.item.has_two_fa_enabled"
                    />
                </template>
            </Table>
            <b-modal
                id="user-register-modal"
                size="lg"
                ref="user-register-modal"
                hide-footer
            >
                <template #modal-title>Add User</template>
                <RegisterUserModalTabs @changeComplete="hideRegisterUser" />
            </b-modal>
            <ExportFileComponent
                :arguments="this.arguments.query"
                :disabled="false"
                page="users"
                class="export"
            />
            <UpdateUserModal
                :user="selectedUser"
                @changeComplete="hideUpdateUser"
            />
            <UpdatePasswordModal
                :user="selectedUser"
                @changeComplete="hideUpdatePassword"
            />
            <b-modal
                id="access-update-modal"
                ref="access-update-modal"
                hide-footer
            >
                <template #modal-title>Update Access</template>
                <UpdateAccessModal
                    :userId="selectedUserId"
                    @changeComplete="hideUpdateAccessModal"
                />
            </b-modal>
            <DeleteUserModal
                @deleteComplete="deleteComplete"
                :user="selectedUser"
            />
            <b-modal id="invite" ref="invite" hide-footer>
                <template #modal-title> Invite User </template>
                <InviteUser @hide="hideInviteModal" />
            </b-modal>
            <UpdateEventModal
                :user="selectedUser"
                @changeComplete="hideEventModal"
            />
            <b-modal
                id="update-driver-code"
                ref="update-driver-code"
                hide-footer
            >
                <template #modal-title>
                    Update - {{ selectedUser.user_type_name }}
                </template>
                <DriverCodeUpdate
                    :userId="selectedUser.id"
                    @hide="hideUpdateDriverCode"
                />
            </b-modal>
        </div>
    </div>
</template>

<script>
import { DynamicSearchComponent, Table, ExportFileComponent } from '../../components';
import { UsersService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import {
    UpdateUserModal,
    RegisterUserModalTabs,
    DeleteUserModal,
    UpdatePasswordModal,
    UpdateAccessModal,
    InviteUser,
} from '../../components/users';
import { formatDate, convertUTCtoLocalDate, generateDynamicFields, openHelpWindow } from '../../utils/utils';
import { UpdateEventModal } from '../../components/events/';
import USER_TYPE_ENUM from '../../models/UserTypeEnum';
import DriverCodeUpdate from './DriverCodes/DriverCodeUpdate.vue';

export default {
    name: 'Users',
    components: {
        DynamicSearchComponent,
        UpdateUserModal,
        ExportFileComponent,
        RegisterUserModalTabs,
        DeleteUserModal,
        UpdatePasswordModal,
        UpdateAccessModal,
        Table,
        InviteUser,
        UpdateEventModal,
        DriverCodeUpdate,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'user_type_name',
                    label: 'User Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'username',
                    label: 'Login',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'email',
                    label: 'Email',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'role.description',
                    label: 'Role',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'created_on',
                    label: 'Created On',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'last_activity_date',
                    label: 'Last Accessed',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'last_password_change_date',
                    label: 'Last Password Change',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'has_two_fa_enabled',
                    label: 'Two Factor Auth',
                    tdClass: 'column',
                    sortable: false
                },
            ],
            searchCriteria: [
                {
                    key: 'login',
                    name: 'Login',
                    type: 'Text',
                    searchQueryName: 'login',
                },
                {
                    key: 'name',
                    name: 'Name',
                    type: 'Text',
                    searchQueryName: 'name',
                },
                {
                    key: 'user_type',
                    name: 'User Type',
                    type: 'UserType',
                    searchQueryName: 'userType',
                    searchQueryValueProperty: 'id',
                },
            ],
            users: [],
            arguments: [],
            isLoading: false,
            selectedUser: {},
            selectedUserId: 0,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -2,
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        dynamicFields() {
            return generateDynamicFields(
                this.fields,
                'actions',
                !this.security.userWrite && !this.security.userDelete
            );
        },
        currentUserId() {
            return this.$store.state.auth.user.user_id;
        },
    },
    mounted() {
        //this.getUsers(null);
    },
    methods: {
        getUsers(args) {
            this.arguments = args;
            this.isLoading = true;
            UsersService.searchQuery(
                args == null
                    ? { login: '', name: '', userType: undefined }
                    : args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then(
                (response) => {
                    if (response) {
                        this.users = response.data.items;
                        this.searchPagination.totalRows =
                            this.users.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                        if (args != undefined || args != null) args.done();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        reloadUsers() {
            this.$refs.usersSearchComponent.reloadDynamicSearch();
        },
        openUpdateModal(item) {
            if (item.role.description == null) item.role = {};
            this.selectedUser = item;

            if (this.selectedUser.user_type_id == USER_TYPE_ENUM.PortalUser) {
                this.$bvModal.show('users-update-modal');
            } else if (this.selectedUser.user_type_id == USER_TYPE_ENUM.KioskUser) {
                this.$bvModal.show('update-driver-code');
            }
        },
        openUpdatePasswordModal(item) {
            if (item.role == null) item.role = {};
            this.selectedUser = item;
            this.$bvModal.show('password-update-modal');
        },
        openUpdateAccessModal(item) {
            this.selectedUserId = item.id;
            this.$bvModal.show('access-update-modal');
        },
        openRegisterModal() {
            this.$bvModal.show('user-register-modal');
        },
        openDeleteModal(item) {
            this.selectedUser = item;
            this.$bvModal.show('users-delete-modal');
        },
        hideRegisterUser() {
            this.$bvModal.hide('user-register-modal');
            this.isLoading = false;
            this.reloadUsers();
        },
        hideUpdateUser() {
            this.$bvModal.hide('users-update-modal');
            this.isLoading = false;
            this.reloadUsers();
        },
        hideUpdatePassword() {
            this.$bvModal.hide('password-update-modal');
            this.isLoading = false;
            this.reloadUsers();
        },
        hideUpdateDriverCode() {
            this.$bvModal.hide('update-driver-code');
            this.isLoading = false;
            this.reloadUsers();
        },
        hideUpdateAccessModal() {
            this.$bvModal.hide('access-update-modal');
            this.isLoading = false;
            this.reloadUsers();
        },
        deleteComplete() {
            this.$bvModal.hide('users-delete-modal');
            this.reloadUsers();
        },
        formatDate,
        convertUTCtoLocalDate,
        openHelpWindow,
        showInviteModal() {
            this.$refs['invite'].show();
        },
        hideInviteModal() {
            this.$refs['invite'].hide();
        },
        openEventModal(item) {
            this.selectedUser = item;
            this.$bvModal.show('events-update-modal');
        },
        hideEventModal() {
            this.$bvModal.hide('events-update-modal');
        },
        isDriver(userTypeId) {
            return userTypeId == USER_TYPE_ENUM.Driver;
        },
        isTechnician(userTypeId) {
            return userTypeId == USER_TYPE_ENUM.Technician;
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.reloadUsers();
        },
    },
};
</script>
<style scoped>
.center {
    text-align: center;
}

::v-deep .action-column {
    min-width: 180px;
    vertical-align: middle !important;
}

.blur {
    filter: blur(3.5px);
}

.blur:hover {
    filter: blur(0);
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 1rem; 
}

@media only screen and (max-width: 575.5px) {
    .icons {
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button-col > button {
        width: 100%;
    }

    .button-col > button:first-child {
        margin-right: 0.5rem;
    }

    .button-col > button:last-child {
        margin-left: 0.5rem;
    }

    .button {
        width: 100%;
        margin-right: 0rem; 
        margin-bottom: 1rem; 
    }
}
</style>