<template>
    <b-modal id="role-write-confirmation-modal">
        <template #modal-title>
            <h3>Remove Roles Access</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label>You are removing "Role {{ roleType }}" access from your own role.
                            you will no longer be able to use this page. 
                        </label>
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Do you wish to continue?</label>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a id="roles-modal-delete-role" @click="confirmRemove()" class="btn btn-danger">Confirm</a>
        </template>
    </b-modal>
</template>

<script>

export default {
    name: "RemoveRoleConfirmationModal",
    emits: ['removeConfirm'],
    props: {
        roleType: {
            type: String,
            required: true,
        }
    },
    methods: {
        confirmRemove(){
            this.$emit('removeConfirm');
        }
    }
}
</script>