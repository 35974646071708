<template>
    <b-form v-if="show">
        <div class="row row-cols-1 row-cols-md-1 g-4">
            <div class="col">
                <b-form-group id="status-input-group" label="Status" label-for="status-input">
                    <b-form-input
                        id="status-input"
                        v-model="form.status"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="pairing-input-group" label="Pairing Code" label-for="pairing-input">
                    <b-form-input
                        id="pairing-input"
                        v-model="form.code"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" >
                <b-button @click="pair()" v-if="security.kioskTrustCreate && !this.pairing && !this.paired" class="details-btn" variant="primary">Pair</b-button>
                <b-button @click="revoke()" v-if="security.kioskTrustDelete && this.paired" class="details-btn" variant="primary">Revoke</b-button>
            </div>
        </div>
    </b-form>
</template>

<script>

import KioskService from '../../services/kiosk.service'

export default {
    name: 'Trust',
    props: {
        kioskId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
        trust: {},
        loading: false,
        pairing: false,
        paired: false,
        show: true,
        form: {
            status: '',
            code: ''
        }
        }
    },
    computed:{
        security(){
            return this.$store.state.auth.security
        },
    },
    mounted() {
        this.getTrust()
    },
    methods: {
        pair() {
            KioskService.pair(
                this.kioskId
            ).then((response) => {
                if (response) {
                    if (response.data) {
                        this.getTrust()
                    }
                }
            })
        },
        revoke() {
            KioskService.revokeTrust(
                this.kioskId
            ).then((response) => {
                if (response) {
                    if (response.data) {
                        this.getTrust()
                    }
                }
            })
        },
        getTrust() {
            this.loading = true
            KioskService.trust(
                this.kioskId
            ).then((response) => {
                if (response) {
                    this.trust = response.data
                    this.setForm(this.trust)

                    if (this.trust.status == 'Ready')
                    this.pairing = true
                    if (this.trust.status == 'Paired')
                    this.paired = true

                    this.loading = false
                }
            })
        },
        setForm(trust) {
            this.form.status = trust.status
            this.form.code = trust.code != 0 ? trust.code : ''
        }
    }
}
</script>

<style scoped>
.details-btn {
    width: 5rem;
}
</style>