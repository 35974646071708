<template>
    <b-form-group
        id="region-select-group"
        label="Region"
        label-for="region-select"
    >
        <b-form-select
            id="region-select"
            v-model="selected"
            :disabled="this.disabled"
            :state="state"
        >
            <option :value="0">{{ noSelectionDescriptor }}</option>
            <option v-for="reg in regions" :value="reg.id" :key="reg.id">
                <template v-if="reg.parentDescription">
                    {{ reg.description + ' / ' + reg.parentDescription }}
                </template>
                <template v-else>
                    {{ reg.description }}
                </template>
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import RegionsService from '../services/regions.service';

export default {
    name: 'Regions',
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        noSelectionDescriptor: {
            type: String,
            required: false,
            default: 'All',
        },
        state: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    data() {
        return {
            regions: [],
        };
    },
    mounted() {
        if (this.security.regionsRead) {
            this.getRegions();
        }
    },
    methods: {
        getRegions() {
            RegionsService.get()
                .then((response) => {
                    this.regions = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'RegionsService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
</style>