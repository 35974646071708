<template>
    <div class="center">
        <h1 class="mb-4">
            401 - Access Denied
        </h1>
        <h3 v-if="isLoggedIn">Go to <a href="#" @click="goToHome()">Home</a> page</h3>
        <h3 v-else>Go to <a href="#" @click="goToLogin()">Login</a> page</h3>
    </div>
</template>

<script>
export default {
    name: "AccessDenied",
    computed: {
        isLoggedIn() {
            return this.$store.state.auth.user !== null
        }
    },
    methods: {
        goToHome() {
            this.$router.push("/home")
        },
        goToLogin() {
            this.$router.push("/login", { redirect_to: this.$route.query.redirect_to })
        }
    }
}
</script>
<style scoped>
</style>