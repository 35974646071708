<template>
    <div v-if="showForm">
        <hr v-if="customFields.length > 0"/>
        <CustomField :ref="'customfield-' + customField.id"
            v-for="customField in customFields"
            :key="customField.id"
            :customField="customField"
            :existingCustomValue="getValueById(customField.id)"
        />
    </div>
</template>
<script>

import CustomFieldsService from "../../services/customfields.service" 
import CustomField from "./CustomField.vue" 

export default {
    name: "CustomFieldsForm",
    components: {
        CustomField
    },
    props: {
        typeId: {
            type: Number,
            required: true
        },
        typeKeyId: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            customFields: [],
            customValues: [],
            showForm: false
        }
    },
    computed:{
        isUpdate() {
            return this.typeKeyId > 0
        }
    },
    watch: {
        typeKeyId() {
            this.checkExistingValues()
        }
    },
    created() {
        this.checkExistingValues()
    },
    methods: {
        getByType() {
            CustomFieldsService.getByType(this.typeId).then((response) => {
                if (response) {
                    this.customFields = response.data
                }
            })
        },
        getExistingValues() {
            CustomFieldsService.getValues(this.typeId, this.typeKeyId).then((response) => {
                if (response) {
                    this.customValues = response.data
                    this.showForm = true
                }
            })
        },
        isFormValid() {
            let validity = []

            this.customFields.forEach(customField => {
    
                const customFieldRefsList = this.$refs['customfield-' + customField.id]

                if (customFieldRefsList.length > 0) {
                    validity.push(customFieldRefsList[0].isValid())  
                }
                else {
                    return false
                }               
            })
            
            return validity.every(Boolean)
        },
        getValues() {
            var values = []

            this.customFields.forEach(customField => {
                const customFieldRefsList = this.$refs['customfield-' + customField.id]

                if (customFieldRefsList.length > 0) {
                    values.push(customFieldRefsList[0].getValue())
                }
                else {
                    return []
                }               
            })

            return values
        },
        getValueById(id) {
            return this.customValues.find(customValue => customValue.custom_field_id == id)
        },
        checkExistingValues() {
            this.getByType()
            
            this.showForm = false

            if (this.isUpdate) {
                this.getExistingValues()
            }
            else {
                this.showForm = true
            }
        }
    }
}
</script>
<style scoped>
</style>