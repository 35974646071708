<template>
    <div>
        <div class="mt-3">
            <div class="row">
                <div class="col">
                    <b-form-file
                        v-model="usersCSV"
                        placeholder="Select a CSV or drop it here"
                        drop-placeholder="Drop CSV Here"
                        accept=".csv"
                        :state="csvState"
                    />
                    <div v-if="csvState == false" class="error">
                        CSV is required
                    </div>
                </div>
            </div>
            <div class="mt-3 button-group">
                <div class="button-group">
                    <a
                        href='/files/users/ExampleUsersBulkEntry.csv'
                        download="ExampleUserBulkEntry.csv"
                        class="align-self-center"
                    >
                        Download Example CSV
                    </a>
                </div>
                <div>
                    <b-button variant="primary" @click="onSubmit" class="right">Import</b-button>
                </div>
            </div>
            <div class="mt-3">
                <div
                    v-for="(error, index) in userErrors"
                    v-bind:key="index"
                >
                    <div style="color: red">{{ error }}</div>
                </div>
                <div v-if="success">
                    Users CSV imported successfully
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { UsersService } from '../../services'

export default {
    name: "BulkImportUsers",
    data() {
        return {
            usersCSV: null,
            userErrors: [],
            success: false,
            checkValid: false,
        }
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
        csvState() {
            if (!this.checkValid) return null
            return this.usersCSV ? true : false
        },
        isFormValid() {
            return this.csvState
        }
    },
     methods: {
        onSubmit() {
            this.checkValid = true
            if (this.isFormValid) {
                var formData = new FormData()
                formData.append('UsersCSV', this.usersCSV)
                UsersService.addNewCsv(formData).then((response) => {
                    this.userErrors = response.data.errors
                    this.success = response.data.success

                    if (this.success) {
                        this.$root.$bvToast.toast('Users Added', {
                            title: 'Success',
                            variant: 'success',
                            solid: true
                        })
                        this.usersCSV = null
                    } else {
                        this.$root.$bvToast.toast('Check Error Messages', {
                            title: 'Users Not Added',
                            variant: 'danger',
                            solid: true
                        })
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
}
.error {
    color: #dc3545;
    margin-top: 0.25rem;
}
</style>