<template>
    <b-modal id="price-delete-modal">
        <template #modal-title>
            <h3>Delete Price</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label>You are about to delete a price.</label>
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Do you want to proceed?</label>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a id="price-modal-delete-price" @click="deletePrice()" class="btn btn-danger">Delete</a>
        </template>
    </b-modal>
</template>

<script>

import ProductsService from "../../../services/products.service"

export default {
    name: "DeletePriceModal",
    props: {
        priceId: Number,
        effectiveDateId: Number
    },
    emits: ['deleteComplete'],
    methods: {
        deletePrice(){
            ProductsService.deletePrice(this.priceId, this.effectiveDateId
            ).then((response) => {
                if(response.data) {
                    this.$root.$bvToast.toast('Deleted Price', {
                        title: 'Success',
                        variant: 'success',
                        solid: true
                    })
                }
                else {
                    this.$root.$bvToast.toast('Error Updating Price', {
                        title: 'Error ',
                        variant: 'danger',
                        solid: true
                    })
                }
            });
            this.$emit('deleteComplete', "Price Deleted")
        }
    }
}
</script>