<template>
    <div class="group">
        <h5>Host VMID:</h5>
        <a
            href="#"
            @click="kioskLink(servant.host_id)"
            v-if="servant.host_id"
            class="underline"
        >
            {{ servant.host_vmid }}
        </a>
        <template v-else>
            <b style="color: red">Servant {{servant.svmid}} does not have host</b>
        </template>
    </div>
</template>

<script>
import { kioskLink } from "../../utils/utils"

export default {
    name: 'ServantExpansionComponent',
    components: {
    },
    props: {
        servant: {
            type: Object,
            require: true
        }
    },
    data() {
        return {
            isLoading: false,
            elapsedCheckin: "Calculating...",
        };
    },
    computed:{
        security() {
            return this.$store.state.auth.security
        },
    },
    methods: {
        kioskLink,
    }
};
</script>

<style scoped>
.group {
    display: flex;
    flex-direction: row;
    gap: 10px;
 }

 @media only screen and (max-width: 575.5px) {
    .group {
        flex-direction: column;
    }
}
</style>