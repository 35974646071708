<template>
    <b-form-group
        id="price-details-group"
        label="Price"
        label-for="price-input-details"
    >
        <b-form-select
        id="price-input-details"
        v-model="selected"
        :disabled="this.disabled"
        :state="state"
        v-if="!showReadOnlyValue"
        >
            <option :value="null" disabled>Select a Price</option>
            <option v-for="price in prices" :value="price.id" :key="price.id">
                {{  price.product_description + ' / ' + price.description }}
            </option>
        </b-form-select>
        <div v-else>
            <b-form-input
                id="price-input-details"
                v-model="readOnlyValue"
                :disabled="true"
            ></b-form-input>
        </div>
    </b-form-group>
</template>

<script>
import ProductsService from '../services/products.service';

export default {
    name: 'PriceSelect',
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        state:{
            type: Boolean,
            required: false,
            default: undefined
        },
        readOnlyValue: {
            type: String,
            required: false,
        },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        security() {
            return this.$store.state.auth.security;
        },
        showReadOnlyValue() {
            if (!this.security.productRead) {
                return this.readOnlyValue != undefined
            }

            return false
        }
    },
    data() {
        return {
            prices: [],
        };
    },
    mounted() {
        if (this.security.productRead) {
            this.getProducts();
        }
    },
    methods: {
        getProducts() {
            ProductsService.getPrices()
                .then((response) => {
                    this.prices = response.data;
                })
                .catch((error) => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'ProductsService.getPrices failed',
                    variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
</style>