<template>
    <div>
        <div class="mt-3">
            <div class="row">
                <div class="col">
                    <b-form-file 
                        v-model="kioskCSV" 
                        placeholder="Select a CSV or drop it here"
                        drop-placeholder="Drop CSV Here"
                        accept=".csv"
                        :state="csvState"
                    />
                    <div v-if="csvState == false" class="error">
                        CSV is required
                    </div>
                </div>
            </div>
            <div class="mt-3 button-group">
                <div class="button-group">
                    <a href="/files/kiosks/CSV Bulk Entry Example.csv" class="align-self-center">
                        Download Example CSV
                    </a>
                </div>
                <div>
                    <b-button variant="primary" @click="onSubmit" class="right">Import</b-button>
                </div>
            </div>
            <div class="mt-3">
                <div v-for="(error, index) in kioskErrors" v-bind:key="index">
                    <div style="color: red">{{ error }}</div>
                </div>
                <div v-if="success">
                    Kiosk CSV imported successfully
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import KiosksService from "../../services/kiosks.service"

export default {
    name: "BulkImportKiosks",
    data() {
        return {
            kioskCSV: null,
            kioskErrors: [],
            success: false,
            checkValid: false
        };
    },
    computed: {
        csvState() {
            if (!this.checkValid) return null
            return this.kioskCSV ? true : false
        },
        isFormValid() {
            return this.csvState
        }
    },
    methods: {
        onSubmit() {
            this.checkValid = true
            if (this.isFormValid) {
                var formData = new FormData();
                formData.append("KioskCSV", this.kioskCSV);
                KiosksService.addNewCsv(formData).then((response) => {
                    this.kioskErrors = response.data.errors;
                    this.success = response.data.success;

                    if (this.success){
                        this.$root.$bvToast.toast('Kiosks Added', {
                            title: 'Success',
                            variant: 'success',
                            solid: true
                        })
                        this.kioskCSV = null
                        this.$emit('hide')
                    }
                    else {
                        this.$root.$bvToast.toast('Check Error Messages', {
                            title: 'Kiosks Not Added',
                            variant: 'danger',
                            solid: true
                        })

                        this.kioskCSV = undefined
                    }
            });
            }
        },
    },
}
</script>

<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
}
.error {
    color: #dc3545;
    margin-top: 0.25rem;
    margin-bottom: 0rem;
}
</style>