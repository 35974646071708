<template>
    <div>
        <div v-if="expired">
            <h4 class="center mt-5">
                <font-awesome-icon icon="circle-exclamation" class="nav-icon fa"/>
                Link expired
            </h4>
        </div>
        <div v-else-if="azuresignin">
            <h3><font-awesome-icon icon="user-plus" class="nav-icon fa" />  Register New Account With Azure</h3>
            <div class="row row-cols-1 row-cols-md-2 g-5"> 
                <div class="col">
                    <b-form-group id="username-input-group" label="Username" label-for="username">
                        <b-form-input 
                            id="username"
                            v-model="user.username"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="name-input-group" label="Name" label-for="name">
                        <b-form-input 
                            id="name"
                            v-model="user.name"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="email-input-group" label="Email" label-for="email">
                        <b-form-input 
                            id="email"
                            v-model="user.email"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="role-input-group" label="Role" label-for="role">
                        <b-form-input
                            id="role"
                            v-model="user.role"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div>
                <button @click="registerWithAzure"><img src="../../assets/users/ms-symbollockup_signin_light.png"></button>
            </div>
        </div>
        <div v-else>
            <h3><font-awesome-icon icon="user-plus" class="nav-icon fa" />  Create Account</h3>
            <div class="row row-cols-1 row-cols-md-2 g-5"> 
                <div class="col">
                    <b-form-group id="username-input-group" label="Username" label-for="username">
                        <b-form-input 
                            id="username"
                            v-model="user.username"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="name-input-group" label="Name" label-for="name">
                        <b-form-input 
                            id="name"
                            v-model="user.name"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="email-input-group" label="Email" label-for="email">
                        <b-form-input 
                            id="email"
                            v-model="user.email"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="role-input-group" label="Role" label-for="role">
                        <b-form-input
                            id="role"
                            v-model="user.role"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div>
                <h3><font-awesome-icon icon="lock" class="nav-icon fa" />  Password</h3>
                <div class="row row-cols-1 row-cols-md-2 g-5"> 
                    <div class="col">
                        <b-form-group id="password-input-group" label="New Password" label-for="password">
                            <b-form-input 
                                id="password"
                                v-model="user.password"
                                type="password"
                                :state="passwordState"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group id="confirm-password-input-group" label="Confirm Password" label-for="password">
                            <b-form-input 
                                id="confirmPassword"
                                v-model="user.confirmPassword"
                                type="password"
                                :state="confirmPasswordState"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="form-group message pt-1">
                    <div v-if="message" class="alert alert-danger" role="alert">
                        {{ message }}
                    </div>
                </div>
            </div>
            <div>
                <h3><font-awesome-icon icon="shield-halved" class="nav-icon fa" />  Two Factor Authentication ({{twoFaRequiredTitle}})</h3>
                <div class="row row-cols-1 row-cols-md-2 g-5">
                    <div class="col">
                        <p>
                            Scan QR code or enter token into authenticator app to enable two factor
                            authentication
                        </p>
                        <div class="row d-flex justify-content-center">
                            <qrcode-vue :value="qrCodeUrl" :size="size" level="H"></qrcode-vue>
                        </div>
                        <div>Token: {{ token }}</div>
                        <div>
                            <label for="authenticatorCode">Authenticator Code</label>
                            <b-form-input
                                v-model="authenticatorCode"
                                type="text"
                                class="form-control"
                                name="authenticatorCode"
                                id="qrcode-text-authenticatorcode"
                                autocomplete="off"
                                :state="authCodeState"
                            />
                        </div>
                    </div>
                </div>    
            </div>
            <button @click="registerUser" variant="primary" class="mt-3 btn btn-primary">Register</button>
        </div>
    </div>
</template>

<script>
import { AuthService, RolesService, TokenService, UsersService } from "../../services/index"
import QrcodeVue from "qrcode.vue";
import { isMobile } from "../../utils/utils";

export default {
    name: "CreateAccount",
    components: {
        QrcodeVue
    },
    data() {
        return {
            isLoading: false,
            user: {
                portalId: 0,
                username: "",
                email: "",
                name: "",
                password: "",
                confirmPassword: "",
                role: "",

            },
            size: 200,
            qrCodeUrl: "",
            token: "",
            authenticatorCode: "",
            loading: false,
            message: "",
            expired: false,
            checkValid: false,
            roleRequiresTwoFa: false,
            azuresignin: false
        };
    },
    computed: {
        passwordState() {
            if (!this.checkValid) return null
            return this.user.password !=""
        },
        confirmPasswordState() {
            if (!this.checkValid) return null
            return this.user.confirmPassword !="" && this.user.confirmPassword == this.user.password
        },
        authCodeState() {
            if (!this.checkValid || !this.roleRequiresTwoFa) return null
            return this.authenticatorCode !=""
        },
        isFormValid() {
            var passwordFormValid = this.passwordState && this.confirmPasswordState

            if (this.roleRequiresTwoFa) {
                return passwordFormValid && this.authCodeState
            }
            else {
                return passwordFormValid
            }
        },
        security() {
            return this.$store.state.auth.security
        },
        twoFaRequiredTitle() {
            return this.roleRequiresTwoFa ? "Required" : "Optional"
        },
    },
    mounted() {
        if(!localStorage.getItem('webcode'))
            this.getWebcode()
        this.setUp()
    },
    methods: {
        setUp(){
            this.inviteTokenId = this.$route.params.tokenid
            this.peekToken()
        },
        peekToken(){
            TokenService.peekToken(this.inviteTokenId).then(
                (response) => {
                    if(response) {
                        var type = response.data.type;
                        
                        if (type == undefined) {
                            this.expired = true
                        }
                        else {
                            var userData = JSON.parse(response.data.data)
                            this.user.portalId = userData.PortalId
                            this.user.email = userData.Email
                            this.user.username = userData.Username
                            this.user.name = userData.Name
                            this.user.role = userData.Role
                            this.azuresignin = userData.AzureAccount
                            this.doesRoleRequireTwoFa()
                        }
                    }
                },
            )
        },
        generateTwoFaToken() {
            AuthService.generateTwoFaTokenInvite(this.user.email).then(
                (response) => {
                    this.qrCodeUrl = response.qr_code_url;
                    this.token = response.two_fa_token;
                }
            );
        },
        doesRoleRequireTwoFa(){
            RolesService.doesRoleRequireTwoFa(this.user.role).then(
                (response) => {
                    this.roleRequiresTwoFa = response.data

                    if (this.roleRequiresTwoFa) {
                        this.generateTwoFaToken()
                    }
                }
            );
        },
        registerUser() {
            this.checkValid = true
            if (this.user.password !== this.user.confirmPassword){
                this.message = "Passwords don't match.";
                return;
            }
            this.message = "";

            if (this.isFormValid) {
                UsersService.inviteRegister(this.inviteTokenId, this.user.password, this.token, this.authenticatorCode).then(
                    (response) => {
                        this.checkValid = false
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success?  'success' : 'danger',
                            solid: true
                        })
                        
                        if (response.data.success) {
                            this.$router.push('/login');
                        }
                    }
                );
            }
        },
        registerWithAzure() {
            this.message = "";

            let redirectUri = "";
            
            if(isMobile()){
                redirectUri = `${window.location.origin}/login`
            }
            else {
                redirectUri = `${window.location.origin}/`
            }

            const loginRequest = {
                scopes: ["User.Read", "email"],
                state: JSON.stringify({ action: "createAccount", inviteToken: this.inviteTokenId }),
                redirectUri: redirectUri,
                loginHint: `${this.user.email}`,
                navigateToLoginRequestUrl: false
            };
            if (isMobile()){
                this.$msal.loginRedirect(loginRequest);
            }
            else {
                this.$msal.loginPopup(loginRequest)
                    .then((response) => {
                    if (response){
                        UsersService.inviteRegisterAzure(this.inviteTokenId, response.idToken).then(
                            (response) => {
                                this.checkValid = false
                                this.$root.$bvToast.toast(response.data.message, {
                                    title: response.data.success ? 'Success' : 'Error',
                                    variant: response.data.success?  'success' : 'danger',
                                    solid: true
                                })
                                if (response.data.success) {
                                    this.$router.push('/login');
                                }
                            }
                        );
                    }
                }).catch((error) => {
                    console.error("Error handling redirect:", error)
                })
            }
        },
        getWebcode() {
            this.$store.dispatch("auth/setWebcodeFromUrl")
        }
    }
}
</script>
<style scoped>
</style>