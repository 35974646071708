<template>
    <b-form-group id="tag-select-group" label="Existing Tags" label-for="tag-select">
        <b-form-select
            id="tag-select"
            v-model="selected">
            <option :value="0"></option>
            <option v-for="tag in tags" :value="tag.id" :key="tag.id">
                {{ tag.name }}
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>

import TagService from "../../services/tag.service"

export default {
    name: "TagListComponent",
    props: {
        kioskId: {
            type: String,
            required: false,
        },
        firmwareId: {
            type: Number,
            required: false
        },
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            tags: [],
        }
    },
    mounted() {
        this.getTags()
    },
    methods: {
        getTags() {
            TagService.listAvailableTag(
                this.kioskId,
                this.firmwareId
            ).then((response) => {
                this.tags = response.data.available
            }).catch(error => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'TagsService.getTags failed',
                    variant: 'danger',
                    solid: true
                })
            })
        }
    }
}
</script>

<style scoped>

</style>