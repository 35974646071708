<template>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div>
            <h5 style="padding-bottom: 1%">System Controls</h5>
        </div>
        <div class="row row-cols-2 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group id="checkin-diagnostics-group" label="Check-in Interval (seconds)" label-for="checkin-diagnostics-details" >
                    <b-form-input
                        id="checkin-diagnostics-details"
                        v-model="form.update_seconds"
                        required
                        :disabled="this.disabled"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="rotation-diagnostics-group" label="Maintenance Rotation (days)" label-for="rotation-diagnostics-details" >
                    <b-form-input
                        id="rotation-diagnostics-details"
                        v-model="form.maintenance_rotation"
                        required
                        :disabled="this.disabled"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="requestlogs-select-group" label="Request Logs" label-for="requestlogs-select">
                    <b-form-select
                        id="requestlogs-select"
                        v-model="form.request_logs"
                        :disabled="this.disabled">
                            <option v-for="item in this.diagnostics.request_logs_options" :value="item.value" :key="item.key">
                            {{ item.key }}
                            </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="rotateandstage-select-group" label="Rotate and Stage Carousel" label-for="rotateandstage-select">
                    <b-form-select
                        id="rotateandstage-select"
                        v-model="form.rotate_and_stage"
                        :disabled="this.disabled">
                            <option v-for="item in this.diagnostics.rotate_and_stage_options" :value="item.value" :key="item.key">
                            {{ item.key }}
                            </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="cycle-diagnostics-group" label="Cycle Kiosk Power" label-for="cycle-checkbox-diagnostics" >
                    <b-form-checkbox
                        id="cycle-checkbox-diagnostics"
                        v-model="form.cycle_power"
                        required
                        :disabled="this.disabled"
                    ></b-form-checkbox>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="outofspace-diagnostics-group" label="Perform Out of Space Repair" label-for="outofspace-checkbox-diagnostics" >
                    <b-form-checkbox
                        id="outofspace-checkbox-diagnostics"
                        v-model="form.perform_out_of_space_repair"
                        required
                        :disabled="this.disabled"
                    ></b-form-checkbox>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="rotatehome-diagnostics-group" label="Rotate Kiosk to Home Position" label-for="rotatehome-checkbox-diagnostics" >
                    <b-form-checkbox
                        id="rotatehome-checkbox-diagnostics"
                        v-model="form.rotate_home_position"
                        required
                        :disabled="this.disabled"
                    ></b-form-checkbox>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="disableapu-diagnostics-group" label="Disable APU" label-for="disableapu-checkbox-diagnostics" >
                    <b-form-checkbox
                        id="disableapu-checkbox-diagnostics"
                        v-model="form.disable_apu"
                        required
                        :disabled="this.disabled || !this.diagnostics.can_disable_apu"
                    ></b-form-checkbox>
                </b-form-group>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div class="col">
                <b-form-group id="stage-diagnostics-group" label="Stage Carousel" label-for="stage-checkbox-diagnostics" >
                    <b-form-checkbox
                        id="stage-checkbox-diagnostics"
                        v-model="form.stage_carousel"
                        required
                        :disabled="this.disabled"
                    ></b-form-checkbox>
                </b-form-group>
            </div>
            </div>

        <div>
            <h5 style="padding-bottom: 1%">Unit Controls</h5>
        </div>
        <div class="row row-cols-1 row-cols-md-1 g-4">
            <div class="col">
                <b-form-group id="carouselid-select-group" label="Select Carousel" label-for="carouselid-select">
                    <b-form-select
                    id="carouselid-select"
                    v-model="form.carousel_id"
                    :disabled="this.disabled">
                        <option v-for="item in this.diagnostics.carousel_options" :value="item.value" :key="item.key">
                        {{ item.key }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="openremote-select-group" label="Open remote bay #" label-for="openremote-select">
                    <b-form-select
                    id="openremote-select"
                    v-model="form.remote_bay_number"
                    :disabled="this.disabled">
                        <option v-for="item in this.diagnostics.bays" :value="item.value" :key="item.key">
                        {{ item.key }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="remotelocker-select-group" label="Open remote locker" label-for="remotelocker-select">
                    <b-form-select
                        id="remotelocker-select"
                        v-model="form.remote_locker_number"
                        :disabled="this.disabled">
                            <option v-for="item in this.diagnostics.lockers" :value="item.value" :key="item.key">
                            {{ item.key }}
                            </option>
                    </b-form-select>
                </b-form-group>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-sm-2 g-4" v-if="security.kioskWrite">
            <div class="col">
                <b-button class="details-btn" @click="disable()" v-if="this.disabled" :disabled="!this.disabled" variant="primary">Edit</b-button>
                <b-button type="reset" class="details-btn" v-if="!this.disabled" :disabled="this.disabled" variant="danger">Cancel</b-button>
            </div>
            <div class="col">
                <b-button type="submit" class="details-btn submit-btn" v-if="!this.disabled" variant="primary">Save</b-button>
            </div>
        </div>
    </b-form>
</template>

<script>

import KioskService from "../../services/kiosk.service"

export default {
    name: 'Diagnostics',
    props: {
        kioskId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            diagnostics: {},
            form: {
                kiosk_id: 0,
                carousel_id: 0,
                cycle_power: false,
                perform_out_of_space_repair: false,
                rotate_home_position: false,
                disable_apu: false,
                stage_carousel: false,
                update_seconds: 0,
                maintenance_rotation: 0,
                request_logs: 0,
                remote_bay_number: 0,
                remote_locker_number: 0, 
                rotate_and_stage: 0
            },
            show: true,
            disabled: true
        }
    },
    mounted() {
        this.refresh()
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
    },
    methods: {
        refresh() {
            this.getDiagnostics()
        },
        getDiagnostics() {
            KioskService.diagnostics(
                this.kioskId
            ).then((response) => {
                this.diagnostics = response.data
                this.setForm(this.diagnostics)
            })
        },
        onSubmit(event) {
            event.preventDefault()
            KioskService.updateDiagnostics(
                this.form
            ).then((response) => {
                this.diagnostics = response.data
                this.setForm(this.diagnostics)
                this.disabled = true

                this.$root.$bvToast.toast('Successful', {
                    title: 'Update Kiosk Diagnostics',
                    variant: 'success',
                    solid: true
                })
            }).catch(error => {
                this.setForm(this.diagnostics)
                this.disabled = true

                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'Update Kiosk Diagnostics',
                    variant: 'danger',
                    solid: true
                })
            })
        },
        onReset(event) {
            event.preventDefault()
            this.setForm(this.diagnostics)
            this.disabled = true
            this.show = false

            this.$nextTick(() => {
                this.show = true
            })
        },
        disable() {
            setTimeout(() => {
                this.disabled = !this.disabled
            }, 150)
        },
        setForm(diagnostics) {
            this.form.kiosk_id = diagnostics.kiosk_id
            this.form.cycle_power = diagnostics.cycle_power
            this.form.perform_out_of_space_repair = diagnostics.perform_out_of_space_repair
            this.form.rotate_home_position = diagnostics.rotate_home_position
            this.form.disable_apu = diagnostics.disable_apu
            this.form.stage_carousel = diagnostics.stage_carousel
            this.form.maintenance_rotation = diagnostics.maintenance_rotation
            this.form.request_logs = diagnostics.request_logs
            this.form.remote_bay_number = diagnostics.remote_bay_number
            this.form.remote_locker_number = diagnostics.remote_locker_umber
            this.form.rotate_and_stage = diagnostics.rotate_and_stage
            this.form.update_seconds = diagnostics.update_seconds
            this.form.carousel_id = diagnostics.carousel_id
        }
    }
}
</script>

<style scoped>
.details-btn {
    width: 5rem;
}

.submit-btn {
    float: right;
}

@media only screen and (max-width: 750px) {
    .details-btn {
        width: 100%;
    }
}
</style>