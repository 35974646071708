import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils"

class CommissionService {
    searchQuery(query, startIndex, pageSize, sortOrder){
        return axios({
            method: 'post',
            url: '/api/commission/get_commission_report',
            headers: authHeader(),
            data: {
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                store: query.store,
                product_id: query.product,
                start_index: startIndex, 
                page_size: pageSize, 
                sort_order: sortOrder
            }
        })   
    }

    downloadCommissionCsv(args) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/commission/download_csv',
            headers: authHeader(),
            params: {
                date_start: addTimezoneInformation(args.dateStart),
                date_end: addTimezoneInformation(args.dateEnd),
                store: args.store,
                product_id: args.productId
            }
        })   
    }

    downloadCommissionXlsx(args) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/commission/download_xlsx',
            headers: authHeader(),
            params: {
                date_start: addTimezoneInformation(args.dateStart),
                date_end: addTimezoneInformation(args.dateEnd),
                store: args.store,
                product_id: args.productId
            }
        })   
    }
}

export default new CommissionService();

