<template>
    <b-modal id="role-delete-modal">
        <template #modal-title>
            <h3>Delete Role</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label>You are about to delete a role.</label>
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Do you want to proceed?</label>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a id="roles-modal-delete-role" @click="deleteRole()" class="btn btn-danger">Delete</a>
        </template>
    </b-modal>
</template>

<script>
import { RolesService } from '../../services';

export default {
    name: "DeleteRoleModal",
    props: {
        roleId: Number
    },
    emits: ['deleteComplete'],
    methods: {
        deleteRole(){
            RolesService.deleteRole(this.roleId ).then((response) => {
                    if (response) {
                        this.isLoading = false;
                        if(response.data.success){
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Role Deleted',
                                variant: 'success',
                                solid: true
                            })
                        }
                        else {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'RolesService.deleRole failed',
                                variant: 'danger',
                                solid: true
                            })
                        }
                        this.$emit('changeComplete');
                    }
                });
        }
    }
}
</script>