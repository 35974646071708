<template>
    <div>
        <b-form-select
            v-model="tagId"
            :disabled="false">
            <option v-for="phase in phases" :value="phase.id" :key="phase.id">
                {{ phase.name }}
            </option>
        </b-form-select>
        <div>
            <SpinnerButton @click="setPhase" :isLoading="isLoading" text="Save" class="btn-primary right" />
        </div>
    </div>
</template>

<script>

import { TagService }from "../../services/"
import SpinnerButton from "../SpinnerButton.vue"

export default {
    name: "PhaseModal",
    components: {
        SpinnerButton
    },
    data() {
        return {
            isLoading: false,
            phases: [],
            tagId: 0
        }
    },
    props: {
        kioskId: {
            type: String,
            required: false,
        },
        phase: {
            type: Number,
            required: false
        }
    },
    computed: {
        purpose() {
            return this.phase > 0 ? 'Update' : 'Add'
        }
    },
    mounted() {
        this.getPhases()
    },
    methods: {
        getPhases() {
            TagService.listPhaseTags().then(
                (response) => {
                    this.phases = response.data
                    
                    if (this.phase > 0) {
                        this.tagId = this.phases.find(phase => phase.name == this.phase.toString()).id
                    }
                    else if (this.phases.length > 0) {
                        this.tagId = this.phases[0].id
                    }
                }
            )
        },
        setPhase() {
            TagService.setPhase(this.kioskId, this.tagId).then(
                (response) => {
                    this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error' ,
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })

                    this.$emit('hide')
                }
            )
        },
    }
}
</script>
<style scoped></style>