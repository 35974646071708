import axios from "axios";
import authHeader from "./auth.header";

class RegionsService {

    get() {
        return axios({
            method: 'get',
            url: '/api/regions/list',
            headers: authHeader()
        })
    }

    getProvinces() {
        return axios({
            method: 'get',
            url: '/api/regions/list_provinces',
            headers: authHeader()
        })
    }

    update(id, description) {
        return axios({
            method: 'post',
            url: '/api/regions/update',
            headers: authHeader(),
            params: {
                id,
                description   
            }
        })
    }

    create(parentId, description) {
        return axios({
            method: 'post',
            url: '/api/regions/create',
            headers: authHeader(),
            params: {
                parentId,
                description
            }
        })
    }

    delete(id) {
        return axios({
            method: 'post',
            url: '/api/regions/delete',
            headers: authHeader(),
            params: {
                id
            }
        })
    }
}

export default new RegionsService();