<template>
    <div>
        <div v-if="!readonly & security.roleWrite">
            <a href="#" @click="selectAll()">
                Select All
            </a>
            |
            <a href="#" @click="clearAll()">
                Clear All
            </a>
        </div>
        <div>
            <Table
              id="claimsTable"
              :fields="fields"
              :items="claims"
              sortBy="id"
              :sortDesc="true"
              :searchPagination="searchPagination"
              :hidePagination="true"
              :verticalScroll="verticalScroll"
              :responsive="false"
              @getItems="listClaims"
            >
                <template #head(read)>
                    <font-awesome-icon icon="book" title="Read"/>
                </template>
                <template #head(write)>
                    <font-awesome-icon icon="pencil" title="Write"/>
                </template>
                <template #head(create)>
                    <font-awesome-icon icon="plus" title="Create"/>
                </template>
                <template #head(delete)>
                    <font-awesome-icon icon="trash-can" title="Delete"/>
                </template>
                <template #head(configure)>
                    <div>
                        <font-awesome-icon icon="gear" title="Configure"/>
                    </div>
                </template>
                <template #cell(name)="data">
                    {{ $t("claims." + data.item.name) }} <font-awesome-icon v-if="data.item.requires_technician_role" icon="wrench" title="Technician Required"/>
                </template>
                <template #cell(read)="data">
                    <b-form-checkbox-group v-model="getSelectedCheckboxes">
                        <b-form-checkbox
                        :value="getCheckboxValue(data.item.name, 'r')"
                        v-if="getCheckboxVisibility(data.item.name, 'r')"
                        :disabled="!security.roleWrite || checkDisabled(data.item.requires_technician_role, data.item.name, 'r')"
                        />
                    </b-form-checkbox-group>
                </template>
                <template #cell(write)="data">
                    <b-form-checkbox-group v-model="getSelectedCheckboxes">
                        <b-form-checkbox
                        :value="getCheckboxValue(data.item.name, 'w')"
                        v-if="getCheckboxVisibility(data.item.name, 'w')"
                        :disabled="!security.roleWrite || checkDisabled(data.item.requires_technician_role, data.item.name, 'w')"
                        />
                    </b-form-checkbox-group>
                </template>
                <template #cell(create)="data">
                    <b-form-checkbox-group v-model="getSelectedCheckboxes">
                        <b-form-checkbox
                        :value="getCheckboxValue(data.item.name, 'c')"
                        v-if="getCheckboxVisibility(data.item.name, 'c')"
                        :disabled="!security.roleWrite || checkDisabled(data.item.requires_technician_role, data.item.name, 'c')"
                        />
                    </b-form-checkbox-group>
                </template>
                <template #cell(delete)="data">
                    <b-form-checkbox-group v-model="getSelectedCheckboxes">
                        <b-form-checkbox
                        :value="getCheckboxValue(data.item.name, 'd')"
                        v-if="getCheckboxVisibility(data.item.name, 'd')"
                        :disabled="!security.roleWrite || checkDisabled(data.item.requires_technician_role, data.item.name, 'd')"
                        />
                    </b-form-checkbox-group>
                </template>
                <template #cell(configure)="data">
                    <b-form-checkbox-group v-model="getSelectedCheckboxes">
                        <b-form-checkbox
                        :value="getCheckboxValue(data.item.name, 'x')"
                        v-if="getCheckboxVisibility(data.item.name, 'x')"
                        :disabled="!security.roleWrite || checkDisabled(data.item.requires_technician_role, data.item.name, 'x')"
                        />
                    </b-form-checkbox-group>
                </template>
            </Table>
        </div>
    </div>
</template>
<script>
import { Table } from '../components'
import SearchPagination from '../models/search.pagination'

export default {
    name: "Claims",
    components: {
        Table
    },
    props: {
        claims: {
            type: Array,
            required: true,
        },
        selectedCheckboxes: {
            type: Array,
            required: true,
        },
        originalSelectedCheckboxes: {
            type: Array,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
            required: false,
        },
        verticalScroll: {
            type: String,
            default: "450px",
            required: false,
        }
    },
    data() {
        return{
            fields: [
                {
                    key: 'name',
                    label: 'Access',
                    tdClass: 'column',
                },
                {
                    key: 'read',
                    label: 'Read',
                    tdClass: 'column',
                },
                {
                    key: 'write',
                    label: 'Write',
                    tdClass: 'column',
                },
                {
                    key: 'create',
                    label: 'Create',
                    tdClass: 'column',
                },
                {
                    key: 'delete',
                    label: 'Delete',
                    tdClass: 'column',
                },
                {
                    key: 'configure',
                    label: 'Configure',
                    tdClass: 'column',
                },
            ],
            searchPagination: new SearchPagination('', 1, 0, 1, 0),
        }
    },
    computed: {
        getSelectedCheckboxes: {
            get() {
                return this.selectedCheckboxes;
            },
            set(value) {
                this.$emit("setSelectedCheckboxes", value);
            },
        },
        security() {
            return this.$store.state.auth.security
        },
    },
    methods: {
        listClaims() {
        },
        formatName(name) {
            var regex = new RegExp('_', 'g');
            return name.replace(regex, ' ');
        },
        getCheckboxVisibility(name, role){
            var filteredClaims = this.claims.find(claim => claim.name == name)
            return filteredClaims.value.includes(role)
        },
        getCheckboxValue(name, role) {
            return name + "," + role
        },
        checkDisabled(requiresTechnician, name, role) {
            return this.readonly || ((!this.security.technician && requiresTechnician) && !this.originalSelectedCheckboxes.includes(this.getCheckboxValue(name, role)))
        },
        selectAll() {
            var roles = ['r', 'w', 'c', 'd', 'x']
            var values = []

            this.claims.forEach(claim => {
                roles.forEach(role => {
                    if (this.getCheckboxVisibility(claim.name, role)) {
                        values.push(this.getCheckboxValue(claim.name, role))
                    } 
                })
            })
        
            this.$emit("setSelectedCheckboxes", values);
        },
        clearAll() {
            this.$emit("setSelectedCheckboxes", []);
        }
    }
};
</script>
<style scoped>
</style>