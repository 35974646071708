<template>
    <div class="table-container">
        <b-table class="table-striped" :fields="fields" :items="issues" responsive="true" stacked="sm">
            <template #cell(start_date)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.start_date)) }}
            </template>
        </b-table>
        <ExportFileComponent
            :arguments="this.arguments"
            class="export"
            :disabled="false"
            page="issues"
        />
    </div>
</template>

<script>

import KioskService from "../../services/kiosk.service"
import { ExportFileComponent } from "../../components/"
import { formatDate, convertUTCtoLocalDate } from "../../utils/utils"

export default {
    name: "Issues",   
    components: {
        ExportFileComponent
    },
    props: {
        kioskId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            issues: [],
            arguments: {},
            fields: [
                {
                key: 'issue',
                label: 'Issue',
                tdClass: 'column',
                sortable: false,
                },
                {
                key: 'value',
                label: 'Value',
                tdClass: 'column',
                sortable: false,
                },
                {
                key: 'servant_number',
                label: 'Carousel',
                tdClass: 'column',
                sortable: false,
                },
                {
                key: 'start_date',
                label: 'Date',
                tdClass: 'column',
                sortable: false,
                },
            ],
        }
    },
    mounted() {
        this.arguments = { kioskId: this.kioskId }
        this.getIssues()
    },
    methods: {
        getIssues() {
            this.loading = true;
            KioskService.issues(
                this.kioskId
            ).then((response) => {
                if (response) {
                    this.issues = response.data;
                    this.loading = false
                }
            });
        },
        formatDate,
        convertUTCtoLocalDate,
    }
}
</script>

<style scoped>

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>