<template>
    <div class="tag-assign-modal">
        <b-modal @submit.prevent="assignTags" id="tag-assign-modal" @shown="refresh">
            <template #modal-title>
                <h3>Assign Firmware Tags</h3>
            </template>
            <DualListComponent 
                itemsTitle="Tags" 
                selectedTitle="Selected Tags" 
                itemsName="name" 
                selectedName="name" 
                :items="tags.available" 
                :selected="tags.used"
                @setSelected="setSelected"
            />
            <div class="mt-3">
                <a href="#" @click="openAddTagModal">Add new tag</a>
            </div>
            <template #modal-footer>
                <SpinnerButton
                    @click="assignTags"
                    :isLoading="isLoading"
                    text="Save"
                    class="btn-primary"
                />
            </template>
        </b-modal>
        <AddTagModal @hide="hideAddTagModal"/>
    </div>
</template>

<script>
import TagService from '../../services/tag.service';
import SpinnerButton from '../SpinnerButton.vue';
import DualListComponent from '../DualListComponent.vue';
import AddTagModal from './AddTagModal.vue';

export default {
    name: 'UpdateEvent',
    components: {
        SpinnerButton,
        DualListComponent,
        AddTagModal
    },
    data(){
        return {
            isLoading: false,
            typeId: 1,
            tags: {
                available: [],
                used: [],
            },
            selectedTagIds: []
        };
    },
    props: {
        kioskId: {
            type: String,
            required: false,
        },
        firmwareId: {
            type: Number,
            required: false,
        },
    },
    methods: {
        refresh() {
            this.isLoading = false,
            this.selectedTagIds = []
            
            this.getTags()
        },
        assignTags() {
            this.isLoading = true
            TagService.assignTags(
                this.selectedTagIds,
                this.typeId,
                this.firmwareId,
                this.kioskId
            ).then((response) => {
                if (response) {
                    this.isLoading = false;
                    if (response.data) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                    }
                    this.$emit('hide');
                }
            });
        },
        getTags() {
            TagService.listAvailableTag(
                this.kioskId,
                this.firmwareId
            ).then((response) => {
                this.tags = response.data
            }).catch(error => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'TagsService.getTags failed',
                    variant: 'danger',
                    solid: true
                })
            })
        },
        openAddTagModal() {
            this.$bvModal.show('tag-add-modal')
        },
        hideAddTagModal() {
            this.$bvModal.hide('tag-add-modal')
            this.refresh()
        },
        setSelected(selected) {
            this.selectedTagIds = selected.map(tag => tag.id)
        }
    },
};
</script>
<style scoped></style>