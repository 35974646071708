<template>
    <div>
        <DynamicSearchComponent
            ref="servantSearchComponent"
            preferencesName="servant_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <Table
            id="servantTable"
            :fields="fields"
            :items="servants"
            sortBy="svmid"
            :sortDesc="false"
            :searchPagination="searchPagination"
            @getItems="getServants"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(action)="row">
                <div>
                    <font-awesome-icon
                        icon="link"
                        class="nav-icon pointer"
                        @click="pair(row.item.id)"
                        v-if="security.servantWrite"
                    />
                </div>
            </template>
            <template #cell(svmid)="data">
                <a
                    href="#"
                    @click="servantLink(data.item.id)"
                    class="pointer underline"
                >
                    {{ data.item.svmid }}
                </a>
            </template>
        </Table>
    </div>
</template>

<script>
import SearchPagination from '../../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../../components';
import ServantsService from '../../../services/servants.service';
import { servantLink } from '../../../utils/utils';

export default {
    name: 'PairServant',
    components: {
        Table,
        DynamicSearchComponent,
    },
    props: {
        kioskId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            servants: [],
            fields: [
                {
                    key: 'action',
                    tdClass: 'column',
                },
                {
                    key: 'svmid',
                    label: 'SVMID',
                    tdClass: 'column',
                    sortable: true,
                    visible: false,
                    sortOrder: 1
                },
                {
                    key: 'hardware_type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'bay_count',
                    label: '# of Bays',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
            ],
            searchCriteria: [
                {
                    key: 'svmid',
                    name: 'SVMID',
                    type: 'Text',
                    searchQueryName: 'svmid',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'installed',
                    name: 'Installed',
                    type: 'Boolean',
                    searchQueryName: 'installed',
                },
                {
                    key: 'bay_count',
                    name: '# of Bays',
                    type: 'BayCount',
                    searchQueryName: 'bayCount',
                },
            ],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
    },
    methods: {
        getServants() {
            this.$refs.servantSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            args.query.paired = false;
            this.currentQuery = args.query;
            ServantsService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.servants = response.data.items;
                        this.searchPagination.totalRows =
                            this.servants.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getServants()
        },
        pair(servantId) {
            ServantsService.pair(servantId, this.kioskId).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success
                            ? 'Pair Successful'
                            : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });

                    this.$emit('hide');
                }
            });
        },
        servantLink,
    },
};
</script>

<style scoped>
.underline {
    text-decoration: underline;
}
</style>