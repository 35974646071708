<template>
    <div>
        <CurrentLevelsComponent :kioskId="kioskId"/>
        <CarouselComponent v-for="(carousel, index) in carousels" :carousel="carousel" :isHost="index == 0" :key='carousel.id'/>
    </div>
</template>

<script>

import KioskService from "../../services/kiosk.service"
import CarouselComponent from "./CarouselComponent"
import CurrentLevelsComponent from "./CurrentLevelsComponent"

export default {
    name: "Inventory",
    props: {
        kioskId: {
            type: String,
            required: true
        },
    },
    components: {
        CarouselComponent,
        CurrentLevelsComponent
    },
    data() {
        return {
            isLoading: false,
            carousels: []
        }
    },
    mounted() {
        this.getInventory()        
    },
    methods: {
        getInventory() {
            this.isLoading = true;
            KioskService.carousels(
                this.kioskId
            ).then((response) => {
                if (response) {
                this.carousels = response.data;
                this.isLoading = false;
                }
            });
        }
    }
}
</script>

<style scoped>

</style>