<template>
    <div class="role-add-modal">
        <b-modal @submit.prevent="addRole" id="role-add-modal" size="xl" @show="refresh">
            <template #modal-title>
                <h3>Add Role</h3>
            </template>
            <b-form-group id="name-input-group" label="Name" label-for="name">
                <b-form-input 
                    id="name"
                    v-model="name"
                ></b-form-input>
            </b-form-group>
            <b-tabs>
                <b-tab title="Permissions" class="pt-3">
                    <ClaimsComponent
                        :claims="claims"
                        :selectedCheckboxes="selected"
                        :originalSelectedCheckboxes="selected"
                        @setSelectedCheckboxes="setSelectedClaim"
                    />
                    <b-form-checkbox
                        id="role-tech-checkbox"
                        v-model="requiresTechnician"
                        name="checkbox-1"
                        :disabled="!security.technician"
                        class="ml-1"
                        >
                        Technician
                    </b-form-checkbox>
                </b-tab>
                <b-tab title="Exclude Columns" class="pt-3">
                    <ExcludeColumnsByRole :roleId="roleId" @setData="setExcludedColumns"/>
                </b-tab>
            </b-tabs>
            <template #modal-footer>
                <SpinnerButton @click="addRole" :isLoading="isLoading" text="Add" class="btn-primary no-margin" />
            </template>
        </b-modal>

    </div>
</template>

<script>

import RolesService from "../../services/roles.service"
import SpinnerButton from "../SpinnerButton.vue"
import { ClaimsComponent } from "../../components/"
import ExcludeColumnsByRole from "../../components/roles/ExcludeColumnsByRole.vue"
export default {
    name: "AddRole",
    components: {
        SpinnerButton,
        ClaimsComponent,
        ExcludeColumnsByRole
    },
    data() {
        return {
            isLoading: false,
            name: "",
            selected: [],
            requiresTechnician: false,
            roleId: 0,
            typeId: 0,
            excludedColumns: []
        }
    },
    props: {
        claims: {
            type: Array,
            required: true
        },
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
    },
    emits: ['changeComplete'],
     methods: {
        addRole() {
            this.isLoading = true;
            if(this.name != ""){
                RolesService.addRole(
                this.name,
                this.selected,
                this.requiresTechnician
                ).then((response) => {
                    if (response) {
                        this.isLoading = false;
                        if(response.data.success){
                            this.roleId = response.data.id

                            if (this.typeId != 0 && this.excludedColumns != []) {
                                this.updateExcludedColumnsByRole()
                            }
                            else {
                                this.$root.$bvToast.toast(response.data.message, {
                                    title: 'Success',
                                    variant: 'success',
                                    solid: true
                                })
                            }
                        }
                        else {
                            this.$root.$bvToast.toast('Error adding role', {
                                title: 'Error',
                                variant: 'danger',
                                solid: true
                            })
                        }
                        this.$emit('changeComplete');
                    }
                });
            }
        },
        setSelectedClaim(value) {
            this.selected = value;
        },
        refresh() {
            this.name = ""
            this.selected =  []
            this.requiresTechnician = false
            this.roleId = 0
            this.typeId = 0
            this.excludedColumns = []
        },
        setExcludedColumns(typeId, excludedColumns) {
            this.typeId = typeId
            this.excludedColumns = excludedColumns
        },
        updateExcludedColumnsByRole() {
            RolesService.updateExcludedColumnsByRole(this.roleId, this.typeId, this.excludedColumns).then(
                (response) => {
                    if (response) {
                        if (response.data.success) {
                            this.$root.$bvToast.toast("Role Added", {
                                title: 'Success',
                                variant: 'success',
                                solid: true    
                            })
                        }
                        else {
                            this.$root.$bvToast.toast("Error adding excluded columns", {
                                title: 'Error',
                                variant: 'danger',
                                solid: true    
                            })
                        }
                    }
                }
            )
        },
   }
}
</script>
<style scoped>
.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.no-margin {
    margin-top: 0px !important;
}
</style>