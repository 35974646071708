<template>
    <b-modal id="events-delete-modal">
        <template #modal-title>
            <h3>Delete Event</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label>You are about to remove a registered event.</label>
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Do you want to proceed?</label>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a id="events-modal-delete-event" @click="deleteEvent()" class="btn btn-danger">Delete</a>
        </template>
    </b-modal>
</template>

<script>

import EventsService from "../../services/events.service"

export default {
    name: "DeleteEventModal",
    props: {
        registrationId: Number
    },
    emits: ['changeComplete'],
    methods: {
        deleteEvent(){
            EventsService.deleteEvent(this.registrationId).then(
                (response) => {
                    if (response) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.$emit('changeComplete')
                    }
                }
            );
        }
    }
}
</script>