<template>
    <b-form-group
        id="hwtype-select-group"
        label="Hardware Type"
        label-for="hwtype-select"
    >
        <b-form-select
            id="hwtype-select"
            v-model="selected"
            :disabled="this.disabled"
            :state="state"
        >
            <option
                v-for="hwType in hardwareTypes"
                :value="hwType.id"
                :key="hwType.value"
            >
                    {{ hwType.description }}
                </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import HardwareTypeService from '../services/hardwaretype.service';

export default {
    name: 'HardwareType',
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        noSelectionDescriptor: {
            type: String,
            required: false,
            default: 'All',
        },
        state: { 
            type: Boolean,
            default: null
        }
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
    },
        },
    },
    data() {
        return {
            hardwareTypes: [],
        };
    },
    mounted() {
        this.getTypes();
    },
    methods: {
        getTypes() {
            HardwareTypeService.getKioskTypes()
                .then((response) => {
                if (response) {
                        this.hardwareTypes = response.data;
                    }
                })
                .catch((error) => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'HardwareTypeService.getKioskTypes failed',
                    variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
</style>
