<template>
    <div data-app>
        <div class="row row-cols-1 row-cols-md-2">
            <div class="col">
                <b-form-group
                    id="description-input-group"
                    label="Description"
                    label-for="description"
                >
                    <b-form-input
                        id="description"
                        :state="descriptionState"
                        v-model="currentStore.description"
                    ></b-form-input>
                </b-form-group>
                <div v-if="descriptionState == false" class="error error-margins">
                    Description is required
                </div>
            </div>
            <div class="col">
                <b-form-group
                    id="address-input-group"
                    label="Address"
                    label-for="address"
                >
                    <b-form-input
                        id="address"
                        :state="addressState"
                        v-model="currentStore.address"
                    ></b-form-input>
                </b-form-group>
                <div v-if="addressState == false" class="error error-margins">
                    Address is required
                </div>
            </div>
            <div class="col">
                <b-form-group
                    id="city-input-group"
                    label="City"
                    label-for="city"
                >
                    <b-form-input
                        id="city"
                        :state="cityState"
                        v-model="currentStore.city"
                    ></b-form-input>
                </b-form-group>
                <div v-if="cityState == false" class="error error-margins">
                    City is required
                </div>
            </div>
            <div class="col mt-3">
                <v-autocomplete
                    :items="provinces"
                    v-model="currentStore.province"
                    item-text="abbr"
                    item-value="id"
                    :label="this.getProvinceString()"
                    return-object
                    :error="!provincesState"
                    :class="{ error: provincesState == false }"
                >
                </v-autocomplete>
                <div v-if="provincesState == false" class="error error-margins">
                    Province is required
                </div>
            </div>
            <div class="col">
                <b-form-group
                    id="postal-code-input-group"
                    label="Postal Code"
                    label-for="postal-code"
                >
                    <b-form-input
                        id="postal-code"
                        :state="postalCodeState"
                        v-model="currentStore.postal_code"
                    ></b-form-input>
                </b-form-group>
                <div v-if="postalCodeState == false" class="error error-margins">
                    Postal Code is required
                </div>
            </div>
            <div class="col">
                <b-form-group
                    id="storeno-input-group"
                    label="Number"
                    label-for="storeno"
                >
                    <b-form-input
                        id="storeno"
                        :state="numberState"
                        v-model="currentStore.number"
                    ></b-form-input>
                </b-form-group>
                <div v-if="numberState == false" class="error error-margins">
                    Store Number is required
                </div>
            </div>
            <div class="col">
                <b-form-group
                    id="shipto-input-group"
                    label="Ship To"
                    label-for="shipto"
                >
                    <b-form-input
                        id="shipto"
                        v-model="currentStore.ship_to"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col mt-3">
                <v-autocomplete
                    :items="regions"
                    v-model="currentStore.region"
                    item-text="fullDescription"
                    item-value="id"
                    label="Region: "
                    return-object
                    :error="!regionsState"
                    :class="{ error: regionsState == false }"
                >
                </v-autocomplete>
                <div v-if="regionsState == false" class="error error-margins">
                    Region is required
                </div>
            </div>
        </div>
        <CustomFieldsForm
            ref="customFieldsForm"
            :typeId="CUSTOM_FIELD_TYPE_ENUM.Store"
            :typeKeyId="currentStore.id"
        />
        <b-button variant="primary" class="right" @click="updateStore">Update</b-button>
    </div>
</template>

<script>
import { StoresService, RegionsService } from '../../services';
import CustomFieldsForm from '../../views/customfields/CustomFieldsForm.vue';
import CUSTOM_FIELD_TYPE_ENUM from '../../models/CustomFieldTypeEnum';

export default {
    name: 'UpdateStore',
    components: {
        CustomFieldsForm,
    },
    data() {
        return {
            isLoading: false,
            currentStore: {
                id: 0,
                description: '',
                address: '',
                region: null,
                number: '',
                city: '',
                province: null,
                postal_code: '',
                ship_to: '',
            },
            regions: [],
            provinces: [],
            CUSTOM_FIELD_TYPE_ENUM,
            checkValid: false
        };
    },
    props: {
        store: { type: Object },
    },
    computed: {
        descriptionState() {
            if (!this.checkValid) return null;
            return this.currentStore.description ? true : false;
        },
        addressState() {
            if (!this.checkValid) return null;
            return this.currentStore.address ? true : false;
        },
        cityState() {
            if (!this.checkValid) return null;
            return this.currentStore.city ? true : false;
        },
        provincesState() {
            if (!this.checkValid) return null;
            if (!this.currentStore.province) return false;
            return this.currentStore.province.id > 0 ? true : false;
        },
        postalCodeState() {
            if (!this.checkValid) return null;
            return this.currentStore.postal_code ? true : false;
        },
        numberState() {
            if (!this.checkValid) return null;
            return this.currentStore.number ? true : false;
        },
        regionsState() {
            if (!this.checkValid) return null;
            if (!this.currentStore.region) return false;
            return this.currentStore.region.id > 0 ? true : false;
        },
        isFormValid() {           
            return (
                this.descriptionState &&
                this.addressState &&
                this.cityState &&
                this.provincesState &&
                this.postalCodeState &&
                this.numberState &&
                this.regionsState
            );
        },
    },
    mounted() {
        this.getRegions();
        this.getProvinces();
        this.currentStore = Object.assign({}, this.store);
    },
    methods: {
        updateStore() {
            this.checkValid = true
            if (this.isFormValid && this.$refs.customFieldsForm.isFormValid()) {
                this.isLoading = true;
                this.currentStore.custom_field_values =
                    this.$refs.customFieldsForm.getValues();

                StoresService.update(this.currentStore).then((response) => {
                    if (response) {
                        if (response.data.success) {
                            this.$root.$bvToast.toast('Store info updated', {
                                title: 'Success',
                                variant: 'success',
                                solid: true,
                            });
                            this.$emit('hide');
                        } else {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                                solid: true,
                            });
                            this.$emit('hide');
                        }
                    }
                });
            }
        },
        getRegions() {
            RegionsService.get()
                .then((response) => {
                    this.regions = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'RegionsService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        getProvinces() {
            RegionsService.getProvinces()
                .then((response) => {
                    this.provinces = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'RegionsService.getProvinces failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
    },
};
</script>
<style scoped>
.error {
    color: #dc3545;
}

.error-margins {
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>