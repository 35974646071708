<template>
    <div>
        <span v-if="lastUpdated" class="last_updated">Problems up to date since {{getTimeOnly12Hours(lastUpdated)}}</span>
        <h3>
            <font-awesome-icon icon="home" class="nav-icon fa" />  Dashboard
        </h3>
        <!-- TODO: Add back user help guide with split HTML -->
        <h5 v-if="false" @click="openHelpWindow()" class="help-link" title="Sleegers User Guide">
            Sleegers User Guide
            <font-awesome-icon icon="question-circle" class="help-icon nav-icon fa"/>
        </h5>
        <h4 class="center">Favorite kiosk overview</h4>
        <div>
            <h4 class="center">Problems</h4>
            <div class="center-content">
                <DonutGraphComponent :chartData="problems" labelName="name" dataName="value" v-if="displayProblemsGraph" class="graph"/>
            </div>
            <h6 class="center mt-5" v-if="!displayProblemsGraph">
                <font-awesome-icon icon="circle-exclamation" class="nav-icon fa"/>
                Favorited kiosks don't have any problems
            </h6>
            <div class="pb-3">
                <Table
                id="favouriteProblemsTable"
                :fields="fields"
                :items="kioskProblems"
                sortBy=""
                :sortDesc="false"
                :searchPagination="searchPagination"
                @getItems="getKioskProblems"
                v-if="displayProblemsGraph"
                >
                    <template #cell(vmid)="data">
                        <a href="#" @click="kioskLink(data.item.id)" class="pointer underline">
                            {{ data.item.vmid }}
                        </a>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<script>
import { FleetService } from "../services"
import { DonutGraphComponent, Table } from "../components"
import SearchPagination from '../models/search.pagination'
import { kioskLink, getTimeOnly12Hours, openHelpWindow } from '../utils/utils'

export default {
    name: "Home",
    components: {
        DonutGraphComponent,
        Table
    },
    data() {
        return {
            problems: [],
            hardwareTypeId: 0,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            displayProblemsGraph: false,
            fields: [
            {
                key: 'vmid',
                label: 'VMID',
                tdClass: 'column',
                sortable: false
            },
            {
                key: 'store.description',
                label: 'Store',
                tdClass: 'column',
                sortable: false
            },
            {
                key: 'store.number',
                label: 'Store Number',
                tdClass: 'column',
                sortable: false
            },
            {
                key: 'store.address',
                label: 'Address',
                tdClass: 'column',
                sortable: false
            },
            {
                key: 'store.city',
                label: 'City',
                tdClass: 'column',
                sortable: false
            },
            {
                key: 'store.province.abbr',
                label: 'Province',
                tdClass: 'column',
                sortable: false
            },
            {
                key: 'event',
                label: 'Event',
                tdClass: 'column',
                sortable: false
            },
            ],
            kioskProblems: [],
            lastUpdated: null
        }
    },
    mounted() {
        this.getKioskProblems();
        setInterval(this.getKioskProblems, 1000 * 60 * 1)
    },
    methods: {
        getKioskProblems() {
            this.isLoading = true;
            FleetService.listKioskProblems(this.hardwareTypeId,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                true,
                this.problems
            ).then((response) => {
                if (response && response.data) {
                    this.displayProblemsGraph = response.data.items.length > 0
                    this.kioskProblems = response.data.kiosk_problems;
                    this.searchPagination.totalRows = 
                        this.kioskProblems.length > 0 ? response.data.total_issues : 0;
                    this.problems = response.data.items
                    this.isLoading = false;
                    this.lastUpdated = new Date()
                }
            })
        },
        kioskLink,
        getTimeOnly12Hours,
        openHelpWindow
    }
};
</script>
<style scoped>
.center {
    text-align: center;
}
.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.graph {
    max-width: 400px;
    max-height: 400px;  
}
.underline {
    text-decoration: underline;
}

.last_updated {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    opacity: 60%;
    font-size: 0.8rem;
    margin-top: -1rem;
}

@media only screen and (max-width: 575.5px) {
    .last_updated {
        font-size: 1rem;
    }
}
</style>