import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils"
class UptimeService {
  searchQuery(query, startIndex, pageSize, sortOrder) {
    return axios({
      method: 'post',
      url: '/api/uptime/search_with_query',
      headers: authHeader(),
      data: {
        vm_id: query.vmid,
        region_id: query.region,
        province_id: query.province,
        product_id: query.product,
        hardware_type_id: query.hardwareType,
        date_start: addTimezoneInformation(query.dateStart),
        date_end: addTimezoneInformation(query.dateEnd),
        start_index: startIndex,
        page_size: pageSize,
        sort_order: sortOrder
      }
    })
  }

  searchRegionQuery(query, startIndex, pageSize, sortOrder) {
    return axios({
      method: 'post',
      url: '/api/uptime/search_region_with_query',
      headers: authHeader(),
      data: {
        vmid: query.vmid,
        region_id: query.region,
        province_id: query.province,
        product_id: query.product,
        hardware_type_id: query.hardwareType,
        store_number: query.storeNumber,
        date_start: addTimezoneInformation(query.dateStart),
        date_end: addTimezoneInformation(query.dateEnd),
        start_index: startIndex,
        page_size: pageSize,
        sort_order: sortOrder
      }
    })
  }

  downloadUptimeCsv(query) {
    return axios({
      method: 'get',
      url: '/api/uptime/download_csv',
      headers: authHeader(),
      params: {
        vmId: query.vmid,
        regionId: query.region,
        provinceId: query.province,
        productId: query.product,
        hardwareTypeId: query.hardwareType,
        dateStart: addTimezoneInformation(query.dateStart),
        dateEnd: addTimezoneInformation(query.dateEnd)
      }
    })
  }

  downloadUptimeXlsx(query) {
    return axios({
      method: 'get',
      responseType: "blob",
      url: '/api/uptime/download_xlsx',
      headers: authHeader(),
      params: {
        vmId: query.vmid,
        regionId: query.region,
        provinceId: query.province,
        productId: query.product,
        hardwareTypeId: query.hardwareType,
        dateStart: addTimezoneInformation(query.dateStart),
        dateEnd: addTimezoneInformation(query.dateEnd)
      }
    })
  }

  downloadRegionUptimeCsv(query) {
    return axios({
      method: 'get',
      url: '/api/uptime/download_region_csv',
      headers: authHeader(),
      params: {
        vmId: query.vmid,
        regionId: query.region,
        provinceId: query.province,
        productId: query.product,
        hardwareTypeId: query.hardwareType,
        dateStart: addTimezoneInformation(query.dateStart),
        dateEnd: addTimezoneInformation(query.dateEnd)
      }
    })
  }

  downloadRegionUptimeXlsx(query) {
    return axios({
      method: 'get',
      responseType: "blob",
      url: '/api/uptime/download_region_xlsx',
      headers: authHeader(),
      params: {
        vmId: query.vmid,
        regionId: query.region,
        provinceId: query.province,
        productId: query.product,
        hardwareTypeId: query.hardwareType,
        dateStart: addTimezoneInformation(query.dateStart),
        dateEnd: addTimezoneInformation(query.dateEnd)
      }
    })
  }
}
export default new UptimeService();