<template>
    <div>
        <div class="flex-group">
            <div class="column-container">
                <div class="content-column content-margin">
                    <h1 class="title text-center">My Profile</h1>
                    <form name="form" @submit.prevent="updateProfile">
                            <b-form-group id="username-input-group" label="Username" label-for="username">
                                <b-form-input 
                                    id="username"
                                    v-model="user.username"
                                    :disabled="true"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group id="name-input-group" label="Name" label-for="name">
                                <b-form-input 
                                    id="name"
                                    v-model="user.name"
                                    :disabled="!security.userWrite"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group id="email-input-group" label="Email" label-for="email">
                                <b-form-input 
                                    id="email"
                                    v-model="user.email"
                                    :disabled="!security.userWrite"
                                ></b-form-input>
                            </b-form-group>
                            <div class="content-row">
                                <div class="content-row">
                                    <button class="responsive-margin btn btn-primary button-width" @click.prevent="enableTwoFa" v-if="!security.hasTwoFaEnabled && !user.is_azure_sso">Enable Two FA</button>
                                    <div id="disable-two-fa">
                                        <button class="responsive-margin btn btn-primary button-width" 
                                        @click.prevent="disableTwoFa" 
                                        v-if="security.hasTwoFaEnabled && !user.is_azure_sso" 
                                        :disabled="security.requiresTwoFa"
                                        
                                        >
                                            Disable Two FA
                                        </button>
                                    </div>
                                    <b-tooltip target="disable-two-fa" placement="left" v-if="security.requiresTwoFa">
                                        Can't disable Two FA because it's a requirement of the user's role
                                    </b-tooltip>
                                    <button v-if="!user.is_azure_sso" class="responsive-margin left-spacing btn btn-primary button-width" @click.prevent="openUpdatePasswordModal">Update Password</button>
                                    <button v-if="!user.is_azure_sso && isSSOAvailable" class="responsive-margin left-spacing btn btn-primary button-width" @click.prevent="openConvertToAzureAccountModal">Convert To SSO Login</button>
                                </div> 
                                <SpinnerButton v-if="security.userWrite" :isLoading="isLoading" text="Update" class="button-width no-top-margin"/>
                            </div>
                    </form>
                    <div>
                        
                    </div>
                </div>
            </div>
            <div class="column-container" v-if="security.eventRead">
                <div class="content-column content-margin">
                    <div class="event-group">
                        <h1 class="title text-center mb-3">My Events</h1>
                        <div class="content-column align-center">
                            <div class="event">
                                <EventGroupNestedComponent
                                    :eventGroups="eventGroups"
                                    :selected="selectedEvents"
                                    @setSelectedCheckboxes="setSelectedEventTypes"/>
                                <div class="right">
                                    <SpinnerButton :isLoading="isEventsLoading" text="Update" class="button-width mb-3" @click="updateEventRegistration"/>
                                </div>  
                            </div>  
                        </div>  
                    </div>  
                </div>
            </div>
        </div>
        <DisableTwoFaModal @disableComplete="disableComplete"/>
        <UpdatePasswordModal :user="user" @changeComplete="hideUpdatePasswordModal"/>
        <b-modal id="convert-to-azure-modal">
            <template #modal-title>
                Link to Azure
            </template>
            <div>Click below to link to your azure account</div>
            <div>This process is NOT reversable</div>
            <template #modal-footer>
                <button @click="convertToAzureAccount"><img src="../../assets/users/ms-symbollockup_signin_light.png"></button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import SpinnerButton from "../../components/SpinnerButton.vue";
import DisableTwoFaModal from "../../components/profile/DisableTwoFaModal.vue"
import { UsersService, EventsService } from '../../services';
import EventGroupNestedComponent from '../../components/EventGroupNestedComponent.vue';
import UpdatePasswordModal from '../../components/users/UpdatePasswordModal.vue';
import { isMobile } from "../../utils/utils";
export default {
    name: "Profile",
    components: {
        SpinnerButton,
        DisableTwoFaModal,
        EventGroupNestedComponent,
        UpdatePasswordModal,
    },
    data() {
        return {
            isLoading: false,
            isEventsLoading: false,
            user: {},
            eventGroups: [],
            selectedEvents: [],
            isSSOAvailable: false
        };
    },
    computed:{
        security() {
            return this.$store.state.auth.security
        },
    },
    created() {
        this.getUser()
        this.getEvents()

        this.isSSOAvailable = this.$store.state.auth.portal_configuration.allowsso == '1';
    },
    methods: {
        async updateProfile() {
            if(this.user.name == "") {
                this.message = "Please provide a name.";
                return;
            }
            const regex = new  RegExp('[a-z0-9]+@[a-z0-9]+\\.[a-z]{2,3}');
            if(!regex.test(this.user.email)) {
                this.message = "Please provide a valid email format.";
                return;
            }
            this.message = "";
            this.isLoading = true
            UsersService.update(
                this.user.id,
                this.user.username,
                this.user.name,
                this.user.email,
                null
            ).then((response) => {
                if (response.data.success) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: 'Update User Profile Successful',
                        variant: 'success',
                        solid: true
                    })
                }
                else{
                    this.$root.$bvToast.toast(response.data.message, {
                        title: 'Update User Profile Error',
                        variant: 'danger',
                        solid: true
                    })
                }

                this.isLoading = false
            });
        },
        enableTwoFa() {
            this.$router.push("/enabletwofa");
        },
        disableTwoFa() {
            this.$bvModal.show('profile-disable-two-fa-modal')
        },
        getUser() {
            UsersService.getUser().then(
                (response) => {
                    if (response) {
                        this.user = response.data;
                        this.getUserEvents()
                    }
                }
            )
        },
        disableComplete(result){
            this.$bvModal.hide('profile-disable-two-fa-modal')
            this.$bvModal.msgBoxOk(result);
            this.getUsers();
        },
        getEvents() {
            EventsService.getGroups().then(
                (response) => {
                    this.eventGroups = response.data;
                }
            );
        },
        getUserEvents() {
            EventsService.getByUserId(this.user.id).then(
                (response) => {
                    this.selectedEvents = response.data.eventTypes.map(eventType => eventType.evType)
            });
        },
        setSelectedEventTypes(value) {
            this.selectedEvents = value
        },
        updateEventRegistration() {
            this.isEventsLoading = true
            EventsService.register(
                this.user.id,
                this.selectedEvents
            ).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })
                    
                }
                this.isEventsLoading = false
            },
            (error) => {
                console.log(error)
                this.isEventsLoading = false
            });
        },
        openUpdatePasswordModal() {
            this.$bvModal.show('password-update-modal')
        },
        hideUpdatePasswordModal(){
            this.$bvModal.hide('password-update-modal')
        },
        openConvertToAzureAccountModal() {
            this.$bvModal.show('convert-to-azure-modal')
        },
        hideConvertToAzureAccountModal() {
            this.$bvModal.hide('convert-to-azure-modal')
        },
        convertToAzureAccount(){
            this.message = "";
            let redirectUri = "";

            if(isMobile()){
                redirectUri = `${window.location.origin}/login`
            }
            else {
                redirectUri = `${window.location.origin}/`
            }
            
            const loginRequest = {
                scopes: ["User.Read", "email"],
                state: this.inviteTokenId,
                redirectUri: redirectUri,
                loginHint: `${this.user.email}`
            };
            if (isMobile()){
                this.$msal.loginRedirect(loginRequest);
            }
            else {
                this.$msal.loginPopup(loginRequest)
                    .then((response) => {
                    if (response){
                        UsersService.convertUserAzure(this.user.id, response.idToken).then(
                            (response) => {
                                this.checkValid = false
                                this.$root.$bvToast.toast(response.data.message, {
                                    title: response.data.success ? 'Success' : 'Error',
                                    variant: response.data.success?  'success' : 'danger',
                                    solid: true
                                })
                                if (response.data.success) {
                                    this.$router.go();
                                }
                            }
                        );
                    }
                }).catch((error) => {
                    console.error("Error handling redirect:", error)
                })
            }
            this.hideConvertToAzureAccountModal();
        }
    }
}
</script>
<style scoped> 
.flex-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
.column-container {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}
.content-column {
    display: flex;
    flex-direction: column;
}
.content-margin {
    margin-left: 1rem;
    margin-right: 1rem;
}
.content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.right {
    float: right;
}
.event {
    width: 80%
}
.align-center {
    align-items: center;
}
.left-spacing{
    margin-left: 1rem;
}
.responsive-margin {
    margin-top: 0rem;
}
.no-top-margin{
    margin-top: 0rem !important;
}

@media only screen and (max-width: 1000px) {
    .content-row {
        flex-direction: column-reverse;
    }
    .button-width {
        width: 100%;
    }
    .left-spacing{
        margin-left: 0rem;
    }
    .responsive-margin {
        margin-top: 1rem;
    }
    .event-group {
        margin-top: 1rem;
    }
    .event {
        width: 100%
    }
    .flex-group {
        flex-direction: column;
    } 
    .right {
        float: none;
    }
}
</style>
