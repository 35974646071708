<template>
    <div>
        Duplicating a role will create a new role with the same access as the source role
        <b-form-group id="name-input-group" label="Name" label-for="name" class="mt-2">
            <b-form-input id="name" v-model="name" :state="nameState"/>
            <div v-if="checkValid  && !roleNameIsUnique" class="error">
                Role name is not unique
            </div>
        </b-form-group>
        <button class="btn btn-primary right mt-2" @click="duplicateRole()">
            Save
        </button>
    </div>
</template>

<script>
import { RolesService } from '../../services';

export default {
    name: "DuplicateRoleModal",
    props: {
        roleId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            name: "",
            checkValid: false,
            roleNameIsUnique: false
        }
    },
    computed: {
        nameState() {
            if (!this.checkValid) return null
            return this.name != "" && this.roleNameIsUnique
        },
        isFormValid() { 
            return this.nameState 
        }
    },
    watch: {
        name() {
            this.roleNameIsUnique = false
            this.isRoleNameUnique()  
        }
    },
    methods: {
        duplicateRole() {
            this.checkValid = true
            if (this.isFormValid) {
                RolesService.duplicateRole(this.roleId, this.name).then((response) => {
                    if (response) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success?  'success' : 'danger',
                            solid: true
                        })
                        
                        this.$emit('hide');
                    }
                });
            }
        },
        isRoleNameUnique() {
            return RolesService.isRoleNameUnique(this.name).then((response) => {
                    if (response) {
                        this.roleNameIsUnique = response.data
                    }
            })
        },
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
}
</style>