<template>
    <div>
        <h3><font-awesome-icon icon="upload" class="nav-icon fa" />  Firmware</h3>
        <div>
            <HardwareTypeComponent
                v-model="hardwareTypeId"
                @input="getKioskProblems"
                :state="null"
            />
            <b-form-group id="installed-select-group" label="Installed" label-for="installed-select">
                <b-form-select
                    id="installed-select"
                    v-model="selectedInstall"
                    @input="getKioskProblems">
                        <option>All</option>
                        <option :value="true">True</option>
                        <option :value="false">False</option>
                </b-form-select>
            </b-form-group>
            <b-form-checkbox
                id="filter-unknown"
                v-model="filterUnknown"
                switch
                class="right filter-unknown"
            >
            Filter Unknown
            </b-form-checkbox>
        </div>
        <SpinnerNoResults :isLoading="isLoading" :spinnerOnly="true" class="mt-5" v-if="isLoading"/>
        <div v-else>
            <div>
                <h4>Firmware</h4>
                <DonutGraphComponent 
                    :chartData="filteredFirmware"
                    labelName="version" 
                    dataName="count"
                />
            </div>
            <div>
                <h4>Media</h4>
                <DonutGraphComponent :chartData="filteredMedia"
                    labelName="version" 
                    dataName="count" 
                    />
            </div>
            <div>
                <h4>Payment</h4>
                <DonutGraphComponent 
                    :chartData="filteredPayment"
                    labelName="version" 
                    dataName="count" 
                />
            </div>
            <div>
                <h4>Payment Term</h4>
                <DonutGraphComponent
                    :chartData="filteredPaymentTerm"
                    labelName="version" 
                    dataName="count" 
                />
            </div>
            <div>
                <h4>Pneumatic</h4>
                <DonutGraphComponent 
                    :chartData="filteredPneu"
                    labelName="version" 
                    dataName="count" 
                />

            </div>
            <div>
                <h4>Relay Client</h4>
                <DonutGraphComponent 
                    :chartData="filteredRelay"
                    labelName="version" 
                    dataName="count" 
                />
            </div>
            <div>
                <h4>Shell Script</h4>
                <DonutGraphComponent 
                    :chartData="filteredScript"
                    labelName="version" 
                    dataName="count" 
                />
            </div>
            <div>
                <h4>Touch Screen</h4>
                <DonutGraphComponent 
                    :chartData="filteredTouch"
                    labelName="version" 
                    dataName="count" 
                />
            </div>
        </div>
    </div>
</template>

<script>
import { FleetService } from "../../services"
import { HardwareTypeComponent, DonutGraphComponent } from "../../components/"
import SpinnerNoResults from '../../components/SpinnerNoResults.vue';
export default {
    name: "Firmware",
    components: {
        HardwareTypeComponent,
        DonutGraphComponent,
        SpinnerNoResults
    },
    data() {
        return {
          firmware: [],
          media: [],
          payment: [],
          paymentTerm: [],
          pneu: [],
          relay: [],
          script: [],
          touch: [],
          filteredFirmware: [],
          filteredMedia: [],
          filteredPayment: [],
          filteredPaymentTerm: [],
          filteredPneu: [],
          filteredRelay: [],
          filteredScript: [],
          filteredTouch: [],
          isLoading: false,
          hardwareTypeId: 0,
          selectedInstall: null,
          filterUnknown: true
        };
    },
    mounted() {
        this.getKioskProblems();
    },
    watch: {
        filterUnknown() {
            this.filterAllData()
        }
    },
    methods: {
        getKioskProblems() {
            this.isLoading = true;
            FleetService.listFleetFirmware(this.hardwareTypeId,
                this.selectedInstall,
                false
            ).then((response) => {
                if (response) {
                    this.firmware = response.data.firmware;
                    this.media = response.data.media;
                    this.payment = response.data.payment;
                    this.paymentTerm = response.data.payment_term;
                    this.pneu = response.data.pneu;
                    this.relay = response.data.relay;
                    this.script = response.data.script;
                    this.touch = response.data.touch;
                    this.isLoading = false;

                    this.filterAllData()
                }
            })
        },
        openKioskInfo(item) {
            var portalId = this.$store.state.auth.portal.id
            var routeData = this.$router.resolve({ path: "/kiosk/", query: { "kiosk_id": item.kiosk.id, "portal_id" : portalId }} )
            window.open(routeData.href, "kiosk_" + item.kiosk.id)
        },
        filterAllData() {
            this.filteredFirmware = this.filterData(this.firmware, 'version')
            this.filteredMedia = this.filterData(this.media, 'version')
            this.filteredPayment = this.filterData(this.payment, 'version')
            this.filteredPaymentTerm = this.filterData(this.paymentTerm, 'version')
            this.filteredPneu = this.filterData(this.pneu, 'version')
            this.filteredRelay = this.filterData(this.relay, 'version')
            this.filteredScript = this.filterData(this.script, 'version')
            this.filteredTouch = this.filterData(this.touch, 'version')
        },
        filterData(data, name) {
            if (this.filterUnknown && data) {
                return data.filter(data => data[name] != 'unknown')
            }
            else {
                return data
            }
        }
    }
}
</script>
<style scoped>
.filter-unknown {
    margin-top: 0.25rem;
}
</style>