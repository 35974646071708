<template>
    <b-form>
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group
                    id="full-levels-group"
                    label="Full Tanks"
                    label-for="full-levels-input"
                >
                    <b-form-input
                        id="full-levels-input"
                        v-model="this.currentLevels.full_tanks"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="empty-levels-group"
                    label="Empty Tanks"
                    label-for="empty-levels-input"
                >
                    <b-form-input
                        id="empty-levels-input"
                        v-model="this.currentLevels.empty_tanks"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="propane-levels-group"
                    label="Propane"
                    label-for="propane-levels-input"
                >
                    <b-form-input
                        id="propane-levels-input"
                        v-model="this.currentLevels.propane"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
    </b-form>
</template>

<script>
import KioskService from '../../services/kiosk.service';
import ServantsService from '../../services/servants.service';

export default {
    name: 'CurrentLevels',
    props: {
        kioskId: {
            type: String,
            required: false,
        },
        servantId: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            loading: false,
            currentLevels: {},
        };
    },
    mounted() {
        if (this.kioskId != null) {
            this.getCurrentLevels();
        } else if (this.servantId != null) {
            this.getCurrentServantLevels();
        }
    },
    methods: {
        getCurrentLevels() {
            this.loading = true;
            KioskService.currentLevels(this.kioskId).then((response) => {
                if (response) {
                    this.currentLevels = response.data;
                    this.loading = false;
                }
            });
        },

        getCurrentServantLevels() {
            this.loading = true;
            ServantsService.currentLevels(this.servantId).then((response) => {
                if (response) {
                    this.currentLevels = response.data;
                    this.loading = false;
                }
            });
        },
    },
};
</script>

<style scoped>
</style>