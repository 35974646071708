<template>
    <div>
        <b-tabs lazy>
            <b-tab title="Bulk Import">
                <BulkImportKiosks @hide="hide"/>
            </b-tab>
            <b-tab title="Manual Import">
                <AddKioskComponent @hide="hide"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

import { AddKioskComponent, BulkImportKiosks } from './index'

export default {
    name: 'AddKioskModalTabs',
    components: {
        AddKioskComponent,
        BulkImportKiosks,
    },
    methods: {
        hide() {
            this.$emit('hide')
        }
    }
}
</script>

<style scoped>
</style>