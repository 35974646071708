<template>
    <div>
        <h3>
            <font-awesome-icon icon="gear" class="nav-icon fa" /> Custom Fields
        </h3>
        <div class="button-group">
            <div class="button-col">
                <button
                    @click="showAddCustomFieldModal"
                    class="btn btn-primary mt-2 button"
                    v-if="security.customFieldsCreate"
                >
                    Add Custom Field
                </button>
            </div>
        </div>
        <br />
        <DynamicSearchComponent
            ref="customFieldSearchComponent"
            preferencesName="custom_field_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <div class="pb-3">
            <Table
                id="singleUseTable"
                :fields="dynamicFields"
                :items="customFields"
                sortBy="field_name"
                :sortDesc="false"
                :searchPagination="searchPagination"
                @getItems="listCustomFields"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="data">
                    <div class="icons">
                        <font-awesome-icon icon="pen-to-square" class="nav-icon pointer mx-2" @click="showUpdateCustomFieldModal(data.item)" v-if="security.customFieldsWrite" title="Title"/>
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" @click="showDeleteCustomFieldModal(data.item)" v-if="security.customFieldsDelete" title="Delete"/>
                    </div>
                </template>
                <template #cell(required)="data">
                    <font-awesome-icon
                        :icon="data.item.required ? 'check' : 'xmark'"
                        :color="data.item.required ? 'green' : 'red'"
                    />
                </template>
                <template #cell(include_in_export)="data">
                    <font-awesome-icon
                        :icon="data.item.include_in_export ? 'check' : 'xmark'"
                        :color="data.item.include_in_export ? 'green' : 'red'"
                    />
                </template>
            </Table>
        </div>
        <b-modal
            id="add-update-custom-field"
            ref="add-update-custom-field"
            hide-footer
        >
            <template #modal-title>
                Custom Field -
                {{ selectedCustomField == undefined ? 'New ' : 'Update' }}
            </template>
            <AddUpdateCustomField
                :id="
                    selectedCustomField != undefined
                        ? selectedCustomField.id
                        : undefined
                "
                @hide="hideAddCustomFieldModal"
            />
        </b-modal>
        <b-modal id="delete-custom-field" ref="delete-custom-field" hide-footer>
            <template #modal-title> Delete Custom Field </template>
            <div class="d-block">
                <p>
                    You are about to delete custom field:
                    {{
                        selectedCustomField != undefined
                            ? selectedCustomField.field_name
                            : ''
                    }}
                </p>
                <p>Do you want to proceed?</p>
            </div>
            <div class="float-right">
                <button variant="" class="mt-3 btn btn-danger" @click="deleteCustomField()">
                    Delete
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../components';
import AddUpdateCustomField from './AddUpdateCustomField.vue';
import { generateDynamicFields } from '../../utils/utils';
import CustomFieldsService from '../../services/customfields.service';

export default {
    name: 'CustomFields',
    components: {
        Table,
        DynamicSearchComponent,
        AddUpdateCustomField,
    },
    data() {
        return {
            customFields: [],
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'field_name',
                    label: 'Field Name',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'data_type',
                    label: 'Data Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'required',
                    label: 'Required',
                    tdClass: 'center',
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'include_in_export',
                    label: 'Include In Export',
                    tdClass: 'center',
                    sortable: true,
                    sortOrder: 5
                },
            ],
            searchCriteria: [
                {
                    key: 'field_name',
                    name: 'Field Name',
                    type: 'Text',
                    searchQueryName: 'fieldName',
                },
                {
                    key: 'type',
                    name: 'Type',
                    type: 'CustomFieldType',
                    searchQueryName: 'typeId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'data_type',
                    name: 'Data Type',
                    type: 'CustomFieldDataType',
                    searchQueryName: 'dataTypeId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'require',
                    name: 'Required',
                    type: 'Boolean',
                    searchQueryName: 'required',
                },
                {
                    key: 'include_in_export',
                    name: 'Include In Export',
                    type: 'Boolean',
                    searchQueryName: 'includeInExport',
                },
            ],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: 1,
            selectedCustomField: {},
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        dynamicFields() {
            return generateDynamicFields(
                this.fields,
                'actions',
                !this.security.customFieldsDelete &&
                    !this.security.customFieldsWrite
            );
        },
    },
    mounted() {
        this.listCustomFields();
    },
    methods: {
        listCustomFields() {
            this.$refs.customFieldSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            CustomFieldsService.list(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then(
                (response) => {
                    if (response) {
                        this.customFields = response.data.items;
                        this.searchPagination.totalRows =
                            this.customFields.length > 0
                                ? response.data.total_rows
                                : 0;
                        args.done();
                    }
                },
                (error) => {
                    if (error) {
                        args.done();
                    }
                }
            );
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.listCustomFields()
        },
        showAddCustomFieldModal() {
            this.selectedCustomField = undefined;
            this.$refs['add-update-custom-field'].show();
        },
        hideAddCustomFieldModal() {
            this.$refs['add-update-custom-field'].hide();
            this.listCustomFields();
        },
        showUpdateCustomFieldModal(customField) {
            this.selectedCustomField = customField;
            this.$refs['add-update-custom-field'].show();
        },
        showDeleteCustomFieldModal(customField) {
            this.selectedCustomField = customField;
            this.$refs['delete-custom-field'].show();
        },
        hideDeleteCustomFieldModal() {
            this.$refs['delete-custom-field'].hide();
            this.listCustomFields();
        },
        deleteCustomField() {
            CustomFieldsService.delete(this.selectedCustomField.id).then(
                (response) => {
                    if (response) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success
                                ? 'success'
                                : 'danger',
                            solid: true,
                        });

                        this.hideDeleteCustomFieldModal();
                    }
                }
            );
        },
    },
};
</script>
<style scoped>
.right {
    float: right;
}
.center {
    text-align: center;
}

::v-deep .action-column {
    min-width: 30px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }
}
</style>