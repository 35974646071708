<template>
    <b-form-group
        id="product-select-group"
        label="Product"
        label-for="product-select"
    >
        <b-form-select
            id="product-select"
            v-model="selected"
            :disabled="this.disabled"
            :state="state"
        >
            <option :value="0" v-if="!hideOptionAll">All</option>
            <option v-for="prod in products" :value="prod.id" :key="prod.id">
                {{ prod.description }}
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import ProductsService from '../services/products.service';

export default {
    name: 'Products',
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        state: {
            type: Boolean,
            required: false,
        },
        hideOptionAll: {
            type: Boolean,
            required: false,
        },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    data() {
        return {
            products: [],
        };
    },
    mounted() {
        this.getProducts();
    },
    methods: {
        getProducts() {
            ProductsService.get()
                .then((response) => {
                    this.products = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'ProductsService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
</style>