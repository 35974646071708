<template>
    <div>
        <div class="mt-3">
            <TreeViewComponent
                :parents="rootRegions"
                :defaultCollapse="false"
                nodeName="description"
                childrenName="subregions"
                :canRead='security.regionsCreate'
                :canWrite='security.regionsWrite'
                :canCreate='security.regionsCreate'
                :canDelete='security.regionsDelete'
                :showAddRoot="true"
                :linkButtons="true"
                addRootName="Add Top Region"
                @editNode="showEditNodeModal"
                @addChild="showAddChildModal"
                @deleteNode="showDeleteModal"
                @addRoot="showAddRootModal"
            />
        </div>
        <b-modal id="update-region-modal" ref="update-region-modal" hide-footer>
            <template #modal-title>Update Region</template>
            <RegionAddUpdateComponent :region="selectedRegion" :isUpdate="true" @hide="hideEditNodeModal"/>
        </b-modal>
        <b-modal id="add-region-modal" ref="add-region-modal" hide-footer>
            <template #modal-title>Add Region</template>
            <RegionAddUpdateComponent :region="selectedRegion" @hide="hideAddChildModal"/>
        </b-modal>
        <b-modal id="delete-region-modal" ref="delete-region-modal">
            <template #modal-title>Delete Region</template>
            Are you sure you want to delete the region {{this.selectedRegion.description}}?
            <template #modal-footer>
                <button class="btn btn-danger" @click="deleteRegion()">
                    Delete
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import TreeViewComponent from '../TreeViewComponent.vue';
import RegionAddUpdateComponent from './RegionAddUpdateComponent';
import { RegionsService } from '../../services';

export default {
    name: "RegionsTreeViewComponent",
    components: {
        TreeViewComponent,
        RegionAddUpdateComponent
    },
    data() {
        return {
            regions: [],
            selectedRegion: {}
        }
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
        rootRegions() {
            return this.regions
                .filter(region => !region.parentDescription)
                .sort((a, b ) => (a.description > b.description) ? 1 : ((b.description > a.description) ? -1 : 0))
        }
    },
    mounted() {
        this.getRegions()
    },
    methods: {
        getRegions() {
            RegionsService.get().then((response)=> {
                if (response) {
                    this.regions = response.data
                }
            })
        },
        showEditNodeModal(region) {
            this.selectedRegion = region
            this.$refs["update-region-modal"].show()
        },
        showAddChildModal(region) {
            this.selectedRegion = region
            this.$refs["add-region-modal"].show()
        },
        showDeleteModal(region) {
            this.selectedRegion = region
            this.$refs["delete-region-modal"].show()
        },
        showAddRootModal() {
            var region = {}
            region.description = ""
            this.selectedRegion = region
            this.$refs["add-region-modal"].show()
        },
        hideEditNodeModal() {
            this.$refs["update-region-modal"].hide()
            this.getRegions()
        },
        hideAddChildModal() {
            this.$refs["add-region-modal"].hide()
            this.getRegions()
        },
        hideDeleteModal() {
            this.$refs["delete-region-modal"].hide()
            this.getRegions()
        },
        deleteRegion() {
            RegionsService.delete(this.selectedRegion.id).then((response)=> {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });
                    this.hideDeleteModal();
                }
            })
        }
    }
}
</script>
<style scoped>
</style>