<template>
    <div>
        <b-form-group id="providedto-input-group" label="Provided To" label-for="providedTo">
            <b-form-input 
                id="providedto-input"
                v-model="freeItemCode.providedTo"
                :state="providedToState"
            >
            </b-form-input>
        </b-form-group>
        <div v-if="providedToState == false" class="error">
            Provided To is required
        </div>
        <LocationSelectComponent v-model="freeItemCode.location" :state="locationState"/>
        <b-form-group id="expires-date-group" label="Expires" label-for="expires-date">
            <b-input-group>
                <b-form-input
                    id="expires-date-input"
                    v-model="freeItemCode.expires"
                    placeholder="YYYY-MM-DD"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                    autocomplete="off"
                    :state="expiresState"
                    :disabled="true"
                    >
                </b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="expires-date"
                        v-model="freeItemCode.expires"
                        button-only
                        right
                        :min="minDate" 
                        :max="maxDate"
                    >
                    </b-form-datepicker>
                </b-input-group-append>
            </b-input-group>   
        </b-form-group>
        <div v-if="expiresState == false" class="error">
            Expiry date is required
        </div>
        <ProductsComponent v-model="freeItemCode.productId" :hideOptionAll="true" :state="productIdState"/>
        <div v-if="productIdState == false" class="error">
            Product is required
        </div>
        <b-form-group id="quantity-input-group" label="Quantity" label-for="quantity">
            <b-form-spinbutton 
                id="quantity-input" 
                v-model="freeItemCode.quantity" 
                inline
                :state="quantityState"
                >
            </b-form-spinbutton>
        </b-form-group>
        <div v-if="quantityState == false" class="error">
            Quantity must be greater than 0
        </div>
        <b-form-group id="creditcard-input-group" label="Credit Card" label-for="creditCard">
            <b-form-input 
                id="creditcard-input"
                v-model="freeItemCode.creditCard"
                :state="creditCardState"
            >
            </b-form-input>
        </b-form-group>
        <div v-if="creditCardState == false" class="error">
            Credit Card is required
        </div>
        <b-button @click="generateFreeItemCode" variant="primary" class="right">Add</b-button>
    </div>
</template>
<script>

import PromosService from "../../services/promos.service"
import { ProductsComponent, LocationSelectComponent } from "../../components"

export default {
    name: "AddFreeItemCode",
    components: {
        ProductsComponent,
        LocationSelectComponent
    },
    data() {
        return {
            freeItemCode:{
                providedTo: "",
                location: {},
                expires: "",
                productId: 0,
                quantity: 1,
                creditCard: "",
            },
            minDate: {},
            maxDate: {},
            checkValid: false         
        };
    },
    computed: {
        providedToState() {
            if (!this.checkValid) return null
            return this.freeItemCode.providedTo != ""
        },
        locationState() {
            if (!this.checkValid) return null
            return Object.keys(this.freeItemCode.location).length != 0
        },
        expiresState() {
            if (!this.checkValid) return null
            return this.freeItemCode.expires != ""
        },
        productIdState() {
            if (!this.checkValid) return null
            return this.freeItemCode.productId > 0
        },
        quantityState() {
            if (!this.checkValid) return null
            return  this.freeItemCode.quantity > 0
        },
        creditCardState() {
            if (!this.checkValid) return null
            return this.freeItemCode.creditCard != ""
        },
        isFormValid() {
            return this.providedToState && this.locationState && this.expiresState && this.productIdState && this.quantityState && this.creditCardState
        }
    },
    created() {
        this.setUpDates();
    },
    methods: {
        setUpDates() {
            const now = new Date()

            this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            this.maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())

            var weekFomNow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)
            var year = weekFomNow.toLocaleString("default", { year: "numeric" });
            var month = weekFomNow.toLocaleString("default", { month: "2-digit" });
            var day = weekFomNow.toLocaleString("default", { day: "2-digit" });

            this.freeItemCode.expires = `${year}-${month}-${day}`
        },
        generateFreeItemCode() {
            this.checkValid = true;
            if (this.isFormValid) {
                PromosService.generateFreeItemCode(
                    this.freeItemCode
                    ).then((response) => {
                    if (response) {   
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.$emit("hide")
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>