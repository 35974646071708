<template>
    <div>
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <div class="row row-cols-1 row-cols-md-2 g-4">
                <div class="col">
                    <b-form-group id="tid-ext-details-group" label="Terminal TID" label-for="tid-ext-details-input">
                        <b-form-input
                            id="tid-ext-details-input"
                            v-model="form.terminal_tid"
                            :disabled="this.disabled"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="mid-ext-details-group" label="Terminal MID" label-for="mid-ext-details-input">
                        <b-form-input
                            id="mid-ext-details-input"
                            v-model="form.terminal_mid"
                            :disabled="this.disabled"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="token-ext-details-group" label="Token Type" label-for="token-ext-details-input">
                        <b-form-input
                            id="token-ext-details-input"
                            v-model="form.token_type"
                            :disabled="this.disabled"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="keyid-ext-details-group" label="Key ID" label-for="keyid-ext-details-input">
                        <b-form-input
                            id="keyid-ext-details-input"
                            v-model="form.key_id"
                            :disabled="this.disabled"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="mac-ext-details-group" label="MAC Address" label-for="mac-ext-details-input">
                        <b-form-input
                            id="mac-ext-details-input"
                            v-model="form.mac_address"
                            :disabled="this.disabled"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="serial-ext-details-group" label="Terminal Serial Number"  label-for="serial-ext-details-input">
                        <b-form-input
                            id="serial-ext-details-input"
                            v-model="this.extDetails.terminal_serial_number"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="outofspace-ext-details-group" label="Last out of space repair check"  label-for="outofspace-ext-details-input">
                        <b-form-input
                            id="outofspace-ext-details-input"
                            v-model="this.extDetails.lastOutOfSpaceRepairCheckDt"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <div class="row row-cols-2 row-cols-sm-2 g-4" v-if="security.kioskWrite">
                <div class="col">
                    <b-button class="details-btn" @click="disable()" v-if="this.disabled" :disabled="!this.disabled" variant="primary">Edit</b-button>
                    <b-button type="reset" class="details-btn" v-if="!this.disabled" :disabled="this.disabled" variant="danger">Cancel</b-button>
                </div>
                <div class="col">
                    <b-button type="submit" class="details-btn submit-btn" v-if="!this.disabled" variant="primary">Save</b-button>
                </div>
            </div>
        </b-form>
        <h4 style="padding-bottom: 2%; padding-top: 2%">Transaction History</h4>
        <TransactionHistoryComponent :kioskId="kioskId"/>
    </div>
</template>

<script>

import KioskService from "../../services/kiosk.service"
import TransactionHistoryComponent from "./TransactionHistoryComponent"
import { formatDate, convertUTCtoLocalDate } from '../../utils/utils'

export default {
    name: "ExtendedDetails",
    props: {
        kioskId: {
            type: String,
            required: true
        },
    },
    components: {
        TransactionHistoryComponent
    },
    data() {
        return {
            form: {
                id: 0,
                terminal_mid: '',
                terminal_tid: '',
                mac_address: '',
                token_type: '',
                key_id: ''
            },
            extDetails: {},
            loading: false,
            show: true,
            disabled: true
        }
    },   
    computed:{
        security(){
            return this.$store.state.auth.security
        },
    },
    mounted() {
        this.getExtendedDetails()
    },
    methods: {
        formatDate,
        convertUTCtoLocalDate,
        getExtendedDetails() {
            this.loading = true
            KioskService.extendedDetails(
                this.kioskId
            ).then((response) => {
                this.extDetails = response.data
                this.extDetails.lastOutOfSpaceRepairCheckDt = this.formatDate(convertUTCtoLocalDate(this.extDetails.last_out_of_space_repair_check))

                this.setForm(this.extDetails)
                this.loading = false
            })
        },
        onSubmit(event) {
            event.preventDefault()

            KioskService.updateExtendedDetails(
                this.form
            ).then((response) => {
                if (response) {
                    this.extDetails = response.data
                    this.extDetails.lastOutOfSpaceRepairCheckDt = this.formatDate(convertUTCtoLocalDate(this.extDetails.last_out_of_space_repair_check))
                    this.setForm(this.extDetails)
                    this.disabled = true

                    this.$root.$bvToast.toast('Successful', {
                        title: 'Update Kiosk Extended Details',
                        variant: 'success',
                        solid: true
                    })
                }
            }).catch(error => {
                this.setForm(this.extDetails)
                this.disabled = true

                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'Update Kiosk Extended Details',
                    variant: 'danger',
                    solid: true
                })
            })
        },
        onReset(event) {
            event.preventDefault()
            this.setForm(this.extDetails)
            this.disabled = true
            this.show = false

            this.$nextTick(() => {
                this.show = true
            })
        },
        disable() {
            setTimeout(() => {
                this.disabled = !this.disabled
            }, 150)
        },
        setForm(extDetails) {
            this.form.id = extDetails.id
            this.form.terminal_mid = extDetails.terminal_mid
            this.form.terminal_tid = extDetails.terminal_tid
            this.form.mac_address = extDetails.mac_address
            this.form.token_type = extDetails.token_type
            this.form.key_id = extDetails.key_id
        }
    }
}

</script>

<style scoped>
.details-btn {
    width: 5rem;
}

.submit-btn {
  float: right;
}

@media only screen and (max-width: 750px) {
    .details-btn {
        width: 100%;
    }
}
</style>