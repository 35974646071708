var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_c('font-awesome-icon',{staticClass:"nav-icon fa",attrs:{"icon":"gear"}}),_vm._v(" Custom Fields ")],1),_c('div',{staticClass:"button-group"},[_c('div',{staticClass:"button-col"},[(_vm.security.customFieldsCreate)?_c('button',{staticClass:"btn btn-primary mt-2 button",on:{"click":_vm.showAddCustomFieldModal}},[_vm._v(" Add Custom Field ")]):_vm._e()])]),_c('br'),_c('DynamicSearchComponent',{ref:"customFieldSearchComponent",attrs:{"preferencesName":"custom_field_search","allCriteria":_vm.searchCriteria},on:{"resetPagination":function () {
                this$1.searchPagination.currentPage = 1;
                this$1.searchPagination.startIndex = 1;
            },"reloadDynamicSearch":_vm.reloadDynamicSearch}}),_c('div',{staticClass:"pb-3"},[_c('Table',{attrs:{"id":"singleUseTable","fields":_vm.dynamicFields,"items":_vm.customFields,"sortBy":"field_name","sortDesc":false,"searchPagination":_vm.searchPagination},on:{"getItems":_vm.listCustomFields,"sortOrderChanged":_vm.sortOrderChanged},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"icons"},[(_vm.security.customFieldsWrite)?_c('font-awesome-icon',{staticClass:"nav-icon pointer mx-2",attrs:{"icon":"pen-to-square","title":"Title"},on:{"click":function($event){return _vm.showUpdateCustomFieldModal(data.item)}}}):_vm._e(),(_vm.security.customFieldsDelete)?_c('font-awesome-icon',{staticClass:"nav-icon pointer mx-2",attrs:{"icon":"trash-can","title":"Delete"},on:{"click":function($event){return _vm.showDeleteCustomFieldModal(data.item)}}}):_vm._e()],1)]}},{key:"cell(required)",fn:function(data){return [_c('font-awesome-icon',{attrs:{"icon":data.item.required ? 'check' : 'xmark',"color":data.item.required ? 'green' : 'red'}})]}},{key:"cell(include_in_export)",fn:function(data){return [_c('font-awesome-icon',{attrs:{"icon":data.item.include_in_export ? 'check' : 'xmark',"color":data.item.include_in_export ? 'green' : 'red'}})]}}])})],1),_c('b-modal',{ref:"add-update-custom-field",attrs:{"id":"add-update-custom-field","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Custom Field - "+_vm._s(_vm.selectedCustomField == undefined ? 'New ' : 'Update')+" ")]},proxy:true}])},[_c('AddUpdateCustomField',{attrs:{"id":_vm.selectedCustomField != undefined
                    ? _vm.selectedCustomField.id
                    : undefined},on:{"hide":_vm.hideAddCustomFieldModal}})],1),_c('b-modal',{ref:"delete-custom-field",attrs:{"id":"delete-custom-field","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Delete Custom Field ")]},proxy:true}])},[_c('div',{staticClass:"d-block"},[_c('p',[_vm._v(" You are about to delete custom field: "+_vm._s(_vm.selectedCustomField != undefined ? _vm.selectedCustomField.field_name : '')+" ")]),_c('p',[_vm._v("Do you want to proceed?")])]),_c('div',{staticClass:"float-right"},[_c('button',{staticClass:"mt-3 btn btn-danger",attrs:{"variant":""},on:{"click":function($event){return _vm.deleteCustomField()}}},[_vm._v(" Delete ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }