<template>
    <div>
        <h3>
            <font-awesome-icon icon="wrench" class="nav-icon fa" /> Maintenance
            Logs
        </h3>

        <DynamicSearchComponent
            ref="maintenanceLogsSearchComponent"
            preferencesName="maintenance_log_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />

        <Table
            id="maintenanceLogTable"
            :fields="fields"
            :items="maintenanceLogs"
            sortBy="timestamp"
            :sortDesc="true"
            :searchPagination="searchPagination"
            @getItems="getMaintenanceLogs"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(actions)="row">
                <font-awesome-icon
                    icon="scroll"
                    @click="setLogs(row.item)"
                    title="Log"
                    class="pointer"
                />
            </template>
            <template #cell(timestamp)="row">
                {{ formatDate(convertUTCtoLocalDate(row.item.timestamp)) }}
            </template>
            <template #cell(vmid)="row">
                <a href="#" @click="kioskLink(row.item.kiosk_id)" class="underline">{{row.item.vmid}}</a>
            </template>
        </Table>

        <MaintenanceLogModal :logs="logs"></MaintenanceLogModal>
    </div>
</template>

<script>
import MaintenanceService from '../../services/maintenance.service';
import { Table, DynamicSearchComponent } from '../../components';
import { MaintenanceLogModal } from '../../components/maintenance';
import SearchPagination from '../../models/search.pagination';
import { formatDate, kioskLink, convertUTCtoLocalDate, getDateOnly } from '../../utils/utils';

export default {
    name: 'MaintenanceLogs',
    components: {
        Table,
        MaintenanceLogModal,
        DynamicSearchComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                },
                {
                    key: 'timestamp',
                    label: 'Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'vmid',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_number',
                    label: 'StoreNum',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'street',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'state',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'region',
                    label: 'Region',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'technician',
                    label: 'Name',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'code',
                    label: 'Code',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
            ],
            searchCriteria: [
                {
                    key: 'date_start',
                    name: 'Start Date',
                    subtype: 'start-date',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: getDateOnly(convertUTCtoLocalDate(new Date().toISOString())),
                    required: true,
                },
                {
                    key: 'date_end',
                    name: 'End Date',
                    type: 'Date',
                    subtype: 'end-date',
                    searchQueryName: 'dateEnd',
                    defaultValue: getDateOnly(convertUTCtoLocalDate(new Date().toISOString())),
                    required: true,
                },
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
            ],
            maintenanceLogs: [],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
            logs: [],
        };
    },
    mounted() {
        this.getMaintenanceLogs();
    },
    methods: {
        getMaintenanceLogs() {
            this.$refs.maintenanceLogsSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.isLoading = true;
            MaintenanceService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                if (response) {
                    this.maintenanceLogs = response.data.items;
                    this.searchPagination.totalRows =
                        this.maintenanceLogs.length > 0
                            ? response.data.total_rows
                            : 0;
                    this.isLoading = false;
                    args.done();
                }
            });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getMaintenanceLogs()
        },
        setLogs(item) {
            MaintenanceService.getMaintenanceLogs(item.maintenance_guid).then((response) => {
                if (response) {
                    this.logs = response.data.logs;
                    this.$bvModal.show('maintenance-log-modal');
                }
            });
        },
        formatDate,
        convertUTCtoLocalDate,
        kioskLink,
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
    },
};
</script>
<style scoped>
.underline {
    text-decoration: underline;
}
</style>