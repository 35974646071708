<template>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <VmidFieldComponent
                    :vmid="form.vmid"
                    :isG3K="form.hardware_type == 17"
                    :disabled=true
                    @setVmid="setVmid"
                />
            </div>
            <div class="col">
                <b-form-group
                    id="sim-details-group"
                    label="SIM"
                    label-for="sim-input-details"
                >
                    <b-form-input
                        id="sim-input-details"
                        v-model="form.sim"
                        :disabled="this.disabled"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <StoresSelectComponent
                    v-model="form.store_id"
                    :disabled="this.disabled"
                    :readOnlyValue="details.store_description"
                />
            </div>
            <div class="col">
                <PriceSelectComponent
                    v-model="form.price_id"
                    :disabled="this.disabled"
                    :readOnlyValue="details.price_description"
                />
            </div>
            <div class="col">
                <HardwareTypeComponent
                    v-model="form.hardware_type"
                    :disabled="hardwareTypeDisabled"
                />
            </div>
            <div class="col">
                <b-form-group
                    id="acc-details-group"
                    label="Account #"
                    label-for="acc-input-details"
                >
                    <b-form-input
                        id="acc-input-details"
                        v-model="form.account_number"
                        :disabled="this.disabled"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>

        <div class="row row-cols-2 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group
                    id="installed-details-group"
                    label="Installed"
                    label-for="installed-checkbox-details"
                >
                    <b-form-checkbox
                        id="installed-checkbox-details"
                        v-model="form.installed"
                        required
                        :disabled="this.disabled"
                    ></b-form-checkbox>
                </b-form-group>
                <div v-if="details.first_installed_on">
                    First installed on
                    {{ formatDate(convertUTCtoLocalDate(details.first_installed_on)) }}
                </div>
            </div>
            <div class="col">
                <b-form-group
                    id="enabled-checkbox-group"
                    label="Enabled"
                    label-for="enabled-checkbox-details"
                >
                    <b-form-checkbox
                        id="enabled-checkbox-details"
                        v-model="form.enabled"
                        required
                        :disabled="this.disabled"
                    ></b-form-checkbox>
                </b-form-group>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-1 g-4">
            <div class="col">
                <b-form-group
                    id="lowinventory-details-group"
                    label="Low Inventory Alert"
                    label-for="lowinventory-input-details"
                >
                    <b-form-input
                        id="lowinventory-input-details"
                        v-model="form.low_inventory"
                        required
                        :disabled="this.disabled"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="servant-textarea-group"
                    label="Servant(s)"
                    label-for="servant-textarea-details"
                >
                    <b-form-textarea
                        id="servant-textarea-details"
                        v-model="form.servant_notes"
                        :disabled="this.disabled"
                    ></b-form-textarea>
                </b-form-group>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group
                    id="disk-details-group"
                    label="Disk Capacity"
                    label-for="disk-input-details"
                >
                    <b-form-input
                        id="disk-input-details"
                        v-model="form.drive_capacity"
                        required
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="avail-details-group"
                    label="Available Capacity"
                    label-for="avail-input-details"
                >
                    <b-form-input
                        id="avail-input-details"
                        v-model="form.available_capacity"
                        required
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>

        <div
            class="row row-cols-2 row-cols-sm-2 g-4"
            v-if="security.kioskWrite"
        >
            <div class="col">
                <b-button
                    class="details-btn"
                    @click="disable()"
                    v-if="this.disabled"
                    :disabled="!this.disabled"
                    variant="primary"
                    >Edit</b-button
                >
                <b-button
                    type="reset"
                    class="details-btn"
                    v-if="!this.disabled"
                    :disabled="this.disabled"
                    variant="danger"
                    >Cancel</b-button
                >
            </div>
            <div class="col">
                <b-button
                    type="submit"
                    class="details-btn submit-btn"
                    v-if="!this.disabled"
                    variant="primary"
                    >Save</b-button
                >
            </div>
        </div>
    </b-form>
</template>

<script>
import KioskService from '../../services/kiosk.service'
import RolesService from '../../services/roles.service'
import {
    HardwareTypeComponent,
    StoresSelectComponent,
    PriceSelectComponent
} from '../../components';
import { formatDate, cleanVmid, convertUTCtoLocalDate } from '../../utils/utils'
import VmidFieldComponent from '../kiosk/VmidFieldComponent.vue'

export default {
    name: 'Details',
    props: {
        kioskId: {
            type: String,
            required: true,
        },
    },
    components: {
        HardwareTypeComponent,
        StoresSelectComponent,
        PriceSelectComponent,
        VmidFieldComponent
    },
    data() {
        return {
            form: {
                id: 0,
                store_id: 0,
                price_id: 0,
                low_inventory: 0,
                vmid: 0,
                sim: '',
                account_number: '',
                servant_notes: '',
                enabled: true,
                installed: true,
                hardware_type: 9,
                available_capacity: '',
                drive_capacity: '',
                tax_value: '',
            },
            details: {
            },
            loading: false,
            show: true,
            disabled: true,
            vmidValidFormat: false,
            isTechnician: false
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        hardwareTypeDisabled() {
            if (this.isTechnician) {
                return this.disabled
            }
            return !this.isTechnician
        }
    },
    mounted() {
        this.getDetails();
        this.getIsTechnician();
    },
    methods: {
        getDetails() {
            this.loading = true;
            KioskService.details(this.kioskId).then((response) => {
                if (response) {
                    this.details = response.data;
                    this.setForm(this.details);
                    this.loading = false;
                }
            });
        },
        onSubmit(event) {
            event.preventDefault();
            this.form.vmid = cleanVmid(this.form.vmid)
            KioskService.updateDetails(this.form)
                .then((response) => {
                    if (response) {
                        this.details = response.data;
                        this.setForm(this.details);
                        this.disabled = true;
                        this.$root.$bvToast.toast('Successful', {
                            title: 'Update Kiosk Details',
                            variant: 'success',
                            solid: true,
                        });
                        this.$emit('refresh')
                    }
                })
                .catch((error) => {
                    this.setForm(this.details);
                    this.disabled = true;

                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'Update Kiosk Details',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        onReset(event) {
            event.preventDefault();
            this.disabled = true;
            this.show = false;
            this.getDetails();
            
            this.$nextTick(() => {
                this.show = true;
            });
        },
        disable() {
            setTimeout(() => {
                this.disabled = !this.disabled;
            }, 150);
        },
        setForm(details) {
            this.form.id = details.id;
            this.form.store_id = details.store_id;
            this.form.price_id = details.price_id;
            this.form.low_inventory = details.low_inventory;
            this.form.nitro_low = details.nitro_low;
            this.form.vmid = details.vmid;
            this.form.sim = details.sim;
            this.form.account_number = details.account_number;
            this.form.servant_notes = details.servant_notes;
            this.form.enabled = details.enabled;
            this.form.installed = details.installed;
            this.form.hardware_type = details.hardware_type;
            this.form.drive_capacity = details.drive_capacity;
            this.form.available_capacity = details.available_capacity;
            this.form.tax_value = details.tax_value;
        },
        formatDate,
        convertUTCtoLocalDate,
        setVmid(value) {
            this.form.vmid = value
        },
        getIsTechnician() {
            RolesService.isTechnician().then((response) => {
                if(response) {
                    this.isTechnician = response.data
                }
            })
        }
    },
};
</script>

<style scoped>
.details-btn {
    width: 5rem;
}

.submit-btn {
    float: right;
}

@media only screen and (max-width: 750px) {
    .details-btn {
        width: 100%;
    }
}
</style>