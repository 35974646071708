<template>
    <div>
        <h3><font-awesome-icon icon="robot" class="nav-icon fa" /> Servants</h3>
        <div class="button-group mt-3" v-if="security.servantCreate">
            <div class="button-col">
                <button
                    class="btn btn-primary button"
                    @click="openBulkImportModal"
                >
                    Add Servants
                </button>
            </div>
        </div>
        <DynamicSearchComponent
            ref="servantSearchComponent"
            preferencesName="servant_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <Table
            id="servantTable"
            :fields="fields"
            :items="servants"
            sortBy="svmid"
            :sortDesc="false"
            :searchPagination="searchPagination"
            @getItems="getServants"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(action)="row">
                <div class="icons">
                    <font-awesome-icon
                        :icon="
                            !row.detailsShowing ? 'chevron-down' : 'chevron-up'
                        "
                        class="nav-icon pointer mx-2"
                        @click="row.toggleDetails"
                        title="Expand Info"
                    />
                    <font-awesome-icon
                        icon="pencil"
                        class="nav-icon pointer mx-2"
                        @click="openAddUpdateModal(row.item)"
                        v-if="security.servantWrite"
                        title="Edit"
                    />
                    <font-awesome-icon
                        icon="trash-can"
                        class="nav-icon pointer mx-2"
                        @click="openDeleteModal(row.item)"
                        v-if="security.servantDelete"
                        title="Delete"
                    />
                    <font-awesome-icon
                        icon="link-slash"
                        class="nav-icon pointer mx-2"
                        color="red"
                        @click="openUnpairModal(row.item)"
                        v-if="security.servantWrite && row.item.host_id"
                        title="Unpair"
                    />
                </div>
            </template>
            <template #cell(svmid)="data">
                <a
                    href="#"
                    @click="servantLink(data.item.id)"
                    class="pointer underline"
                >
                    {{ data.item.svmid }}
                </a>
            </template>
            <template #row-details="data">
                <b-card>
                    <ServantExpansionComponent :servant="data.item" />
                </b-card>
            </template>
        </Table>
        <ExportFileComponent
            :arguments="this.currentQuery"
            :disabled="false"
            page="servants"
            class="button export"
        />
        <b-modal id="add-update-servant" ref="add-update-servant" hide-footer>
            <template #modal-title>
                Servant - {{ selectedServant ? 'Update' : 'Add' }}
            </template>
            <AddUpdateServant
                @hide="hideAddUpdateModal"
                :servant="selectedServant"
            />
        </b-modal>
        <b-modal id="add-update-servant" ref="add-update-servant" hide-footer>
            <template #modal-title>
                Servant - {{ selectedServant ? 'Update' : 'Add' }}
            </template>
            <AddUpdateServant
                @hide="hideAddUpdateModal"
                :servant="selectedServant"
            />
        </b-modal>
        <b-modal id="unpair-servant" ref="unpair-servant" hide-footer>
            <template #modal-title> Servant - Unpair </template>
            <UnpairServant :servant="selectedServant" @hide="hideUnpairModal" />
        </b-modal>
        <b-modal id="delete-servant" ref="delete-servant" hide-footer>
            <template #modal-title> Servant - Delete </template>
            <DeleteServant :servant="selectedServant" @hide="hideDeleteModal" />
        </b-modal>
        <b-modal id="bulk-import-servant" ref="bulk-import-servant" size='lg' hide-footer>
            <template #modal-title> Add Servants </template>
            <AddServantModalTabs @hide="hideBulkImportModal" />
        </b-modal>
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../components';
import ServantsService from '../../services/servants.service';
import { AddUpdateServant, DeleteServant, UnpairServant, AddServantModalTabs } from '../../components/kiosk/servant'
import ServantExpansionComponent from '../../components/kiosks/ServantExpansionComponent.vue';
import { servantLink } from "../../utils/utils"
import ExportFileComponent from '../../components/ExportFileComponent.vue';

export default {
    name: 'Servants',
    components: {
        Table,
        DynamicSearchComponent,
        AddUpdateServant,
        DeleteServant,
        UnpairServant,
        AddServantModalTabs,
        ServantExpansionComponent,
        ExportFileComponent
    },
    data() {
        return {
            servants: [],
            fields: [
                {
                    key: 'action',
                    tdClass: 'action-column',
                },
                {
                    key: 'svmid',
                    label: 'SVMID',
                    tdClass: 'column',
                    sortable: true,
                    visible: false,
                    sortOrder: 1
                },
                {
                    key: 'hardware_type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'product',
                    label: 'Product',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5
                },
                {
                    key: 'store_number',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7
                },
                {
                    key: 'province',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 8
                },
                {
                    key: 'last_checkin_desc',
                    label: 'Last Check-in',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 8,
                    sortOrder: 12
                },
                {
                    key: 'last_ping_desc',
                    label: 'Last Ping',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 9,
                    sortOrder: 15
                }
            ],
            searchCriteria: [
                {
                    key: 'svmid',
                    name: 'SVMID',
                    type: 'Text',
                    searchQueryName: 'svmid',
                },
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'store_number',
                    name: 'Store Number',
                    type: 'Text',
                    searchQueryName: 'storeNumber',
                },
                {
                    key: 'installed',
                    name: 'Installed',
                    type: 'Boolean',
                    searchQueryName: 'installed',
                },
                {
                    key: 'enabled',
                    name: 'Enabled',
                    type: 'Boolean',
                    searchQueryName: 'enabled',
                },
                {
                    key: 'paired',
                    name: 'Paired',
                    type: 'Boolean',
                    searchQueryName: 'paired',
                },
            ],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: 1,
            currentQuery: {},
            selectedServant: {},
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
    },
    mounted() {
    },
    methods: {
        getServants() {
            this.$refs.servantSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.currentQuery = args.query;
            ServantsService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.servants = response.data.items;
                        this.searchPagination.totalRows =
                            this.servants.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getServants()
        },
        servantLink,
        openAddUpdateModal(servant) {
            this.selectedServant = servant;
            this.$refs['add-update-servant'].show();
        },
        hideAddUpdateModal() {
            this.$refs['add-update-servant'].hide();
            this.getServants();
        },
        openDeleteModal(servant) {
            this.selectedServant = servant;
            this.$refs['delete-servant'].show();
        },
        hideDeleteModal() {
            this.$refs['delete-servant'].hide();
            this.getServants();
        },
        openUnpairModal(servant) {
            this.selectedServant = servant;
            this.$refs['unpair-servant'].show();
        },
        hideUnpairModal() {
            this.$refs['unpair-servant'].hide();
            this.getServants();
        },
        openBulkImportModal() {
            this.$refs['bulk-import-servant'].show();
        },
        hideBulkImportModal() {
            this.$refs['bulk-import-servant'].hide();
            this.getServants();
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
    },
};
</script>

<style scoped>
::v-deep .action-column {
    min-width: 155px;
    vertical-align: middle !important;
}

.underline {
    text-decoration: underline;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 1rem; 
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    .icons {
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button-col > button {
        width: 100%;
    }

    .button-col > button:first-child {
        margin-right: 0.5rem;
    }

    .button-col > button:last-child {
        margin-left: 0.5rem;
    }

    .button {
        width: 100%;
        margin-right: 0rem; 
        margin-bottom: 1rem; 
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>