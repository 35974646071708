<template>
    <div>     
        <b-card>
            <b-card-title class="title-group">
                <a href="#" @click="servantLink(servant.id)" class="pointer underline">
                    {{ servant.svmid }}
                </a>
                <div class="icon-group">
                    <font-awesome-icon icon="pen-to-square" class="right icon pointer"  @click="openUpdateModal()" v-if="security.servantWrite"/>
                    <font-awesome-icon icon="link-slash" class="right icon pointer" @click="openUnpairModal()" v-if="security.servantWrite"/>
                    <font-awesome-icon icon="trash-can" class="right icon pointer" @click="openDeleteModal()" v-if="security.servantDelete"/>
                </div>
            </b-card-title>
            <hr/>
            <div class="phase-group">
                <div class="left">
                    <ul>
                        <li>Hardware Type: <b>{{servant.hardware_type}}</b></li>
                        <li>Product:  <b>{{servant.product}}</b></li>
                        <li>Bay Count:  <b>{{servant.bay_count}}</b></li>
                        
                    </ul>
                </div>
                <div class="right">
                    <b-form-checkbox v-model="servant.installed" name="toggle-override" size="md" switch :disabled="true">
                        Installed
                    </b-form-checkbox>
                </div>
            </div>
        </b-card>
        <b-modal id="update-servant" ref="update-servant" hide-footer>
            <template #modal-title>
                Servant - Update
            </template>
            <AddUpdateServant @hide="hideUpdateModal" :servant="servant"/>
        </b-modal>
        <b-modal id="unpair-servant" ref="unpair-servant" hide-footer>
            <template #modal-title>
               Servant - Unpair
            </template>
            <UnpairServant :servant="servant" @hide="hideUnpairModal"/>
        </b-modal>
        <b-modal id="delete-servant" ref="delete-servant" hide-footer>
            <template #modal-title>
               Servant - Delete
            </template>
            <DeleteServant :servant="servant" @hide="hideDeleteModal"/>
        </b-modal>
    </div>
</template>

<script>

import AddUpdateServant from "./AddUpdateServant.vue"
import DeleteServant from "./DeleteServant.vue"
import UnpairServant from "./UnpairServant.vue"

export default {
    name: 'Servant',
    components: {
        AddUpdateServant,
        DeleteServant,
        UnpairServant
    },
    props: {
        servant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        }
    },
    computed: {
        security(){
            return this.$store.state.auth.security
        },
    },
    mounted() {
    },
    methods: {
        openUpdateModal() {
            this.$refs["update-servant"].show()
        },
        hideUpdateModal() {
            this.$refs["update-servant"].hide()
            this.$emit("refresh")
        },
        openDeleteModal() {
            this.$refs["delete-servant"].show()
        },
        hideDeleteModal() {
            this.$refs["delete-servant"].hide()
            this.$emit('refresh');
        },
        openUnpairModal(servant) {
            this.selectedServant = servant
            this.$refs["unpair-servant"].show()
        },
        hideUnpairModal() {
            this.$refs["unpair-servant"].hide()
            this.getServants()
        },
        servantLink(servantId) {
            var routeData = this.$router.resolve({
                path: '/servant/',
                query: { servant_id: servantId },
            });
            window.open(routeData.href, 'servant_' + servantId);
        },
    }
}
</script>

<style>
.title-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.icon-group {
    display: flex;
    align-items: center;
}
.icon {
    font-size: 1rem;
    margin-left: 1rem
}
.servant-group {
    display: flex;
}
ul {
    list-style: none;
    padding-left: 0;
}
.left {
    flex: 0 0 50%;
}
.right {
    flex: 1;
    display: flex;
}

</style>