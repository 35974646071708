<template>
    <form name="form" @submit.prevent="validatePin">
        <div class="row d-flex justify-content-center">
            <div class="col-md-6">
                <h4 class="text-center">
                    Scan QR code or enter token into authenticator app to enable two factor
                    authentication
                </h4>
                <div class="row d-flex justify-content-center">
                    <qrcode-vue :value="qrCodeUrl" :size="size" level="H"></qrcode-vue>
                </div>
                <div>Token: {{ token }}</div>
                <div>
                    <label for="authenticatorCode">Authenticator Code</label>
                    <input
                        v-model="authenticatorCode"
                        type="text"
                        class="form-control"
                        name="authenticatorCode"
                        id="qrcode-text-authenticatorcode"
                        autocomplete="off"
                    />
                    <b-button class="mt-3" variant="outline-primary" @click="onCancel" v-if="!(security.requiresTwoFa && !security.hasTwoFaEnabled)">Cancel</b-button>
                    <SpinnerButton
                        :isLoading="loading"
                        text="Validate Pin"
                        testId="qrcode-button-validatepin"
                        klass="float-right"
                    />
                </div>
                <div class="form-group message">
                    <div v-if="message" class="alert alert-danger" role="alert">
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import AuthService from "../../services/auth.service";
import SpinnerButton from "../../components/SpinnerButton.vue";

export default {
    name: "EnableTwoFa",
    components: {
        QrcodeVue,
        SpinnerButton,
    },
    data() {
        return {
            size: 200,
            qrCodeUrl: "",
            token: "",
            authenticatorCode: "",
            loading: false,
            message: "",
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
    },
    created() {
        AuthService.generateTwoFaToken().then(
            (response) => {
                this.qrCodeUrl = response.qr_code_url;
                this.token = response.two_fa_token;
            }
        );
    },
    methods: {
        async validatePin() {
            var authenticatorCode = this.authenticatorCode;
            var token = this.token;
            AuthService.validatePin({ authenticatorCode, token }).then(
                (response) => {
                    this.onValidate(response);
                },
                (error) => {
                    this.onValidateError(error);
                }
            );
        },

        onValidate(response) {
            console.log(response)
            if (response.data.success) {
                this.$root.$bvToast.toast('Successful', {
                    title: 'Successfully Enabled Two Factor Authentication',
                    variant: 'success',
                    solid: true
                })
                this.loading = false;

                this.$store.dispatch("auth/refreshJwtToken").then(
                    (response) => {
                        if (response.success) {
                            this.$router.push("/home");
                        }
                        else {
                            this.showRefreshErrorToast()
                        }
                    }
                ).catch(()=> this.showRefreshErrorToast());
            } else {
                this.$root.$bvToast.toast('Failed Validating Pin', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
            }
        },
        onValidateError(error) {
            this.loading = false;
            this.message = (error.response && error.response.data) || error.message || error.toString();
        },
        onCancel() {
            this.$router.push("/profile");
        },
        showRefreshErrorToast(){
            this.$root.$bvToast.toast("Failed to refresh authorization token, please log out and log back in", {
                title: 'Error',
                variant: 'danger',
                solid: true
                }
            )
        },
    },
};
</script>