<template>
    <button
        class="mt-3 btn btn-primary"
        :class="klass"
        :disabled="isDisabled"
        @click="$emit('click')"
    >
        <span
            v-show="isLoading"
            class="spinner-border spinner-border-sm spinner"
        ></span>
        <span>{{ text }}</span>
    </button>
</template>

<script>
export default {
    name: 'SpinnerButton',
    props: {
        isLoading: { type: Boolean, required: true },
        text: { type: String, required: true },
        disabled: { type: Boolean, required: false },
        klass: { type: String, required: false },
    },
    computed: {
        isDisabled() {
            return this.isLoading || this.disabled;
        },
    },
};
</script>

<style scoped>
.spinner {
    margin-right: 10px;
}
</style>