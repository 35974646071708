<template>
    <div>
        <h3>
            <font-awesome-icon icon="filter" class="nav-icon fa" />  Roles
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pfb')" class="help-icon nav-icon fa" title="Sleegers User Guide - Roles"/>
        </h3>
        <div class="button-group mt-3">
            <div class="left-group">
                <div class="button-col">
                    <button class="btn btn-primary button button-margin" @click="openAddModal" v-if="security.roleCreate">Add</button>
                    <button class="btn btn-primary button button-margin" @click="openUpdateModal" :disabled="roleId == 0" v-if="security.roleWrite">Update</button>
                </div>
                <div class="button-col">
                    <button class="btn btn-primary button button-margin" @click="openDuplicateModal" :disabled="roleId == 0" v-if="security.roleCreate">Duplicate Role</button>
                    <button class="btn btn-danger button button-margin" @click="openDeleteModal" :disabled="roleId == 0" v-if="security.roleDelete">Delete Role</button>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <b-form-group id="role-select-group" label="Role:" label-for="role-select">
                <b-form-select
                    id="role-select"
                    v-model="selectedRole"
                    :disabled="false">
                    <option v-for="role in this.roles" :value="role.id" :key="role.id">
                        {{ formatRoleName(role) }}
                    </option>
                </b-form-select>
            </b-form-group>
        </div>
        <ClaimsComponent
            :claims="claims"
            :selectedCheckboxes="selected"
            :originalSelectedCheckboxes="originalSelected"
            :readonly="true"
            @setSelectedCheckboxes="setSelectedClaim"
        />
        <AddRoleModal @changeComplete="changeComplete" :claims="claims"/>
        <UpdateRoleModal 
            :claims="claims" 
            :selected="selected"
            :role="role"
            @changeComplete="changeComplete"
        />
        <DeleteRoleModal @changeComplete="deleteComplete" :roleId="roleId"/>
        <RemoveRoleConfirmationModal @removeConfirm="removeConfirm" :roleType="roleType"/>
        <b-modal id="duplicate-role-modal" ref="duplicate-role-modal" hide-footer>
            <template #modal-title>
               Duplicate Role - {{roleName}}
            </template>
            <DuplicateRole :roleId="roleId" @hide="changeComplete"/>
        </b-modal>
    </div>
</template>

<script>
import { RolesService } from "../../services"
import { ClaimsComponent } from "../../components/"
import { AddRoleModal, UpdateRoleModal, DeleteRoleModal, RemoveRoleConfirmationModal, DuplicateRole } from "../../components/roles/"
import { openHelpWindow } from '../../utils/utils'

export default {
    name: "Roles",
    components: {
        ClaimsComponent,
        AddRoleModal,
        UpdateRoleModal,
        DeleteRoleModal,
        RemoveRoleConfirmationModal,
        DuplicateRole
    },
    computed: {
        selectedRole: {
            get() {
                return this.value
            },
            set(id) {
                this.roleId = id;
                this.role = this.roles.find(role => role.id == id);
                this.getRoleClaims(id);
                this.$emit('input', id)
            }
        },
        security() {
            return this.$store.state.auth.security
        },
        userRoleId() {
            return this.$store.state.auth.user.role_id
        }
    },
    data() {
        return {
          roles: [],
          claims: [],
          selected: [],
          originalSelected: [],
          role: {},
          roleId: 0,
          isLoading: false,
          readConfirmed: true,
          writeConfirmed: true,
          hasRoles: false,
          roleType: "",
          roleName: ""
        };
    },
    mounted() {
        this.getRoles();
        this.getClaims();
    },
    methods: {
        openHelpWindow,
        getRoles() {
            return RolesService.getRoles(this.security.technician
            ).then((response) => {
                if (response) {
                    this.roles = response.data
                } 
            }).catch(error => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'RolesService.getRoles failed',
                    variant: 'danger',
                    solid: true
                })
            })
        },
        getRoleClaims(id){
            this.selected = [];
            RolesService.getClaimsByRole(id).then(
                (response) => {
                    if(response.data.items.some(e => e.name == "role" && e.value == "w")) {
                        this.writeConfirmed = true;
                        this.hasRoles = true;
                    }
                    if(response.data.items.some(e => e.name == "role" && e.value == "r")) {
                        this.readConfirmed = true;
                        this.hasRoles = true;
                    }
                    if(!this.security.technician) {
                        var tempClaims = [];
                        this.claims.forEach(element => {
                            if(!element.requires_technician_role) {
                                tempClaims.push(element);   
                            }
                            else {
                                if(response.data.items.some(e => e.name == element.name)) {
                                    tempClaims.push(element);
                                }
                            }
                            
                        });
                        this.claims = tempClaims;
                    }
                    response.data.items.forEach(claims => {
                        this.selected.push(claims.name + "," + claims.value);
                    });
                    this.originalSelected = this.selected;
            });
        },
        getClaims(){
            RolesService.getClaims().then(
                (response) => {
                    this.claims = response.data.items;
            });
        },
        setSelectedClaim(value) {
            this.selected = value;
        },
        openAddModal() {
            if(!this.security.technician){
                var tempClaims = []
                this.claims.forEach(element => {
                    if(!element.requires_technician_role) {
                        tempClaims.push(element);
                    }
                });
                this.claims = tempClaims;
            }
            this.$bvModal.show('role-add-modal')
        },
        openUpdateModal() {
            if(!this.security.technician){
                var tempClaims = []
                this.claims.forEach(element => {
                    if(!element.requires_technician_role) {
                        tempClaims.push(element);
                    }
                });
                this.claims = tempClaims;
            }
            this.$bvModal.show('role-update-modal')
        },
        openDeleteModal() {
            RolesService.getUserCount(this.roleId).then(
                (response) => {
                    if(response.data == 0) {
                        this.$bvModal.show('role-delete-modal')
                    }
                    else {
                        this.$root.$bvToast.toast(response.data + " user(s) assigned to this role. Please update user roles before deleting.", {
                            title: 'Error',
                            variant: 'danger',
                            solid: true
                            }
                        )
                    }
                }
            )
        },
        openDuplicateModal() {
            this.role = this.roles.find(role => role.id == this.roleId);
            if (this.role) {
                this.roleName = this.role.description
            }
            
            this.$refs["duplicate-role-modal"].show()
        },
        hideDuplicateRoleModal(){
            this.$refs["duplicate-role-modal"].hide()
        },
        changeComplete(){
            this.$bvModal.hide('role-delete-modal')
            this.$bvModal.hide('role-add-modal')
            this.$bvModal.hide('role-update-modal')
            this.$bvModal.hide('duplicate-role-modal')

            Promise.all([this.getRoles()]).then(() => {
                 this.role = this.roles.find(role => role.id == this.roleId);
            });
            
            this.getClaims()
            this.getRoleClaims(this.roleId)
        },
        deleteComplete() {
            this.$bvModal.hide('role-delete-modal')

            this.role = {}
            this.roleId = 0
            this.selected = []
            this.originalSelected = []

            this.getClaims()
            this.getRoles()
        },
        showRefreshErrorToast(){
            this.$root.$bvToast.toast("Failed to refresh authorization token, please log out and log back in to refresh roles", {
                title: 'Error',
                variant: 'danger',
                solid: true
                }
            )
        },
        removeConfirm() {
            this.$bvModal.hide('role-write-confirmation-modal')
            this.updateClaims();
        },
        formatRoleName(role) {
            if (role == undefined) return ''
            return role.id == this.userRoleId ? role.description + " (assigned)" : role.description
        }
    }
}
</script>
<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
}
.left-group {
    display: flex;
    justify-content: space-between;
}

.button-margin {
    margin-right: 0.5rem;
}
body.modal-open {
    overflow: hidden;
}
@media only screen and (max-width: 575.5px) {
    .button-group {
        flex-direction: column;
    }
 
    .left-group {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button-col > button {
        width: 100%;
    }

    .button-col > button:first-child {
        margin-right: 0.5rem;
    }

    .button-col > button:last-child {
        margin-left: 0.5rem;
    }

    .button {
        margin-top: 1rem;
        width: 100%; 
    }
    .button-margin:last-of-type  {
        margin-right: 0rem;
    }
}

</style>