<template>
    <div>
        <b-modal @submit.prevent="updateEventRegistration" @show="refresh" id="events-update-modal" v-cloak>
            <template #modal-title>
                <h3>Update Event</h3>
            </template>
            <div class="row row-cols-1 g-5 px-3"> 
                <b-form-group id="email-input-group" label="Email" label-for="email">
                    <b-form-input 
                        id="email-input"
                        v-model="user.email"
                        :disabled="true"
                    >
                </b-form-input>
        </b-form-group>
            </div>
            <h3>Event Groups</h3>
            <EventGroupNestedComponent 
                :eventGroups="eventGroups"
                :selected="selected"
                @setSelectedCheckboxes="setSelectedEventTypes"
            />
            <hr/>
            <div>
                <div class="blackout-container">
                    <div class="blackout-left">
                        <h3 class="mb-0">Blackout (Optional)</h3>
                    </div>
                    <div class="blackout-right">
                        <b-form-checkbox 
                            id="enable-blackout"
                            v-model="enableBlackout"
                            switch
                            class="right"
                            size="lg"
                        >
                        </b-form-checkbox>
                    </div>
                </div>
                Event notifications will not be sent during the black out period
                <div class="row row-cols-1 row-cols-md-2">
                    <div class="col">
                        <b-form-group label="Start Time" label-for="blackout-start-time-timepicker">
                            <b-form-timepicker
                                id="blackout-start-time-timepicker"
                                v-model="blackoutStartTime"
                                :disabled="!enableBlackout"
                            />
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group label="End Time" label-for="blackout-end-time-timepicker">
                            <b-form-timepicker
                                id="blackout-end-time-timepicker"
                                v-model="blackoutEndTime"
                                :disabled="!enableBlackout"
                            />
                        </b-form-group>
                    </div> 
                </div>
            </div>
            <div class="form-group message pt-3">
                <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                </div>
            </div>
            <template #modal-footer>
                <a id="events-modal-register-event" @click="updateEventRegistration()" class="btn btn-primary">Update</a>
            </template>
        </b-modal>
    </div>
</template>

<script>

import { EventGroupNestedComponent }  from '../../components/'
import EventsService from "../../services/events.service"

export default {
    name: "UpdateEvent",
    components: {
        EventGroupNestedComponent
    },
    data() {
        return {
            eventGroups: [],
            selected: [],
            message: "",
            users: [],
            enableBlackout: false,
            blackoutStartTime: "00:00:00",
            blackoutEndTime: "00:00:00"
        }
    },
    props: {
        user: {
            type: Object,
            require: true
        },
    },
    emits: ['changeComplete'],
    watch:{
        'user.id'() {
            this.getUserInfo()
        }
    },
    mounted() {
        this.getGroups()
    },
     methods: {
        updateEventRegistration() {
            EventsService.register(
                this.user.id,
                this.selected,
                this.enableBlackout ? this.blackoutStartTime : undefined,
                this.enableBlackout ? this.blackoutEndTime : undefined,
            ).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })
                
                    this.message = "";
                    this.$emit('changeComplete');
                }
            });
        },
        setSelectedEventTypes(value) {
            this.selected = value;
            this.isValid = true;
        },
        getGroups() {
            EventsService.getGroups().then(
                (response) => {
                    this.eventGroups = response.data;
            });
        },
        getUserInfo() {
            EventsService.getByUserId(this.user.id).then(
                (response) => {
                    this.selected = response.data.eventTypes.map(eventType => eventType.evType)
                    
                    this.enableBlackout = response.data.blackout_start_time != undefined && response.data.blackout_start_time != undefined

                    if (this.enableBlackout) {
                        this.blackoutStartTime = response.data.blackout_start_time
                        this.blackoutEndTime = response.data.blackout_end_time
                    }
            });
        },
        refresh() {
            this.enableBlackout = false,
            this.blackoutStartTime = "00:00:00"
            this.blackoutEndTime = "00:00:00"
            this.getGroups()
            this.getUserInfo()
        }
    }
}
</script>
<style scoped></style>