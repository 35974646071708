import axios from "axios";
import authHeader from "./auth.header";

class RolesService {

    getRoles(isTechnician) {
        return axios({
            method: 'get',
            url: '/api/roles/list',
            headers: authHeader(),
            params: { 
                isTechnician: isTechnician
            }
        })
    }

    getRolesById(id) {
        return axios({
            method: 'get',
            url: '/api/roles/list_by_id',
            headers: authHeader(),
            params: { userId: id }
        })
    }

    getClaims() {
        return axios({
            method: 'get',
            url: '/api/roles/list_claims',
            headers: authHeader()
        });
    }

    getClaimsByRole(roleId) {
        return axios({
            method: 'get',
            url: '/api/roles/list_claims_by_role',
            headers: authHeader(),
            params: { roleId: roleId }
        })
    }

    getUserCount(roleId) {
        return axios({
            method: 'get',
            url: '/api/roles/count_users',
            headers: authHeader(),
            params: { roleId: roleId }
        })
    }

    updateRoleClaims(roleId, requiresTechnician, claims) {
        return axios({
            method: 'post',
            url: '/api/roles/update',
            headers: authHeader(),
            data: {
                role_id: roleId,
                requires_technician: requiresTechnician,
                claims: claims
            }
        })
    }

    addRole(name, claims, requiresTechnician) {
        return axios({
            method: 'post',
            url: 'api/roles/add',
            headers: authHeader(),
            data: {
                name: name,
                claims: claims,
                requires_technician: requiresTechnician
            }
        })
    }

    deleteRole(roleId) {
        return axios({
            method: 'delete',
            url: '/api/roles/delete',
            headers: authHeader(),
            params: { roleId: roleId }
        });
    }

    doesRoleRequireTwoFa(role) {
        return axios({
            method: 'get',
            url: '/api/roles/does_role_require_two_fa',
            headers: authHeader(),
            params: {
                webcode: authHeader().webcode,
                role
            }
        })
    }

    getExcludedColumnTypes () {
        return axios({
            method: 'get',
            url: '/api/roles/excluded_column_types',
            headers: authHeader(),
        })
    }

    getExcludedColumnsByRoleAndType(roleId, typeId) {
        return axios({
            method: 'get',
            url: '/api/roles/excluded_columns_by_role_and_type',
            headers: authHeader(),
            params: {
                roleId,
                typeId
            }
        })
    }

    getExcludedColumnsByRole(roleId) {
        return axios({
            method: 'get',
            url: '/api/roles/excluded_columns_by_role',
            headers: authHeader(),
            params: {
                roleId
            }
        })
    }

    updateExcludedColumnsByRole(roleId, typeId, excludedColumns) {
        return axios({
            method: 'post',
            url: '/api/roles/excluded_columns_by_role',
            headers: authHeader(),
            data: {
                role_id: roleId,
                type_id: typeId,
                excluded_columns: excludedColumns
            }
        })
    }

    isTechnician() {
        return axios({
            method: 'get',
            url: '/api/roles/is_technician',
            headers: authHeader(),
        })
    }

    duplicateRole(roleId, name) {
        return axios({
            method: 'post',
            url: '/api/roles/duplicate_Role',
            headers: authHeader(),
            params: {
                roleId: roleId,
                name
            }
        });
    }

    isRoleNameUnique(name) {
        return axios({
            method: 'get',
            url: '/api/roles/is_role_name_unique',
            headers: authHeader(),
            params: {
                name
            }
        });
    }
}

export default new RolesService();