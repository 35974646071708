<template>
    <div data-app>
        <UpdateTaxListValue
            ref="updateTaxListValue"
            :values="effective_dates"
            :checkValid="checkValid"
        />
        <hr/>
        <b-button 
            variant="primary" 
            @click="updateStoreTax" 
            :disabled="effective_dates.length == 0"
            class="mt-2 right"
        >
            Update
        </b-button>
    </div>
</template>

<script>
import { StoresService } from '../../services';
import UpdateTaxListValue from './UpdateTaxListValue.vue';

export default {
    name: 'UpdateTax',
    components: {
        UpdateTaxListValue
    },
    data() {
        return {
            isLoading: false,
            effective_dates: [],
            minDate: new Date(),
            checkValid: false
        };
    },
    props: {
        store: { type: Object },
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.checkValid = false
            this.getTax()
        },
        updateStoreTax() {
            this.checkValid = true
            this.isLoading = true;
            if (this.$refs.updateTaxListValue.validateAll()) {           
                StoresService.updateTax(this.store.id, this.effective_dates).then((response) => {
                    if (response) {
                        if (response.data.success) {
                            this.$root.$bvToast.toast('Store tax info updated', {
                                title: 'Success',
                                variant: 'success',
                                solid: true,
                            });
                        } else {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                                solid: true,
                            });
                    }
                            this.$emit('hide');
                        }
                });
            }
        },
        formatDate(date) {
            if(date == undefined || date == "") return;
            return new Date(Date.parse(date)).toLocaleString();
        },
        getTax(){
            StoresService.getTax(this.store.id).then((response) => {
                    this.effective_dates = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'StoreServuce.getTax failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        }
    },
};
</script>
<style scoped></style>