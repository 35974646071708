<template>
    <div>
        <b-tabs lazy>
            <b-tab title="Bulk Import">
                <BulkImportQRCodes @hide="hide"/>
            </b-tab>
            <b-tab title="Manual Import">
                <AddQRCodeComponent @hide="hide"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

import { AddQRCodeComponent, BulkImportQRCodes } from './index'

export default {
    name: 'AddQRCodeComponentTabs',
    components: {
        AddQRCodeComponent,
        BulkImportQRCodes,
    },
    methods: {
        hide() {
            this.$emit('hide')
        }
    }
}
</script>

<style scoped>
</style>