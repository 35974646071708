<template>
    <div>
        <div>
            <b-form-group id="email-input-group" label="Email" label-for="email">
                <b-form-input 
                    id="email-input"
                    v-model="invite.email"
                    :state="emailState"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="emailState == false" class="error">
                Email format in invalid
            </div>
            <b-form-group id="username-input-group" label="Username" label-for="username">
                <b-form-input 
                    id="username-input"
                    v-model="invite.username"
                    :state="usernameState"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="usernameState == false" class="error">
                Username is required
            </div>
            <b-form-group id="name-input-group" label="Name" label-for="name">
                <b-form-input 
                    id="name-input"
                    v-model="invite.name"
                    :state="nameState"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="nameState == false" class="error">
                Name is required
            </div>
            <ClaimRoleComponent v-model="invite.role" :state="roleState"/>
            <div v-if="roleState == false" class="error">
                Role is required
            </div>
            <b-form-checkbox 
                id="azure-account-input"
                v-model="invite.azure_account"
                v-if="isSSOAvailable"
            >
            Azure Account
            </b-form-checkbox>
        </div>
        <b-button @click="inviteUser" variant="primary" class="right">Invite</b-button>
    </div>
</template>

<script>
import { UsersService} from "../../services"
import { ClaimRoleComponent } from "../../components"

export default {
    name: "InviteUser",
    components: {
        ClaimRoleComponent
     },
    data() {
        return {
            invite: {
                email: "",
                username: "",
                name: "",
                role: {}
            },
            roles: [],
            checkValid: false,
            emailRegex: new RegExp('[a-z0-9]+@[a-z0-9]+\\.[a-z]{2,3}'),
            isSSOAvailable: false,
        };
    },
    computed:{
        emailState() {
            if (!this.checkValid) return null
            return this.invite.email != "" && this.emailRegex.test(this.invite.email)
        },
        usernameState() {
            if (!this.checkValid) return null
            return this.invite.username != ""
        },
        nameState() {
            if (!this.checkValid) return null
            return this.invite.name != ""
        },
        roleState() {
            if (!this.checkValid) return null
            return this.invite.role.description != ""
        },
        isFormValid() {
            return this.emailState && this.nameState && this.roleState
        },
        security() {
            return this.$store.state.auth.security
        },
    },
    mounted() {
        this.isSSOAvailable = this.$store.state.auth.portal_configuration.allowsso == '1';
    },
    methods: {
        inviteUser(){
            this.checkValid = true 

            if(this.isFormValid) {
                UsersService.inviteUser(
                    this.invite.email,
                    this.invite.username,
                    this.invite.name,
                    this.invite.role.description,
                    this.invite.azure_account
                    ).then((response) => {
                        if (response.data) {
                            this.checkValid = false

                            this.$root.$bvToast.toast(response.data.message, {
                                title: response.data.success ? 'Success' : 'Error',
                                variant: response.data.success?  'success' : 'danger',
                                solid: true
                            })

                            this.$emit('hide')
                        }
                })
            }
        }
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>