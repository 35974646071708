<template>
    <div>
        <h3>
            <font-awesome-icon icon="boxes-stacked" class="nav-icon fa" />  Products
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf23')" class="help-icon nav-icon fa" title="Sleegers User Guide - Products"/>
        </h3>
        <div class="button-group">
            <div class="button-col">
                <button @click="openAddModal" class="btn btn-primary button" v-if="security.productCreate">New Product</button>
            </div>
        </div>
        <div class="pb-3">
            <Table
              id="productsTable"
              :fields="dynamicFields"
              :items="products"
              sortBy="description"
              :sortDesc="false"
              :searchPagination="searchPagination"
              :horizontalScroll="true"
              @getItems="getProducts"
              @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="row">
                    <div v-if="row.item.typeId != 4" class="icons">
                        <font-awesome-icon icon="pencil" class="nav-icon pointer mx-2" v-if="security.productWrite" @click="openUpdateModal(row.item)" title="Edit"/>
                        <font-awesome-icon icon="gear" class="nav-icon pointer mx-2" v-if="security.portalConfigWrite" @click="openProductConfigModal(row.item)" title="Configuration"/>
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" v-if="security.productDelete" @click="openDeleteProductModal(row.item)" title="Delete"/>
                    </div>
                </template>
                <template #cell(message_start_date)="data">
                    {{data.item.message_start_date != null ? formatDate(convertUTCtoLocalDate(data.item.message_start_date)) : '' }}
                </template>
                <template #cell(message_end_date)="data">
                    {{data.item.message_end_date != null ? formatDate(convertUTCtoLocalDate(data.item.message_end_date)) : '' }}
                </template>
            </Table>

            <ExportFileComponent
                :arguments="currentQuery"
                :disabled="false"
                page="products"
                class="export"
            />

            <DeleteProductModal  @deleteComplete="deleteComplete" :productId="selectedProductId" />
            <AddProductModal @changeComplete="changeComplete" :billingLocationLength="billingLocationLength" />
            <UpdateProductModal @changeComplete="changeComplete" :productDetails="productDetails" :billingLocationLength="billingLocationLength"/>
            <ProductConfigModal @changeComplete="changeComplete" :productDetails="productDetails"/>
        </div>
    </div>
</template>

<script>
import { ProductsService, CustomFieldsService } from "../../services"
import SearchPagination from '../../models/search.pagination'
import { Table } from "../../components/"
import { DeleteProductModal, AddProductModal, UpdateProductModal, ProductConfigModal } from "../../components/kiosk/products"
import CUSTOM_FIELD_TYPE_ENUM from "../../models/CustomFieldTypeEnum"
import ExportFileComponent from '../../components/ExportFileComponent.vue'
import { formatDate, convertUTCtoLocalDate, openHelpWindow } from '../../utils/utils'

export default {
    name: "Products",
    components: {
        Table,
        DeleteProductModal,
        AddProductModal,
        UpdateProductModal,
        ProductConfigModal,
        ExportFileComponent
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'receipt_message',
                    label: 'Receipt Message',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'email_message',
                    label: 'Email Message',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'message_start_date',
                    label: 'Message Start Date',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'message_end_date',
                    label: 'Message End Date',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'gst_reg',
                    label: 'GST Reg',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'receipt_heading',
                    label: 'Heading',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'receipt_primary_message',
                    label: 'Message 1',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'receipt_secondary_message',
                    label: 'Message 2',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'receipt_phone_number',
                    label: 'Phone',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'billing_location',
                    label: 'Billing Location',
                    tdClass: 'column',
                    sortable: false
                }
          ],
          products: [],
          isLoading: false,
          selectedProductId: 0,
          searchPagination: new SearchPagination('', 1, 10, 1, 0),
          productDetails: {},
          sortOrder: 1,
          customColumns: [],
          currentQuery: {},
          billingLocationLength: 2048
        };
    },
    computed: {
        dynamicFields() {
            return this.fields.concat(this.customColumns)
        },
        security() {
            return this.$store.state.auth.security
        },
    },
    created() {
        this.getCustomColumns()
        this.getProducts();
    },
    methods: {
        openHelpWindow,
        formatDate,
        convertUTCtoLocalDate,
        getProducts() {
            this.isLoading = true;
            ProductsService.getPaged(
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                if (response) {
                    this.products = response.data.items;                 
                    this.searchPagination.totalRows = 
                    this.products.length > 0 ? response.data.total_rows : 0;
                    this.billingLocationLength = response.data.billing_location_length
                    this.isLoading = false;
                }
            })
        },
        openDeleteProductModal(item) {
            this.selectedProductId = item.id;
            this.$bvModal.show('product-delete-modal')
        },
        deleteComplete(){
            this.$bvModal.hide('product-delete-modal')
            this.getProducts();
        },
        changeComplete(){
            this.$bvModal.hide('product-add-modal')
            this.$bvModal.hide('product-update-modal')
            this.$bvModal.hide('product-config-modal')
            this.getProducts();
        },
        openAddModal() {
            this.$bvModal.show('product-add-modal')
        },
        openUpdateModal(item) {
            this.productDetails = item;
            this.$bvModal.show('product-update-modal')
        },
        openProductConfigModal(item) {
            this.productDetails = item;
            this.$bvModal.show('product-config-modal')
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getProducts()
        },
        getCustomColumns() {
            CustomFieldsService.getByType(CUSTOM_FIELD_TYPE_ENUM.Product).then((response) => {
                if (response) {
                    var cols = response.data

                    cols.forEach(col => {
                        this.customColumns.push({
                                key: 'custom_values.' + col.field_name,
                                label: col.field_name,
                                tdClass: 'column',
                                sortable: false
                            })
                    });
                }
            })
        },
    }
}
</script>
<style scoped>
::v-deep .action-column {
    min-width: 130px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>