<template>
    <div>
        <b-modal
            @submit.prevent="reloadAddPrice"
            id="price-add-modal"
            size="xl"
            @show="refresh"
        >
            <template #modal-title>
                <h3>Add Price</h3>
            </template>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group
                        id="description-input-group"
                        label="Description"
                        label-for="description"
                    >
                        <b-form-input
                            id="description"
                            v-model="priceDetails.description"
                            :state="descriptionState"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="descriptionState == false" class="error error-margins">
                        Description is required
                    </div>
                </div>
            </div>
            <b-tabs v-model="tabIndex">
                <b-tab
                    v-for="(
                        valueInfo, index
                    ) in priceDetails.price_effective_dates"
                    :key="index"
                >
                    <template #title>
                        <div :class="{'error' : validate(index, false) == false}">
                            ${{ valueInfo.purchase_price }} / ${{
                                valueInfo.exchange_price
                            }}
                            - {{ valueInfo.effective_date.split('T')[0] }}
                        </div>
                    </template>
                    <UpdatePriceTabValue
                        :ref="'updatePriceTabValue-' + index"
                        :priceDetails="valueInfo"
                        :checkValid="checkValid"
                        :dateOffset="index"
                    />
                </b-tab>
                <template #tabs-end>
                    <b-nav-item
                        role="presentation"
                        v-if="
                            priceDetails.price_effective_dates &&
                            priceDetails.price_effective_dates.length < 4
                        "
                        @click.prevent="addEffectiveDate"
                        href="#"
                        >+</b-nav-item
                    >
                </template>
            </b-tabs>
            <hr/>
            <div class="row row-cols-1">
                <b-form-checkbox
                    id="include-in-output-input"
                    class="ml-3"
                    v-model="priceDetails.include_in_output_generation"
                >
                    Include in Output Generation
                </b-form-checkbox>
            </div>
            <template #modal-footer>
                <div class="button-group">
                    <div>
                        <a
                            id="price-modal-add-price"
                            @click="deleteByCurrentTabIndex"
                            class="btn btn-danger"
                            >Delete</a
                        >
                    </div>
                    <div>
                        <a
                            id="price-modal-add-price"
                            @click="add()"
                            class="btn btn-primary right"
                            >Add</a
                        >
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import ProductsService from '../../../services/products.service';
import UpdatePriceTabValue from './UpdatePriceTabValue.vue';

export default {
    name: 'AddPriceModal',
    components: { UpdatePriceTabValue },
    data() {
        return {
            priceDetails: {
                description: '',
                price_effective_dates: [],
                include_in_output_generation: false,
            },
            isLoading: false,
            checkValid: false,
            tabIndex: 0,
        };
    },
    emits: ['changeComplete'],
    props: {
        productId: {
            type: Number,
            required: true,
    },
    },
    computed: {
        descriptionState() {
            if (!this.checkValid) return null;
            return this.priceDetails.description != '' ? true : false;
        },
        isFormValid() {
            return this.descriptionState && this.validateAll()
        }
    },
    methods: {
        refresh() {
            this.priceDetails = {
                description: '',
                price_effective_dates: [],
                include_in_output_generation: false,
            }
            this.isLoading = false
            this.checkValid = false
            this.tabIndex = 0
            this.addEffectiveDate()
        },
        reloadAddPrice() {
            this.$emit('reloadAddPrice');
        },
        add() {
            this.checkValid = true
            if (this.isFormValid) {
                this.priceDetails.product_id = this.productId;
                ProductsService.addPrice(this.priceDetails).then((response) => {
                    if (response) {
                        this.isLoading = false;
                        if (response.data.success) {
                            this.$root.$bvToast.toast(response.data.message, {
                            title: 'Successful',
                            variant: 'success',
                                solid: true,
                            });
                            this.checkValid = false;
                            this.$emit('changeComplete');
                            this.priceDetails = {};
                        } else {
                            this.vmidDuplicate = false;
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                                solid: true,
                            });
                        }
                        this.checkValid = true;
                    }
                });
            }
        },
        addEffectiveDate() {
            let d = new Date();
            let datestring = d.toISOString().split('T')[0];
            this.priceDetails.price_effective_dates.push({
                purchase_price: 0,
                exchange_price: 0,
                restock_purchase_cost: 0,
                restock_exchange_cost: 0,
                effective_date: datestring,
            });
        },
        removeDate() {
            this.priceDetails.price_effective_dates.pop();
        },
        deleteByCurrentTabIndex() {
            this.priceDetails.price_effective_dates.splice(this.tabIndex, 1);

            if (this.priceDetails.price_effective_dates.length == 0) {
                this.addEffectiveDate();
            }
        },
        validate(index, ignoreCheckValid) {
            var reference = this.$refs['updatePriceTabValue-' + index]

            if (reference && reference.length > 0) {
                return reference[0].validate(ignoreCheckValid)
            }
            else {
                return false
            }
        },
        validateAll() {
            this.checkValid = true

            var validity = []

            for (let i = 0; i < this.priceDetails.price_effective_dates.length; i++) {
                validity.push(this.validate(i, true))
            }

            return !validity.includes(false) && !validity.includes(null)
        }
    },
};
</script>

<style scoped>
.new-search {
    text-align: right;
    margin-right: 12px;
    position: relative;
    top: 38px;
}
.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.error {
    color: #dc3545;
}

.error-margins {
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>