import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils";

class KioskService {

    general(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/general',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    carousels(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/carousels',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    details(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/details',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    inventory(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/inventory',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    issues(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/issues',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    currentLevels(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/current_levels',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    extendedDetails(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/extended_details',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    checkinHistory(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/checkin_history',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    diagnostics(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/diagnostics',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    configuration(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/configuration',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    getPortalKioskConfiguration(){
        return axios({
            method: 'get',
            url: '/api/kiosk/portal_kiosk_configuration',
            headers: authHeader()
        })
    }

    issuesHistory(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/issues_history',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    searchIssuesHistory(kioskId, query, startIndex, pageSize, sortOrder) {     
        return axios({
            method: 'post',
            url: '/api/kiosk/search_issues_history',
            headers: authHeader(),
            data: {
                kiosk_id: kioskId,
                issue_type_id: query.issueTypeId,
                start_date: addTimezoneInformation(query.dateStart),
                end_date: addTimezoneInformation(query.dateEnd),
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    firmware(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/firmware',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    remoteAccess(kioskId) {
        return axios({
            method: 'post',
            url: '/api/kiosk/remote_access',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    transactionHistory(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/transaction_history',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    driverCodes(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/driver_codes',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    trust(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/trust',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    pair(kioskId) {
        return axios({
            method: 'post',
            url: '/api/kiosk/pair',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    revokeTrust(kioskId) {
        return axios({
            method: 'post',
            url: '/api/kiosk/revoke_trust',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    qrcode(kioskId) {
        return axios({
            method: 'get',
            url: '/api/kiosk/qr_code',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    restoreData(kioskId, logDataType) {
        return axios({
            method: 'get',
            url: '/api/kiosk/restore_data',
            headers: authHeader(),
            params: {
                kioskId,
                logDataType
            }
        })
    }

    restoreDataOptions() {
        return axios({
            method: 'get',
            url: '/api/kiosk/restore_data_options',
            headers: authHeader()
        })
    }

    downloadRestoreData(kioskId, dayOfWeek, logDataType) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/kiosk/download_restore_data',
            headers: authHeader(),
            params: {
                kioskId,
                dayOfWeek,
                logDataType,
            }
        })
    }

    updateDetails(request) {
        return axios({
            method: 'post',
            url: '/api/kiosk/update_details',
            headers: authHeader(),
            data: {
                kiosk_id: request.id,
                store_id: request.store_id,
                price_id: request.price_id,
                low_inventory: request.low_inventory,
                vmid: request.vmid,
                sim: request.sim,
                account_number: request.account_number,
                servant_notes: request.servant_notes,
                enabled: request.enabled,
                installed: request.installed,
                hardware_type: request.hardware_type,
                drive_capacity: request.drive_capacity,
                available_capacity: request.available_capacity
            },
        })
    }

    updateExtendedDetails(request) {
        return axios({
            method: 'post',
            url: '/api/kiosk/update_extended_details',
            headers: authHeader(),
            data: {
                kiosk_id: request.id,
                terminal_mid: request.terminal_mid,
                terminal_tid: request.terminal_tid,
                mac_address: request.mac_address,
                token_type: request.token_type,
                key_id: request.key_id
            }
        })
    }

    updateDiagnostics(request) {
        return axios({
            method: 'post',
            url: '/api/kiosk/update_diagnostics',
            headers: authHeader(),
            data: {
                kiosk_id: request.kiosk_id,
                carousel_id: request.carousel_id,
                maintenance_rotation: request.maintenance_rotation,
                update_seconds: request.update_seconds,
                rotate_and_stage: request.rotate_and_stage,
                remote_locker_number: request.remote_locker_number,
                remote_bay_number: request.remote_bay_number,
                cycle_power: request.cycle_power,
                perform_out_of_space_repair: request.perform_out_of_space_repair,
                activate_fan: request.activate_fan,
                rotate_home_position: request.rotate_home_position,
                stage_carousel: request.stage_carousel,
                disable_apu: request.disable_apu,
                request_logs: request.request_logs,
            }
        })
    }

    updateConfiguration(kioskId, request) {
        return axios({
            method: 'post',
            url: '/api/kiosk/update_kiosk_configuration',
            headers: authHeader(),
            data: {
                kiosk_id: kioskId,
                items: request
            }
        })
    }

    updatePortalKioskConfiguration(request){
        return axios({
            method: 'post',
            url: '/api/kiosk/update_portal_kiosk_configuration',
            headers: authHeader(),
            data: {
                items: request
            }
        })
    }

    downloadIssuesCsv(args) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/kiosk/download_issues_csv',
            headers: authHeader(),
            data: {
                kiosk_id: args.kioskId
            }
        })
    }

    downloadIssuesXlsx(args) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/kiosk/download_issues_xlsx',
            headers: authHeader(),
            data: {
                kiosk_id: args.kioskId
            }
        })
    }

    downloadIssuesHistoryCsv(args) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/kiosk/download_issues_history_csv',
            headers: authHeader(),
            data: {
                kiosk_id: args.kioskId,
                issue_type_id: args.query.issueTypeId,
                start_date: addTimezoneInformation(args.query.dateStart),
                end_date: addTimezoneInformation(args.query.dateEnd)
            }
        })
    }

    downloadIssuesHistoryXlsx(args) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/kiosk/download_issues_history_xlsx',
            headers: authHeader(),
            data: {
                kiosk_id: args.kioskId,
                issue_type_id: args.query.issueTypeId,
                start_date: addTimezoneInformation(args.query.dateStart),
                end_date: addTimezoneInformation(args.query.dateEnd)
            }
        })
    }

    getKioskOperations() {
        return axios({
            method: 'get',
            url: '/api/kiosk/kiosk_operations',
            headers: authHeader()
        })
    }

    hasNewCheckin(kioskId, lastCheckin) {
        return axios({
            method: 'get',
            url: '/api/kiosk/has_new_checkin',
            headers: authHeader(),
            params: {
                kioskId,
                lastCheckin
            }
        })
    }
}

export default new KioskService();