<template>
    <div class="api-login-register-modal">
        <b-modal @submit.prevent="registerApiLogin" @show="refresh" id="api-login-register-modal">
            <template #modal-title>
                <h3>Add Api Login</h3>
            </template>
            <div>
                <b-form-group id="name-input-group" label="Name" label-for="name">
                    <b-form-input 
                        id="name"
                        v-model="apiLogin.name"
                        :state="nameState"
                    ></b-form-input>
                </b-form-group>
                <div v-if="nameState == false" class="error">
                    Name is required
                </div>
                <b-form-group id="role-select-group" label="Role" label-for="role-select">
                    <b-form-select
                        id="role-select"
                        v-model="apiLogin.roleId"
                        :disabled="false"
                        :state="roleIdState"
                    >
                            <option v-for="role in roles" :value="role.id" :key="role.id">
                            {{ role.description }}
                            </option>
                    </b-form-select>
                </b-form-group>
                <div v-if="roleIdState == false" class="error">
                    Role is required
                </div>
            </div>
            <template #modal-footer>
                <a id="api-login-modal-register" @click="registerApiLogin" class="btn btn-primary btn-margin-right">Add</a>
            </template>
        </b-modal>
    </div>
</template>

<script>

import { AuthService, RolesService } from '../../services'

export default {
    name: "RegisterUserModal",
    components: { 
        
    },
    data() {
        return {
            apiLogin: {
                name: '',
                roleId: 0
            },
            checkValid: false,
            roles: []
        }
    },
    mounted() {
        
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
        nameState() {
            if(!this.checkValid) return null
            return this.apiLogin.name != ""
        },
        roleIdState() {
            if(!this.checkValid) return null
            return this.apiLogin.roleId != 0
        },
        isFormValid() {
            return this.nameState && this.roleIdState
        }
    },
     methods: {
        registerApiLogin() {
            this.checkValid = true

            if(this.isFormValid) {
                AuthService.registerApiLogin(
                    this.apiLogin.name,
                    this.apiLogin.roleId,
                ).then((response) => {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });
                    this.$emit('hide');
                });
            }
        },
        getRoles() {
            RolesService.getRoles(this.security.technician).then(
                (response) => {
                    this.roles = response.data;
            });
        },
        refresh() {
            this.apiLogin =  {
                name: '',
                roleId: 0
            }
            
            this.getRoles()
        }
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
    
}
</style>