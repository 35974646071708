<template>
    <b-form :disabled="true">
        <div class="row row-cols-2 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group id="main-current-group" label="Main - Current" label-for="main-current-firmware">
                    <b-form-input
                        id="main-current-firmware"
                        v-model="firmware.current_main_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="main-assigned-group" label="Assigned" label-for="main-assigned-firmware">
                    <b-form-input
                        id="main-assigned-firmware"
                        v-model="firmware.assigned_main_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
            <b-form-group id="pneu-current-group" label="Pneumatic - Current" label-for="pneu-current-firmware">
                <b-form-input
                    id="pneu-current-firmware"
                    v-model="firmware.current_pneumatic_firmware"
                    :disabled="true"
                ></b-form-input>
            </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="pneu-assigned-group" label="Assigned" label-for="pneu-assigned-firmware">
                    <b-form-input
                        id="pneu-assigned-firmware"
                        v-model="firmware.assigned_pneumatic_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="media-current-group" label="Media - Current" label-for="media-current-firmware">
                    <b-form-input
                        id="media-current-firmware"
                        v-model="firmware.current_media_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="media-assigned-group" label="Assigned" label-for="media-assigned-firmware">
                    <b-form-input
                        id="media-assigned-firmware"
                        v-model="firmware.assigned_media_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="relay-current-group" label="Relay - Current" label-for="relay-current-firmware">
                    <b-form-input
                        id="relay-current-firmware"
                        v-model="firmware.current_relay_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="relay-assigned-group" label="Assigned" label-for="relay-assigned-firmware">
                    <b-form-input
                        id="relay-assigned-firmware"
                        v-model="firmware.assigned_relay_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="col" v-if="!this.servantId">
                <b-form-group id="payment-current-group" label="Payment - Current" label-for="payment-current-firmware">
                    <b-form-input
                        id="payment-current-firmware"
                        v-model="firmware.current_payment_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="payment-assigned-group" label="Assigned" label-for="payment-assigned-firmware">
                    <b-form-input
                        id="payment-assigned-firmware"
                        v-model="firmware.assigned_payment_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="col" v-if="!this.servantId">
                <b-form-group id="screen-current-group" label="Touch Screen - Current" label-for="screen-current-firmware">
                    <b-form-input
                        id="screen-current-firmware"
                        v-model="firmware.current_touch_screen_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="screen-assigned-group" label="Assigned" label-for="screen-assigned-firmware">
                    <b-form-input
                        id="screen-assigned-firmware"
                        v-model="firmware.assigned_touch_screen_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="col" v-if="!this.servantId">
                <b-form-group id="shell-current-group" label="Shell Script - Current" label-for="shell-current-firmware">
                    <b-form-input
                        id="shell-current-firmware"
                        v-model="firmware.current_shell_script"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="shell-assigned-group" label="Assigned" label-for="shell-assigned-firmware">
                    <b-form-input
                        id="shell-assigned-firmware"
                        v-model="firmware.assigned_shell_script"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="col" v-if="!this.servantId">
                <b-form-group id="payment-terminal-group" label="Payment Terminal" label-for="payment-terminal-firmware">
                    <b-form-input
                        id="payment-terminal-firmware"
                        v-model="firmware.current_payment_term_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="runtime-group" label="Runtime" label-for="runtime-firmware">
                    <b-form-input
                        id="runtime-firmware"
                        v-model="firmware.current_runtime_firmware"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group id="os-version-group" label="OS Version" label-for="os-version-firmware">
                    <b-form-input
                        id="os-version-firmware"
                        v-model="firmware.current_os_version"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col" v-if="!this.servantId">
                <b-form-group id="os-build-group" label="OS Build" label-for="os-build-firmware">
                    <b-form-input
                        id="os-build-firmware"
                        v-model="firmware.current_build_version"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>

        </div>
    </b-form>

</template>
 
<script>

import KioskService from "../../services/kiosk.service"
import ServantsService from "../../services/servants.service"

export default {
    name: "Firmware",
    props: {
        kioskId: {
            type: String,
            required: true
        },
        servantId: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            firmware: {},
            loading: false
        }
    },
    mounted() {
        this.getFirmware()
    },
    methods: {
        getFirmware() {
            this.loading = true
            if (this.servantId) {
                ServantsService.firmware(
                    this.servantId,
                    this.kioskId
                ).then((response) => {
                    if (response) {
                        this.firmware = response.data
                        this.loading = false
                    }
                })
            } else {
                KioskService.firmware(
                    this.kioskId
                ).then((response) => {
                    if (response) {
                        this.firmware = response.data
                        this.loading = false
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>