<template>
    <b-form @submit="onSubmit">
        <div class="row row-cols-2 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group id="port-group" label="Port" label-for="port-select">
                    <b-form-select
                        id="port-select"
                        v-model="form.selectedPort"
                        :disabled="this.disabled">
                        <option v-for="preset in remoteAccess.presets" :value="preset.id" :key="preset.id">
                            {{ preset.description }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col" v-if="security.relayRead">
                <div class="button-group">
                    <b-button type="submit" class="details-btn" v-if="!this.showClose" variant="primary">Open</b-button>
                    <b-button class="details-btn" @click="closePort" v-if="this.showClose" variant="secondary">Close</b-button>
                </div>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-1 g-4">
            <div class="col">
                <b-form-group id="status-group" label="Status" label-for="status-input">
                    <b-form-input
                        id="status-input"
                        v-model="form.statusMessage"
                        :disabled="true">
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                {{ form.lastPingMessage }}
            </div>
            <div class="col mt-4">
                <a href="/files/relay/relayclientinstall.exe" download>Download Relay Client Installer</a>
            </div>
        </div>
    </b-form>
</template>

<script>

import KioskService from "../../services/kiosk.service"
import RelayService from "../../services/relay.service"

export default {
    name: 'RemoteAccess',
    props: {
        kioskId: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            form: {
                selectedPort: 1,
                statusMessage: '',
                lastPingMessage: ''
            },
            remoteAccess: {},
            loading: false,
            disabled: false,
            showClose: false
        }
    },
    computed:{
        security(){
            return this.$store.state.auth.security
        },
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.getRemoteAccess()
            this.checkStatus()
        },
        getRemoteAccess() {
            this.loading = true
            KioskService.remoteAccess(
                this.kioskId
            ).then((response) => {
                if (response) {
                    this.remoteAccess = response.data
                    this.form.statusMessage = this.remoteAccess.status_message
                    this.loading = false
                }
            })
        },
        onSubmit(event) {
            event.preventDefault()
            this.showClose = true
            RelayService.openPort(
                this.form.selectedPort,
                this.kioskId,
                this.$store.state.auth.user.user_name
            ).then((openPortResp) => {
                if (openPortResp.data == true) {
                    RelayService.GetRelayClientString(
                        this.kioskId
                    ).then((relayStringResp) => {
                        if (relayStringResp) {
                            let args = relayStringResp.data.protocol + ':' + relayStringResp.data.connection_string
                            location.href = args;
                            console.log('relay client args: ' + args)
                        }
                    })
                    this.disabled = true
                }
            })
        },
        closePort() {
            RelayService.closePort(
                this.kioskId
            ).then((response) => {
                if (response) {
                    this.showClose = false
                    this.disabled = false
                    this.getRemoteAccess()
                }
            })
        },
        checkStatus() {
            RelayService.checkStatus(
                this.remoteAccess.status_id, this.kioskId
            ).then((response) => {
                if (response) {
                    let data = response.data
                    if (data.state_change) {
                        this.getRemoteAccess()
                    }
                    if (data.last_ping !== null && data.last_ping !== undefined) {
                        if (data.last_ping < 60) 
                            this.form.lastPingMessage = data.last_ping + ' seconds since last ping'
                        else if (data.last_ping < 3600) 
                            this.form.lastPingMessage = Math.floor(data.last_ping / 60) + ' minute(s) since last ping'
                        else if (data.last_ping < 86400) 
                            this.form.lastPingMessage = Math.floor(data.last_ping / 3600) + ' hour(s) since last ping'
                        else 
                            this.form.lastPingMessage = 'More than a day since last ping'
                    }
                    else {
                        this.form.lastPingMessage = 'Kiosk Relay has not pinged'
                    }
                }
            })
            setTimeout(this.checkStatus, 5000)
        }
    }
}
</script>

<style scoped>
.button-group {
    margin-top: 2rem
}
.details-btn {
    width: 5rem;
}

@media only screen and (max-width: 750px) {
    .details-btn {
        width: 100%;
    }
}
</style>