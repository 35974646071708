<template>
    <div>
        <div class="d-block">
            <p>You are about to unpair servant <b>{{servant.svmid}}</b></p>
            <p>Do you want to proceed?</p>
        </div>
        <div class="float-right">
            <button class="btn btn-danger" @click="unpair()">
                Unpair
            </button>
        </div>
    </div>
</template>

<script>
import ServantsService from "../../../services/servants.service"
export default {
    name: 'UnpairServant',
    props: {
        servant: {
            type: Object,
            required: true,
        },
    },
    methods: {
        unpair() {
            ServantsService.unpair(this.servant.id)
            .then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? "Un-pair Successful" : "Error",
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })

                    this.$emit("hide")
                }
            })
        }
    }
}
</script>

<style scoped>
</style>