<template>
    <div>
        <h3>
            <font-awesome-icon icon="hourglass" class="nav-icon fa" /> Region
            Uptime
        </h3>
        <DynamicSearchComponent
            ref="regionUptimeSearchComponent"
            preferencesName="region_uptime_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadUptimeReport"
        />
        <div class="pb-4">
            <Table
                id="regionUptimeTable"
                :fields="fields"
                :items="uptimeReport"
                sortBy=""
                :sortDesc="false"
                :searchPagination="searchPagination"
                @getItems="getRegionUptime"
            >
                <template #cell(date)="data">
                    {{ formatDate(data.item.date) }}
                </template>
            </Table>
        </div>
        <ExportFileComponent
            :arguments="this.arguments.query"
            :disabled="false"
            page="regionUptime"
            class="export"
        />
    </div>
</template>

<script>
import { DynamicSearchComponent } from '../../components';
import { UptimeService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import { Table, ExportFileComponent } from '../../components/';

export default {
    name: 'RegionUptime',
    components: {
        DynamicSearchComponent,
        Table,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'region',
                    label: 'Vending Markets',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_count',
                    label: 'Current Store Count',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'weekly_uptime',
                    label: 'Weekly Up-time',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'weekly_in_stock',
                    label: 'Weekly In-Stock',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                },
                {
                    key: 'store_number',
                    name: 'Store Number',
                    type: 'Text',
                    searchQueryName: 'storeNumber',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'province',
                    name: 'Province',
                    type: 'Province',
                    searchQueryName: 'province',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'datestart',
                    name: 'Date Start',
                    type: 'Date',
                    subtype: 'start-date',
                    searchQueryName: 'dateStart',
                    defaultValue: new Date().toISOString().slice(0, 10),
                    required: true,
                },
                {
                    key: 'dateend',
                    name: 'Date End',
                    type: 'Date',
                    subtype: 'end-date',
                    searchQueryName: 'dateEnd',
                    defaultValue: new Date().toISOString().slice(0, 10),
                    required: true,
                },
            ],
            uptimeReport: [],
            arguments: [],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
        };
    },
    mounted() {
    },
    methods: {
        getRegionUptime() {
            this.$refs.regionUptimeSearchComponent.reloadDynamicSearch();
        },
        reloadUptimeReport(args) {
            this.arguments = args;
            this.isLoading = true;
            UptimeService.searchRegionQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                if (response) {
                    this.uptimeReport = response.data.items;
                    this.searchPagination.totalRows = this.uptimeReport.length > 0 ? response.data.total_rows : 0;
                    this.isLoading = false;
                    args.done();
                }
            });
        },
        redirectToKiosk() {
            this.$router.push('/kioskUptime');
        },
    },
};
</script>
<style scoped>

::v-deep .export > div {
    margin-top: -2.5rem !important;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}

</style>