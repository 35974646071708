<template>
    <div data-app>
        <v-autocomplete
            :items="items"
            v-model="selected"
            :disabled="disabled"
            item-text="storeText"
            item-value="id"
            label="Stores: "
            :error="!state"
            :class="{'error' : state == false}"
            v-if="!showReadOnlyValue"
        />
        <div v-else>
            <b-form-group
                    id="read-only-store-group"
                    label="Store "
                    label-for="read-only-store"
                >
                    <b-form-input
                        id="read-only-store"
                        v-model="readOnlyValue"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
        </div>
    </div>
</template>

<script>
import StoresService from '../services/stores.service';

export default {
    name: 'StoresSelect',
    components: {},
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        state:{
            type: Boolean,
            required: false,
            default: undefined
		},
        readOnlyValue: {
            type: String,
            required: false,
        },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
        items() {
            return this.stores.map((store) => {
                const storeText =
                    store.number +
                    ', ' +
                    store.description +
                    ', ' +
                    store.city +
                    ', ' +
                    store.province.abbr;
                return Object.assign({}, store, { storeText });
            });
        },
        security() {
            return this.$store.state.auth.security;
        },
        showReadOnlyValue() {
            if (!this.security.storeRead) {
                return this.readOnlyValue != undefined
            }

            return false
        }
    },
    data() {
        return {
            stores: [],
        };
    },
    mounted() {
        if (this.security.storeRead) {
            this.getStores();
        }
    },
    methods: {
        getStores() {
            StoresService.get()
                .then((response) => {
                    this.stores = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'StoresService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
.error {
    color:#dc3545 !important;
}
</style>