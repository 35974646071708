<template>
    <div data-app>
        <div>
            <div class="row">
                <div class="col">
                    <b-form-file
                        v-model="storeCSV"
                        placeholder="Select a CSV or drop it here"
                        drop-placeholder="Drop CSV Here"
                        accept=".csv"
                        :state="csvState"
                    />
                    <div v-if="csvState == false" class="error">
                        CSV is required
                    </div>
                </div>
            </div>
            <div class="mt-3 button-group">
                <div class="button-group">
                    <a @click="downloadCurrentStoreview" class="align-self-center pointer">
                        Download current store view as .csv
                    </a>
                </div>
                <div>
                    <b-button variant="primary" @click="onSubmit">Import</b-button>
                </div>
            </div>
            <div class="result-box mt-3">
                <div class="mb-2">
                    <div v-for="(error, index) in storeErrors" v-bind:key="index" style="color: red">
                        {{ error }}
                    </div>
                </div>

                <div
                    v-if="confirmationTaxValues.length > 0"
                    @click="forceTaxChange"
                    class="submit"
                    style="margin-left: 14px"
                >
                    <b-button>Confirm Tax Changes</b-button>
                </div>
                <div v-if="success">Store Tax Imported Successfully</div>
            </div>
        </div>
    </div>
</template>

<script>
import { StoresService } from '../../services';
import { saveAs } from 'file-saver';

export default {
    name: 'AddBulkStoreTaxComponent',
    props: {
        arguments: {
            type: Object,
        },
    },
    data() {
        return {
            storeCSV: null,
            success: false,
            storeErrors: [],
            confirmationTaxValues: [],
            checkValid: false
        };
    },
    computed: {
        csvState() {
            if (!this.checkValid) return null
            return this.storeCSV ? true : false
        },
        isFormValid() {
            return this.csvState
        }
    },
    methods: {
        onSubmit() {
            this.checkValid = true
            if (this.isFormValid) {
                var formData = new FormData();
                formData.append('StoreCSV', this.storeCSV);
                StoresService.uploadTaxCsv(formData).then((response) => {
                this.storeErrors = response.data.errors
                this.success = response.data.success
                    this.confirmationTaxValues =
                        response.data.confirmationTaxValues;

                if (this.success && this.storeErrors.length === 0) {
                        this.$root.$bvToast.toast('Tax Updated', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                    this.storeCSV = null
                    this.hideModal()
                    } else {
                        this.$root.$bvToast.toast('Check Error Messages', {
                            title: 'Stores Not Added',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                });
            }
        },
        forceTaxChange() {
            StoresService.forceTaxChange(this.confirmationTaxValues).then(
                (response) => {
                    this.storeErrors = response.data.errors
                    this.success = response.data.success

                    if (this.success) {
                        this.$root.$bvToast.toast('Tax Updated', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                        this.storeCSV = null;
                    } else {
                        this.$root.$bvToast.toast('Check Error Messages', {
                            title: 'Stores Not Added',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                }
            );
        },
        downloadCurrentStoreview() {
            this.displayToast();
            Object.assign(this.arguments, { fromBulkUpload: true });
            StoresService.downloadCsv(this.arguments).then(
                (response) => {
                    if (response) {
                        const blob = new Blob([response.data], {
                            type: response.headers['content-type'],
                        });
                        saveAs(
                            blob,
                            response.headers['content-disposition']
                                .split('filename=')[1]
                                .split(';')[0]
                        );
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        displayToast() {
            this.$root.$bvToast.toast(`File will download momentarily`, {
                title: 'Export In Progress',
                variant: 'success',
                solid: true,
            });
        },
        hideModal() {
            setTimeout(() => {
                this.$emit('hide');
            }, 700)
        }
    },
};
</script>

<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
}
.error {
    color: #dc3545;
}
</style>