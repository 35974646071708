<template>
    <b-modal id="driver-code-delete-modal">
        <template #modal-title>
            <h3>Delete Kiosk Access Code</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p class="text-danger font-weight-bold">
                        Removing a kiosk access code will delete the associated
                        driver.
                    </p>
                    <p>Do you want to proceed?</p>
                </div>
            </div>
        </div>
        <template #modal-footer="{ cancel }">
            <a @click="deleteDriverCode()" class="btn btn-danger"> Delete </a>
            <a @click="cancel()" class="btn btn-secondary"> Close </a>
        </template>
    </b-modal>
</template>

<script>
import UsersService from '../../../services/users.service';

export default {
    name: 'DriverCodeDelete',
    props: {
        userId: Number,
    },
    emits: ['deleteComplete'],
    methods: {
        deleteDriverCode() {
            UsersService.deleteUser(this.userId).then((response) => {
                if (response.data.success) {
                    this.$root.$bvToast.toast('User Deleted', {
                        title: 'Delete User Successful',
                        variant: 'success',
                        solid: true,
                    });
                    this.$emit('deleteComplete', 'User Deleted');
                }
            });
        },
    },
};
</script>