<template>
    <div class="container-fluid">
        <div v-if="!isMobile">
            <div class="row mb-2">
                <div
                    id="tax-value-input-group"
                    class="col pl-0"
                >
                    Tax Percent (%)
                </div>
                <div
                    id="update-date-datepicker-group"
                    class="col title"
                >
                    Effective Date
                </div>
                <div @click="addValue" :style="{ 'visibility': values.length < maxValues ? 'visible' : 'hidden' }">
                    <font-awesome-icon icon="plus" class="col nav-icon fa cursor icon-hover" title="Add tax"/>
                </div>
            </div>
            <div v-for="(valueInfo, index) in values" :key="index" class="row mb-2">
                <div class="col mr-1 pl-0">
                    <b-form-input
                        :id="index + '-tax-value'"
                        type="number"
                        v-model="values[index].tax_value"
                        :state="validatePercent(index)"
                    ></b-form-input>
                    <div v-if="validatePercent(index) == false" class="error">
                        Tax Percent must be greater than 0
                    </div>
                </div>
                <div class="col">
                    <b-form-datepicker
                        :id="index + '-update-date-datepicker'"
                        v-model="values[index].effective_date"
                        right
                        :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit'}"
                        :state="validateDate(index)"
                    />
                    <div v-if="validateDate(index) == false" class="error">
                        Effective Date is required
                    </div>
                </div>
                <div class="col col-auto m-0 p-0">
                    <div class="buttons trash">
                        <div @click="deleteValue(index)" v-if="values.length > 1">
                            <font-awesome-icon icon="trash-can" class="nav-icon fa cursor icon-hover" title="Delete tax"/>
                        </div>
                        <div v-else>
                            <font-awesome-icon icon="trash-can" style="color: grey" class="fa" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row mb-2">
                <div class="col p-0">
                    <div @click="addValue" :style="{ 'visibility': values.length < maxValues ? 'visible' : 'hidden' }">
                        <font-awesome-icon icon="plus" class="right nav-icon fa cursor icon-hover" title="Add tax"/>
                    </div>
                </div>
            </div>
            <div v-for="(valueInfo, index) in values" :key="index">
                <hr class="divider"/>
                <div class="row">
                    <div class="col p-0">
                        <b-form-group :id="index + '-tax-value-input-group'" label="Tax Percent (%)" :label-for="index + '-tax-value'" label-size="sm">
                            <b-form-input
                                :id="index + '-tax-value'"
                                type="number"
                                v-model="values[index].tax_value"
                                :state="validatePercent(index)"
                            ></b-form-input>
                        </b-form-group>
                        <div v-if="validatePercent(index) == false" class="error">
                            Tax Percent must be greater than 0
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col p-0">
                        <b-form-group :id="index + '-update-date-datepicker'" label="Effective Date (%)" :label-for="index + '-update-date-datepicker'" label-size="sm">
                            <b-form-datepicker
                                :id="index + '-update-date-datepicker'"
                                v-model="values[index].effective_date"
                                right
                                :date-format-options="{ year: '2-digit', month: '2-digit', day: '2-digit'}"
                                :state="validateDate(index)"
                            />
                        </b-form-group>
                        <div v-if="validateDate(index) == false" class="error">
                            Effective Date is required
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col p-0">
                        <div class="buttons trash right">
                            <div @click="deleteValue(index)" v-if="values.length > 1">
                                <font-awesome-icon icon="trash-can" class="nav-icon fa cursor icon-hover" title="Delete tax"/>
                            </div>
                            <div v-else>
                                <font-awesome-icon icon="trash-can" style="color: grey" class="fa" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "UpdateTaxListValue",
    props: {
        maxValues: {
            type: Number,
            default: 4
        },
        values: {
            type: Array
        },
        checkValid: { 
            type: Boolean
        },
    },
    data() {
        return {
            ignoreCheckValid: false
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.width <= 1000;
        },
    },
    watch: {
        values() {
            if (this.values == null || this.values.length == 0) {
                this.values.push({
                    tax_value: 0,
                    effective_date: ""
                })
            }
        }
    },
    mounted() {
        if (this.values == null || this.values.length == 0)  {
            this.values.push({
                tax_value: 0,
                effective_date: ""
            })
        }
    },
    methods: {
        addValue() {
            if (this.values.length < this.maxValues) {
                this.values.push({
                    tax_value: 0,
                    effective_date: ""
                })
            }
        },
        deleteValue(index) {
            this.values.splice(index, 1)
        },
        validatePercent(index) {
            if (!this.ignoreCheckValid && !this.checkValid) return null
            var valueInfo = this.values[index]
            if (!valueInfo.tax_value) return false
            return valueInfo.tax_value > 0
        },
        validateDate(index) {
            if (!this.ignoreCheckValid && !this.checkValid) return null
            var valueInfo = this.values[index]
            if (!valueInfo.effective_date) return false
            return valueInfo.effective_date.length > 0
        },
        validate(index) {
            return this.validatePercent(index) && this.validateDate(index) 
        },
        validateAll() {
            var validity = []
            this.ignoreCheckValid = true

            for (let i = 0; i < this.values.length; i++) {
                validity.push(this.validate(i))
            }

            return validity.length > 0 && !validity.includes(false)
        }
    }
}
</script>
<style scoped>
.title {
    padding-left: 3.25rem;
}
.right {
    float: right;
}

.drop-down {
    border: 1px dashed #ced4da;
    border-radius: 0.25rem;
}

.group {
    display: flex;
}

.buttons {
    align-self: center;
    
}

.cursor {
    cursor: pointer;
}

.icon-hover:hover {
    opacity: 60%;
}

.error {
    color: #dc3545;
    margin-top: 0.25rem;
}

.trash {
    margin-top : 0.4rem;
}

@media only screen and (max-width: 1000px) {
    .error {
        color: #dc3545;
        margin-top: -0.75rem;
        margin-bottom: 0.5rem;
    }
    .divider {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}
</style>