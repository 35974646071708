<template>
    <b-modal id="transaction-email-receipt-modal">
        <template #modal-title>
            <h3>Email Receipt</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label>Transaction #</label>
                        <b-form-input v-model="transactionId" class="span8 transaction-search-field" disabled />
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Email</label>
                        <b-form-input v-model="receiptEmail" :state="emailValid" class="transaction-search-field receipt-email" type="email" required />
                        <b-form-invalid-feedback class="field-validation-error">Requires valid email</b-form-invalid-feedback>
                    </p>
                </div>
                <div id="email-modal-receipt-confim" class="control-group span8" style="display:none">
                    <p>
                        <span id="email-modal-receipt-confim-msg"></span>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a id="email-modal-receipt-send" @click="send()" class="btn btn-primary">Send</a>
        </template>
    </b-modal>
</template>

<script>

import TransactionsService from '../../services/transactions.service'

export default {
    name: "TransactionEmailReceiptModal",
    data() {
        return {
            receiptEmail: "",
            emailCheckValid: false,
            message: ""
        }
    },
    props: {
        transactionId: Number
    },
    computed: {
        emailValid(){
            if (!this.emailCheckValid){
                return null
            }

            var empatt = new RegExp('.+@.+\\..+');

            if (!empatt.test(this.receiptEmail)){
                return false
            }

            return true
        }
    },
    emits: ['emailComplete'],
    methods: {
        send(){
            this.emailCheckValid = true
            if (this.emailValid){
                TransactionsService.emailReceipt(this.transactionId, this.receiptEmail).then(result => {
                    if (!result.data.success){
                        this.$root.$bvToast.toast(result.data.message ?? 'An error has occurred emailing receipt', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                    else {
                        this.$root.$bvToast.toast('The receipt was successfully added to the email queue', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                    }

                    this.$emit('emailComplete')
                })
            }
        }
    }
}
</script>