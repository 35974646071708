<template>
    <div>
        <div class="mb-2"> 
            {{ parentDescription }}
        </div>
        <b-form-input 
            id="region-description-input"
            v-model="description"
            :state="descriptionState"
        />
        <div v-if="descriptionState == false" class="error">
            Region is required
        </div>
        <button class="btn btn-primary right mt-3" @click="save()">
            Save
        </button>
    </div>
</template>

<script>
import { RegionsService } from '../../services';

export default {
    name: "RegionAddUpdateComponent",
     props: {
        region: {
            type: Object
        },
        isUpdate: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            description: "",
            checkValid: false
        }
    },
    computed: {
        descriptionState() {
            if (!this.checkValid) return null
            return this.description != ""
        },
        isFormValid() {
            return this.descriptionState
        },
        parentDescription() {
            if (this.isUpdate) {
                if (this.region.parentDescription != "") {
                    return "Parent: " + this.region.parentDescription
                }
            }
            else {
                if (this.region.description != "") {
                    return "Parent: " + this.region.description
                } 
            }

            return null
        }
    },
    mounted() {
        if (this.isUpdate) {
            this.description = this.region.description
        }
    },
    methods: {
        save() {
            this.checkValid = true

            if (this.isFormValid){
                this.isUpdate ? this.update() : this.addChild()
            }
        },
        addChild() {
            RegionsService.create(this.region.id, this.description).then((response)=> {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });
                    this.$emit('hide')
                }
            })
        },
        update() {
            RegionsService.update(this.region.id, this.description).then((response)=> {
                if (response) {
                    if (response.data.success) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true,
                        });
                        this.$emit('hide');
                    }
                }
            })
        }
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
}
</style>