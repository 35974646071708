var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_c('font-awesome-icon',{staticClass:"nav-icon fa",attrs:{"icon":"truck"}}),_vm._v(" Kiosk Access Codes ")],1),_c('div',{staticClass:"col"},[_c('DynamicSearchComponent',{ref:"driverCodesSearchComponent",attrs:{"preferencesName":"driver_code_search","allCriteria":_vm.searchCriteria},on:{"resetPagination":function () {
                    this$1.searchPagination.currentPage = 1;
                    this$1.searchPagination.startIndex = 1;
                },"reloadDynamicSearch":_vm.reloadDynamicSearch}})],1),_c('Table',{attrs:{"id":"driverCodesTable","fields":_vm.dynamicFields,"items":_vm.driverCodes,"sortBy":"created","sortDesc":true,"searchPagination":_vm.searchPagination},on:{"getItems":_vm.listDriverCodes,"sortOrderChanged":_vm.sortOrderChanged},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [(_vm.security.userDelete)?_c('font-awesome-icon',{staticClass:"nav-icon pointer action-btn",attrs:{"icon":"trash-can","title":"Delete"},on:{"click":function($event){return _vm.openDeleteModal(row.item)}}}):_vm._e()]}},{key:"cell(created)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(_vm.convertUTCtoLocalDate(data.item.created)))+" ")]}}])}),_c('b-button',{style:({
            visibility: _vm.security.driverCodeCreate ? 'visible' : 'hidden',
        }),attrs:{"variant":"outline-primary"},on:{"click":_vm.showAddDriverCodeModal}},[_vm._v("Add Access Code")]),_c('b-modal',{ref:"add-driver-code",attrs:{"id":"add-driver-code","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Add Access Code ")]},proxy:true}])},[_c('DriverCodeAdd',{on:{"hide":_vm.hideAddDriverCodeModal}})],1),_c('DriverCodeDelete',{attrs:{"userId":_vm.selectedDriverCode.user_id},on:{"deleteComplete":_vm.deleteComplete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }