<template>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <UnitConfigurationItemComponent
            v-for="config in form"
            :key="config.name"
            :config="config"
            :overridden="config.is_overridden"
            :disabled="disabled"
        />
        <div class="row row-cols-2 row-cols-sm-2 g-4" v-if="security.kioskWrite">
            <div class="col-md-4">
                <b-button
                    class="details-btn"
                    @click="disable()"
                    v-if="disabled"
                    :disabled="!disabled"
                    variant="primary"
                    >Edit</b-button
                >
                <b-button
                    type="reset"
                    class="details-btn"
                    v-if="!disabled"
                    :disabled="disabled"
                    variant="danger"
                    >Cancel</b-button
                >
            </div>
            <div class="col">
                <b-button
                    type="submit"
                    class="details-btn submit-btn"
                    v-if="!disabled"
                    variant="primary"
                    >Save</b-button
                >
            </div>
        </div>
    </b-form>
</template>

<script>
import KioskService from '../../services/kiosk.service';
import UnitConfigurationItemComponent from './UnitConfigurationItemComponent';

export default {
    name: 'UnitConfiguration',
    components: {
        UnitConfigurationItemComponent,
    },
    props: {
        kioskId: {
            type: String,
            required: true,
        },
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
    },
    data() {
        return {
            form: [],
            configurations: {},
            loading: false,
            show: true,
            disabled: true,
        };
    },
    mounted() {
        this.getConfiguration();
    },
    methods: {
        getConfiguration() {
            this.loading = true;
            KioskService.configuration(this.kioskId).then((response) => {
                if (response) {
                    this.configurations = response.data;
                    this.setForm(this.configurations);
                    this.loading = false;
                }
            });
        },
        onSubmit(event) {
            event.preventDefault();
            KioskService.updateConfiguration(this.kioskId, this.form)
                .then((response) => {
                    this.configurations = response.data;
                    this.setForm(this.configurations);
                    this.loading = false;
                    this.disabled = true;

                    this.$root.$bvToast.toast('Successful', {
                        title: 'Update Kiosk Unit Configuration',
                        variant: 'success',
                        solid: true,
                    });
                })
                .catch((error) => {
                    this.setForm(this.configurations);
                    this.disabled = true;

                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'Update Kiosk Unit Configuration',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        onReset(event) {
            event.preventDefault();
            this.disabled = true;
            this.setForm(this.configurations);
            this.show = false;

            this.$nextTick(() => {
                this.show = true;
            });
        },
        disable() {
            setTimeout(() => {
                this.disabled = !this.disabled;
            }, 150);
        },
        setForm(configs) {
            this.form = [];
            configs.items.forEach((config) => {
                this.form.push({ ...config });
            });
        },
    },
};
</script>

<style scoped>
.details-btn {
    width: 5rem;
}

.submit-btn {
    float: right;
}

@media only screen and (max-width: 750px) {
    .details-btn {
        width: 100%;
    }
}
</style>