<template>
    <div>
        <h3><font-awesome-icon icon="robot" class="nav-icon fa" /> Servant</h3>
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group
                    id="svmid-group"
                    label="SVMID"
                    label-for="svmid-input"
                >
                    <b-form-input
                        id="svmid-input"
                        v-model="servant.svmid"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="store-group"
                    label="Store"
                    label-for="store-input"
                >
                    <b-form-input
                        id="store-input"
                        v-model="servant.store_description"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="region-group"
                    label="Region"
                    label-for="region-input"
                >
                    <b-form-input
                        id="region-input"
                        v-model="servant.region_description"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="checkin-group"
                    :label="lastCheckInElapsed"
                    label-for="checkin-input"
                >
                    <b-form-input
                        id="checkin-input"
                        v-model="servant.lastCheckInDt"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="hardwaretype-group"
                    label="Hardware Type"
                    label-for="hardwaretype-input"
                >
                    <b-form-input
                        id="hardwaretype-input"
                        v-model="servant.hardware_type"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="host-vmid-group"
                    label="Host VMID"
                    label-for="host-vmid-input"
                >
                    <div class="unpair-group">
                        <div class="textbox host-vmid">
                            <a
                                href="#"
                                @click="kioskLink(servant.host_id)"
                                v-if="servant.host_id"
                                class="underline"
                                >{{ servant.host_vmid }}</a
                            >
                            <span v-else>No host, servant is unpaired</span>
                        </div>
                        <button
                            class="btn btn-primary unpair"
                            @click="openUnpairModal()"
                            v-if="security.servantWrite && servant.host_id"
                        >
                            Unpair <font-awesome-icon icon="link-slash" />
                        </button>
                    </div>
                </b-form-group>
            </div>
        </div>
        <b-tabs content-class="mt-4" lazy>
            <b-tab title="Inventory">
                <ServantInventoryComponent :servantId="servantId" />
            </b-tab>
            <b-tab title="Issues">
                <ServantIssuesComponent :servantId="servantId" :active="true" />
            </b-tab>
            <b-tab title="Issues History">
                <ServantIssuesComponent
                    :servantId="servantId"
                    :active="false"
                />
            </b-tab>
            <b-tab title="Firmware">
                <AssignedFirmwareComponent
                    :kioskId="servant.host_id"
                    :servantId="servantId"
                    v-if="servant.host_id"
                />
                <div v-else>No firmware available</div>
            </b-tab>
        </b-tabs>
        <b-modal id="unpair-servant" ref="unpair-servant" hide-footer>
            <template #modal-title> Servant - Unpair </template>
            <UnpairServant :servant="servant" @hide="hideUnpairModal" />
        </b-modal>
    </div>
</template>

<script>
import AssignedFirmwareComponent from '../../components/kiosk/AssignedFirmwareComponent.vue';
import ServantIssuesComponent from '../../components/kiosk/servant/ServantIssuesComponent.vue';
import ServantInventoryComponent from '../../components/kiosk/servant/ServantInventoryComponent.vue';
import UnpairServant from '../../components/kiosk/servant/UnpairServant.vue';
import ServantsService from '../../services/servants.service';
import {
    getElapsedReadableTime,
    isDateTimeMin,
    kioskLink,
} from '../../utils/utils';

export default {
    name: 'Servant',
    components: {
        UnpairServant,
        AssignedFirmwareComponent,
        ServantIssuesComponent,
        ServantInventoryComponent,
    },
    data() {
        return {
            isLoading: false,
            servant: {},
            lastCheckInElapsed: 'calculating...',
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        servantId() {
            return +this.$route.query.servant_id;
        },
    },
    mounted() {
        this.getServant();
    },
    methods: {
        getElapsedReadableTime,
        isDateTimeMin,
        updateCheckinLabel() {
            if (isDateTimeMin(this.servant.last_checkin)) {
                this.lastCheckInElapsed = 'Last Check In: Never';
                return;
            }
            var lastCheckinTimestamp = new Date(this.servant.last_checkin);
            var currentTimestamp = new Date();
            var diff = currentTimestamp - lastCheckinTimestamp;
            this.lastCheckInElapsed =
                'Last Check In: ' + this.getElapsedReadableTime(diff);
        },
        getServant() {
            this.isLoading = true;
            ServantsService.getById(this.servantId).then((response) => {
                if (response) {
                    this.servant = response.data;
                    this.servant.lastCheckInDt = new Date(
                        Date.parse(this.servant.last_checkin)
                    ).toLocaleString();
                    this.updateCheckinLabel();
                    setInterval(this.updateCheckinLabel, 1000);
                    this.isLoading = false;
                    document.title = 'Servant: ' + response.data.svmid;
                }
            });
        },
        openUnpairModal() {
            this.$refs['unpair-servant'].show();
        },
        hideUnpairModal() {
            this.$refs['unpair-servant'].hide();
            this.getServant();
        },
        kioskLink,
    },
};
</script>

<style scoped>
.unpair-group {
    display: flex;
}
.host-vmid {
    flex: 1;
}
.unpair {
    margin-left: 0.5rem;
}

.textbox {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #e9ecef;
}

.underline {
    text-decoration: underline;
}

@media only screen and (max-width: 575.5px) {
    .unpair {
        width: 50%;
        margin-bottom: 1rem;
    }
}
</style>