<template>
<div class="table-container">
    <template v-if="codes.length > 0">
        <b-table class="table-striped" :field="fields" :items="codes" response="true" stacked="sm">
        </b-table>
    </template>
    <template v-else>
        <h5 class="center">
            <font-awesome-icon icon="circle-exclamation" class="nav-icon fa"/>
            No driver codes assigned
        </h5>
    </template>
</div>
</template>

<script>

import KioskService from "../../services/kiosk.service"

export default {
    name: "DriverCodes",
    props: {
        kioskId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            codes: [],
            loading: false,
            fields: [
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'code',
                    label: 'Code',
                    tdClass: 'column',
                    sortable: false
                }
            ]
        }
    },
    mounted() {
        this.getDriverCodes()
    },
    methods: {
        getDriverCodes() {
            this.loading = true
            KioskService.driverCodes(
                this.kioskId
            ).then((response) => {
                if (response) {
                    this.codes = response.data
                    this.loading = false
                }
            })
        }
    }
}

</script>

<style scoped>
.center {
    text-align: center;
}
</style>
