<template>
    <div>
        <h3>
            <font-awesome-icon icon="receipt" class="nav-icon fa" /> Sales
        </h3>

        <DynamicSearchComponent
            ref="salesSearchComponent"
            preferencesName="sales_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />

        <div class="pb-4">
            <Table
                id="salesTable"
                :fields="fields"
                :items="sales"
                :sortDesc="false"
                :searchPagination="searchPagination"
                :horizontalScroll="true"
                verticalScroll="500px"
                @getItems="getSales"
                @sortOrderChanged="sortOrderChanged"
                sortBy=""
            >
            </Table>
            <ExportFileComponent
                :arguments="this.arguments.query"
                :searchPagination="this.searchPagination"
                :disabled="false"
                page="sales"
                class="export"
            />
        </div>
    </div>
</template>

<script>
import { Table } from '../../components';
import { DynamicSearchComponent, ExportFileComponent } from '../../components';
import SearchPagination from '../../models/search.pagination';
import { SalesService } from '../../services';
import { convertUTCtoLocalDate } from '../../utils/utils';


export default {
    name: 'SalesData',
    components: {
        Table,
        DynamicSearchComponent,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_number',
                    label: 'Store Number',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'province',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'region',
                    label: 'Region',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'exchange_qty',
                    label: 'Exchange Qty',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'exchange_sales',
                    label: 'Exchange Sales',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'purchase_qty',
                    label: 'Purchase Qty',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'purchase_sales',
                    label: 'Purchase Sales',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'sub_total',
                    label: 'SubTotal',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'tax_a',
                    label: 'Tax A',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'tax_b',
                    label: 'Tax',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'refund',
                    label: 'Refund',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'total',
                    label: 'Total',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'include_incomm',
                    name: 'Incomm',
                    type: 'Boolean',
                    searchQueryName: 'includeIncomm',
                    defaultValue: false,
                },
                {
                    key: 'date_start',
                    name: 'Date Start',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                    required: true,
                },
                {
                    key: 'date_end',
                    name: 'Date End',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: convertUTCtoLocalDate(new Date().toISOString()),
                    required: true,
                },
            ],
            sales: [],
            arguments: [],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
            isLoading: false,
        };
    },
    mounted() {
    },
    methods: {
        convertUTCtoLocalDate,
        getSales() {
            this.$refs.salesSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.arguments = args;
            this.isLoading = true;
            SalesService.sales(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                this.sales = response.data.items;
                this.searchPagination.totalRows =
                    this.sales.length > 0 ? response.data.total_rows : 0;
                this.isLoading = false;
                args.done();
            });
        },
        sortOrderChanged() {},
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
        
    },
};
</script>

<style scoped>

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}

</style>