<template>
    <div>
        <h3><font-awesome-icon icon="circle-exclamation" class="nav-icon fa" />  Problems</h3>
        <div class="col">
                <HardwareTypeComponent
                    v-model="hardwareTypeId"
                    @input="getKioskProblems"
                    :state="null"
                />
        </div>
        <div class="col">
            <DonutGraphComponent 
                :chartData="chartData"
                labelName="name" 
                dataName="value"
                :colours="colours"
                v-if="chartData.length > 0"
            />
        </div>
        <div class="pb-3">
            <Table
              id="eventsTable"
              :fields="fields"
              :items="kioskProblems"
              sortBy=""
              :sortDesc="false"
              :searchPagination="searchPagination"
              :busy="isLoading"
              @getItems="getKioskProblems"
            >
                <template #cell(vmid)="data">
                    <a href="#" @click="kioskLink(data.item.id)" class="pointer underline">
                        {{ data.item.vmid }}
                    </a>
                </template>
            </Table>
        </div>
    </div>
</template>

<script>
import { FleetService } from "../../services"
import { Table, HardwareTypeComponent, DonutGraphComponent } from "../../components/"
import SearchPagination from '../../models/search.pagination'
import { kioskLink } from '../../utils/utils'
export default {
    name: "Problems",
    components: {
        Table,
        HardwareTypeComponent,
        DonutGraphComponent
    },
    data() {
        return {
            fields: [
                {
                    key: 'vmid',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'store.description',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'store.number',
                    label: 'Store Number',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'store.address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'store.city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'store.province.abbr',
                    label: 'Province',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'event',
                    label: 'Event',
                    tdClass: 'column',
                    sortable: false
                },
          ],
          kioskProblems: [],
          chartData: [],
          isLoading: false,
          hardwareTypeId: 0,
          searchPagination: new SearchPagination('', 1, 10, 1, 0),
          sortOrder: -1,
          colours: ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#EF1B16', '#94DA28', '#1FA0F4', '#1913EA', '#4A4247', '#F80808', '#460341', '#10E6D6', '#BAC4B5', '#E91616']
        };
    },
    mounted() {
        this.getKioskProblems();
    },
    methods: {
        getKioskProblems() {
            this.isLoading = true;
            FleetService.listKioskProblems(this.hardwareTypeId,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize
            ).then((response) => {
                if (response && response.data) {
                    this.kioskProblems = response.data.kiosk_problems;
                    this.searchPagination.totalRows = 
                        this.kioskProblems.length > 0 ? response.data.total_issues : 0;
                    this.chartData = response.data.items
                    this.isLoading = false;
                }
            })
        },
        kioskLink
    }
}
</script>
<style scoped>
.underline {
    text-decoration: underline;
}
</style>