import axios from "axios";
import authHeader from "./auth.header";

class FleetService {
    listKioskProblems(hardwareTypeId, startIndex, pageSize, favourite = undefined, currentProblems = null) {
        return axios({
            method: 'post',
            url: '/api/fleet/list_problems',
            headers: authHeader(),
            data: {
                hardware_type_id: hardwareTypeId, 
                start_index: startIndex, 
                page_size: pageSize, 
                favourite, 
                old_problems: currentProblems
            }
        })
    }

    listFleetFirmware(hardwareTypeId, installed, favourite) {
        return axios({
            method: 'get',
            url: '/api/fleet/list_firmware',
            headers: authHeader(),
            params: {
                hardwareTypeId, installed, favourite
            }
        })
    }
}
export default new FleetService();