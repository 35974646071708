<template>
    <div>
        <span v-if="lastUpdated" class="last_updated">Release progress up to date since {{getTimeOnly12Hours(lastUpdated)}}</span>
        <h3><font-awesome-icon icon="floppy-disk" class="nav-icon fa" />  Software</h3>
        <div class="button-group">
            <div class="button-col">
                <button @click="openRegisterModal" class="btn btn-primary button" v-if="security.softwareCreate"><span>Upload</span></button>
            </div>
        </div>
        <div class="mt-3">
            <b-form-group id="hardware-type-select-group" label="Hardware Types:" label-for="hardware-type-select">
                <b-form-select
                    id="hardware-type-select"
                    v-model="selectedHardwareType"
                    :disabled="false">
                    <option :value="0">All</option>
                    <option v-for="ht in this.hardwareTypes" :value="ht.id" :key="ht.id">
                        {{ ht.description }}
                    </option>
                </b-form-select>
            </b-form-group>
        </div>
        <div class="pb-4">
            <Table
              id="softwareTable"
              ref="softwareTable"
              :fields="dynamicFields"
              :items="software"
              sortBy="created_on"
              :sortDesc="true"
              :searchPagination="searchPagination"
              :horizontalScroll="true"
              verticalScroll="500px"
              :busy="isLoading"
              @getItems="getSoftware"
              @sortOrderChanged="sortOrderChanged"
            >
            <template #cell(actions)="row">
                <div class="icons">
                    <font-awesome-icon 
                        icon="tag" 
                        class="nav-icon pointer mx-2" 
                        :class="{ 'disable' : row.item.release_completed}" 
                        @click="!row.item.release_completed && openTagModal(row.item)" 
                        v-if="security.softwareWrite" 
                        :title="!row.item.release_completed ? 'Tag' : 'Tags can\'t be assigned to a completed release'"
                    />
                    <font-awesome-icon icon="paper-plane" class="nav-icon pointer mx-2" @click="openRelease(row.item.id)" v-if="security.phaseRead" title="Phase"/>
                    <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" @click="openDeleteModal(row.item)" v-if="security.softwareDelete && row.item.can_delete" title="Delete"/>
                </div>
            </template>
            <template #cell(created_on)="row">
                {{ formatDate(convertUTCtoLocalDate(row.item.created_on)) }}
            </template>
            <template #cell(release_progress)="row">
                <div class="graph-group">
                    <font-awesome-icon icon="check" class="nav-icon" v-if="row.item.release_completed"/>
                    <div v-else-if="getReleaseProgressByFirmwareId(row.item.id) != undefined">
                        <!-- <div class="release_percent">
                            {{getReleaseProgressByFirmwareId(row.item.id).completed_percentage}}%
                        </div> -->
                        <DonutGraphComponent 
                        :chartData="getReleaseProgressByFirmwareId(row.item.id).chart_data" 
                        labelName="name" 
                        dataName="value" 
                        colourName="colour" 
                        class="graph" 
                        :height="75" 
                        :width="75" 
                        :colours="['#e5e5e5', '#007bff']"
                        :showLegend="false" 
                        :showTooltip="false" 
                        />
                    </div>
                </div>
            </template>
            </Table>
        </div>
        <RegisterSoftwareModal @changeComplete="closeRegisterModal"/>
        <DeleteSoftwareModal @changeComplete="closeDeleteModal" :softwareId="selectedId"/>
        <AssignTagModal :firmwareId="selectedId" :isRegion="false" @hide="closeTagModal"/>
    </div>
</template>

<script>
import { HardwareTypeService, SoftwareService } from "../../services"
import { RegisterSoftwareModal, DeleteSoftwareModal } from "../../components/firmware"
import AssignTagModal from "../../components/tags/AssignTagModal.vue"
import SearchPagination from '../../models/search.pagination'
import { Table, DonutGraphComponent } from "../../components/"
import { formatDate, generateDynamicFields, getTimeOnly12Hours, convertUTCtoLocalDate } from '../../utils/utils'

export default {
    name: "Software",
    components: {
        Table,
        RegisterSoftwareModal,
        DeleteSoftwareModal,
        AssignTagModal,
        DonutGraphComponent
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'hardware_type',
                    label: 'Hardware',
                    tdClass: 'column',
                    sortable: false,
                    sortOrder: 1
                },
                {
                    key: 'system_hardware',
                    label: 'System',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'version',
                    label: 'Version',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'runtime_version',
                    label: 'Runtime Version',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
                { 
                    key: 'operating_system',
                    label: 'Operating System',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5
                },
                {
                    key: 'product',
                    label: 'Product',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6
                },
                {
                    key: 'created_on',
                    label: 'Created On',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7
                },
                {
                    key: 'tags',
                    label: 'Tags',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'title',
                    label: 'Title',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'release_notes',
                    label: 'Release Notes',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'release_progress',
                    label: 'Release Progress',
                    tdClass: 'column',
                    sortable: false
                }
            ],
            software: [],
            hardwareTypes: [],
            selectedId: 0,
            hardwareType: 0,
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -7,
            allReleaseProgress: [],
            lastUpdated: null
        };
    },
    created() {
        HardwareTypeService.get().then(
            (response) => {
                this.hardwareTypes = response.data;
            }
        );
    },
    computed: {
        selectedHardwareType: {
            get() {
                return this.value
            },
            set(value) {
                this.hardwareType = value;
                this.searchPagination.startIndex = 1;
                this.refresh();
                this.$emit('input', value)
            }
        },
        security(){
            return this.$store.state.auth.security
        },
        dynamicFields() {
           return generateDynamicFields(this.fields, 'actions', !this.security.softwareRead && !this.security.softwareWrite && !this.security.softwareDelete && !this.security.phaseRead)
        },
    },
    mounted() {
        this.refresh()
    },
    methods: {
        formatDate,
        convertUTCtoLocalDate,
        getTimeOnly12Hours,
        refresh() {
            this.getSoftware()
            this.getReleaseProgress()
            setInterval(this.getReleaseProgress, 1000 * 60 * 5)
        },
        getSoftware() {
            this.isLoading = true;
            SoftwareService.list(
                this.hardwareType,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                if (response) {
                    this.software = response.data.items;
                    this.searchPagination.totalRows = 
                    this.software.length > 0 ? response.data.total_rows : 0;
                    this.isLoading = false;
                }
            },
            (error) => {
                this.$bvToast.toast(`Error: ${error}`, {
                        title: 'Download failed',
                        variant: 'danger',
                        solid: true
                    })
            }
          )

        },
        closeRegisterModal(){
            this.$bvModal.hide('software-register-modal')
            this.getSoftware();
        },
        closeDeleteModal(){
            this.$bvModal.hide('software-delete-modal');
            this.getSoftware();
        },
        openRegisterModal() {
            this.$bvModal.show('software-register-modal')
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.refresh()
        },
        openDeleteModal(item){
            this.selectedId = item.id;
            this.$bvModal.show('software-delete-modal');
        },
        openTagModal(item){
            this.selectedId = item.id;
            this.$bvModal.show('tag-assign-modal')
        },
        closeTagModal(){
            this.$bvModal.hide('tag-assign-modal')
            this.getSoftware();
        },
        openRelease(id){
            this.$router.push(`/release/${id}`)
        },
        getReleaseProgress(){ 
            SoftwareService.getReleaseProgress(
                this.hardwareType,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder,
                this.allReleaseProgress
            ).then((response) => {
                if (response) {
                    if (response.data) {
                        this.allReleaseProgress = response.data.items
                        this.$refs.softwareTable.refresh()

                        this.lastUpdated = new Date()
                    }
                }
            })
        },
        getReleaseProgressByFirmwareId(firmwareId) {
            if (this.allReleaseProgress.length > 0) {
                var releaseProgress = this.allReleaseProgress.find(releaseProgress => releaseProgress.id == firmwareId)
                if(releaseProgress !== undefined)
                    return releaseProgress.chart_data.length > 0 ? releaseProgress : undefined
                else
                    return undefined;
            }
            else {
                return undefined
            }
        }
    }
}
</script>
<style scoped>
.wrap-colum {
    overflow-wrap: anywhere;
}
::v-deep .action-column {
    min-width: 150px;
    vertical-align: middle !important;
}
.graph-group {
    display: flex;
    justify-content: center;
}
.graph-group > div {
    height: auto
}
.release_percent {
    text-align: center;
    padding-bottom: 5px;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 0.5rem;
}

.last_updated {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    opacity: 60%;
    font-size: 0.8rem;
    margin-top: -1rem;
}

.disable {
    opacity: 60%;
    cursor: not-allowed;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    .last_updated {
        font-size: 1rem;
    }
}

</style>