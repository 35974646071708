<template>
    <div class="pagination-group">
        <div class="page-size-group">
            <div class="page-total-rows">
        Displaying {{ startIndex }} to {{ endIndex }} of <b>{{ searchPagination.totalRows }}</b> items
            </div>
        </div>
        <div class="page-size-group">
            Page Size
            <input
                v-model="searchPagination.pageSize"
                type="number"
                name="page-size"
                class="form-control page-size"
                placeholder="10"
                min="1"
                @change="pageSizeChanged"
            />
        </div>
        <b-pagination
            v-model="searchPagination.currentPage"
            :total-rows="searchPagination.totalRows"
            :per-page="searchPagination.pageSize"
            :aria-controls="tableControl"
            class="pagination"
            @change="pageChanged"
        ></b-pagination>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        searchPagination: {
            type: Object,
            required: true,
        },
        currentNumRows: {
            type: Number,
            required: true,
        },
        tableControl: {
            type: String,
            required: true,
        },
    },
    computed: {
        startIndex() {
            return this.searchPagination.totalRows > 0
                ? this.searchPagination.startIndex
                : 0;
        },
        endIndex() {
            let result =
                parseInt(this.searchPagination.startIndex) +
                parseInt(this.searchPagination.pageSize - 1);
            if (
                result > this.searchPagination.totalRows &&
                this.searchPagination.totalRows > 0
            ) {
                result = this.searchPagination.totalRows;
            }
            return result;
        },
    },
    methods: {
        pageSizeChanged() {
      this.searchPagination.pageSize = this.searchPagination.pageSize != 0 ? Math.abs(this.searchPagination.pageSize) : 10 
      this.searchPagination.currentPage = 1
      this.searchPagination.startIndex = 1
            this.$emit('refreshTable');
        },
        pageChanged(pageNumber) {
            this.searchPagination.currentPage = pageNumber;
            this.searchPagination.startIndex =
                (this.searchPagination.currentPage - 1) *
                    this.searchPagination.pageSize +
                1;
            this.$emit('refreshTable');
        },
    },
};
</script>

<style scoped>
.page-size-group {
    display: inline-block;
}

.page-size {
    width: 70px;
    margin: 0px 10px;
    display: inline-block;
}

.page-total-rows {
    margin: 0px 15px;
    display: inline-block;
}

.pagination-group {
    float: right;
}

.pagination {
    float: right;
}
@media only screen and (max-width: 750px) {
    .pagination-group {
        float: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .page-size-group {
        margin-bottom: 10px;
    }
}
</style>