<template>
    <div>
        <div class="m-3">
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <VmidFieldComponent 
                    :vmid="manualAddModel.vmid" 
                    :isG3K="manualAddModel.hardwareTypeId == 17"
                    :state="vmidState"
                    @setVmid="setVmid"
                    @isValid="setVmidValidFormat"
                    />
                    <div v-if="vmidState == false" class="error">
                        Invalid VMID
                    </div>
                </div>
                <div class="col">
                    <b-form-group id="sim-input-group" label="SIM" label-for="sim">
                        <b-form-input 
                        id="sim"
                        v-model="manualAddModel.sim"
                        ></b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <PriceSelectComponent
                        :state="priceState"
                        v-model="manualAddModel.priceId"
                    />
                    <div v-if="priceState == false" class="error">
                        Price is required
                    </div>
                </div>
                <div class="col">
                    <b-form-group id="lowinv-input-group" label="Low Inventory Alert" label-for="lowinv">
                        <b-form-input 
                        id="lowinv"
                        :state="lowInventoryState"
                        v-model="manualAddModel.lowInventory"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="lowInventoryState == false" class="error">
                        Low Inventory Alert must be greater than 0
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <HardwareTypeComponent
                        :state="hardwareState"
                        v-model="manualAddModel.hardwareTypeId"
                        noSelectionDescriptor="Select a Hardware Type"
                    />
                    <div v-if="hardwareState == false" class="error">
                        Hardware Type is required
                    </div>
                </div>
                <div class="col mt-3">
                    <StoresSelectComponent
                        :state="storeState"
                        v-model="manualAddModel.storeId"
                    />
                    <div v-if="storeState == false" class="error">
                        Store is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group id="installed-input-group" label="Installed" label-for="installed">
                        <b-form-select
                            id="installed"
                            v-model="manualAddModel.installed"
                        >
                            <option :value="false">False</option>
                            <option :value="true">True</option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group id="enabled-input-group" label="Enabled" label-for="enabled">
                        <b-form-select
                            id="enabled"
                            v-model="manualAddModel.enabled"
                        >
                            <option :value="false">False</option>
                            <option :value="true">True</option>
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <b-button @click="addNewManual" variant="primary" class="right mb-1"> 
                Add
            </b-button>
        </div>
    </div>
</template>

<script>

import KiosksService from "../../services/kiosks.service"
import StoresSelectComponent from "../StoresSelectComponent.vue";
import HardwareTypeComponent from "../HardwareTypeComponent.vue";
import PriceSelectComponent from "../PriceSelectComponent.vue";
import VmidFieldComponent from "./VmidFieldComponent.vue";

export default {
    name: "AddKioskComponent",
    components: { 
        StoresSelectComponent, 
        HardwareTypeComponent, 
        PriceSelectComponent, 
        VmidFieldComponent
    },
    data() {
        return {
            manualAddModel: {
                vmid: 0,
                sim: "",
                hardwareTypeId: 0,
                storeId: 0,
                priceId: 0,
                enabled: false,
                installed: false,
                lowInventory: 0,
            },
            vmidDuplicate: false,
            vmidValidFormat: false,
            checkValid: false,
        };
    },
    computed: {
        vmidState() {
            if (!this.checkValid) return null
            return this.vmidValidFormat && !this.vmidDuplicate
        },
        storeState() {
            if (!this.checkValid) return null
            return this.manualAddModel.storeId > 0
        },
        hardwareState(){
            if (!this.checkValid) return null
            return this.manualAddModel.hardwareTypeId > 0
        },
        lowInventoryState() {
            if (!this.checkValid) return null
            return this.manualAddModel.lowInventory > 0
        },
        priceState() {
            if (!this.checkValid) return null
            return this.manualAddModel.priceId > 0
        },
        isFormValid() {
            return this.vmidState && this.storeState && this.hardwareState && this.lowInventoryState && this.priceState
        }
    },
    methods: {
        addNewManual(){
            this.checkValid = true
            if (this.isFormValid) {
                KiosksService.addNew(this.manualAddModel).then((response) => {
                    if (response.data.success){
                        this.manualAddModel = {
                            vmid: 0,
                            sim: "",
                            hardwareTypeId: 0,
                            storeId: 0,
                            enabled: false,
                            installed: false,
                            lowInventory: 0,
                            priceId: 0
                        }
                        this.checkValid = false
                        this.vmidDuplicate = false
                        this.vmidValidFormat = false

                        this.$root.$bvToast.toast('Kiosk Added', {
                            title: 'Success',
                            variant: 'success',
                            solid: true
                        })

                        this.$emit('hide')
                    }
                    else {
                        if (response.data.duplicate){
                            this.vmidDuplicate = true
                            this.$root.$bvToast.toast('Duplicate VMID', {
                                title: 'Error',
                                variant: 'danger',
                                solid: true
                            })
                        }
                        else {
                            this.vmidDuplicate = false
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                                solid: true
                            })
                        }
                        this.checkValid = true
                    }
                });
            }
        },
        setVmid(value) {
            if (this.vmidDuplicate) {
                this.vmidDuplicate = false
            }
            this.manualAddModel.vmid = value
        },
        setVmidValidFormat(isValid) {
            this.vmidValidFormat = isValid
        },
    },
}
</script>

<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>