<template>
    <b-form-group
        id="issue-select-group"
        label="Issue Type"
        label-for="issue-select"
    >
        <b-form-select
            id="issue-select"
            v-model="selected"
            :disabled="this.disabled"
        >
            <option :value="0">{{ this.noSelectionDescriptor }}</option>
            <option
                v-for="issue in issues"
                :value="issue.value"
                :key="issue.value"
            >
                {{ issue.description }}
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import IssuesService from '../services/issues.service';

export default {
    name: 'IssueSelect',
    props: {
        value: {
            type: Number,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        noSelectionDescriptor: {
            type: String,
            required: false,
            default: 'Most Recent',
        },
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    data() {
        return {
            issues: [],
        };
    },
    mounted() {
        this.getIssues();
    },
    methods: {
        getIssues() {
            IssuesService.get()
                .then((response) => {
                    this.issues = response.data.issues;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'IssuesService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
</style>