<template>
    <div>
        <div v-if="isLoading" class="spinner-container">
            <div class="spinner-border" role="status" />
        </div>
        <div v-if="isLoading && loadingMessage" class="message">
            <h3>{{ loadingMessage }}</h3>
        </div>
        <div v-if="!spinnerOnly && showError && !isLoading" class="message">
            <h3>{{ errorMessage }}</h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpinnerNoResults',
    props: {
        isLoading: { type: Boolean, required: true },
        loadingMessage: { type: String, required: false },
        showError: { type: Boolean, required: false },
        errorMessage: { type: String, required: false },
        spinnerOnly: { type: Boolean, required: false },
    },
};
</script>

<style scoped>
.spinner-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 10px;
}
.message {
    display: flex;
    justify-content: center;
    margin: 20px;
}
</style>