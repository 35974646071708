import { render, staticRenderFns } from "./ClaimRoleComponent.vue?vue&type=template&id=5d47f8c0&scoped=true&"
import script from "./ClaimRoleComponent.vue?vue&type=script&lang=js&"
export * from "./ClaimRoleComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d47f8c0",
  null
  
)

export default component.exports