<template>
    <div>
        <p>
            Excluded columns won't show in tables
        </p>
        <b-tabs v-model="tabIndex" v-if="excludedColumnTypes != []">
            <b-tab :title="excludedColumnType.type" v-for="excludedColumnType in excludedColumnTypes" :key="excludedColumnType.id">
                <div v-if="security.roleWrite" class="mt-3">
                    <a href="#" @click="selectAll()">
                        Select All
                    </a>
                    |
                    <a href="#" @click="clearAll()">
                        Clear All
                    </a>
                    |
                    <a href="#" @click="reset()">
                        Reset
                    </a>
                </div>
                <Table
                    :id="'excludeColumnTable_' + excludedColumnType.id"
                    :ref="'excludeColumnTable_' + excludedColumnType.id"
                    :fields="fields"
                    :items="columns"
                    sortBy="column"
                    :sortDesc="false"
                    :searchPagination="searchPagination"
                    :hidePagination="true"
                    :responsive="false"
                    @getItems="refresh"
                    >
                        <template #cell(included)="data">
                            <b-form-checkbox-group v-model="getSelectedCheckboxes">
                                <b-form-checkbox :value="data.item.id" />
                            </b-form-checkbox-group>
                        </template>
                        <template #cell(column)="data">
                            {{data.item.column}}
                        </template>
                </Table>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

import RolesService from "../../services/roles.service"
import { Table } from '../../components'
import SearchPagination from '../../models/search.pagination'

export default {
    name: "ExcludeColumnsByRole",
    components: {
        Table
    },
    data() {
        return {
            fields: [
                {
                    key: 'included',
                    label: 'Included',
                    tdClass: 'small-column',
                },
                {
                    key: 'column',
                    label: 'Column',
                    tdClass: 'column',
                },
            ],
            searchPagination: new SearchPagination('', 1, 0, 1, 0),
            excludedColumnTypes: [],
            excludedColumns: [],
            tabIndex: 0,
        }
    },
    props: {
        roleId: {
            type: Number,
            required: true,
        },
    },
    computed: {
        getSelectedCheckboxes: {
            get() {
                var index = this.excludedColumns.findIndex(excludedColumn => excludedColumn.id == this.typeId)

                if (index < 0) {
                    return []
                }

                return this.columns
                                .filter(column => !this.excludedColumns[index].excluded_columns.some(excludedColumn => excludedColumn == column.id))
                                .map(column => column.id)
            },
            set(includedColumns) {
                var index = this.excludedColumns.findIndex(excludedColumn => excludedColumn.id == this.typeId)

                if (index < 0) {
                    return []
                }

                this.excludedColumns[index].excluded_columns = this.columns
                                                .filter(column => !includedColumns.some(includedColumn => includedColumn == column.id))
                                                .map(column => column.id)
            },
        },
        security() {
            return this.$store.state.auth.security
        },
        columns() {
            if (this.tabIndex < 0) {
                return []
            }

            return this.excludedColumnTypes[this.tabIndex].columns
        },
        typeId() {
            return this.excludedColumnTypes[this.tabIndex].id
        }
    },
    watch: {
        tabIndex() { 
            console.log(this.tabIndex)
        },
        excludedColumns() {
            this.updateExcludedColumnsByRole()
        }
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.excludedColumnTypes = []
            this.excludedColumns = []
            this.tabIndex = 0
            this.getExcludedColumnType()
            this.getExcludedColumnsByRole()
        },
        getExcludedColumnsByRole() {
            RolesService.getExcludedColumnsByRole(this.roleId).then(
                (response) => {
                    if (response) {
                        this.excludedColumns = response.data

                        if (this.roleId < 0) {
                            this.selectAll()
                        }
                    }
                }
            )
        },
        getExcludedColumnType() {
            RolesService.getExcludedColumnTypes().then(
                (response) => {
                    if (response) {
                        this.excludedColumnTypes = response.data
                    }
                }
            )
        },
        updateExcludedColumnsByRole() {
            this.$emit("setData", this.typeId, this.excludedColumns)
        },
        selectAll() {
            var index = this.excludedColumns.findIndex(excludedColumn => excludedColumn.id == this.typeId)
            this.excludedColumns[index].excluded_columns = []
            this.refreshTable()
        },
        clearAll() {
            var index = this.excludedColumns.findIndex(excludedColumn => excludedColumn.id == this.typeId)
            this.excludedColumns[index].excluded_columns = this.columns.map(column => column.id)
            this.refreshTable()
        },
        reset() {
            this.getExcludedColumnsByRole()
        },
        refreshTable() {
            this.$refs['excludeColumnTable_' + this.typeId][0].refresh()
        }
    }
}
</script>
<style scoped>
.right {
    float: right;
}
.header {
    text-align: center;
}
::v-deep .small-column {
    width: 15px
}

::v-deep .table-container {
    margin-top: 1rem;
}
</style>