<template>
    <div>
        <div class="mt-3">
            <div class="row">
                <div class="col">
                    <b-form-file 
                        v-model="servantCSV" 
                        placeholder="Select a CSV or drop it here"
                        drop-placeholder="Drop CSV Here"
                        accept=".csv"
                        :state="csvState"
                    />
                    <div v-if="csvState == false" class="error">
                        CSV is required
                    </div>
                </div>
            </div>
            <div class="mt-3 button-group">
                <div class="button-group">
                    <a class="align-self-center" variant="primary" href="/files/servants/CSV Bulk Entry Example.csv">Download Example CSV</a>
                </div>
                <div>
                    <b-button variant='primary' class="btn btn-primary" @click="importCSV">Import</b-button>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div v-if="csvImported">
                Servant CSV imported successfully
            </div>
            <div class="alert alert-danger mb-0" v-if="servantErrors.length > 0">
                <div v-for="(error, index) in servantErrors" v-bind:key="index">
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ServantService from '../../../services/servants.service'

export default {
    name: 'BulkImportServant',
    data() {
        return {
            servantCSV: null,
            servantErrors: [],
            csvImported: false,
            checkValid: false
        }
    },
    computed: {
        csvState() {
            if (!this.checkValid) return null
            return this.servantCSV ? true : false
        },
        isFormValid() {
            return this.csvState
        }
    },
    methods: {
        importCSV() {
            this.checkValid = true
            if (this.isFormValid) {
                var formData = new FormData();
                formData.append("ServantCSV", this.servantCSV);
                
                ServantService.importCSV(formData).then((response) => {
                    this.servantErrors = response.data.errors;
                    this.csvImported = response.data.success;

                    this.$root.$bvToast.toast(response.data.success ? "Servants Imported" : "Failed to import servants", {
                        title: response.data.success ? "Success" : "Error",
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })

                    if (response.data.success) {
                        this.$emit('hide')
                    }
                });
            }
        },
    }
}
</script>

<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
}
.error {
    color: #dc3545;
}
</style>