<template>
  <div>
    <h3>Log Data - Kiosk {{restoreData.vmid}}</h3>

    <b-form-group id="restore-select-group" label-for="restore-select">
        <b-form-select
            id="restore-select"
            v-model="selectedLogType"
            @change="onLogChange">
            <option v-for="opt in options" :value="opt.id" :key="opt.value">
                {{ opt.description }}
            </option>
        </b-form-select>
    </b-form-group>

    <div class="row-fluid" v-for="item in restoreData.items" :value="item.date" :key="item.date">
        <div style="font-weight: 600">
            {{ item.day_of_week }}
        </div>
        {{ formatDate(item.date) }} - 
        <a class="text-info pointer" style="text-align: right" v-on:click="downloadRestoreData(item.day_of_week)">Download</a>
    </div>
    <div v-if="restoreData.items.length == 0" class="center">
            No Logs Found
    </div>
  </div>
</template>

<script>

import KioskService from "../../services/kiosk.service"
import { saveAs } from 'file-saver'

export default {
    name: 'RestoreData',
    data() {
        return {
            restoreData: {},
            options: [],
            selectedLogType: 1,
            kioskId: this.$route.query.kiosk_id
        }
    },
    mounted() {
        this.getRestoreData()
        this.getOptions()
    },
    methods: {
        getRestoreData() {
            KioskService.restoreData(
                this.kioskId,
                this.selectedLogType
            ).then((response) => {
                this.restoreData = response.data
            })
        },
        getOptions() {
            KioskService.restoreDataOptions(
            ).then((response) => {
                this.options = response.data
            })
        },
        onLogChange() {
            this.getRestoreData()
        },
        downloadRestoreData(dayOfWeek) {
            KioskService.downloadRestoreData(
                this.kioskId,
                dayOfWeek,
                this.selectedLogType
            ).then((response)  => {
                if (response) {
                    const blob = new Blob([response.data], {type: response.headers['content-type']})
                    let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
                    fileName = fileName.replaceAll('"', '')
                    saveAs(blob, fileName)
                }
            },
            (error) => {
                console.log(error)
            })
        },
        formatDate(date) {
            return new Date(date).toISOString().slice(0, 10)
        }
    }
}
</script>

<style scoped>
.center {
    text-align: center;
}
</style>