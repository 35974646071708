<template>
    <div>
        <DynamicSearchComponent
            ref="kioskSearchComponent"
            preferencesName="kiosk_pair"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <Table
            id="kioskTable"
            :fields="fields"
            :items="kiosks"
            sortBy="vmid"
            :sortDesc="false"
            :searchPagination="searchPagination"
            @getItems="getKiosks"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(action)="row">
                <div>
                    <font-awesome-icon
                        icon="link"
                        class="nav-icon pointer"
                        @click="pair(row.item.id)"
                        v-if="security.servantWrite"
                    />
                </div>
            </template>
            <template #cell(vmid)="data">
                <a href="#" @click="kioskLink(data.item.id)" class="underline">{{data.item.vmid}}</a>
            </template>
        </Table>
    </div>
</template>

<script>
import SearchPagination from '../../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../../components';
import KiosksService from '../../../services/kiosks.service';
import ServantsService from '../../../services/servants.service';
import { kioskLink } from "../../../utils/utils"

export default {
    name: 'PairKiosk',
    components: {
        Table,
        DynamicSearchComponent,
    },
    props: {
        servant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            kiosks: [],
            fields: [
                {
                    key: 'action',
                    tdClass: 'column',
                },
                {
                    key: 'vmid',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: true,
                    visible: false,
                    sortOrder: 1
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'store_number',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'province',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'svmid',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'store_number',
                    name: 'Store Number',
                    type: 'Text',
                    searchQueryName: 'storeNumber',
                },
                {
                    key: 'installed',
                    name: 'Installed',
                    type: 'Boolean',
                    searchQueryName: 'installed',
                },
                {
                    key: 'enabled',
                    name: 'Enabled',
                    type: 'Boolean',
                    searchQueryName: 'enabled',
                },
                {
                    key: 'bay_count',
                    name: '# of Bays',
                    type: 'BayCount',
                    searchQueryName: 'bayCount',
                },
                {
                    key: 'paired',
                    name: 'Paired',
                    type: 'Boolean',
                    searchQueryName: 'paired',
                },
            ],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
    },
    methods: {
        getKiosks() {
            this.$refs.kioskSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.currentQuery = args.query;
            KiosksService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
            .then((response) => {
                if (response) {
                        this.kiosks = response.data.items;
                        this.searchPagination.totalRows =
                            this.kiosks.length > 0
                                ? response.data.total_rows
                                : 0;
                }
                args.done();
            })
            .catch(() => {
                args.done();
            });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getKiosks()
        },
        pair(kioskId) {
            ServantsService.pair(this.servant.id, kioskId).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success
                            ? 'Pair Successful'
                            : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });

                    this.$emit('hide');
                }
            })
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
        kioskLink
    }
}
</script>

<style scoped>
.underline {
    text-decoration: underline;
}
</style>