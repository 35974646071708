import axios from "axios";
import authHeader from "./auth.header"

class StoresService {

    get() {
        return axios({
            method: 'get',
            url: '/api/stores/list',
            headers: authHeader()
        })
    }

    getTax(storeId) {
        return axios({
            method: 'get',
            url: '/api/stores/get_tax',
            headers: authHeader(),
            params: {
                storeId
            }
        })
    }

    search(startIndex, pageSize, sortOrder) {
        return axios({
            method: 'get',
            url: '/api/stores/search',
            headers: authHeader(),
            params: {
                startIndex, pageSize, sortOrder
            }
        })
    }

    searchQuery(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/stores/search_with_query',
            headers: authHeader(),
            data: {
                description: query.description,
                region_id: query.region,
                store_number: query.storeNumber,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    update(params) {
        return axios({
            method: 'post',
            url: '/api/stores/update',
            headers: authHeader(),
            data: {
                id: params.id,
                description: params.description,
                address: params.address,
                region_id: params.region.id,
                number: params.number,
                city: params.city,
                province_id: params.province.id,
                ship_to: params.ship_to,
                postal_code: params.postal_code,
                tax_percent: params.tax_value,
                custom_field_values: params.custom_field_values
            }
        })
    }

    updateTax(store_id, effective_dates) {
        return axios({
            method: 'post',
            url: '/api/stores/update_tax',
            headers: authHeader(),
            data: {
                store_id,
                effective_dates: effective_dates
            }
        })
    }

    getConfiguration(storeId) {
        return axios({
            method: 'get',
            url: '/api/stores/get_configuration',
            headers: authHeader(),
            params: {
                storeId
            }
        })
    }

    updateConfiguration(storeId, request) {
        return axios({
            method: 'post',
            url: '/api/stores/update_configuration',
            headers: authHeader(),
            data: {
                store_id: storeId,
                items: request
            }
        })
    }

    deleteStore(id) {
        return axios({
            method: 'delete',
            url: '/api/stores/delete',
            headers: authHeader(),
            params: { storeId: id }
        });
    }

    downloadCsv(query) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/stores/download_csv',
            headers: authHeader(),
            params: {
                description: query.description != undefined ? query.description : "",
                regionId: query.region != undefined ? query.region : 0,
                storeNumber: query.storeNumber != undefined ? query.storeNumber : "",
                fromBulkUpload: query.fromBulkUpload != undefined ? query.fromBulkUpload : null
            }
        })
    }

    downloadXlsx(query) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/stores/download_xlsx',
            headers: authHeader(),
            params: {
                description: query.description != undefined ? query.description : "",
                regionId: query.region != undefined ? query.region : 0,
                storeNumber: query.storeNumber != undefined ? query.storeNumber : "",
            }
        })
    }

    addNewCsv(form_data) {
        var headers = {}

        Object.assign(headers, authHeader())
        Object.assign(headers, { "content-type": undefined })

        return axios({
            method: 'post',
            url: '/api/stores/bulk_new',
            data: form_data,
            headers: headers
        })
    }

    uploadTaxCsv(form_data) {
        var headers = {}

        Object.assign(headers, authHeader())
        Object.assign(headers, { "content-type": undefined })

        return axios({
            method: 'post',
            url: '/api/stores/upload_tax_csv',
            data: form_data,
            headers: headers
        })
    }

    forceTaxChange(stores) {
        return axios({
            method: 'post',
            url: '/api/stores/force_update_tax',
            headers: authHeader(),
            data: {
                stores: stores
            }
        })
    }

    addNew(store) {
        return axios({
            method: 'post',
            url: 'api/stores/new',
            headers: authHeader(),
            data: {
                description: store.description,
                address: store.address,
                city: store.city,
                province_id: store.province.id,
                postal_code: store.postal_code,
                number: store.number,
                ship_to: store.ship_to,
                region_id: store.region.id,
                tax_percent: store.tax_value,
                effective_dates: store.effective_dates,
                custom_field_values: store.custom_field_values
            }
        })
    }

    listForDriverCodes(regionId, productId, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: 'api/stores/listForDriverCodes',
            headers: authHeader(),
            data: {
                regionId,
                productId,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    updateUrlCsv(form_data, productId) {
        var headers = {}

        Object.assign(headers, authHeader())
        Object.assign(headers, { "content-type": undefined })

        return axios({
            method: 'post',
            url: '/api/stores/bulk_update_url',
            data: form_data,
            headers: headers,
            params: {
                productId
            }
        })
    }

    updateUrl(number, url, productId) {
        return axios({
            method: 'post',
            url: 'api/stores/update_url',
            headers: authHeader(),
            params: {
                number, url, productId
            }
        })
    }
}

export default new StoresService();