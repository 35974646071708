import axios from "axios";
import authHeader from "./auth.header"

class RelayService {

    checkStatus(status, kioskId) {
        return axios({
            method: 'post',
            url: '/api/relay/check_status',
            headers: authHeader(),
            params: {
                status, kioskId
            }
        })
    }

    openPort(presetId, kioskId, userName) {
        return axios({
            method: 'post',
            url: '/api/relay/open_port',
            headers: authHeader(),
            params: {
                presetId, kioskId, userName
            }
        })
    }

    GetRelayClientString(kioskId) {
        return axios({
            method: 'post',
            url: '/api/relay/get_relay_client_string',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    closePort(kioskId) {
        return axios({
            method: 'delete',
            url: '/api/relay/close_port',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }
}

export default new RelayService();