<template>
    <div>
        <h3>
            <font-awesome-icon icon="gears" class="nav-icon fa" /> Portal Configuration
            <!-- TODO: Add back user help guide with split HTML --> 
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf9')" class="help-icon nav-icon fa" title="Sleegers User Guide - Portal Configuration"/>
        </h3>

        <div class="sub-section">
            <div class="sub-title">
                Portal Options
            </div>
            <b-form @submit.prevent="submitPortalConfiguration">
                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Allow Azure SSO Account Creation" label-for="allowsso-checkbox">
                    <b-form-checkbox class="col" id="allowsso-checkbox" v-model="portalConfigurations.allowAzureSSO">
                    </b-form-checkbox>
                </b-form-group>
                
                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Province Localization" label-for="province-localization-input">
                    <b-form-input id="province-localization-input" class="col" type="text" v-model="portalConfigurations.provinceLocalization"></b-form-input>
                </b-form-group>

                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Postal Code Localization" label-for="postal-code-localization-input">
                    <b-form-input id="postal-code-localization-input" type="text" v-model="portalConfigurations.postalCodeLocalization"></b-form-input>
                </b-form-group>
                
                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Kiosk Services Email" label-for="services-email-input">
                    <b-form-input id="services-email-input" type="text" v-model="portalConfigurations.servicesEmail"></b-form-input>
                </b-form-group>

                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Default Number of Days Back to Show for Transaction Search" label-for="transaction-days-back-input">
                    <b-form-input id="transaction-days-back-input" type="number" v-model.number="portalConfigurations.transactionDaysBack"></b-form-input>
                </b-form-group>

                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Coupon Length" label-for="couponlength-input">
                    <b-form-input :state="couponState" id="couponlength-input" type="number" v-model.number="portalConfigurations.couponLength"></b-form-input>
                </b-form-group>

                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Show Response Message" label-for="showrespmsg-checkbox">
                    <b-form-checkbox id="showrespmsg-checkbox" v-model="portalConfigurations.showResponseMessage">
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group content-cols class="row row-cols-3 row-cols-md-3 g-4" label="Show GST Registration" label-for="showgst-checkbox">
                    <b-form-checkbox id="showgst-checkbox" v-model="portalConfigurations.showGSTReg">
                    </b-form-checkbox>
                </b-form-group>

                <b-button v-if="security.portalConfigWrite"
                    type="submit"
                    class="details-btn submit-btn"
                    variant="primary"
                >
                    Save
                </b-button>
            </b-form>
        </div>

        <div class="divider"></div>

        <div class="sub-section">
            <div class="sub-title">
            Kiosk Configuration Defaults
            </div>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <UnitConfigurationItemComponent
                    class="item"
                    v-for="config in form"
                    :key="config.name"
                    :config="config"
                    :overridden="true"
                    :disabled="disabled"
                    :useOverride="false"
                />
                <div class="row g-3" v-if="security.portalConfigWrite">
                    <div class="col-auto">
                        <b-button
                            class="details-btn mb-2"
                            @click="disable()"
                            v-if="disabled"
                            :disabled="!disabled"
                            variant="primary"
                            >Edit</b-button
                        >
                        <b-button
                            @click="disable()"
                            class="details-btn mb-2"
                            v-if="!disabled"
                            :disabled="disabled"
                            variant="danger"
                            >Cancel</b-button
                        >
                    </div>
                    <div class="col-auto">
                        <b-button
                            type="submit"
                            class="details-btn submit-btn"
                            v-if="!disabled"
                            variant="primary"
                            >Save</b-button
                        >
                    </div>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
import KioskService from '../../services/kiosk.service';
import UnitConfigurationItemComponent from '../../components/kiosk/UnitConfigurationItemComponent.vue';
import { AuthService } from '../../services';
import { openHelpWindow } from '../../utils/utils'

export default {
    name: 'PortalConfiguration',
    components: { UnitConfigurationItemComponent },
    data() {
        return {
            form: [],
            kioskConfigurations: {},
            portalConfigurations: {
                allowAzureSSO: false,
                provinceLocalization: '',
                postalCodeLocalization: '',
                transactionDaysBack: 0,
                couponLength: 8,
                servicesEmail: '',
                showResponseMessage: false,
                showGSTReg: false,
            },
            isLoading: false,
            selected: [],
            show: true,
            checkValid: false,
            disabled: true,
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        couponState() {
            if (!this.checkValid) return null
            return this.portalConfigurations.couponLength > 0
        },
        isFormValid() {
            return this.couponState
        }
    },
    mounted() {
        this.getKioskConfiguration();
        this.getPortalConfiguration();
    },
    methods: {
        openHelpWindow,
        getKioskConfiguration() {
            this.loading = true;
            KioskService.getPortalKioskConfiguration().then((response) => {
                if (response) {
                    this.kioskConfigurations = response.data;
                    this.setForm(this.kioskConfigurations);
                    this.loading = false;
                }
            });
        },
        getPortalConfiguration(){
            AuthService.getPortalConfiguration().then(
                (response) => {
                    if (response) {
                        var config = response.data.portal_configuration;
                        this.portalConfigurations.allowAzureSSO = config.allowsso == 1; 
                        this.portalConfigurations.provinceLocalization = config.province;
                        this.portalConfigurations.postalCodeLocalization = config.postalcode;
                        this.portalConfigurations.transactionDaysBack = config.transdaysback;
                        this.portalConfigurations.couponLength = config.couponlength;
                        this.portalConfigurations.servicesEmail = config.servicesemail;
                        this.portalConfigurations.showResponseMessage = config.showrespmsg == 1; 
                        this.portalConfigurations.showGSTReg = config.showgstreg == 1; 
                        console.log(this.portalConfigurations)
                    }
                }
            ).catch(error => {
                console.error("Failed to fetch portal configuration:", error);
            });
        },
        submitPortalConfiguration() {
            this.checkValid = true
            if (this.isFormValid) {
                AuthService.updatePortalConfiguration(this.portalConfigurations).then(
                    () => {
                        this.$store.dispatch("auth/refreshPortalConfiguration");
                        this.$root.$bvToast.toast('Successful', {
                            title: 'Update Portal Configurations',
                            variant: 'success',
                            solid: true,
                        });
                    }
                ).catch(error => {
                    console.error("Failed to update portal configuration:", error);
                });

                this.checkValid = false
            }
        },
        onSubmit(event) {
            event.preventDefault();
            KioskService.updatePortalKioskConfiguration(this.form)
                .then((response) => {
                    this.kioskConfigurations = response.data;
                    this.setForm(this.kioskConfigurations);
                    this.loading = false;
                    this.disabled = true;

                    this.$root.$bvToast.toast('Successful', {
                        title: 'Update Kiosk Configurations',
                        variant: 'success',
                        solid: true,
                    });
                })
                .catch((error) => {
                    this.setForm(this.kioskConfigurations);
                    this.disabled = true;

                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'Update Kiosk Configurations',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        onReset(event) {
            event.preventDefault();
            this.disabled = true;
            this.setForm(this.kioskConfigurations);
            this.show = false;

            this.$nextTick(() => {
                this.show = true;
            });
        },
        disable() {
            this.$set(this, 'disabled', !this.disabled);
            this.$forceUpdate();
        },
        setForm(configs) {
            this.form = [];
            configs.items.forEach((config) => {
                this.form.push({ ...config });
            });
        },
    },
};
</script>
<style scoped>
.item {
    padding-left: 20px;
}

.container {
    padding: 20px;
}

.main-title {
    font-size: 24px;
    margin-bottom: 20px;
}

.sub-section {
    margin-top: 30px;
}

.sub-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.divider {
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
}
</style>