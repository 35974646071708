import axios from "axios";
import authHeader from "./auth.header";

class CustomFieldsService {

    getTypes() {
        return axios({
            method: 'get',
            url: '/api/customfields/getTypes',
            headers: authHeader(),
        })
    }

    getDataTypes() {
        return axios({
            method: 'get',
            url: '/api/customfields/getDataTypes',
            headers: authHeader(),
        })
    }

    isFieldNameUnique(id, typeId, fieldName) {
        return axios({
            method: 'get',
            url: '/api/customfields/isFieldNameUnique',
            headers: authHeader(),
            params: {
                id,
                typeId,
                fieldName
            }
        })
    }

    addUpdate(customField) {
        return axios({
            method: 'post',
            url: '/api/customfields/addUpdate',
            headers: authHeader(),
            data: {
                id: customField.id,
                field_name: customField.fieldName,
                type_id: customField.typeId,
                data_type_id: customField.dataTypeId,
                min_length: customField.minLength,
                max_length: customField.maxLength,
                min_Num: customField.minNum,
                max_Num: customField.maxNum,
                min_date: customField.minDate,
                max_date: customField.maxDate,
                list_values: customField.listValues,
                required: customField.required,
                include_in_export: customField.export
            }
        })
    }

    list(args, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/customfields/list',
            headers: authHeader(),
            data: {
                field_name: args.fieldName,
                type_id: args.typeId,
                data_type_id: args.dataTypeId,
                required: args.required,
                include_in_export: args.includeInExport,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder,
            }
        })
    }

    delete(id) {
        return axios({
            method: 'delete',
            url: '/api/customfields/delete',
            headers: authHeader(),
            params: {
                id
            }
        })
    }

    getById(id) {
        return axios({
            method: 'get',
            url: '/api/customfields/getById',
            headers: authHeader(),
            params: {
                id
            }
        })
    }

    getByType(typeId) {
        return axios({
            method: 'get',
            url: '/api/customfields/getByType',
            headers: authHeader(),
            params: {
                typeId
            }
        })
    }

    getValues(typeId, typeKeyId) {
        return axios({
            method: 'get',
            url: '/api/customfields/getValues',
            headers: authHeader(),
            params: {
                typeId,
                typeKeyId
            }
        })
    }

    getValuesByIds(typeId, ids) {
        return axios({
            method: 'get',
            url: '/api/customfields/getValuesByIds',
            headers: authHeader(),
            data: {
                typeId,
                ids
            }
        })
    }
}
export default new CustomFieldsService();