import axios from "axios";
import authHeader from "./auth.header";

class TokenService {
    peekToken(tokenId) {
        return axios({
            method: "post",
            url: "/api/token/peek_token",
            headers: authHeader(),
            params: {
                webcode: authHeader().webcode,
                tokenId: tokenId,
            },
        });
    }

    getToken(tokenId) {
        return axios({
            method: "post",
            url: "/api/token/get_token",
            headers: authHeader(),
            params: {
                webcode: authHeader().webcode,
                tokenId: tokenId,
            },
        });
    }
}
export default new TokenService();