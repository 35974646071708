<template>
    <div>
        <b-form-group id="name-input-group" label="Name" label-for="name">
            <b-form-input 
                id="name-input"
                v-model="promoProgram.name"
                :state="nameState"
            >
            </b-form-input>
        </b-form-group>
        <div v-if="nameState == false" class="error">
            Name is required
        </div>
        <b-form-group id="start-date-group" label="Start Date" label-for="start-date">
            <b-input-group>
                <b-form-input
                    id="star-date-input"
                    v-model="promoProgram.startDate"
                    placeholder="YYYY-MM-DD"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                    autocomplete="off"
                    :state="startDateState"
                    :disabled="true"
                    >
                </b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="start-date"
                        v-model="promoProgram.startDate"
                        button-only
                        right
                        :min="minDate" 
                        :max="maxDate"
                    >
                    </b-form-datepicker>
                </b-input-group-append>
            </b-input-group>   
        </b-form-group>
        <div v-if="startDateState == false" class="error">
            Start Date must be less than End Date
        </div>
        <b-form-group id="end-date-group" label="End Date" label-for="end-date">
            <b-input-group>
                <b-form-input
                    id="end-date-input"
                    v-model="promoProgram.endDate"
                    placeholder="YYYY-MM-DD"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                    autocomplete="off"
                    :state="endDateState"
                    :disabled="true"
                    >
                </b-form-input>
                <b-input-group-append>
                    <b-form-datepicker
                        id="end-date"
                        v-model="promoProgram.endDate"
                        button-only
                        right
                        :min="minDate" 
                        :max="maxDate"
                    >
                    </b-form-datepicker>
                </b-input-group-append>
            </b-input-group>   
        </b-form-group>
        <div v-if="endDateState == false" class="error">
            End Date must be greater than Start Date
        </div>
        <div>
            <b-form-group id="expiryType-select-group" label="Expiry Type" label-for="expiryType-select">
                <b-form-select 
                    id="location-select" 
                    v-model="selectedExpiryType" 
                    :disabled="false">
                        <option v-for="expiryType in expiryTypes" :value="expiryType.id" :key="expiryType.id">
                            {{ expiryType.name }}
                        </option>
                </b-form-select>
            </b-form-group>
            <div v-if="selectedExpiryType == 0">
                <b-form-group id="fixed-expiry-group" label="Fixed Expiry Date" label-for="fixed-expiry">
                    <b-input-group>
                        <b-form-input
                            id="fixed-expiry-input"
                            v-model="promoProgram.fixedExpiry"
                            placeholder="YYYY-MM-DD"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                            autocomplete="off"
                            :state="fixedExpiryState"
                            :disabled="true"
                            >
                        </b-form-input>
                        <b-input-group-append>
                            <b-form-datepicker
                                id="fixed-expiry"
                                v-model="promoProgram.fixedExpiry"
                                button-only
                                right
                                :min="minDate" 
                                :max="maxDate"
                            >
                            </b-form-datepicker>
                        </b-input-group-append>
                    </b-input-group>   
                </b-form-group>
                <div v-if="fixedExpiryState == false" class="error">
                    Fixed Expiry Date is required
                </div>
            </div>
            <div v-if="selectedExpiryType == 1">
                <b-form-group id="days-to-expiry-input-group" label="Days To Expiry" label-for="days-to-expiry">
                    <b-form-spinbutton 
                        id="days-to-expiry-input" 
                        v-model="promoProgram.daysToExpiry" 
                        inline
                        :state="daysToExpiryState"
                        :min="0"
                        :max="500"
                        >
                    </b-form-spinbutton>
                </b-form-group>
                <div v-if="daysToExpiryState == false" class="error">
                    Days To Expiry must be greater than 0 
                </div>
            </div>
        </div>
        <ProductsComponent v-model="promoProgram.productId" :hideOptionAll="true" :state="productIdState"/>
        <div v-if="productIdState == false" class="error">
            Product is required
        </div>
        <TransactionTypeSelectComponent v-model="promoProgram.transactionTypeId" :state="transactionTypeIdState"/>
        <div v-if="transactionTypeIdState == false" class="error">
            Transaction Type is required
        </div>
        <DiscountTypeSelectComponent v-model="promoProgram.discountTypeId"/>
        <b-form-group id="discount-input-group" label="Discount" label-for="discount-input">
            <b-input-group>
                <b-input-group-prepend is-text>
                    <font-awesome-icon :icon="promoProgram.discountTypeId != 4 ? 'dollar-sign' : 'percent'" class="nav-icon fa" />
                </b-input-group-prepend>
                <b-form-input 
                    id="discount-input" 
                    v-model="promoProgram.discount" 
                    :state="discountState"
                ></b-form-input>
            </b-input-group>
        </b-form-group>
        <div v-if="discountState == false" class="error">
            Discount must be greater than 0
        </div>
        <b-form-group id="receiptMessage-input-group" label="Receipt Message" label-for="receiptMessage">
            <b-form-textarea 
                id="receiptMessage-input"
                v-model="promoProgram.receiptMessage"
                :state="receiptMessageState"
                rows="3"
            >
            </b-form-textarea>
        </b-form-group>
        <div v-if="receiptMessageState == false" class="error">
            Receipt Message is required
        </div>
        <b-button @click="addUpdatePromoProgram" variant="primary" class="right">{{ isAddProgram ? "Add" : "Update"}}</b-button>
    </div>
</template>
<script>

import PromosService from "../../services/promos.service"
import { ProductsComponent, TransactionTypeSelectComponent, DiscountTypeSelectComponent } from "../../components"

export default {
    name: "AddUpdatePromoProgram",
    components: {
        ProductsComponent,
        TransactionTypeSelectComponent,
        DiscountTypeSelectComponent
    },
    props: {
        program:{
            type: Object,
        }
    },
    data() {
        return {
            promoProgram: {
                id: 0,
                name: "",
                startDate: "",
                endDate: "",
                productId: 0,
                transactionTypeId: 0,
                fixedExpiry: "",
                daysToExpiry: 0,
                discountTypeId: 1,
                discount: 0.0,
                receiptMessage: ""
            },
            minDate: {},
            maxDate: {},
            checkValid: false, 
            selectedExpiryType: 0,   
            expiryTypes: [
                { id: 0, name: "Fixed Expiry Date" },
                { id: 1, name: "Days To Expire" },
            ]   ,
            setUpCompleted: false         
        };
    },
    computed: {
        isAddProgram() {
            return this.program == undefined
        },
        nameState() {
            if (!this.checkValid) return null
            return this.promoProgram.name != ""
        },
        startDateState() {
            if (!this.checkValid) return null
            return Date.parse(this.promoProgram.startDate) <= Date.parse(this.promoProgram.endDate)
        },
        endDateState() {
            if (!this.checkValid) return null
            return Date.parse(this.promoProgram.endDate) >= Date.parse(this.promoProgram.startDate)
        },
        productIdState() {
            if (!this.checkValid) return null
            return this.promoProgram.productId > 0
        },
        transactionTypeIdState() {
            if (!this.checkValid) return null
            return this.promoProgram.transactionTypeId > 0
        },
        fixedExpiryState() {
            if (!this.checkValid) return null
            return this.promoProgram.fixedExpiry != ""
        },
        daysToExpiryState() {
            if (!this.checkValid) return null
            return this.promoProgram.daysToExpiry > 0
        },
        discountState() {
            if (!this.checkValid) return null
            return  this.promoProgram.discount > 0
        },
        receiptMessageState() {
            if (!this.checkValid) return null
            return this.promoProgram.receiptMessage != ""
        },
        isFormValid() {
            if (this.selectedExpiryType == 0) {
                return this.nameState 
                    && this.startDateState 
                    && this.endDateState 
                    && this.productIdState 
                    && this.transactionTypeIdState 
                    && this.fixedExpiryState 
                    && this.discountState
                    && this.receiptMessageState
            }
            else {
                return this.nameState 
                    && this.startDateState 
                    && this.endDateState 
                    && this.productIdState 
                    && this.transactionTypeIdState 
                    && this.daysToExpiryState 
                    && this.discountState
                    && this.receiptMessageState
            }
            
        }
    },
    created() {
        this.setUpDates();
    },
    mounted() {
        if (!this.isAddProgram) {
            this.promoProgram = Object.assign({}, this.program)
            this.selectedExpiryType = this.program.daysToExpiry > 0 ? 1 : 0
        }        

        this.$watch('selectedExpiryType', () => {
            this.promoProgram.fixedExpiry = ""
            this.promoProgram.daysToExpiry = 0
      });
    },
    methods: {
        setUpDates() {
            const now = new Date()

            this.minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            this.maxDate = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())

            if (this.isAddProgram) {
                var year = this.minDate.toLocaleString("default", { year: "numeric" });
                var month = this.minDate.toLocaleString("default", { month: "2-digit" });
                var day = this.minDate.toLocaleString("default", { day: "2-digit" });

                this.promoProgram.startDate = `${year}-${month}-${day}`
                this.promoProgram.endDate = `${year}-${month}-${day}`
            }
        },
        addUpdatePromoProgram() {
            this.checkValid = true
            if (this.isFormValid) {
                PromosService.addUpdatePromoProgram(this.promoProgram).then((response) => {
                    if (response) {   
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.$emit("hide")
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>