<template>
    <b-form-group id="location-select-group" label="Location" label-for="location-select">
        <b-form-select 
            id="location-select" 
            v-model="selectedLocationId" 
            :disabled="this.disabled" 
            :state="state">
                <option v-for="location in locations" :value="location.id" :key="location.id">
                    {{ location.name }}
                </option>
        </b-form-select>
        <div class="mt-2">
        <KioskSelectComponent v-model="selectedKioskId" :state="selectedKioskState" v-if="selectedLocationId == 1"/>
        <div v-if="selectedKioskState == false && selectedLocationId == 1" class="error" >
            Kiosk is required
        </div>
        <StoresSelectComponent v-model="selectedStoreId" :state="selectedStoreState" v-if="selectedLocationId == 2"/>
        <div v-if="selectedStoreState == false && selectedLocationId == 2" class="error">
            Store is required
        </div>
        <RegionsComponent v-model="selectedRegionId" :state="selectedRegionState" v-if="selectedLocationId == 3"/>
        <div v-if="selectedRegionState == false && selectedLocationId == 3" class="error">
            Region is required
        </div>
        </div>
    </b-form-group>
</template>

<script>
import StoresSelectComponent from "./StoresSelectComponent.vue"
import RegionsComponent from "./RegionsComponent.vue"
import KioskSelectComponent from "./KioskSelectComponent.vue"

export default {
    name: "LocationSelect",
    components: {
        StoresSelectComponent,
        RegionsComponent,
        KioskSelectComponent
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        state: {
            type: Boolean,
            required: false
        },
    },
    data() {
        return {
            locations: [
                { id: 0, name:"All" },
                { id: 1, name:"Kiosk" },
                { id: 2, name:"Store" },
                { id: 3, name:"Region" }
                ],
            selectedLocationId: 0,
            selectedKioskId: 0,
            selectedStoreId: 0,
            selectedRegionId: 0
        }
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
        },
        selectedKioskState() {
            return this.selectedKioskId != 0
        },
        selectedStoreState() {
            return this.selectedStoreId != ""
        },
        selectedRegionState() {
            return this.selectedRegionId != ""
        },
        locationData() {
            return {
                locationTypeId: this.selectedLocationId,
                kioskId: this.selectedKioskId,
                storeId: this.selectedStoreId,
                regionId: this.selectedRegionId,
            } 
        }
    },
    watch: {
        selectedLocationId() {
            this.selectedKioskId = 0
            this.selectedStoreId = 0
            this.selectedRegionId = 0

            if (this.selectedLocationId == 0) {
                this.$emit('input', this.locationData)
            }
            else {
                this.$emit('input', {})
            }
         },
         selectedKioskId() {
            this.$emit('input', this.locationData)
         },
         selectedStoreId() {
            this.$emit('input', this.locationData)
         },
         selectedRegionId() {
            this.$emit('input', this.locationData)
         }
    },
    mounted() {
        this.$emit('input', this.locationData)
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>