<template>
    <div>
        <div v-if="customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.Numeric">
            <b-form-group id="num-input-group" :label="customField.field_name" label-for="num">
                <b-form-input 
                    id="num-input"
                    v-model="customValue.value"
                    :state="isFormValid"
                    type="number"
                    :min="customField.min_num"
                    :max="customField.max_num"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="isFormValid == false" class="error mb-3">
                Must be between {{customField.min_num}} and {{customField.max_num}}
            </div>
        </div>
        <div v-if="customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.Text">
            <b-form-group id="text-input-group" :label="customField.field_name" label-for="text-input">
                <b-form-input 
                    id="text-input"
                    v-model="customValue.value"
                    :state="isFormValid"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="isFormValid == false" class="error mb-3">
                Must be between {{customField.min_length}} and {{customField.max_length}} characters in length
            </div>
        </div>
        <div v-if="customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.Date">
            <b-form-group id="date-group" :label="customField.field_name" label-for="date-input">
                <b-input-group>
                    <b-form-input
                        id="date-input"
                        v-model="customValue.value"
                        placeholder="YYYY-MM-DD"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                        autocomplete="off"
                        :state="isFormValid"
                        :disabled="true"
                        >
                    </b-form-input>
                    <b-input-group-append>
                        <b-form-datepicker
                            id="date"
                            v-model="customValue.value"
                            button-only
                            right
                            :min="customField.min_date" 
                            :max="customField.max_date"
                        >
                        </b-form-datepicker>
                    </b-input-group-append>
                </b-input-group> 
            </b-form-group>  
            <div v-if="isFormValid == false" class="error mb-3">
                Must be between {{customField.min_date}} and {{customField.max_date}}
            </div>
        </div>
        <div v-if="customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.List">
            <b-form-group id="list-values-select-group" :label="customField.field_name" label-for="list-values-select">
                <b-form-select 
                    id="type-select" 
                    v-model="customValue.value"
                    :state="isFormValid"
                >
                    <option v-if="!customField.required" :value="null">No Selection</option>
                    <option v-for="listValue in customField.list_values" :value="listValue.id" :key="listValue.id">
                        {{ listValue.value }}
                    </option>
                </b-form-select>
            </b-form-group>
        </div>
    </div>
</template>
<script>

import CUSTOM_FIELD_DATA_TYPE_ENUM from "../../models/CustomFieldDataTypeEnum"
import { getDateOnly } from "../../utils/utils"

export default {
    name: "CustomField",
    components: {
    },
    props: {
        customField: {
            type: Object,
            required: true
        },
        existingCustomValue: {
            type: Object,
        }
    },
    data() {
        return {
            customValue: {
                id: 0,
                value : undefined
            },
            checkValid: false,
            CUSTOM_FIELD_DATA_TYPE_ENUM,
        }
    },
    computed:{
        isFormValid() {
            if (!this.checkValid) return null
            if (!this.customField.required && (this.customValue.value == undefined || this.customValue.value.length == 0)) 
            {
                return true   
            }
            else
            {
                if (this.customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.Numeric) {
                    if (!this.customValue.value) return false
                    if (this.customValue.value >= this.customField.min_num && this.customValue.value <= this.customField.max_num) {
                        return true
                    }
                }
                else if (this.customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.Text) {
                    if (!this.customValue.value) return false
                    if (this.customValue.value.length >= this.customField.min_length && this.customValue.value.length <= this.customField.max_length) {
                        return true
                    }
                }
                else if (this.customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.Date) {
                    if (!this.customValue.value) return false
                    if (this.customValue.value >= this.customField.min_date && this.customValue.value <= this.customField.max_date) {
                        return true
                    }
                }
                else if (this.customField.data_type_id == CUSTOM_FIELD_DATA_TYPE_ENUM.List) {
                    if (!this.customValue.value) return false
                    if (this.customField.list_values.some(customField => customField.id == this.customValue.value)) {
                        return true
                    }
                }
            }

            return false
        }
    },
    mounted() {
        if(this.existingCustomValue) {
            this.customValue = this.existingCustomValue
        }
    },
    methods: {
        setUpDates() {
            const now = new Date()
            this.customValue.value = this.getDateOnly(now)
        },
        getDateOnly,
        isValid() {
            this.checkValid = true
            
            return this.isFormValid
        },
        getValue() {
            return {
                id: this.customValue ? this.customValue.id : 0,
                custom_field_id: this.customField.id,
                value: String(this.customValue.value)
            } 
        }
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
}
.form-group {
    margin-bottom: 1rem;
}
</style>