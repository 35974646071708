import Vue from "vue"
import VueMeta from "vue-meta"
import App from "./App.vue"
import store from "./modules"
import axios from "axios"
import i18n from "@/lng/i18n"
import { router } from "./router"
import { BootstrapVue, BootstrapVueIcons, ToastPlugin } from "bootstrap-vue"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "bootstrap/dist/css/bootstrap.css"
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
const Autocomplete = require("v-autocomplete")
import 'v-autocomplete/dist/v-autocomplete.css'
import msalPlugin from './msal.plugin';
import './css/webportal.css';


import {
    faUser,
    faHome,
    faCashRegister,
    faMoneyBills,
    faInfoCircle,
    faGaugeHigh,
    faTrashCan,
    faPlusSquare,
    faPencil,
    faScroll,
    faReceipt,
    faCut,
    faAt,
    faPlus,
    faDoorOpen,
    faHourglass,
    faWrench,
    faScrewdriverWrench,
    faBell,
    faChartPie,
    faNetworkWired,
    faTerminal,
    faFloppyDisk,
    faDisplay,
    faMoneyBillTransfer,
    faBalanceScale,
    faCreditCard,
    faGlobe,
    faFilter,
    faBook,
    faGear,
    faKey,
    faDownload,
    faTruck,
    faUserSlash,
    faTag,
    faFile,
    faCheck,
    faDollarSign,
    faFaceSmile,
    faCheckDouble,
    faHandHoldingDollar,
    faHashtag,
    faDesktop,
    faPercent,
    faBoxesStacked,
    faUpload,
    faStar,
    faCircleExclamation,
    faEquals,
    faArrowTrendUp,
    faPaperPlane,
    faPenToSquare,
    faLock,
    faLockOpen,
    faStore,
    faQrcode,
    faCoins,
    faMagnifyingGlass,
    faUserPlus,
    faShieldHalved,
    faRobot,
    faLink,
    faLinkSlash,
    faXmark,
    faIdBadge,
    faLandmark,
    faAngleRight,
    faAngleLeft,
    faChevronUp,
    faChevronDown,
    faUserGear,
    faUserSecret,
    faEarthAmericas,
    faEllipsis,
    faGears,
    faRotateRight,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import vuetify from './plugins/vuetify'

library.add(
    faUser,
    faHome,
    faCashRegister,
    faMoneyBills,
    faInfoCircle,
    faGaugeHigh,
    faTrashCan,
    faPlusSquare,
    faPencil,
    faScroll,
    faReceipt,
    faCut,
    faAt,
    faPlus,
    faDoorOpen,
    faHourglass,
    faWrench,
    faScrewdriverWrench,
    faBell,
    faChartPie,
    faNetworkWired,
    faTerminal,
    faFloppyDisk,
    faDisplay,
    faMoneyBillTransfer,
    faBalanceScale,
    faCreditCard,
    faGlobe,
    faFilter,
    faBook,
    faGear,
    faKey,
    faDownload,
    faTruck,
    faUserSlash,
    faTag,
    faFile,
    faCheck,
    faDollarSign,
    faCheckDouble,
    faHandHoldingDollar,
    faFaceSmile,
    faHashtag,
    faDesktop,
    faPercent,
    faUpload,
    faStar,
    faHome,
    faCircleExclamation,
    faEquals,
    faArrowTrendUp,
    faPaperPlane,
    faPenToSquare,
    faLock,
    faLockOpen,
    faFaceSmile,
    faStore,
    faQrcode,
    faHandHoldingDollar,
    faBoxesStacked,
    faCoins,
    faMagnifyingGlass,
    faUserPlus,
    faShieldHalved,
    faRobot,
    faLink,
    faLinkSlash,
    faXmark,
    faIdBadge,
    faLandmark,
    faAngleRight,
    faAngleLeft,
    faChevronUp,
    faChevronDown,
    faUserGear,
    faUserSecret,
    faEarthAmericas,
    faEllipsis,
    faGears,
    faRotateRight,
    faQuestionCircle,
)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ToastPlugin)
Vue.use(VueMeta)
Vue.use(vuetify)
Vue.use(Autocomplete)
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(msalPlugin)

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                //this.$store.dispatch("auth/checkJWTExpiry")
                //store.dispatch("auth/logout")
                router.push("/accessdenied")
            }
            else if (error.response.status === 404) {
                router.push("/pagenotfound")
            }
        }
        return Promise.reject(error)
    }
)

export const vueInst = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
});

vueInst.$mount('#app')