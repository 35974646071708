<template>
    <div>
        <h3>
            <font-awesome-icon icon="truck" class="nav-icon fa" /> Kiosk Access
            Codes
        </h3>
        <div class="col">
            <DynamicSearchComponent
                ref="driverCodesSearchComponent"
                preferencesName="driver_code_search"
                :allCriteria="searchCriteria"
                @resetPagination="
                    () => {
                        this.searchPagination.currentPage = 1;
                        this.searchPagination.startIndex = 1;
                    }
                "
                @reloadDynamicSearch="reloadDynamicSearch"
            />
        </div>
        <Table
            id="driverCodesTable"
            :fields="dynamicFields"
            :items="driverCodes"
            sortBy="created"
            :sortDesc="true"
            :searchPagination="searchPagination"
            @getItems="listDriverCodes"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(actions)="row">
                <font-awesome-icon
                    v-if="security.userDelete"
                    icon="trash-can"
                    class="nav-icon pointer action-btn"
                    @click="openDeleteModal(row.item)"
                    title="Delete"
                />
            </template>
            <template #cell(created)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.created)) }}
            </template>
        </Table>
        <b-button
            @click="showAddDriverCodeModal"
            variant="outline-primary"
            :style="{
                visibility: security.driverCodeCreate ? 'visible' : 'hidden',
            }"
            >Add Access Code</b-button
        >
        <b-modal id="add-driver-code" ref="add-driver-code" hide-footer>
            <template #modal-title> Add Access Code </template>
            <DriverCodeAdd @hide="hideAddDriverCodeModal" />
        </b-modal>
        <DriverCodeDelete
            @deleteComplete="deleteComplete"
            :userId="selectedDriverCode.user_id"
        />
    </div>
</template>

<script>
import SearchPagination from '../../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../../components';
import DriverCodesService from '../../../services/drivercodes.service';
import DriverCodeAdd from './DriverCodeAdd.vue';
import DriverCodeDelete from './DriverCodeDelete.vue';
import { formatDate, convertUTCtoLocalDate, generateDynamicFields } from '../../../utils/utils';

export default {
    name: 'DriverCodes',
    components: {
        Table,
        DriverCodeAdd,
        DriverCodeDelete,
        DynamicSearchComponent,
    },
    data() {
        return {
            driverCodes: [],
            selectedDriverCode: {},
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -4,
            fields: [
                {
                    key: 'actions',
                },
                {
                    key: 'code',
                    label: 'Code',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'email',
                    label: 'Email',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'user_type',
                    label: 'User Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'created',
                    label: 'Created',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
            ],
            searchCriteria: [
                {
                    key: 'email',
                    name: 'Email',
                    type: 'Emails',
                    searchQueryName: 'userId',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'user_type',
                    name: 'User Type',
                    type: 'UserType',
                    searchQueryName: 'userTypeId',
                    searchQueryValueProperty: 'id',
                },
            ],
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        dynamicFields() {
            var filteredFields = generateDynamicFields(
                this.fields,
                'actions',
                !this.security.driverCodeWrite
            );
            return generateDynamicFields(filteredFields);
        },
    },
    mounted() {
        this.listDriverCodes();
    },
    methods: {
        listDriverCodes() {
            this.$refs.driverCodesSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            DriverCodesService.list(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.driverCodes = response.data.items;
                        this.searchPagination.totalRows =
                            this.driverCodes.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.listDriverCodes()
        },
        showAddDriverCodeModal() {
            this.$refs['add-driver-code'].show();
        },
        hideAddDriverCodeModal() {
            this.$refs['add-driver-code'].hide();
            this.listDriverCodes();
        },
        formatDate,
        convertUTCtoLocalDate,
        formatDateNotAvailable(date) {
            return date != '0001-01-01T00:00:00'
                ? this.formatDate(date)
                : 'N/A';
        },
        openDeleteModal(item) {
            this.selectedDriverCode = item;
            this.$bvModal.show('driver-code-delete-modal');
        },
        deleteComplete() {
            this.$bvModal.hide('driver-code-delete-modal');
            this.listDriverCodes();
        },
    },
};
</script>
<style scoped></style>