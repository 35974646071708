import axios from "axios";
import authHeader from "./auth.header";

class UserPreferencesService {

    list(type) {
        return axios({
            method: 'get',
            url: '/api/user_preferences/list',
            headers: authHeader(),
            params: {
                type: type
            }
        })
    }

    put(request) {
        return axios({
            method: 'put',
            url: '/api/user_preferences/put',
            headers: authHeader(),
            data: {
                type: request.type,
                name: request.name,
                default: request.default,
                value: btoa(request.value),
                id: request.id ?? 0
            },
        })
    }

    delete(request) {
        return axios({
            method: 'delete',
            url: '/api/user_preferences/delete',
            headers: authHeader(),
            data: {
                type: request.type,
                id: request.id
            },
        })
    }
}

export default new UserPreferencesService();