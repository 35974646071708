import { render, staticRenderFns } from "./DualListComponent.vue?vue&type=template&id=35f3a145&scoped=true&"
import script from "./DualListComponent.vue?vue&type=script&lang=js&"
export * from "./DualListComponent.vue?vue&type=script&lang=js&"
import style0 from "./DualListComponent.vue?vue&type=style&index=0&id=35f3a145&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f3a145",
  null
  
)

export default component.exports