<template>
    <div class="role-update-modal">
        <b-modal @submit.prevent="addRole" id="role-update-modal" size="xl" @show="refresh">
            <template #modal-title>
                <h3>Update Role - {{formattedRoleName}}</h3>
            </template>
            <b-tabs>
                <b-tab title="Permissions" class="pt-3">
                    <b-form-checkbox
                        id="role-tech-checkbox"
                        v-model="isTechnician"
                        name="checkbox-1"
                        :disabled="!security.technician"
                        class="ml-1 right"
                        switch
                        >
                        Technician
                    </b-form-checkbox>
                    <ClaimsComponent
                        :claims="claims"
                        :selectedCheckboxes="internalSelected"
                        :originalSelectedCheckboxes="internalSelected"
                        stickyheader="300px"
                        @setSelectedCheckboxes="setSelectedClaim"
                    />
                </b-tab>
                <b-tab title="Exclude Columns" class="pt-3">
                    <ExcludeColumnsByRole :roleId="roleId" @setData="setExcludedColumns"/>
                </b-tab>
            </b-tabs>
            <template #modal-footer>
                <SpinnerButton @click="confirmClaims" :isLoading="isLoading" text="Update" class="btn-primary no-margin" />
            </template>
        </b-modal>
    </div>
</template>

<script>

import RolesService from "../../services/roles.service"
import SpinnerButton from "../SpinnerButton.vue"
import { ClaimsComponent } from "../../components/"
import ExcludeColumnsByRole from "../../components/roles/ExcludeColumnsByRole.vue"

export default {
    name: "AddRole",
    components: {
        SpinnerButton,
        ClaimsComponent,
        ExcludeColumnsByRole
    },
    data() {
        return {
            isLoading: false,
            internalSelected: [],
            roleId: 0,
            isTechnician: false,
            typeId: 0,
            excludedColumns: []
        }
    },
    props: {
        claims: {
            type: Array,
            required: true
        },
        selected: {
            type: Array,
            required: true
        },
        role: {
            type: Object,
            required: true
        },
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
        userRoleId() {
            return this.$store.state.auth.user.role_id
        },
        formattedRoleName() {
            if (this.role == undefined) return ''
            return this.role.id == this.userRoleId ? this.role.description + " (assigned)" : this.role.description
        }
    },
    methods: {
        refresh() {
            this.internalSelected = this.selected
            if (this.role) {
                this.roleId = this.role.id;
                this.isTechnician = this.role.is_technician;
            }
            else {
                this.roleId = 0
                this.isTechnician = false
            }
            
            this.typeId = 0
            this.excludedColumns = []
        },
        setSelectedClaim(value) {
            this.internalSelected = value;
        },
        confirmClaims(){
            if(this.roleId == 0 || this.roleId == null || this.roleId == undefined){
                this.$root.$bvToast.toast("Please select a role before updating claims.", {
                        title: 'Error',
                        variant: 'danger',
                        solid: true
                        })
                return;
            }
            this.readConfirmed = true;
            this.writeConfirmed = true;
            if(!this.internalSelected.some(e => e == "role,r") && this.roleId == this.userRoleId) this.readConfirmed = false;
            if(!this.internalSelected.some(e => e == "role,w") && this.roleId == this.userRoleId) this.writeConfirmed = false;
            if((!this.readConfirmed || !this.writeConfirmed) && this.hasRoles) {
                if(!this.readConfirmed && !this.writeConfirmed) {
                    this.roleType = "Read/Write";
                }
                else if(!this.readConfirmed) {
                    this.roleType = "Read";
                }
                else if(!this.writeConfirmed) {
                    this.roleType = "Write";
                }
                this.$bvModal.show('role-write-confirmation-modal')
                return;
            }
            this.updateClaims();
        },
        updateClaims(){
            RolesService.updateRoleClaims(this.roleId, this.isTechnician, this.internalSelected).then(
                (response) => {
                    if(response.data.success){
                        if (this.typeId != 0 && this.excludedColumns != []) {
                            this.updateExcludedColumnsByRole()
                        }
                        else {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Success',
                                variant: 'success',
                                solid: true
                            })
                        }

                        this.$store.dispatch("auth/refreshJwtToken").then(
                            (response) => {
                                if (!response.success) {
                                    this.showRefreshErrorToast()
                                }
                            }
                        ).catch(()=> this.showRefreshErrorToast());
                    }
                    else {
                        this.$root.$bvToast.toast('Error updating role', {
                            title: 'Error',
                            variant: 'danger',
                            solid: true
                        })
                    }
                    this.$emit('changeComplete');
                }
            )
        },
        setExcludedColumns(typeId, excludedColumns) {
            this.typeId = typeId
            this.excludedColumns = excludedColumns
        },
        updateExcludedColumnsByRole() {
            RolesService.updateExcludedColumnsByRole(this.roleId, this.typeId, this.excludedColumns).then(
                (response) => {
                    if (response) {
                        if (response.data.success) {
                            this.$root.$bvToast.toast("Role Updated", {
                                title: 'Success',
                                variant: 'success',
                                solid: true    
                            })
                        }
                        else {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                                solid: true    
                            })
                        }
                    }
                }
            )
        },
    }
}
</script>
<style scoped>
.right {
    float: right;
}
.no-margin {
    margin-top: 0rem !important;
}
</style>