<template>
    <div>
        <b-form-group id="comment-input-group" label="Comments" label-for="comment">
            <b-form-input 
                id="comment-input"
                v-model="reimbursement.comment"
                :state="commentState"
            >
            </b-form-input>
        </b-form-group>
        <div v-if="productIdState == false" class="error">
            Comments is required
        </div>
        <LocationSelectComponent v-model="reimbursement.location" :state="locationState"/>
        <ProductsComponent v-model="reimbursement.productId" :hideOptionAll="true" :state="productIdState"/>
        <div v-if="productIdState == false" class="error">
            Product is required
        </div>
        <b-form-group id="price-input-group" label="Price" label-for="price-input">
            <b-input-group>
                <b-input-group-prepend is-text>
                    <font-awesome-icon icon="dollar-sign" class="nav-icon fa" />
                </b-input-group-prepend>
                <b-form-input
                    id="price-input" 
                    v-model="reimbursement.price" 
                    :state="priceState"
                ></b-form-input>
            </b-input-group>
        </b-form-group>
        <div v-if="priceState == false" class="error">
            Price must be greater than 0
        </div>
        <b-button @click="generateReimbursement" variant="primary" class="right">Add</b-button>
    </div>
</template>
<script>

import PromosService from "../../services/promos.service"
import { ProductsComponent, LocationSelectComponent } from "../../components"

export default {
    name: "AddReimbursement",
    components: {
        ProductsComponent, 
        LocationSelectComponent
    },
    data() {
        return {
            reimbursement:{
                comment: "",
                location: {},
                productId: 0,
                price: 0.0,
            },
            checkValid: false,                
        };
    },
    computed: {
        commentState() {
            if (!this.checkValid) return null
            return this.reimbursement.comment != ""
        },
        locationState() {
            if (!this.checkValid) return null
            return Object.keys(this.reimbursement.location).length != 0
        },
        productIdState() {
            if (!this.checkValid) return null
            return this.reimbursement.productId > 0
        },
        priceState() {
            if (!this.checkValid) return null
            return  this.reimbursement.price > 0
        },
        isFormValid() {
            return this.commentState && this.locationState && this.productIdState && this.priceState
        }
    },
    created() {
    },
    methods: {
        generateReimbursement() {
            this.checkValid = true
            if (this.isFormValid) {
                PromosService.generateReimbursement(this.reimbursement).then((response) => {
                    if (response) {   
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.$emit("hide")
                    }
                })
            }
        },
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>