<template>
    <div>
        <b-form-group id="svmid" label="SVMID" label-for="svmid">
            <b-input-group class="prepend-group">
                <template #prepend v-if="prependChar != ''">
                    <b-input-group-text 
                    class="prepend" 
                    :class="{'prepend-valid' : svmidState == true, 'prepend-error' : svmidState == false }"
                    >
                     {{prependChar}}
                     </b-input-group-text>
                </template>
                <b-form-input
                    id="svmid"
                    v-model="internalServant.svmid" 
                    :class="{'svmid-text' : prependChar != ''}"
                    :state="svmidState">
                </b-form-input>
            </b-input-group>
        </b-form-group>
        <div v-if="svmidState == false" class="error">
            SVMID format is invalid
        </div>
        <HardwareTypeComponent :state="hardwareTypeState" v-model="internalServant.hardware_type_id" noSelectionDescriptor="Select a Hardware Type"/>
        <div v-if="hardwareTypeState == false" class="error">
            Hardware Type is required
        </div>
        <b-form-group id="bays-select-group" label="Bay Count" label-for="bays-select">
            <b-form-select id="bays-select" v-model="internalServant.bay_count">
                <option v-for="bay in bayCount" :value="bay.name" :key="bay.id">
                    {{ bay.name }}
                </option>
            </b-form-select>
        </b-form-group>
        <b-button @click="addUpdateServant" variant="primary" class="right">{{isUpdate ? 'Update' : 'Add'}}</b-button>
    </div>
</template>

<script>
import ServantsService from "../../../services/servants.service"
import { HardwareTypeComponent } from "../.."
export default {
    name: 'AddUpdateServant',
    components: {
        HardwareTypeComponent
    },
    props: {
        servant: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            internalServant: {},
            bayCount: [
                {
                    id: 1,
                    name: 24
                },
                {
                    id: 2,
                    name: 150
                }
            ],
            checkValid: false
        }
    },
    computed: {
        isUpdate() {
            return this.servant
        },
        prependChar() {
            if (this.internalServant.svmid == "") {
                return 'S'
            }
            else if (this.internalServant.svmid.charAt(0) != 'S') {
                return 'S'
            }
            else { 
                return ''
            }
        },
        svmidState() {
            if(!this.checkValid) return null
            return this.internalServant.svmid != "" && this.internalServant.svmid != "S"
        },
        hardwareTypeState() {
            if(!this.checkValid) return null
            return this.internalServant.hardware_type_id > 0
        },
        isFormValid() { 
            return this.svmidState && this.hardwareTypeState
        }
    },
    created() {
        if (!this.isUpdate) {
            this.internalServant = {
                id: 0,
                svmid: "",
                hardware_type_id: 0,
                bay_count: 24
            }
        }
        else {
            this.internalServant = Object.assign({}, this.servant)
        }
    },
    methods: {
        addUpdateServant() {
            this.checkValid = true

            if (this.isFormValid) {    
                
                if (this.internalServant.svmid.charAt(0) != 'S' && this.internalServant.svmid.charAt(0) != 's') {
                    this.internalServant.svmid = 'S' + this.internalServant.svmid 
                }

                else if (this.internalServant.svmid.charAt(0) == 's') {
                    this.internalServant.svmid = 'S' + this.internalServant.svmid.substring(1)
                }
                
                ServantsService.update(this.internalServant).then((response) => {
                    if (response) {
                        
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? "Update Successful" : "Error",
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        
                        this.$emit('hide')
                    }
                })
            }
        }
    }
}
</script>

<style scoped>
.prepend-group > .input-group-prepend > .prepend {
    background-color: white ;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding-right: 0px;
    padding-top: 0.45rem;
    padding-bottom: 0.325rem;
}

.prepend-group > .input-group-prepend > .prepend-valid {
    border-top: 1px solid #28a745;
    border-left: 1px solid #28a745;
    border-bottom: 1px solid #28a745;
    padding-right: 0px;
}

.prepend-group > .input-group-prepend > .prepend-error {
    border-top: 1px solid #dc3545;
    border-left: 1px solid #dc3545;
    border-bottom: 1px solid #dc3545;
    padding-right: 0px;
}

.prepend-group > .svmid-text {
    border-left: none;
    padding-left: 0px;
}

.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>