<template>
    <div>
        <Table
            id="servantTable"
            :fields="fields"
            :items="servants"
            sortBy="svmid"
            :sortDesc="false"
            :searchPagination="searchPagination"
            :hidePagination="true"
            :busy="isLoading"
            @getItems="getServants"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(svmid)="data">
                <a
                    href="#"
                    @click="servantLink(data.item.id)"
                    class="pointer underline"
                >
                    {{ data.item.svmid }}
                </a>
            </template>
        </Table>
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import { Table } from '../../components';
import ServantsService from '../../services/servants.service';
import { servantLink } from '../../utils/utils';

export default {
    name: 'KioskServantsComponent',
    components: {
        Table,
    },
    props: {
        kioskId: {
            type: Number,
            require: true,
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'svmid',
                    label: 'SVMID',
                    tdClass: 'column',
                    sortable: true,
                    visible: false,
                    sortOrder: 1
                },
                {
                    key: 'hardware_type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'product',
                    label: 'Product',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'bay_count',
                    label: '# of Bays',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5
                },
                {
                    key: 'store_number',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'province',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            servants: [],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: 1,
            isLoading: false,
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
    },
    mounted() {
        this.getServants();
    },
    methods: {
        getServants() {
            this.isLoading = true;
            ServantsService.getServants(this.kioskId, this.sortOrder).then((response) => {
                if (response) {
                    this.servants = response.data;
                }
                this.isLoading = false;
            });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getServants()
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
        servantLink,
    },
};
</script>

<style scoped>
.underline {
    text-decoration: underline;
}
</style>