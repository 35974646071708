<template>
  <div>
    <div class="container">
        <div class="left-col column">
            <div class="title">
                {{itemsTitle}}
            </div>
            <div>
                <select size="10" multiple class="select" v-model="highlightedItems">
                    <option v-for="(item, index) in sortedInternalItems" :key="index" :value="item">
                        {{item[itemsName]}}
                    </option>
                </select>
            </div>
        </div>
        <div class="icons">
            <div class="arrow-container">
                <font-awesome-icon icon="angle-right" class="arrow" @click="moveRight" />
            </div>
            <div class="arrow-container">
                <font-awesome-icon icon="angle-left" class="arrow" @click="moveLeft"/>
            </div>            
        </div>
        <div class="right-col column">
            <div class="title">
                {{selectedTitle}}
            </div>
            <div>
                <select size="10" multiple class="select" v-model="highlightedSelected">
                    <option v-for="(item, index) in sortedInternalSelected" :key="index" :value="item">
                        {{item[selectedName]}}
                    </option>
                </select>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "DualListComponent",
    components: {
    },
    props: {
        itemsTitle: {
            type: String,
            required: true,
        },
        selectedTitle: {
            type: String,
            required: true,
        },
        itemsName: {
            type: String,
            required: true,
        },
        selectedName: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        selected: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            internalItems: [], 
            internalSelected: [], 
            highlightedItems: [],
            highlightedSelected: []
        }
    },
    computed: {
        sortedInternalItems() {
            return this.sort(this.internalItems)
        },
        sortedInternalSelected() {
            return this.sort(this.internalSelected)
        }
    },
    watch: {
        items() {
            this.internalItems = this.items
        },
        selected() {
            this.internalSelected = this.selected
        },
        internalSelected() {
            this.$emit("setSelected", this.internalSelected)
        }
    },
    methods: {
        moveLeft() {
            if (this.highlightedSelected.length > 0) {
 
                this.highlightedSelected.forEach(highlightedItem => {
                    this.internalItems.push(highlightedItem)
                
                    let index = this.internalSelected.findIndex(item => item.id == highlightedItem.id)
                    if (index > -1) {
                        this.internalSelected.splice(index, 1)
                    }
                })
                
                this.clearHiglighted()
            }
        },
        moveRight() {
            if (this.highlightedItems.length > 0) {

                this.highlightedItems.forEach(highlightedItem => {

                    let index = this.internalItems.findIndex(item => item.id == highlightedItem.id)
                    if (index > -1) {
                        this.internalItems.splice(index, 1)
                    }

                    this.internalSelected.push(highlightedItem)


                })

                this.clearHiglighted()
            }
        },
        sort(list) {
            return list.sort((a, b) => a.name.localeCompare(b.name))
        },
        clearHiglighted() {
            this.highlightedItems = []
            this.highlightedSelected = []
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: space-between;
}
.column {
    width: 100%;
}
.icons {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.arrow-container {
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.arrow:hover {
    opacity: 60%;
    cursor: pointer;
}
.title {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}
.select {
    width: 100%;
}
</style>