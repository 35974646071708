<template>
    <div>
        <h3 class="ml-3">
            <font-awesome-icon icon="equals" class="nav-icon fa" /> Assignment
        </h3>
        <DynamicSearchComponent
            ref="assignmentSearchComponent"
            preferencesName="assignment_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />

        <Table
            id="softwareTable"
            :fields="fields"
            :items="software"
            sortBy=""
            :sortDesc="false"
            :searchPagination="searchPagination"
            @getItems="getSoftware"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #cell(vm_id)="data">
                <a href="#" @click="kioskLink(data.item.id)" class="pointer underline">
                    {{ data.item.vm_id }}
                </a>
            </template>
            <template #cell(created_on)="row">
                {{ formatDate(convertUTCtoLocalDate(row.item.created_on)) }}
            </template>
        </Table>
        <ExportFileComponent
            :arguments="this.arguments.query"
            class="export"
            :disabled="false"
            page="assignment"
        />
    </div>
</template>

<script>
import { SoftwareService } from "../../services"
import SearchPagination from '../../models/search.pagination'
import { Table, DynamicSearchComponent, ExportFileComponent } from "../../components/"
import { formatDate, kioskLink, convertUTCtoLocalDate } from '../../utils/utils'

export default {
    name: 'Assignment',
    components: {
        Table,
        DynamicSearchComponent,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'vm_id',
                    label: 'Vmid',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'current_main_firmware',
                    label: 'Current Main Firmware',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'current_pneu_firmware',
                    label: 'Current Pneu Firmware',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'current_media_firmware',
                    label: 'Current Media Firmware',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'current_relay_firmware',
                    label: 'Current Relay Firmware',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'current_payment_firmware',
                    label: 'Current Payment Firmware',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'current_payment_terminal_firmware',
                    label: 'Current Payment Terminal Firmware',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'current_touch_screen_firmware',
                    label: 'Current Touch Screen Firmware',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store.description',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'installed',
                    name: 'Installed',
                    type: 'Boolean',
                    searchQueryName: 'installed',
                },
                {
                    key: 'enabled',
                    name: 'Enabled',
                    type: 'Boolean',
                    searchQueryName: 'enabled',
                },
            ],
            software: [],
            arguments: [],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
        };
    },
    mounted() {
        this.getSoftware();
    },
    methods: {
        getSoftware() {
            this.$refs.assignmentSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.isLoading = true;
            this.arguments = args;
            SoftwareService.listAssignment(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then(
                (response) => {
                if (response) {
                    this.software = response.data.items;
                        this.searchPagination.totalRows =
                            this.software.length > 0
                                ? response.data.total_rows
                                : 0;
                    this.isLoading = false;
                    args.done();
                }
            },
            (error) => {
                console.log(error);
                this.$root.$bvToast.toast(`Error: ${error}`, {
                        title: 'Download failed',
                        variant: 'danger',
                        solid: true,
                    });
            }
            );
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getSoftware()
        },
        formatDate,
        kioskLink,
        convertUTCtoLocalDate
    }
}
</script>
<style scoped>
.wrap-colum {
    overflow-wrap: anywhere;
}
.underline {
    text-decoration: underline;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>