<template>
    <b-form-group id="discount-type-select-group" label="Discount Type" label-for="discount-type">
        <b-form-select 
        id="discount-type" 
        v-model="selected" 
        :disabled="this.disabled" 
        :state="state">
            <option v-for="type in discountTypes" :value="type.id" :key="type.id">
                {{ type.name }}
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
import { PromosService } from '../services'

export default {
    name: "DiscountTypeSelect",
    props: {
        value: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        state: {
            type: Boolean,
            required: false
        },
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            discountTypes: []
        }
    },
    mounted() {
        this.getDiscountTypes()
    },
    methods: {
        getDiscountTypes() {
            PromosService.getDiscountTypes().then((response)=>{
                if (response) {
                    this.discountTypes = response.data
                }
            })
        }
    }
}
</script>
<style scoped>
</style>