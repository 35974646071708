<template>
    <div class="row row-cols-3 row-cols-md-3 g-4">
        <div v-if="useOverride" class="col-md-2">
            <b-form-checkbox v-model="override" name="toggle-override" size="md" switch :disabled="disabled" @change="toggleOverride">
                Override
            </b-form-checkbox>
        </div>
        <div class="col">
            {{config.description }}
        </div>
        <div class="col">
            <div class="p-1">
                <template v-if="!config.is_list">
                    <b-form-input
                        v-bind:id="config.name"
                        v-model="config.value"
                        :disabled="this.canEdit">
                    </b-form-input>
                </template>
                <template v-else>
                    <b-form-select
                        v-bind:id="config.name"
                        v-model="config.value"
                        :disabled="this.canEdit">
                        <option v-for="opt in config.options" :value="opt.item1" :key="opt.item1">
                            {{ opt.item2 }}
                        </option>
                    </b-form-select>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "UnitConfigurationItem",
    props: {
        config: {
            type: Object,
            required: true
        },
        overridden: {
            type: Boolean,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        useOverride: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        canEdit() {
            if (this.disabled)
                return true
            else
                return !this.override
        }
    },
    data() {
        return {
            override: this.overridden,
            initialOverridden: false
        }
    },
    mounted() {
        this.initialOverridden = this.overridden
    },
    methods: {
        toggleOverride() {
            this.config.is_deleted = !this.override
            this.config.is_overridden = this.override
        }
    }
}

</script>

<style scoped>

</style>