<template>
    <div class="carousel-outter-frame">
        <table class="carousel-frame">
            <span>{{this.carousel.name}}</span>
            <br />
            <span>Propane Level: {{this.carousel.analog_2}}</span>
            <div class="kiosk">
                <div class="left-door" :class="{ open: this.getLeftDoorOpen() }">
                    <div class="touchscreen" v-if="isHost">
                    </div>
                </div>
                <tbody class="inventory-block">
                    <CarouselTierComponent v-for='tier in this.carousel.tiers' :carousel="carousel" :tier="tier" :key='tier'/>
                </tbody>
                <div class="right-door" :class="{ open: this.getRightDoorOpen() }"></div>
            </div>
        </table>
    </div>
</template>

<script>

import CarouselTierComponent from './CarouselTierComponent'

export default {
    name: "Carousel",
    props: {
        carousel: {
            type: Object,
            required: true
        },
        isHost: {
            type: Boolean,
            required: true
        },
    },
    components: {
        CarouselTierComponent  
    },
    methods: {
        getLeftDoorOpen() {
            if (this.carousel.doors) {
                for (var i = 0; i < this.carousel.doors.length; ++i) {
                    let door = this.carousel.doors[i]
                    if (door.tier == -1) {
                        return door.open
                    }
                }
            }
            return false
        },
        getRightDoorOpen() {
            if (this.carousel.doors) {
                for (var i = 0; i < this.carousel.doors.length; ++i) {
                    let door = this.carousel.doors[i]
                    if (door.tier == -2) {
                        return door.open
                    }
                }
            }
            return false
        }
    }, 
    mounted() {
    }
}
</script>

<style scoped>

.inventory-block {
    width: auto;
    display: inline-block;
    vertical-align: top;
}
.carousel-frame {
    width: auto;
    margin: auto; 
    margin-bottom: 8px;
}
.carousel-outter-frame {
    width: auto;
    margin: auto; 
    margin-bottom: 8px;
}
.left-door {
    background-color: rgb(182, 192, 196);
    width: 100px;
}
.right-door {
    background-color: rgb(182, 192, 196);
    width: 100px;
}
.touchscreen {
    width: 80px;
    height: 60px;
    top: 30px;
    left: 10px;
    position: relative;
    background-color: rgb(10, 10, 143);
    border-style: solid;
    border-width: 8px;
    border-color: black;
}
.open {
    background-color: rgba(255, 0, 0, 0.15);
    border-style: solid;
    border-color: red;
    border-width: 2px;
}
.kiosk {
  border: 4px solid rgb(109, 115, 117);
  width:100%;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
    .left-door {
        background-color: rgb(182, 192, 196);
        width: 20px;
    }
    .right-door {
        background-color: rgb(182, 192, 196);
        width: 20px;
    }
    .touchscreen {
        display: none;
    }
}
</style>