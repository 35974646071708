<template>
    <div>
        <b-tabs lazy>
            <b-tab title="Bulk Import">
                <BulkImportStores @hide="hide"/>
            </b-tab>
            <b-tab title="Manual Import">
                <AddStoreComponent @hide="hide"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>

import { AddStoreComponent, BulkImportStores } from './index'

export default {
    name: 'AddStoreTabs',
    components: {
        AddStoreComponent,
        BulkImportStores,
    },
    methods: {
        hide() {
            this.$emit('hide')
        }
    }
}
</script>

<style scoped>
</style>