<template>
    <div class="table-container">
        <template>
            <h5>Phase</h5>
            <p v-if="phase > 0">Phase {{ phase }}</p>
            <p v-else>No phase assigned</p>
        </template>
        <template v-if="kioskTags.length > 0">
            <h5>Kiosk Tags</h5>
            <b-table
                class="table-striped"
                :field="kioskFields"
                :items="kioskTags"
                response="true"
                stacked="sm"
            >
            </b-table>
        </template>
        <template v-else>
            <h5>No Tags Assigned to Kiosk</h5>
        </template>
        <template>
            <button
                @click="openPhaseModal()"
                class="btn btn-primary float-right my-3 ml-3"
                v-if="security.phaseCreate"
                >
                {{ phaseAction }} Phase Tag
            </button>
            <button
                @click="openAddModal()"
                class="btn btn-primary float-right my-3"
                v-if="security.tagCreate"
                >
                Assign Firmware Tags
            </button>
        </template>
        <AssignTagModal :kioskId="kioskId" :firmwareId="0" @hide="closeAddModal" />

        <b-modal id="tag-phase-modal" ref="tag-phase-modal" hide-footer>
            <template #modal-title>
                <h3>{{ phaseAction }} phase</h3>
            </template>
            <PhaseModal
                :kioskId="kioskId"
                :phase="phase"
                @hide="hidePhaseModal"
            />
        </b-modal>
    </div>
</template>

<script>
import TagService from '../../services/tag.service';
import { AssignTagModal, PhaseModal } from '../tags';

export default {
    name: 'Tag',
    components: {
        AssignTagModal,
        PhaseModal,
    },
    props: {
        kioskId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            kioskFields: [
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            kioskTags: [],
            phase: 0,
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        phaseAction() {
            return this.phase > 0 ? 'Update' : 'Add';
        },
        kioskTagsAction() {
            return this.kioskTags.length > 0 ? 'Update' : 'Add';
        },
    },
    mounted() {
        this.refresh()
    },
    methods: {
        refresh() {
            this.getTags();
            this.getPhase();
        },
        getTags() {
            this.loading = true;
            TagService.kioskTags(this.kioskId).then((response) => {
                if (response) {
                    this.kioskTags = response.data;
                }
            });
            this.loading = false;
        },
        getPhase() {
            this.loading = true;
            TagService.getPhase(this.kioskId).then((response) => {
                if (response) {
                    this.phase = +response.data.name;
                }
            });
            this.loading = false;
        },
        openAddModal() {
            this.$bvModal.show('tag-assign-modal');
        },
        closeAddModal() {
            this.$bvModal.hide('tag-assign-modal');
            this.getTags();
            this.getPhase();
        },
        openPhaseModal() {
            this.$bvModal.show('tag-phase-modal');
        },
        hidePhaseModal() {
            this.$bvModal.hide('tag-phase-modal');
            this.getTags();
            this.getPhase();
        },
    },
};
</script>
<style scoped>
</style>