<template>
    <div>
        <b-dropdown
            split
            :text="exportOption"
            v-on:click="exportToFile(selectedExportType)"
            class="mt-2"
            variant="outline-primary"
        >
            <b-dropdown-item
                v-for="(option, index) in exportOptions"
                :key="index"
                v-on:click="updateExport(option)"
            >
                {{ option.name }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>
<script>
import {
    UptimeService,
    CommissionService,
    SalesService,
    UserPreferencesService,
    KioskService,
    KiosksService,
    StoresService,
    SoftwareService,
    TransactionsService,
    ProductsService,
    ServantsService,
UsersService
} from '../services/';
import { saveAs } from 'file-saver';

export default {
    name: 'ExportFileComponent',
    props: {
        arguments: {
            type: Object,
        },
        disabled: {
            type: Boolean,
        },
        page: {
            type: String,
            require: true,
        },
        searchPagination: {
            type: Object,
        },
    },
    data() {
        return {
            selectedExportType: 'csv',
            exportOption: 'Export to CSV',
        };
    },
    mounted() {
        this.getFilePreference();
    },
    computed: {
        exportOptions() {
            return [
                { name: 'Export to CSV', extension: 'csv' },
                { name: 'Export to XLSX', extension: 'xlsx' },
            ];
        },
    },
    methods: {
        updateExport(option) {
            this.selectedExportType = option.extension;
            this.exportOption = option.name;
            UserPreferencesService.put({
                type: 'file_default',
                name: 'File Search Preference',
                default: true,
                value: option.extension,
            });
        },
        exportToFile(fileType) {
            if (this.page === 'kioskUptime') {
                if (fileType === 'csv') {
                    this.displayToast()
                    UptimeService.downloadUptimeCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    UptimeService.downloadUptimeXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'regionUptime') {
                if (fileType === 'csv') {
                    this.displayToast()
                    UptimeService.downloadRegionUptimeCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    UptimeService.downloadRegionUptimeXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'sales') {
                if (fileType === 'csv') {
                    this.displayToast()
                    SalesService.downloadCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    SalesService.downloadXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        }
                    );
                }
            } else if (this.page == 'commission') {
                if (fileType == 'csv') {
                    this.displayToast()
                    CommissionService.downloadCommissionCsv(
                        this.arguments
                    ).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    CommissionService.downloadCommissionXlsx(
                        this.arguments
                    ).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'issues') {
                if (fileType === 'csv') {
                    this.displayToast()
                    KioskService.downloadIssuesCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    KioskService.downloadIssuesXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'issuesHistory') {
                if (fileType === 'csv') {
                    this.displayToast()
                    KioskService.downloadIssuesHistoryCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    KioskService.downloadIssuesHistoryXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'assignment') {
                if (fileType === 'csv') {
                    this.displayToast()
                    SoftwareService.downloadAssignmentCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    SoftwareService.downloadAssignmentXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'stores') {
                if (fileType === 'csv') {
                    this.displayToast()
                    StoresService.downloadCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    StoresService.downloadXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'kiosks') {
                if (fileType === 'csv') {
                    this.displayToast()
                    KiosksService.downloadCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    KiosksService.downloadXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'transactions') {
                if (fileType === 'csv') {
                    this.displayToast()
                    TransactionsService.downloadCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    TransactionsService.downloadXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'refunds') {
                if (fileType === 'csv') {
                    this.displayToast()
                    TransactionsService.downloadRefundsCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    TransactionsService.downloadRefundsXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'products') {
                if (fileType === 'csv') {
                    this.displayToast()
                    ProductsService.downloadCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    ProductsService.downloadXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'prices') {
                if (fileType === 'csv') {
                    this.displayToast()
                    ProductsService.downloadPricesCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    ProductsService.downloadPricesXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            } else if (this.page === 'servants') {
                if (fileType === 'csv') {
                    this.displayToast()
                    ServantsService.downloadServantsCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    ServantsService.downloadServantsXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            }
            else if (this.page === 'servants') {
                if (fileType === 'csv') {
                    this.displayToast()
                    ServantsService.downloadCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    ServantsService.downloadXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            }
            else if (this.page === 'users') {
                if (fileType === 'csv') {
                    this.displayToast()
                    UsersService.downloadCsv(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else if (fileType === 'xlsx') {
                    this.displayToast()
                    UsersService.downloadXlsx(this.arguments).then(
                        (response) => {
                            if (response) {
                                const blob = new Blob([response.data], {
                                    type: response.headers['content-type'],
                                });
                                saveAs(
                                    blob,
                                    response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split(';')[0]
                                );
                            }
                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                }
            }
        },
        getFilePreference() {
            UserPreferencesService.list('file_default').then(
                (response) => {
                    if (response && response.data.length > 0) {
                        this.selectedExportType = response.data[0].value;
                        this.exportOptions.forEach((option) => {
                            if (option.extension == response.data[0].value) {
                                this.exportOption = option.name;
                                return;
                            }
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        displayToast() {
            this.$root.$bvToast.toast(`Exporting ${this.selectedExportType} file. File will download momentarily`, {
                title: "Export In Progress",
                variant: 'success',
                solid: true
            })
        }
    },
};
</script>
<style scoped>
::v-deep .b-dropdown ul.dropdown-menu {
    max-height: 200px !important;
    overflow-y: auto !important;
    min-width: 100% !important;
    max-width: fit-content;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .b-dropdown > button:first-child {
        width: 60%
    }
}
</style>