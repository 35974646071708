<template>
    <Doughnut
        :chart-options="chartOptions"
        :chart-data="formattedChartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut,
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    chartData: {
        type: Array,
        require: true
    },
    labelName: {
        type: String,
        require: true
    },
    dataName: {
        type: String,
        require: true
    },
    colours: {
        type: Array,
        require: false
    },
    showLegend: {
        type: Boolean,
        require: false,
        default: true
    },
    showTooltip: {
        type: Boolean,
        require: false,
        default: true
    },
    hideLabelsWithoutData: {
        type: Boolean,
        require: false,
        default: false
    },
    colourName: {
        type: String,
        require: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
          legend: {
            display: this.showLegend
          },
          tooltip: {
            enabled: this.showTooltip
          }
        }
      }
    }
  },
  computed: {
    formattedChartData() {
        var labels = [];
        var data = [];
        var customColours = this.colourName != undefined ? [] : this.colours;
        var defaultColours = ['#41B883', '#E46651', '#00D8FF', '#DD1B16', '#EF1B16', '#94DA28', '#1FA0F4', '#1913EA', '#4A4247', '#F80808', '#460341', '#10E6D6', '#BAC4B5', '#E91616'];
        if (this.chartData) {
            this.chartData.forEach(element => {
              if (this.hideLabelsWithoutData) {
                if (element[this.dataName] > 0) {
                  this.setData(element, labels, data, customColours)
                }
              }
              else {
                this.setData(element, labels, data, customColours)
              }
            });
        }
        return {
            labels: labels,
            datasets: [
                {
                    backgroundColor: customColours ?? defaultColours,
                    data: data
                }
            ]
        };
    },
  },
  methods: {
    setData(element, labels, data, colours) {
      labels.push(element[this.labelName]);
      data.push(element[this.dataName]);

      if (this.colourName != undefined) { 
        colours.push(element[this.colourName]);
      }
    }
  }
}
</script>