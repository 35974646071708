<template>
    <div>
        <span v-if="lastUpdated" class="last_updated">Kiosk up to date since {{getTimeOnly12Hours(lastUpdated)}}</span>
        <h3>
            <font-awesome-icon icon="cash-register" class="nav-icon fa" /> Kiosk
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf2c')" class="help-icon nav-icon fa" title="Sleegers User Guide - Kiosk"/>
        </h3>
        <GeneralComponent :kioskId="kioskId" ref="kioskGeneral" @notifyRefresh="notifyRefresh"/>

        <b-form-select
            id="tab-select"
            v-model="tabIndex"
            class="mb-3" 
            v-if="isMobile"
        >
            <option v-for="(tab, index) in tabs" :value="index" :key="index">
                {{ tab }}
            </option>
        </b-form-select>

    <b-card no-body v-if="security.hasKioskDetails">
        <b-tabs content-class="mt-4" lazy :vertical="false" card v-model="tabIndex" :nav-wrapper-class="tabStyling">
            <b-tab title="Details" v-if="security.kioskDetailsRead">
                <DetailsComponent :kioskId="kioskId" ref="kioskDetails" @refresh="refresh" />
            </b-tab>
            <b-tab title="Extended Details" v-if="security.kioskExtendedDetailsRead">
                <ExtendedDetailsComponent :kioskId="kioskId" ref="kioskExtendedDetails"/>
            </b-tab>
            <b-tab title="Inventory" v-if="security.kioskInventoryRead">
                <InventoryComponent :kioskId="kioskId" ref="kioskInventory"/>
            </b-tab>
            <b-tab title="Issues" v-if="security.kioskIssuesRead">
                <IssuesComponent :kioskId="kioskId" ref="kioskIssues"/>
            </b-tab>
            <b-tab title="Issues History" v-if="security.kioskIssuesRead">
                <IssuesHistoryComponent :kioskId="kioskId" ref="kioskIssuesHistory"/>
            </b-tab>
            <b-tab title="Diagnostics and Maintenance" v-if="security.kioskDiagnosticMaintenanceRead">
                <DiagnosticsComponent :kioskId="kioskId" ref="kioskDiagnostics"/>
            </b-tab>
            <b-tab title="Unit Configuration" v-if="security.kioskConfigurationRead">
                <UnitConfigurationComponent :kioskId="kioskId" ref="kioskUnitConfiguration"/>
            </b-tab>
            <b-tab title="Firmware" v-if="security.firmwareRead">
                <AssignedFirmwareComponent :kioskId="kioskId" ref="kioskAssignedFirmware"/>
            </b-tab>
            <b-tab title="Remote Access" v-if="security.relayRead">
                <RemoteAccessComponent :kioskId="kioskId" ref="kioskRemoteAccess"/>
            </b-tab>
            <b-tab title="Trust" v-if="security.kioskTrustRead">
                <TrustComponent :kioskId="kioskId" ref="kioskTrust"/>
            </b-tab>
            <b-tab title="QR Code" v-if="security.kioskQRCodeRead">
                <QRCodeComponent :kioskId="kioskId" ref="kioskQrCode"/>
            </b-tab>
            <b-tab title="Tags" v-if="security.tagRead">
                <TagComponent :kioskId="kioskId" ref="kioskTag"/>
            </b-tab>
            <b-tab title="Servants" v-if="security.servantRead">
                <ServantsComponent :kioskId="kioskId" ref="kioskServants"/>
            </b-tab>
        </b-tabs>
  </b-card>
    </div>
</template>

<script>
import {
    GeneralComponent,
    DetailsComponent,
    ExtendedDetailsComponent,
    InventoryComponent,
    IssuesComponent,
    DiagnosticsComponent,
    UnitConfigurationComponent,
    IssuesHistoryComponent,
    AssignedFirmwareComponent,
    RemoteAccessComponent,
    TrustComponent,
    QRCodeComponent,
    TagComponent,
    ServantsComponent
} from '../../components/kiosk';

import { getTimeOnly12Hours, openHelpWindow } from '../../utils/utils'

export default {
    name: 'Kiosk',
    components: {
        GeneralComponent,
        DetailsComponent,
        ExtendedDetailsComponent,
        InventoryComponent,
        IssuesComponent,
        IssuesHistoryComponent,
        DiagnosticsComponent,
        UnitConfigurationComponent,
        AssignedFirmwareComponent,
        RemoteAccessComponent,
        TrustComponent,
        QRCodeComponent,
        TagComponent,
        ServantsComponent
    },
    computed:{
        security(){
            return this.$store.state.auth.security
        },
        isMobile() {
            return this.$vuetify.breakpoint.width <= 750
        },
        tabStyling() {
            return this.isMobile ? 'd-none' : 'false'
        }
    },
    data() {
        return {
            kioskId: this.$route.query.kiosk_id,
            tabIndex: 0,
            tabs: [
                "Details",
                "Extended Details",
                "Inventory",
                "Issues",
                "Issues History",
                "Diagnostics and Maintenance",
                "Unit Configuration",
                "Firmware",
                "Remote Access",
                "Trust",
                "QR Code",
                "Tags",
                "Servants",
                ],
            lastUpdated: null
        };
    },
    mounted() {
        this.lastUpdated = new Date()
    },
    methods: {
        refresh() {
            this.$refs["kioskGeneral"].getGeneral();
        },
        notifyRefresh() {
            this.$refs["kioskGeneral"].getGeneral();

            switch(this.tabIndex) {
                case 0:
                    this.$refs["kioskDetails"].getDetails()
                    break
                case 1:
                    this.$refs["kioskExtendedDetails"].getExtendedDetails()
                    break
                case 2:
                    this.$refs["kioskInventory"].getInventory()
                    break
                case 3:
                    this.$refs["kioskIssues"].getIssues()
                    break
                case 4:
                    this.$refs["kioskIssuesHistory"].getEventBlocks()
                    break
                case 5:
                    this.$refs["kioskDiagnostics"].refresh()
                    break
                case 6:
                    this.$refs["kioskUnitConfiguration"].getConfiguration()
                    break
                case 7:
                    this.$refs["kioskAssignedFirmware"].getFirmware()
                    break
                case 8:
                    this.$refs["kioskRemoteAccess"].refresh()
                    break
                case 9:
                    this.$refs["kioskTrust"].getTrust()
                    break
                case 10:
                    this.$refs["kioskQrCode"].getQRCode()
                    break
                case 11:
                    this.$refs["kioskTag"].refresh()
                    break
                case 12:
                    this.$refs["kioskServants"].getServants()
                    break
                default:
            }

            this.lastUpdated = new Date()
        },
        getTimeOnly12Hours,
        openHelpWindow,
    }
};
</script>

<style scoped>
.card-body {
    padding-top: 0px;
}

.last_updated {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    opacity: 60%;
    font-size: 0.8rem;
    margin-top: -1rem;
}

@media only screen and (max-width: 575.5px) {
    .last_updated {
        font-size: 1rem;
    }
}
</style>