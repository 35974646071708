<template>
    <div>
        <h3><font-awesome-icon icon="fa-face-smile" class="nav-icon" />  Free Item</h3>
        <div class="button-group">
            <div class="button-col">
                <b-button @click="showAddFreeItemCodeModal" variant="primary" class="button" v-if="security.promoCreate">Add</b-button>
            </div>
        </div>
        <div class="pb-4">
            <Table
              id="freeItemTable"
              :fields="dynamicFields"
              :items="freeItemCodes"
              sortBy="code"
              :sortDesc="true"
              :searchPagination="searchPagination"
              @getItems="listFreeItemCodes"
              @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="data">
                    <div class="icons">
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" @click="showRemoveCodeModal(data.item)" v-if="security.promoDelete" title="Delete"/>
                    </div>
                </template>
                <template #cell(price)="data">
                    {{ formatPrice(data.item.price) }}
                </template>
                <template #cell(issued)="data">
                    {{ formatDate(convertUTCtoLocalDate(data.item.issued)) }}
                </template>
                <template #cell(endDate)="data">
                    {{ formatDate(convertUTCtoLocalDate(data.item.endDate)) }}
                </template>

            </Table>
        </div>
        <PromoExportFileComponent
            class="export"
            :disabled="false"
            :promoType="PROMO_TYPE_ENUM.FreeItem"
        />
        <b-modal id="add-free-item-code" ref="add-free-item-code" hide-footer>
            <template #modal-title>
               Free Item Code - New 
            </template>
            <AddFreeItemCode @hide="hideAddFreeItemCodeModal"/>
        </b-modal>
        <b-modal id="remove-code" ref="remove-code">
            <template #modal-title>
               Remove Free Item
            </template>
            <div class="d-block">
                <p>You are about to remove item #{{selectedFreeItemCode.code}}</p>
                <p>Do you want to proceed?</p>
            </div>
            <template #modal-footer>
                <button class="btn btn-danger" @click="removeCode(selectedFreeItemCode)">
                    Remove
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>

import SearchPagination from '../../models/search.pagination'
import { Table, PromoExportFileComponent } from "../../components/"
import AddFreeItemCode from "./AddFreeItemCode.vue"
import PromosService from "../../services/promos.service" 
import PROMO_TYPE_ENUM from '../../models/PromoTypeEnum'
import { formatDate, formatPrice, generateDynamicFields, convertUTCtoLocalDate } from '../../utils/utils'

export default {
    name: "FreeItem",
    components: {
        Table,
        AddFreeItemCode,
        PromoExportFileComponent
    },
    data() {
        return {
            freeItemCodes: [],
            selectedFreeItemCode: {},
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'code',
                    label: 'Code',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'comment',
                    label: 'Provided To',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'location',
                    label: 'Location',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'issued',
                    label: 'Issued',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 13
                },
                {
                    key: 'endDate',
                    label: 'End Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 11
                },
                {
                    key: 'creditCard',
                    label: 'Credit Card',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 8
                },
                {
                    key: 'valid',
                    label: 'Valid',
                    tdClass: 'column',
                    sortable: false
                },
            ],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
            PROMO_TYPE_ENUM
        };
    },
    computed:{
        security(){
            return this.$store.state.auth.security
        },
        dynamicFields() {
            return generateDynamicFields(this.fields, 'actions', !this.security.promoDelete)
        }
    },
    mounted() {
        this.listFreeItemCodes()
    },
    methods: {
        listFreeItemCodes() {
            PromosService.listCoupons(
                PROMO_TYPE_ENUM.FreeItem,
                this.searchPagination.startIndex, 
                this.searchPagination.pageSize, 
                this.sortOrder
                ).then((response) => {
                if (response) {
                    this.freeItemCodes = response.data.items
                    this.searchPagination.totalRows = this.freeItemCodes.length > 0 ? response.data.total_rows : 0
                }
            })
        },
        showAddFreeItemCodeModal() {
            this.$refs["add-free-item-code"].show()
        },
        hideAddFreeItemCodeModal() {
            this.$refs["add-free-item-code"].hide()
            this.listFreeItemCodes()
        },
        showRemoveCodeModal(freeItemCode) {
            this.selectedFreeItemCode = freeItemCode
            this.$refs["remove-code"].show()
        },
        removeCode() {
            PromosService.removeCode(
                this.selectedFreeItemCode.id
                ).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                    this.hideRemoveCodeModal();
                    this.listFreeItemCodes();
                }
            })
        },
        hideRemoveCodeModal() {
            this.$refs["remove-code"].hide()
            this.listFreeItemCodes()
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.listFreeItemCodes();
        },
        formatDate,
        formatPrice,
        convertUTCtoLocalDate
    }
}
</script>
<style scoped>

::v-deep .action-column {
    min-width: 30px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 0.5rem;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>