import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils"

class CodeActivityService {
    searchWithQuery(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/code_activity/search_with_query',
            headers: authHeader(),
            data: {
                vmid: query.vmid,
                vendor: query.vendor,
                code: query.code,
                code_state: query.state,
                activity_type: query.type,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    vendors() {
        return axios({
            method: 'get',
            url: '/api/code_activity/vendors',
            headers: authHeader()
        })
    }

    states() {
        return axios({
            method: 'get',
            url: '/api/code_activity/states',
            headers: authHeader()
        })
    }

    types() {
        return axios({
            method: 'get',
            url: '/api/code_activity/types',
            headers: authHeader()
        })
    }
}

export default new CodeActivityService();