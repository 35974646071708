<template>
    <b-card :class="{ 'disable-card' : locked}">
        <b-card-title class="title-group">
            <h4>Phase {{ phase.phase }}<font-awesome-icon icon="lock" class="pl-3" v-if="locked"/></h4>
            <div class="icon-group">
                <font-awesome-icon icon="trash-can" class="right phase-icon mr-3" :class="[locked ? 'not-allowed' :'pointer']" @click="!locked ? openDeletePhaseModal() : undefined" v-if="security.phaseDelete" title="Delete"/>
                <font-awesome-icon icon="arrow-trend-up" class="right phase-icon mr-3 pointer" @click="openKioskPromotionModal()" title="Kiosks"/>
                <font-awesome-icon icon="pen-to-square" class="right phase-icon" :class="[locked ? 'not-allowed' :'pointer']" @click="!locked ? openEditPhaseModal() : undefined" v-if="security.phaseWrite" title="Edit"/>
            </div>
        </b-card-title>
        <hr/>
        <div class="phase-group">
            <div class="info">
            <ul>
                <li>Update On: {{formatDate(convertUTCtoLocalDate(phase.update_on))}}</li>
                <li>Hours until update: {{formatTimeUntilUpdate(phase.time_until_update)}}</li>
                <li>Number of kiosks: {{numKiosk}}</li>
            </ul>
            
            </div>
            <div class="graph-group">
                <DonutGraphComponent 
                    :chartData="progress" 
                    labelName="name" 
                    dataName="value"
                    colourName="colour"
                    class="graph"
                />
            </div>
        </div>
        <b-modal id="promotion-modal" ref="promotion-modal" size="xl" hide-footer>
            <template #modal-title>
                <font-awesome-icon icon="arrow-trend-up" class="nav-icon fa" />  Promotion - Phase {{ phase.phase }}
            </template>
            <KioskPromotion :phase="phase" :firmware="firmware" :locked="locked" @hide="hideKioskPromotionModal"/>
        </b-modal>
        <b-modal id="edit-phase" ref="edit-phase" hide-footer>
            <template #modal-title>
               Edit Phase
            </template>
            <AddEditPhase :phase="phase" :firmware="firmware" :isEdit="true" @hide="hideEditPhaseModal"/>
        </b-modal>
        <b-modal id="delete-phase" ref="delete-phase" hide-footer>
            <template #modal-title>
                Delete Phase?
            </template>
            <p>Are you sure you want to delete phase {{ phase.phase }} for {{ firmware.hardware_type }} ({{firmware.version}})?</p>
            <p>All kiosks belonging to the phase will not be promoted</p>
            <div class="float-right">
                <button variant="" class="mt-3 btn btn-danger" @click="deletePhase">
                    Delete
                </button>
            </div>
        </b-modal>
    </b-card>
</template>

<script>
import { DonutGraphComponent } from "../../components"
import AddEditPhase from "../../components/firmware/AddEditPhase.vue"
import KioskPromotion from "../../components/firmware/KioskPromotion.vue"
import { SoftwareService } from "../../services"
import { formatDate, convertUTCtoLocalDate } from "../../utils/utils"

export default {
    name: "Phase",
    components: {
        DonutGraphComponent,
        AddEditPhase,
        KioskPromotion
    },
    props: {
        phase: {
            type: Object,
            require: true
        },
        firmware: {
            type: Object,
            require: true
        },
        locked: {
            type: Boolean,
            require: true
        },
    },
    data() {
        return {
            progress: [],
            numKiosk: 0
        }
    },
    computed: {
        security(){
            return this.$store.state.auth.security
        },
    },
    mounted() {
        this.getProgress()
    },
    methods: {
        formatDate,
        convertUTCtoLocalDate,
        getProgress() {
            this.isLoading = true;
            SoftwareService.getPhaseProgress(this.phase.id).then((response) => {
                if (response) {
                    this.progress = response.data.items
                    this.numKiosk = response.data.kiosks_in_phase
                    this.isLoading = false;
                }
            })
        },
        openKioskPromotionModal() {
            this.$refs["promotion-modal"].show()
        },
        hideKioskPromotionModal() {
            this.$refs["promotion-modal"].hide()
            this.refresh()
        },
        openEditPhaseModal() {
            this.$refs["edit-phase"].show()
        },       
        hideEditPhaseModal() {
            this.$refs["edit-phase"].hide()
            this.refresh()
        },
        refresh(){
            this.$emit("refresh")
        },
        openDeletePhaseModal() {
            this.$refs["delete-phase"].show()
        },       
        hideDeletePhaseModal() {
            this.$refs["delete-phase"].hide()
        },
        deletePhase() {
            SoftwareService.deletePhase(this.phase.id).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success?  'success' : 'danger',
                        solid: true
                    })
                    this.hideDeletePhaseModal();
                    this.refresh();
                }
            })
        }, 
        formatTimeUntilUpdate(timeUntilUpdate) {
            return !timeUntilUpdate.includes('-') ? timeUntilUpdate : 0  
        }
    }
}
</script>

<style scoped>
.title-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.icon-group {
    display: flex;
    align-items: center;
}
.phase-icon {
    font-size: 1rem;
}
.phase-group {
    display: flex;
}
.info {
    flex: 0 0 50%;
}
ul {
    list-style: none;
    padding-left: 0;
}
.graph-group {
    flex: 1;
    display: flex;
    justify-content: right;
}
.graph {
    max-width: 200px;
    max-height: 200px;  
}
.disable-card {
    filter: brightness(90%);
}

.not-allowed {
    cursor: not-allowed;
}

.modal-xl {
    max-width: 95%;
}

@media only screen and (max-width: 1000px) {
.info {
    flex: 0 0 100%;
}

.graph-group {
    flex: 1;
    display: flex;
    justify-content: center;
}  
}

</style>