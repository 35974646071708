import { render, staticRenderFns } from "./RegisterApiLoginModal.vue?vue&type=template&id=404a054f&scoped=true&"
import script from "./RegisterApiLoginModal.vue?vue&type=script&lang=js&"
export * from "./RegisterApiLoginModal.vue?vue&type=script&lang=js&"
import style0 from "./RegisterApiLoginModal.vue?vue&type=style&index=0&id=404a054f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "404a054f",
  null
  
)

export default component.exports