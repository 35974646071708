<template>
    <b-modal id="profile-disable-two-fa-modal">
        <template #modal-title>
            <h3>Disable Two Factor Authentication</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label>Input your password to disable two factor authentication</label>
                    </p>
                </div>
            </div>  
            <div class="row-fluid">
                <b-form-group id="password-input-group" label="Password" label-for="password">
                    <b-form-input 
                        id="password"
                        type="password"
                        v-model="password"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
        <template #modal-footer>
            <a id="users-modal-delete-user" @click="disableTwoFa()" class="btn primary">Send</a>
        </template>
    </b-modal>
</template>

<script>

import AuthService from "../../services/auth.service"

export default {
    name: "DisableTwoFaModal",
    emits: ['disableComplete'],
    data() {
        return {
            password: ""
        }
    },
    methods: {
        disableTwoFa(){
            AuthService.disableTwoFa(this.password ).then((response) => {
                if (response.data.success) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: 'Two Factor Authentication Disabled',
                        variant: 'success',
                        solid: true
                    })
                    this.message = "";
                    this.$emit('disableComplete');
                }
                else{
                    this.$root.$bvToast.toast(response.data.message, {
                        title: 'Two Factor Authentication Disable Error',
                        variant: 'danger',
                        solid: true
                    })
                }
            });
        }
    }
}
</script>