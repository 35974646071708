export default {
    common: {
        add: "Add",
        update: "Update",
        delete: "Delete"
    },
    claims: {
        remote_access_remote_ts: "Remote Access Remote Ts",
        remote_access_vnc: "Remote Access Vnc",
        remote_access_rvnc: "Remote Access Rvnc",
        software: "Software",
        technician: "Technician",
        audit: "Audit",
        code_activity: "Code Activity",
        commission_report: "Commission Report",
        driver_code: "Driver Code",
        events: "Events",
        file_archive_data: "File Archive Data",
        firmware: "Firmware",
        kiosks: "Kiosks",
        log_data: "Log Data",
        kiosk_trust: "Kiosk Trust",
        maintenance_logs: "Maintenance Logs",
        parts_request: "Parts Request",
        phase: "Phase",
        products: "Products",
        promo: "Promo",
        refunds: "Refunds",
        remote_access: "Remote Access",
        requires_two_fa: "Requires Two FA",
        role: "Role",
        sales: "Sales",
        stores: "Stores",
        tags: "Tags",
        transactions: "Transactions",
        transaction_logs: "Transaction Logs",
        uptime: "Uptime",
        user: "User",
        prices: "Prices",
        servant: "Servants",
        custom_fields: "Custom Fields",
        api_login: "API Login",
        regions: "Regions",
        portal_configuration: "Portal Configuration",
        excluded_columns: "Excluded Columns",
        kiosk_configuration: "Kiosk Configuration",
        kiosk_details: "Kiosk Details",
        kiosk_diagnostic_maintenance: "Kiosk Diagnostic Maintenance",
        kiosk_extended_details: "Kiosk Extended Details",
        kiosk_inventory: "Kiosk Inventory",
        kiosk_issues: "Kiosk Issues",
        kiosk_qrcode: "Kiosk QR Code"
    }
}