<template>
    <div>
        <h3>QR Code</h3>
        <template v-if="form.url.length > 0">
            <qrcode-vue :value="form.url" :size="150" level="H" />
            <a :href="form.url" target="_blank" style="margin-top: 4%">{{
                qrcodeData.url
            }}</a>
        </template>
        <template v-else>
            <h5>No QR Code Assigned to Kiosk</h5>
        </template>
    </div>
</template>

<script>
import KioskService from '../../services/kiosk.service';
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'QRCode',
    props: {
        kioskId: {
            type: String,
            required: true,
        },
    },
    components: {
        QrcodeVue,
    },
    data() {
        return {
            form: {
                url: '',
            },
            qrcodeData: {},
            loading: false,
        };
    },
    mounted() {
        this.getQRCode();
    },
    methods: {
        getQRCode() {
            this.loading = true;
            KioskService.qrcode(this.kioskId)
                .then((response) => {
                    if (response) {
                        this.qrcodeData = response.data;
                        this.form.url =
                            this.qrcodeData.url !== null
                                ? this.qrcodeData.url
                                : '';
                        this.loading = false;
                    }
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'KioskService.qrCode failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
</style>