<template>
    <div>
        <div class="mb-2">
            <a href="#" @click="selectAll()">
                Select All
            </a>
            |
            <a href="#" @click="clearAll()">
                Clear All
            </a>
        </div>
        <div v-for="eventGroup in eventGroupsWithChildren" :key="eventGroup.id">
            <b-form-checkbox
                :checked="isChecked(eventGroup.eventTypes)"
                :id="
                    'parent-checkbox-' +
                    eventGroup.description.toLowerCase().replace(' ', '')
                "
                :indeterminate="isIndeterminate(eventGroup.eventTypes)"
                @change="toggleChildren(eventGroup)"
            >
                {{ eventGroup.description }}
            </b-form-checkbox>
            <EventTypesNestedComponent
                :ref="'child-group-' + eventGroup.description.toLowerCase().replace(' ', '')"
                :allCheckboxes="eventGroup.eventTypes"
                :selectedCheckboxes="selected"
                @setSelectedCheckboxes="setSelectedEventTypes"
                class="indent"
            />
        </div>
        
    </div>
</template>

<script>
import EventTypesNestedComponent from './EventTypesNestedComponent.vue'
export default {
    name: "EventGroupNestedComponent",
    components: {
        EventTypesNestedComponent
    },
    props: {
        eventGroups: {
            type: Array
        },
        selected: {
            type: Array
        },
    },
    computed: {
        eventGroupsWithChildren() {
            return this.eventGroups.filter(eventGroup => eventGroup.eventTypes.length > 0)
        }
    },
    methods: {
        setSelectedEventTypes(value) {
            this.$emit('setSelectedCheckboxes', value)
        },
        toggleChildren(eventGroup) {
            var parentName = eventGroup.description
            const refName = 'child-group-' + parentName.toLowerCase().replace(' ', '')
            const checkboxGroupRef = this.$refs[refName]

            if (checkboxGroupRef.length > 0) {
                var newChildValue = false
                
                if (this.isIndeterminate(eventGroup.eventTypes)) {
                    newChildValue = false
                }
                else {
                    newChildValue = !this.isChecked(eventGroup.eventTypes)
                }

                checkboxGroupRef[0].toggleAllCheckboxes(newChildValue)
            }
            
        },
        isChecked(eventTypes) {
            var results = []
            
            eventTypes.forEach(eventType => {
                results.push(this.selected.includes(eventType.evType))
            });

            if (results.every(result => result)) {
                return true
            }
            else if (results.every(result => !result)) {
                return false
            }
            else {
                return true
            }
        },
        isIndeterminate(eventTypes) {
            var results = []
            
            eventTypes.forEach(eventType => {
                results.push(this.selected.includes(eventType.evType))
            });

            if (results.every(result => result)) {
                return false
            }
            else if (results.every(result => !result)) {
                return false
            }
            else {
                return true
            }
        },
        selectAll() {
            this.$emit('setSelectedCheckboxes', Array.prototype.concat.apply([], this.eventGroups.map(eventGroup => eventGroup.eventTypes.map(eventType => eventType.evType))))
        },
        clearAll() {
            this.$emit('setSelectedCheckboxes', [])
        },
    }
}
</script>

<style scoped>
.indent {
  margin-left: 25px;
}
</style>