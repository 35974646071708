<template>
    <b-form-group id="vmid" :label="fieldName" label-for="vmid-input">
        <b-input-group class="prepend-group">
            <template #prepend v-if="prependChar != ''">
                <b-input-group-text 
                class="prepend" 
                :class="{'prepend-valid' : state == true, 'prepend-error' : state == false, 'prepend-disabled': disabled }"
                >
                    {{prependChar}}
                </b-input-group-text>
            </template>
            <b-form-input
                id="vmid-input"
                v-model="vmidModel" 
                :class="{'vmid-text' : prependChar != ''}"
                :state="state"
                :disabled="disabled"
                type="number"
            >
            </b-form-input>
        </b-input-group>
    </b-form-group>
</template>
<script>
export default {
    name: 'VmidFieldComponent',
    components: {
    },
    props: {
        vmid: {
            type: [String, Number],
            required: true
        },
        isG3K: {
            type: Boolean,
            default: false
        },
        state:{
            type: Boolean,
            required: false,
            default: undefined
        },
        fieldName: {
            type: String,
            default: 'VMID'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            prefixChar: "H",
            internalVmid: 0,
        }
    },
    computed: {
        prependChar() {
            if (this.isG3K) {
                return this.prefixChar
            }
            else { 
                return ''
            }
        },
        vmidModel: {
            get() {
                return this.vmid

            },
            set(value) {
                this.setVmid(value)
            }
        },
    },
    watch: {
        vmid() {
            if (typeof(this.vmid) == 'string') {
                this.internalVmid = this.vmid.replace(/\D/g,'')
            }
            this.setVmid(this.internalVmid)
        },
        isG3K() {
            this.validate(this.internalVmid)
        }
    },
    methods: {
        setVmid(value) {
            this.validate(value)
            this.$emit("setVmid", value)
        },
        validate(value) {
            var valid = true
            var numVmidDigits = value.toString().length

            if (value <= 0 || (this.isG3K && numVmidDigits != 7) || (!this.isG3K && numVmidDigits != 6)) {
                valid = false
            }
            this.$emit("isValid", valid)
        },
    }
}
</script>
<style>
.prepend-group > .input-group-prepend > .prepend {
    background-color: white ;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding-right: 0px;
    padding-top: 0.45rem;
    padding-bottom: 0.325rem;
}

.prepend-group > .input-group-prepend > .prepend-valid {
    border-top: 1px solid #28a745;
    border-left: 1px solid #28a745;
    border-bottom: 1px solid #28a745;
    border-radius: 0.25rem;
    padding-right: 0px;
}

.prepend-group > .input-group-prepend > .prepend-error {
    border-top: 1px solid #dc3545;
    border-left: 1px solid #dc3545;
    border-bottom: 1px solid #dc3545;
    border-radius: 0.25rem;
    padding-right: 0px;
}

.prepend-group > .vmid-text {
    border-left: none;
    padding-left: 0px;
}

.prepend-group > .input-group-prepend > .prepend-disabled {
    background-color: #e9ecef !important;
    opacity: 1;
}
</style>
<style scoped>
/* Remove number field arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
