<template>
    <b-form-group id="kiosk-select-group" label="Kiosk" label-for="kiosk-select">
        <b-form-select 
        id="kiosk-select" 
        v-model="selected" 
        :disabled="this.disabled" 
        :state="state">
            <option :value="0" disabled>Select a kiosk</option>
            <option v-for="(kiosk, index) in kiosks" :value="kiosk.kioskId" :key="index">
                {{ kiosk.vmid }}
            </option>
        </b-form-select>
    </b-form-group>
</template>

<script>
    
import KiosksService from "../services/kiosks.service"

export default {
    name: "KioskSelect",
    props: {
        value: {
            type: Number,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false
        },
        state: {
            type: Boolean,
            required: false
        },
    },
    computed: {
        selected: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    data() {
        return {
            kiosks: [],
        }
    },
    mounted() {
        this.getKiosks()
    },
    methods: {
        getKiosks() {
            KiosksService.getAllVmid().then((response) => {
                if (response) {
                    this.kiosks = response.data.items
                }
            })
        }
    }
}
</script>

<style scoped>
</style>