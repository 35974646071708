<template>
    <b-modal id="product-delete-modal">
        <template #modal-title>
            <h3>Delete Product</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label>You are about to delete a product.</label>
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Do you want to proceed?</label>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a id="product-modal-delete-product" @click="deleteProduct()" class="btn btn-danger">Delete</a>
        </template>
    </b-modal>
</template>

<script>

import ProductsService from "../../../services/products.service"

export default {
    name: "DeleteProductModal",
    props: {
        productId: Number
    },
    emits: ['deleteComplete'],
    methods: {
        deleteProduct(){
            ProductsService.delete(this.productId
            ).then((response) => {
                if(response.data) {
                    this.$root.$bvToast.toast('Successful', {
                        title: 'Delete Product Successful',
                        variant: 'success',
                        solid: true
                    })
                }
                else {
                    this.$root.$bvToast.toast('Error', {
                        title: 'Error Updating Product',
                        variant: 'danger',
                        solid: true
                    })
                }

                this.$emit('deleteComplete', "Product Deleted")
            });
            
        }
    }
}
</script>