<template>
    <b-form>
        <div>
            <h5 style="padding-bottom: 1%">Last Failed</h5>
        </div>
        <div class="row row-cols-2 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group
                    id="lastdate-datepicker-group"
                    label="Date"
                    label-for="lastdate-datepicker"
                >
                    <b-form-input
                        id="lastdate-datepicker"
                        :value="formatDate(convertUTCtoLocalDate(form.failedDate))"
                        :disabled="true"
                        v-if="form.failedDate && form.failedDate != '0001-01-01T00:00:00'"
                    ></b-form-input>
                    <b-form-input
                        id="lastdate-datepicker-empty"
                        value=""
                        :disabled="true"
                        v-else
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="lasttype-input-group"
                    label="Type"
                    label-for="lasttype-input"
                >
                    <b-form-input
                        id="lasttype-input"
                        v-model="form.failedType"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
        <div>
            <h5 style="padding-bottom: 1%">Last Successful</h5>
        </div>
        <div class="row row-cols-2 row-cols-md-2 g-4">
            <div class="col">
                <b-form-group
                    id="successdate-datepicker-group"
                    label="Date"
                    label-for="successdate-datepicker"
                >
                    <b-form-input
                        id="successdate-datepicker"
                        :value="formatDate(convertUTCtoLocalDate(form.successDate))"
                        :disabled="true"
                        v-if="form.successDate && form.successDate != '0001-01-01T00:00:00'"
                    ></b-form-input>
                    <b-form-input
                        id="successdate-datepicker-empty"
                        value=""
                        :disabled="true"
                        v-else
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="successtype-input-group"
                    label="Type"
                    label-for="successtype-input"
                >
                    <b-form-input
                        id="successtype-input"
                        v-model="form.successType"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <b-form-group
                    id="price-input-group"
                    label="Current Price"
                    label-for="price-input"
                >
                    <b-form-input
                        id="price-input"
                        v-model="price"
                        :disabled="true"
                    ></b-form-input>
                </b-form-group>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-sm-2 g-4" v-if="security.transactionRead">
            <div class="col">
                <b-button
                    class="details-btn"
                    @click="
                        transactionsVmidLink(
                            null,
                            history.vmid
                        )
                    "
                    >Transactions</b-button
                >
            </div>
        </div>
    </b-form>
</template>

<script>
import KioskService from '../../services/kiosk.service';
import ProductsService from '../../services/products.service';
import { transactionsVmidLink, formatDate, convertUTCtoLocalDate } from '../../utils/utils';

export default {
    name: 'TransactionHistory',
    props: {
        kioskId: {
            type: String,
            required: true,
        },
    },
    computed: {
        price() {
            return (
                'Exchange: ' +
                this.priceInfo.exchange_price +
                ' - Purchase: ' +
                this.priceInfo.purchase_price
            );
        },
        security() {
            return this.$store.state.auth.security
        },
    },
    data() {
        return {
            form: {
                successDate: '',
                successType: '',
                failedDate: '',
                failedType: '',
            },
            history: {},
            priceInfo: {},
            loading: false,
        };
    },
    mounted() {
        this.getHistory();
        this.getPriceInfo();
    },
    methods: {
        getHistory() {
            this.loading = true;
            KioskService.transactionHistory(this.kioskId).then((response) => {
                if (response) {
                    this.history = response.data;
                    this.setForm(this.history);
                    this.loading = false;
                }
            });
        },
        getPriceInfo() {
            this.loading = true;
            ProductsService.getPriceDetailsByKioskId(this.kioskId).then(
                (response) => {
                    if (response) {
                        this.priceInfo = response.data;
                        this.loading = false;
                    }
                }
            );
        },
        setForm(history) {
            this.form.successDate = history.successful_transaction.timestamp;
            this.form.failedDate = history.failed_transaction.timestamp;
            this.form.successType = history.successful_transaction.type;
            this.form.failedType = history.failed_transaction.type;
        },
        transactionsVmidLink,
        formatDate,
        convertUTCtoLocalDate
    },
};
</script>

<style scoped>
</style>