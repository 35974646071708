<template>
    <div>
        <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="mfg-checkbox-group"
                class="chk-lbl"
                v-model="getSelectedCheckboxes"
                :aria-describedby="ariaDescribedby"
                name="checkbox"
            >
                <div v-for="checkbox in allCheckboxes" :key="checkbox.evType">
                    <b-form-checkbox
                        :value="checkbox.evType"
                        :id="
                            'checkboxgroup-checkbox-' +
                            checkbox.description.toLowerCase().replace(' ', '')
                        "
                    >
                        {{ checkbox.description }}
                    </b-form-checkbox>
                </div>
            </b-form-checkbox-group>
        </b-form-group>
    </div>
</template>
<script>
export default {
    name: 'EventTypesNestedComponent',
    props: {
        allCheckboxes: {
            type: Array,
            required: true,
        },
        selectedCheckboxes: {
            type: Array,
            required: true,
        },
    },
    computed: {
        getSelectedCheckboxes: {
            get() {
                return this.selectedCheckboxes;
            },
            set(value) {
                this.$emit('setSelectedCheckboxes', value);
            },
        },
    },
    methods: {
        toggleAllCheckboxes(checkAll) {
            if (checkAll) {
                var newSelectedCheckboxes = this.selectedCheckboxes

                this.allCheckboxes.map(checkbox => checkbox.evType).forEach(checkbox => {
                    if (!newSelectedCheckboxes.includes(checkbox)) {
                        newSelectedCheckboxes.push(checkbox)
                    }
                });

                this.$emit('setSelectedCheckboxes', newSelectedCheckboxes);
            }
            else {
                var allCheckboxesExceptCurrentChildren = this.selectedCheckboxes.filter(selectedCheckbox => !this.allCheckboxes.map(checkbox => checkbox.evType).includes(selectedCheckbox))
                this.$emit('setSelectedCheckboxes', allCheckboxesExceptCurrentChildren);
            }            
        },
    }
};
</script>
<style scoped>
.form-group {
    margin-bottom: 0px;
}
</style>