<template>
    <div>
        <h3><font-awesome-icon icon="check-double" class="nav-icon fa" />  Multiple Use</h3>
        <div class="button-group">
            <div class="button-col">
                <b-button @click="showAddMultipleUseCodeModal" variant="primary" class="button" v-if="security.promoCreate">Add</b-button>
            </div>
        </div>
        <div class="pb-3">
            <Table
              id="multipleUseTable"
              :fields="dynamicFields"
              :items="multipleUseCodes"
              sortBy="code"
              :sortDesc="true"
              :searchPagination="searchPagination"
              @getItems="listMultipleUseCodes"
              @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="data">
                    <div class="icons">
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" @click="showRemoveCodeModal(data.item)" v-if="security.promoDelete" title="Delete"/>
                    </div>
                </template>
                <template #cell(price)="data">
                    {{ data.item.discountTypeId == 2 ? data.item.price.toString() + '%' : formatPrice(data.item.price) }}
                </template>
                <template #cell(startDate)="data">
                    {{ formatDate(data.item.startDate) }}
                </template>
                <template #cell(endDate)="data">
                    {{ formatDate(data.item.endDate) }}
                </template>
            </Table>
        </div>
        <PromoExportFileComponent
            class="export"
            :disabled="false"
            :promoType="PROMO_TYPE_ENUM.MultiUse"
        />
        <b-modal id="add-multiple-use-code" ref="add-multiple-use-code" hide-footer>
            <template #modal-title>
               Multiple Use Code - New 
            </template>
            <AddMultipleUseCode @hide="hideAddMultipleUseCodeModal"/>
        </b-modal>
        <b-modal id="remove-code" ref="remove-code">
            <template #modal-title>
               Remove Multiple Use
            </template>
            <div class="d-block">
                <p>You are about to remove item #{{selectedMultipleUseCode.code}}</p>
                <p>Do you want to proceed?</p>
            </div>
            <template #modal-footer>
                <button class="btn btn-danger" @click="removeCode(selectedMultipleUseCode)">
                    Remove
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>

import SearchPagination from '../../models/search.pagination'
import { Table, PromoExportFileComponent } from "../../components/"
import AddMultipleUseCode from "./AddMultipleUseCode.vue"
import PromosService from "../../services/promos.service" 
import PROMO_TYPE_ENUM from '../../models/PromoTypeEnum'
import { formatDate, formatPrice, generateDynamicFields } from '../../utils/utils'

export default {
    name: "MultipleUse",
    components: {
        Table,
        AddMultipleUseCode,
        PromoExportFileComponent,
    },
    data() {
        return {
            multipleUseCodes: [],
            selectedMultipleUseCode: {},
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'code',
                    label: 'Code',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'comment',
                    label: 'Promo Name',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'location',
                    label: 'Location',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'discountType',
                    label: 'Discount Type',
                    tdClass: 'column',
                    sortable: false
                },
                {
                    key: 'price',
                    label: 'Amount',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5
                },
                {
                    key: 'startDate',
                    label: 'Start Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 10
                },
                {
                    key: 'endDate',
                    label: 'End Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 11
                },
                {
                    key: 'product',
                    label: 'Product',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7
                },
                {
                    key: 'usesOutOfTotal',
                    label: '# Uses',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 12
                },
                {
                    key: 'valid',
                    label: 'Valid',
                    tdClass: 'column',
                    sortable: false
                },
            ],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
            PROMO_TYPE_ENUM
        };
    },
    computed:{
        security(){
            return this.$store.state.auth.security
        },
        dynamicFields() {
            return generateDynamicFields(this.fields, 'actions', !this.security.promoDelete)
        }
    },
    mounted() {
        this.listMultipleUseCodes()
    },
    methods: {
        listMultipleUseCodes() {
            PromosService.listCoupons(
                PROMO_TYPE_ENUM.MultiUse,
                this.searchPagination.startIndex, 
                this.searchPagination.pageSize, 
                this.sortOrder
                ).then((response) => {
                if (response) {
                    this.multipleUseCodes = response.data.items
                    this.searchPagination.totalRows = this.multipleUseCodes.length > 0 ? response.data.total_rows : 0
                }
            })
        },
        showAddMultipleUseCodeModal() {
            this.$refs["add-multiple-use-code"].show()
        },
        hideAddMultipleUseCodeModal() {
            this.$refs["add-multiple-use-code"].hide()
            this.listMultipleUseCodes()
        },
        showRemoveCodeModal(multipleUseCode) {
            this.selectedMultipleUseCode = multipleUseCode
            this.$refs["remove-code"].show()
        },
        removeCode() {
            PromosService.removeCode(
                this.selectedMultipleUseCode.id
                ).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                    this.hideRemoveCodeModal();
                    this.listMultipleUseCodes();
                }
            })
        },
        hideRemoveCodeModal() {
            this.$refs["remove-code"].hide()
            this.listMultipleUseCodes()
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.listMultipleUseCodes();
        },
        formatDate,
        formatPrice
    }
}
</script>
<style scoped>
::v-deep .action-column {
    min-width: 30px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 0.5rem;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>