<template>
    <div class="software-register">
        <b-modal id="software-register-modal" @show="refresh">
            <template #modal-title>
                <h3 v-if="!uploaded">Upload Firmware</h3>
                <h3 v-if="uploaded">Confirm Firmware Upload</h3>
            </template>
            <template>
                <div v-if="!uploaded">
                    <b-form-file
                        v-model="firmware"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        class="mt-3"
                        :state="firmwareState"
                    ></b-form-file>
                    <div v-if="firmwareState == false" class="error">
                        Firmware is required
                    </div>
                    <div v-if="message" class="error">
                        {{message}}
                    </div>
                </div>
                <div v-if="uploaded" class="fields-container">
                    <b-form-group id="hardware-input-group" label="Hardware Type" label-for="hardware" label-size="sm">
                        <b-form-input
                            id="hardwareType"
                            v-model="firmwarePack.hardware_type"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="system-hardware-input-group" label="System Hardware Type" label-size="sm" label-for="systemHardware">
                        <b-form-input
                            id="systemHardwareType"
                            v-model="firmwarePack.system_hardware_type"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="product-name-input-group" label="Product Name" label-size="sm" label-for="productName">
                        <b-form-input
                            id="productName"
                            v-model="firmwarePack.product_name"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="version-input-group" label="Version" label-size="sm" label-for="version">
                        <b-form-input
                            id="version"
                            v-model="firmwarePack.version"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="runtime-input-group" label="Runtime Version" label-size="sm" label-for="runtime">
                        <b-form-input
                            id="runtime"
                            v-model="firmwarePack.runtime_version"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="associated-hardware-type-input-group" label="Associated Hardware Type" label-size="sm" label-for="associatedHardwareType">
                        <b-form-input
                            id="associatedHardwareType"
                            v-model="firmwarePack.associated_hardware_type"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="associated-versions-input-group" label="Associated Versions" label-size="sm" label-for="associatedVersions">
                        <b-form-input
                            id="associatedVersions"
                            v-model="firmwarePack.associated_versions"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="operating-system-input-group" label="Operating System" label-size="sm" label-for="operatingSystem">
                        <b-form-input
                            id="operatingSystem"
                            v-model="firmwarePack.operating_system"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="title-input-group" label="Title" label-size="sm" label-for="title">
                        <b-form-input
                            id="title"
                            v-model="firmwarePack.title"
                            :disabled="true"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group id="release-notes-input-group" label="Release Notes" label-size="sm" label-for="releaseNotes">
                        <b-form-input
                            id="releaseNotes"
                            v-model="firmwarePack.release_notes"
                            :disabled="true"
                            :title="firmwarePack.release_notes"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-checkbox
                        v-if="firmwarePack.hardware_type_id == 9 || firmwarePack.hardware_type_id == 17"
                        id="media=pack-checkbox"
                        v-model="mediaPack"
                        name="checkbox-1"
                        :disabled="true"
                        >
                        Use Latest Media Pack
                    </b-form-checkbox>
                </div>
                <div v-if="Object.keys(tags).length !== 0 && tags.length !== 0" class="mt-2">
                    <span class="label">Tags</span>
                    <div>
                        <div v-for="(tagValidity, tagName, index) in tags" :key="index">
                            <b-form-input
                                :id="'tag_' + index"
                                :value="tagName"
                                :disabled="true"
                                :state="tagValidity"
                                class="mt-2"
                            ></b-form-input>
                            <div v-if="!tagValidity" class="error">
                                Tag is invalid
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #modal-footer>
                <SpinnerButton @click="upload" :isLoading="isLoading" text="Upload" class="btn btn-primary" />
            </template>
        </b-modal>
    </div>
</template>

<script>
import { SoftwareService } from '../../services';
import { SpinnerButton } from "../../components"
export default {
    name: "RegisterSoftwareModal",
    components: {
        SpinnerButton
    },
    data() {
        return {
            isLoading: false,
            uploaded: false,
            firmware: null,
            firmwarePack: [],
            tags: [],
            mediaPack: true,
            message: "",
            checkValid: false
        }
    },
    emits: ['changeComplete'],
    computed: {
        firmwareState() {
            if (!this.checkValid) return null
            return this.firmware ? true : false
        },
        isFormValid() {
            return  this.firmwareState
        }
    },
    methods: {
        refresh() {
            this.isLoading = false
            this.uploaded = false,
            this.firmware = null,
            this.firmwarePack = [],
            this.tags = [],
            this.mediaPack = true,
            this.message = ""
            this.checkValid = false
        },
        upload() {
            this.checkValid = true
            if (this.isFormValid) { 
                var formData = new FormData();
                formData.append("FirmwareFile", this.firmware);
                this.isLoading = true;
                if(!this.uploaded){
                    SoftwareService.upload(
                        formData
                    ).then((response) => {
                        if (response) {
                            if(response.data.success){
                                this.firmwarePack = response.data;
                                this.tags = response.data.tags;
                                this.uploaded = true;
                            }
                            else {
                                this.$root.$bvToast.toast(`Error: ${response.data.message}`, {
                                    title: 'Upload failed',
                                    variant: 'danger',
                                    solid: true
                                })

                                this.tags = response.data.tags
                            }
                        }

                        this.isLoading = false;
                    });
                }   
                else {
                    this.firmwarePack.media_pack = this.mediaPack;
                    SoftwareService.submit(
                        this.firmwarePack
                    ).then((response) => {
                        if(response.data.success) {
                            this.$root.$bvToast.toast(`${response.data.message}`, {
                                title: "Submit Successful",
                                variant: 'success',
                                solid: true
                            })
                            this.$emit('changeComplete')
                            this.refresh()
                        }
                        else {
                            this.$root.$bvToast.toast(`${response.data.message}`, {
                                title: "Submit Failed",
                                variant: 'danger',
                                solid: true
                            })
                        }

                        this.isLoading = false;
                    });
                }
            }
        },
    }
}
</script>

<style scoped>
.new-search {
    text-align: right;
    margin-right: 12px;
    position: relative;
    top: 38px;
}
.fields-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.error {
    color: #dc3545;
    margin-top: 0.25rem;
}

.label {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

@media only screen and (max-width: 575.5px) {
    .fields-container {
        display: inherit;
        flex-wrap: nowrap;
    }
}
</style>