<template>
    <div class="drop-down p-2 my-3">
        <div v-for="(valueInfo, index) in values" :key="index" >
            <div class="group">
                <b-form-input 
                    :id="index + '-field'"
                    v-model="values[index].value"
                    class="my-2"
                    :state="validate(index)"
                >
                </b-form-input>
                <div class="buttons ml-2">
                    <div @click="addValue" v-if="index == 0" :style="{ 'visibility': values.length < maxValues ? 'visible' : 'hidden' }">
                        <font-awesome-icon icon="plus" class="nav-icon fa cursor" title="Add list item"/>
                    </div>
                    <div @click="deleteValue(index)" v-if="index != 0">
                        <font-awesome-icon icon="trash-can" class="nav-icon fa cursor" title="Delete list item"/>
                    </div>
                </div>
            </div>
            <div v-if="validate(index) == false" class="error">
                List item is required, and must be unique
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "CustomFieldListValues",
    props: {
        maxValues: {
            type: Number,
            default: 4
        },
        listValues: {
            type: Array,
        },
    },
    data() {
        return {
            values: [{}],
        };
    },
    watch: {
        values() {
            this.$emit("listValues", this.values)
        },
    },
    mounted() {
        if (this.listValues.length > 0) {
            this.values = this.listValues
        }
    },
    methods: {
        addValue() {
            if (this.values.length < this.maxValues) {
                this.values.push(
                    {
                        id: 0,
                        value: "" 
                    })
            }
        },
        deleteValue(index) {
            this.values.splice(index, 1)
        },
        validate(index) {
            var valueInfo = this.values[index]
            if (!valueInfo.value) return false
            return valueInfo.value.length > 0 && !this.existsMoreThanOnce(index, valueInfo.value)
        },
        existsMoreThanOnce(index, value) {
            return this.values.map(valueInfo => valueInfo.value).indexOf(value) != index
        },
        validateAll() {
            var validity = []

            for (let i = 0; i < this.values.length; i++) {
                validity.push(this.validate(i))
            }

            return !validity.includes(false)
        }
    }
}
</script>
<style scoped>
.right {
    float: right;
}

.drop-down {
    border: 1px dashed #ced4da;
    border-radius: 0.25rem;
}

.group {
    display: flex;
}

.buttons {
    align-self: center;
    
}

.cursor {
    cursor: pointer;
}

.buttons:hover {
    opacity: 60%;
}

.error {
    color: #dc3545;
}
</style>