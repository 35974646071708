import axios from "axios";
import authHeader from "./auth.header";

class EventsService {
    listEvents(filterEmail, filterTypes, startIndex, pageSize, sortOrder) {       
        return axios({
            method: 'post',
            url: '/api/events/list',
            headers: authHeader(),
            data: {
                filter_email: filterEmail,
                filter_types: filterTypes,
                start_index: startIndex, 
                page_size: pageSize, 
                sort_order: sortOrder
            }
        })
    }

    getGroups() {
        return axios({
            method: 'get',
            url: '/api/events/get_events_groups',
            headers: authHeader()
        }
        );
    }

    register(userId, typeIds, blackoutStartTime, blackoutEndTime) {
        return axios({
            method: 'post',
            url: '/api/events/register',
            headers: authHeader(),
            data: {
                user_id: userId,
                type_ids: typeIds,
                blackout_start_time: blackoutStartTime,
                blackout_end_time: blackoutEndTime
            }
        })
    }

    deleteEvent(id) {
        return axios({
            method: 'delete',
            url: '/api/events/delete',
            headers: authHeader(),
            params: { registrationId: id }
        });
    }

    getEmails() {
        return axios({
            method: 'get',
            url: '/api/events/get_emails',
            headers: authHeader(),
        })
    }

    getByUserId(userId) {
        return axios({
            method: 'get',
            url: '/api/events/get_by_user_id',
            headers: authHeader(),
            params: {
                userId
            }
        })
    }
}
export default new EventsService();