import axios from "axios"
import authHeader from "./auth.header"
import { addTimezoneInformation } from "../utils/utils"

class SalesService {

    sales(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/sales/search_with_query',
            headers: authHeader(),
            data: {
                region_id: query.region,
                include_incomm: query.includeIncomm,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    downloadCsv(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/sales/download_csv',
            headers: authHeader(),
            data: {
                region_id: query.region,
                include_incomm: query.includeIncomm,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    downloadXlsx(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            responseType: "blob",
            url: '/api/sales/download_xlsx',
            headers: authHeader(),
            data: {
                region_id: query.region,
                include_incomm: query.includeIncomm,
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }
}

export default new SalesService();