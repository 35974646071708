<template>
    <b-modal id="users-delete-modal">
        <template #modal-title>
            <h3>Delete User</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>You are about to remove a user</p>
                    <p>Do you want to proceed?</p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <a @click="deleteUser()" class="btn btn-danger">Delete</a>
        </template>
    </b-modal>
</template>

<script>
import UsersService from '../../services/users.service';

export default {
    name: 'DeleteUserModal',
    props: {
        user: {},
    },
    emits: ['deleteComplete'],
    methods: {
        deleteUser() {
            UsersService.deleteUser(this.user.id).then((response) => {
                if (response.data) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });

                    this.$emit('deleteComplete');
                }
            })
        },
    },
};
</script>