<template>
    <div>
        <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
                id="mfg-checkbox-group"
                class="chk-lbl"
                v-model="getSelectedCheckboxes"
                :aria-describedby="ariaDescribedby"
                name="checkbox"
            >
                <div v-for="checkbox in allCheckboxes" :key="checkbox.id">
                    <b-form-checkbox
                        :value="checkbox"
                        :id="
                            'checkboxgroup-checkbox-' +
                            checkbox.description.toLowerCase().replace(' ', '')
                        "
                    >
                        {{ checkbox.description }}
                    </b-form-checkbox>
                </div>
            </b-form-checkbox-group>
        </b-form-group>
    </div>
</template>
<script>
export default {
    name: 'CheckboxGroup',
    props: {
        allCheckboxes: {
            type: Array,
            required: true,
        },
        selectedCheckboxes: {
            type: Array,
            required: true,
        },
    },
    computed: {
        getSelectedCheckboxes: {
            get() {
                return this.selectedCheckboxes;
            },
            set(value) {
                this.$emit('setSelectedCheckboxes', value);
            },
        },
    },
};
</script>
<style scoped></style>