import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils"

class MaintenanceService {
    searchQuery(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/maintenance/search_with_query',
            headers: authHeader(),
            data: {
                date_start: addTimezoneInformation(query.dateStart),
                date_end: addTimezoneInformation(query.dateEnd),
                vmid: query.vmid,
                region_id: query.region,
                product_id: query.product,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder
            }
        })
    }

    getMaintenanceLogs(guid) {
        return axios({
            method: 'get',
            url: '/api/maintenance/get_logs',
            params: {
                id: guid
            },
            headers: authHeader()
        })
    }
}

export default new MaintenanceService();