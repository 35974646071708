import axios from "axios";
import authHeader from "./auth.header";

class KiosksService {

  search(startIndex, pageSize, sortOrder) {
    return axios({
      method: 'get',
      url: '/api/kiosks/search',
      headers: authHeader(),
      params: {
        startIndex, pageSize, sortOrder
      }
    })
  }

  searchQuery(query, startIndex, pageSize, sortOrder) {
    return axios({
      method: 'post',
      url: '/api/kiosks/search_with_query',
      headers: authHeader(),
      data: {
        vmids: query.vmid,
        svmid: query.svmid,
        region_id: query.region,
        hardware_type_id: query.hardwareType,
        product_id: query.product,
        event_type_id: query.issue,
        severity_level_id: query.severityLevel,
        store_number: (query.storeNumber === null || query.storeNumber === undefined) ? null : query.storeNumber.trim(),
        account_number: query.accountNumber,
        event_date: query.issueDate,
        enabled: query.enabled,
        installed: query.installed,
        disable_apu: query.disableApu,
        favourite: query.favourite,
        start_index: startIndex,
        page_size: pageSize,
        sort_order: sortOrder
      }
    })
  }

  getAllVmid() {
    return axios({
      method: 'post',
      url: '/api/kiosks/getAllVmid',
      headers: authHeader(),
    })
  }

  addNew(kiosk) {
    return axios({
      method: 'post',
      url: 'api/kiosks/new',
      headers: authHeader(),
      data: {
        vmid: kiosk.vmid,
        sim: kiosk.sim,
        hardware_type_id: kiosk.hardwareTypeId,
        store_id: kiosk.storeId,
        price_id: kiosk.priceId,
        enabled: kiosk.enabled,
        installed: kiosk.installed,
        low_inventory_alert: kiosk.lowInventory
      }
    })
  }

  addNewCsv(form_data) {
    var headers = {}

    Object.assign(headers, authHeader())
    Object.assign(headers, { "content-type": undefined })

    return axios({
      method: 'post',
      url: '/api/kiosks/bulk_new',
      data: form_data,
      headers: headers
    })
  }

  delete(kioskId) {
    return axios({
      method: 'post',
      url: 'api/kiosks/delete',
      headers: authHeader(),
      params: {
        kioskId
      }
    })
  }

  favouriteKiosk(kioskId, favourite) {
    return axios({
      method: 'post',
      url: '/api/kiosks/favourite_kiosk',
      headers: authHeader(),
      params: {
        kioskId,
        favourite
      }
    })
  }

  favouriteAllKiosk(query, startIndex, pageSize, sortOrder, favouriteAll) {
    return axios({
      method: 'post',
      url: '/api/kiosks/favourite_all_kiosk',
      headers: authHeader(),
      data: {
        vmids: query.vmid,
        region_id: query.region,
        hardware_type_id: query.hardwareType,
        product_id: query.product,
        event_type_id: query.issue,
        severity_level_id: query.severityLevel,
        store_number: query.storeNumber,
        account_number: query.accountNumber,
        event_date: query.issueDate,
        enabled: query.enabled,
        installed: query.installed,
        disable_apu: query.disableApu,
        favourite: query.favourite,
        start_index: startIndex,
        page_size: pageSize,
        sort_order: sortOrder,
        favourite_all: favouriteAll
      }
    })
  }

  downloadCsv(query) {
    return axios({
        method: 'post',
        responseType: "blob",
        url: '/api/kiosks/download_csv',
        headers: authHeader(),
        data: {
          vmids: query.vmid,
          region_id: query.region,
          hardware_type_id: query.hardwareType,
          product_id: query.product,
          event_type_id: query.issue,
          severity_level_id: query.severityLevel,
          store_number: query.storeNumber,
          account_number: query.accountNumber,
          enabled: query.enabled,
          installed: query.installed,
          disable_apu: query.disableApu,
          favourite: query.favourite,
        }
    })
  }

  downloadXlsx(query) {
    return axios({
        method: 'post',
        responseType: "blob",
        url: '/api/kiosks/download_xlsx',
        headers: authHeader(),
        data: {
          vmids: query.vmid,
          region_id: query.region,
          hardware_type_id: query.hardwareType,
          product_id: query.product,
          event_type_id: query.issue,
          severity_level_id: query.severityLevel,
          store_number: query.storeNumber,
          account_number: query.accountNumber,
          event_date: query.issueDate,
          enabled: query.enabled,
          installed: query.installed,
          disable_apu: query.disableApu,
          favourite: query.favourite,
          start_index: 0,
          page_size: 0,
          sort_order: 0
        }
    })
  }
}

export default new KiosksService();