import axios from "axios";
import authHeader from "./auth.header";

class TagService {
    kioskTags(kioskId) {
        return axios({
            method: 'get',
            url: '/api/tag/list_kiosk_tags',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    listTagsByType(typeId) {
        return axios({
            method: 'get',
            url: '/api/tag/list_tag_by_type',
            headers: authHeader(),
            params: {
                tagType: typeId
            }
        })
    }

    listAvailableTag(kioskId, firmwareId) {
        return axios({
            method: 'get',
            url: '/api/tag/list_available_tags',
            headers: authHeader(),
            params: {
                kioskId,
                firmwareId
            }
        })
    }

    listFirmwareTags() {
        return axios({
            method: 'get',
            url: '/api/tag/list_firmware_tags',
            headers: authHeader()
        })
    }

    listPhaseTags() {
        return axios({
            method: 'get',
            url: '/api/tag/list_phase_tags',
            headers: authHeader()
        })
    }

    getPhase(kioskId) {
        return axios({
            method: 'get',
            url: '/api/tag/get_phase',
            headers: authHeader(),
            params: {
                kioskId
            }
        })
    }

    setPhase(kioskId, tagId) {
        return axios({
            method: 'post',
            url: '/api/tag/set_phase',
            headers: authHeader(),
            data: {
                tag_id: tagId,
                kiosk_Id: kioskId,
            }
        })
    }

    assignTags(tagIds, typeId, firmwareId, kioskId) {
        return axios({
            method: 'post',
            url: '/api/tag/assign_tags',
            headers: authHeader(),
            data: {
                tag_ids: tagIds,
                type_id: typeId,
                kiosk_id: kioskId,
                software_id: firmwareId,
            }
        })
    }

    addNewTag(typeId, name) {
        return axios({
            method: 'post',
            url: '/api/tag/add_new_tag',
            headers: authHeader(),
            data: {
                type_id: typeId,
                name
            }
        })
    }
}

export default new TagService();