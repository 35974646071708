<template>
    <b-modal id="transaction-refund-modal">
        <template #modal-title>
            <h3>Refund Transaction</h3>
        </template>
        <div class="modal-body">
            <label>Purchase</label>
            <b-form-input v-model="RefundInfo.total" id="refund-modal-purchase" class="transaction-search-field mb-2" disabled />

            <label>Refunded to date</label>
            <b-form-input v-model="RefundInfo.refundedAmount" id="refund-modal-refunded" class="transaction-search-field mb-2" disabled />

            <label>Refund Type</label>
            <b-form-select v-model="refundData.refundType" @input="onRefundTypeInput()" :options="refundTypeOptions" id="refund-type" class="mb-2"></b-form-select>

            <b-form-group 
                label="Refund Amount"
                :invalid-feedback="amountValidationMessage"
                :state="validAmount()" >
                <b-form-input number id="refund-modal-amount" class="transaction-search-field" type="number" min="0.01" max="100.00" step="0.01" :disabled="amountDisabled" v-model="refundData.amount" required />
            </b-form-group>
            
            <b-form-group 
                label="Expiry Date (mm / yy)" 
                :invalid-feedback="dateValidationMessage"
                :state="validMonth() && validYear()">
                <b-form-input number id="refund-modal-expmonth" class="transaction-search-field" style="width:85px; display: inline-block" type="number" min="1" max="12" v-model="refundData.expiryMonth" :state="validMonth()" required /> /
                <b-form-input number id="refund-modal-expyear" class="transaction-search-field" style="width:85px; display: inline-block" type="number" min="1" max="99" v-model="refundData.expiryYear" :state="validYear()" required />
                <!-- <div v-if="dateValidationMessage != null" class="invalid-feedback display">{{ dateValidationMessage }}</div> -->
            </b-form-group>
            <p>Do you want to proceed?</p>
            <div class="alert alert-danger mt-3" role="alert" v-if="showError">
                Please make sure all values are correct
            </div>
        </div>
        <template #modal-footer>
        <a id="transaction-modal-refund-confirm" @click="refundTransaction()" class="btn btn-danger">Refund</a>
        </template>
    </b-modal>
</template>
<script>

import TransactionsService from '../../services/transactions.service'

export default {
    name: "RefundTransactionModal",
    props: {
        RefundInfo: {
            total: Number,
            exchangeTotal: Number,
            transactionType: Number,
            refundedAmount: Number,
            transactionID: Number
        }
    },
    data(){
        return {
            refundData: {
                amount: 0,
                refunded: 0,
                expiryMonth: "",
                expiryYear: "",
                refundType: 0
            },
            refundTypeOptions: [
                { value: 1, text: 'Complete' },
                { value: 2, text: 'Difference' },
                { value: 3, text: 'Amount' }
            ],
            amountDisabled: false,
            showError: false
        }
    },
    mounted(){
        this.$root.$on('bv::modal::show', () => {
            this.refundData.amount = this.RefundInfo.total
            this.refundData.refunded = this.RefundInfo.refundedAmount
            this.refundData.refundType = (this.refundData.refunded > 0.0) ? 3 : 1

            this.refundTypeOptions.find(v => v.value == 1).disabled = (this.refundData.refunded > 0.0)
            this.refundTypeOptions.find(v => v.value == 2).disabled = (this.RefundInfo.transactionType == "Exchange")

            this.showError = false
            this.onRefundTypeInput()
        })
    },
    computed: {
        amountValidationMessage() {
            var amount = this.refundData.amount
            var total = this.RefundInfo.total
            var refunded = this.refundData.refunded
            
            if (this.refundData.refundType != 3) {
                return null
            }

            if (isNaN(amount) || (amount < 0.01) || (amount > 100)){
                return "Please enter an amount between $0.01 and $100.00"
            }
            if (amount > total){
                return "Please enter an amount less than the purchase total"
            }
            if (amount > (total - refunded).toPrecision(4)) {
                return "Please enter an amount less than the refunded amount to date"
            }
            return null
        },
        dateValidationMessage() {
            var expiryMonth = this.refundData.expiryMonth
            if (isNaN(expiryMonth) || (expiryMonth < 1) || (expiryMonth > 12)) {
                return "Please enter a month between 1 and 12"
            }

            var expiryYear = this.refundData.expiryYear
            if (isNaN(expiryYear) || (expiryYear < 1) || (expiryYear > 99)) {
                return "Please enter a year between 1 and 99"
            }

            return null
        }
    },
    emits: ['refundComplete'],
    methods: {
        validAmount() {
            var amount = this.refundData.amount
            var total = this.RefundInfo.total
            var refunded = this.refundData.refunded
            if (isNaN(amount) || (amount < 0.01) || (amount > 100)){
                return false
            }
            if (amount > total){
                return false
            }
            if (amount > (total - refunded)) {
                return false
            }
            return null
        },
        validMonth() {
            var expiryMonth = this.refundData.expiryMonth
            if (expiryMonth === "")
                return null

            if (isNaN(expiryMonth) || (expiryMonth < 1) || (expiryMonth > 12)) {
                return false
            }

            return null
        },
        validYear() {
            var expiryYear = this.refundData.expiryYear
            if (expiryYear === "")
                return null


            if (isNaN(expiryYear) || (expiryYear < 1) || (expiryYear > 99)) {
                return false
            }

            return null
        },
        validRefund(){
            if (this.refundData.expiryMonth == "" || this.refundData.expiryYear == "")
                return false;

            if (this.validAmount() === false || this.validMonth() === false || this.validYear() === false)
                return false;

            return true;
        },
        onRefundTypeInput() {
            var type = this.refundData.refundType
            if (type === 1){
                this.refundData.amount = Number(this.RefundInfo.total).toFixed(2)
                this.amountDisabled = true    
            }
            else if (type === 2){
                var differenceTotal = this.RefundInfo.total - this.RefundInfo.exchangeTotal
                this.refundData.amount = differenceTotal.toFixed(2)
                this.amountDisabled = true
            }
            else if (type === 3){
                this.refundData.amount = Number(this.RefundInfo.total).toFixed(2)
                this.amountDisabled = false
            }
        },
        refundTransaction(){
            if (!this.validRefund()){
                this.showError = true
                return
            }
            TransactionsService.refundTransaction(this.RefundInfo.transactionID, this.refundData.amount, this.refundData.expiryMonth, this.refundData.expiryYear, this.refundData.refundType).then((response) => {
                if (response) {
                    this.$emit('refundComplete', response.data)
                }
            })
        }
    }
}
</script>
<style scoped>
.display {
    display: block;
}
</style>