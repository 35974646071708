<template>
    <b-modal id="transaction-refund-result-modal">
        <template #modal-title>
            <h3 v-if="RefundResponse.error">Refund Error</h3>
            <h3 v-else>Refund Result</h3>
        </template>
        <div class="modal-body">        
            <h4 v-if="RefundResponse.success">Refund issued</h4>
            <h4 v-else>Failed to issue refund</h4>
            <div>
                <span>Message</span>
                <textarea v-model="RefundResponse.message" style="width:100%; height: 140px" readonly></textarea>
            </div>
            <div>
                <span>Data</span>
                <textarea v-model="RefundResponse.data" style="width:100%; height: 140px" readonly></textarea>
            </div>
        </div>
        <template #modal-footer="{cancel}">
            <a id="transaction-modal-refund-result-close" @click="cancel()" class="btn">Close</a>
        </template>
    </b-modal>
</template>

<script>

export default {
    name: "RefundTransactionResultModal",
    props: {
        RefundResponse: {
          error: Boolean,
          success: Boolean,
          message: String,
          data: String
        }
    }
}

</script>