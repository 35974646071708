<template>
    <div>
        <h3><font-awesome-icon icon="bell" class="nav-icon fa" /> Events</h3>
        <div class="button-group">
            <button
                @click="openRegisterModal"
                class="btn btn-primary row row-cols-1 ml-0"
                v-if="security.eventCreate"
            >
                <span>Subscribe</span>
            </button>
            <button
                @click="showFilterByTypes"
                class="btn btn-primary row row-cols-1 mr-0"
            >
                Filter By Types
                <span v-if="filterTypes.length > 0"
                    >({{ filterTypes.length }})</span
                >
            </button>
        </div>
            <DynamicSearchComponent
                ref="eventSearchComponent"
                preferencesName="events_search"
                :allCriteria="searchCriteria"
                @resetPagination="
                    () => {
                        this.searchPagination.currentPage = 1;
                        this.searchPagination.startIndex = 1;
                    }
                "
                @reloadDynamicSearch="reloadDynamicSearch"
            />
        <div class="pb-3">
            <Table
                id="eventsTable"
                :fields="dynamicFields"
                :items="events"
                sortBy="registered"
                :sortDesc="true"
                :searchPagination="searchPagination"
                @getItems="getEvents"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="row">
                    <div v-if="row.item.typeId != 4" class="icons">
                        <font-awesome-icon icon="pencil" class="nav-icon pointer mx-2" @click="openUpdateModal(row.item)" v-if="security.eventWrite" title="Edit"/>
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" @click="openDeleteEventModal(row.item)" v-if="security.eventDelete" title="Delete"/>
                    </div>
                    </template>
                <template #cell(registered)="data">
                    {{ formatDate(convertUTCtoLocalDate(data.item.registered)) }}
                </template>
            </Table>
            <DeleteEventModal :registrationId="selectedRegistrationId" @changeComplete="hideDeleteModal" />
            <RegisterEventModal @changeComplete="hideRegisterModal"/>
            <UpdateEventModal :user="selectedUser" @changeComplete="hideUpdateModal"/>
            <b-modal id="filter-by-types-modal" ref="filter-by-types-modal" no-close-on-backdrop @close="refresh">
                <template #modal-title>
                    Filter By Types
                </template>
                <EventGroupNestedComponent
                    :eventGroups="eventGroupOptions"
                    :selected="filterTypes"
                    @setSelectedCheckboxes="setSelectedEventTypes"
                />
                <template #modal-footer>
                    <a id="filter-by-types-modal-" @click="refresh()" class="btn btn-primary">Apply</a>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { EventsService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import { Table, DynamicSearchComponent } from '../../components/';
import {
    DeleteEventModal,
    RegisterEventModal,
    UpdateEventModal,
} from '../../components/events/';
import { EventGroupNestedComponent } from '../../components';
import { formatDate, convertUTCtoLocalDate, generateDynamicFields } from '../../utils/utils';

export default {
    name: 'Events',
    components: {
        Table,
        DeleteEventModal,
        RegisterEventModal,
        UpdateEventModal,
        EventGroupNestedComponent,
        DynamicSearchComponent,
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        dynamicFields() {
            return generateDynamicFields(
                this.fields,
                'actions',
                !this.security.eventWrite && !this.security.eventDelete
            );
        },
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column'
                },
                {
                    key: 'email',
                    label: 'Email',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'event_type',
                    label: 'Event Type',
                    tdClass: 'wrap',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'registered',
                    label: 'Registered On',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'blackout_start_time',
                    label: 'Blackout Start Time',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'blackout_end_time',
                    label: 'Blackout End Time',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5
                },
            ],
            searchCriteria: [
                {
                    key: 'event_emails',
                    name: 'Emails',
                    type: 'EventEmails',
                    searchQueryName: 'email',
                },
            ],
            events: [],
            eventGroupOptions: [],
            eventGroup: 0,
            selectedRegistrationId: 0,
            selectedUser: {
                id: 0,
                email: '',
            },
            filterTypes: [],
            isLoading: false,
            message: '',
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -3,
        };
    },
    created() {
        EventsService.getGroups().then((response) => {
            this.eventGroupOptions = response.data;
        });
    },
    mounted() {
        this.getEvents();
    },
    methods: {
        getEvents() {
            this.$refs.eventSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.isLoading = true;
            EventsService.listEvents(
                args.query.email,
                this.filterTypes,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        this.events = response.data.items;
                        this.searchPagination.totalRows =
                            this.events.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                    }
                    args.done();
                })
                .catch(() => {
                    args.done();
                });
        },
        openDeleteEventModal(item) {
            this.selectedRegistrationId = item.id;
            this.$bvModal.show('events-delete-modal');
        },
        hideRegisterModal() {
            this.$bvModal.hide('events-register-modal');
            this.getEvents();
        },
        hideUpdateModal() {
            this.$bvModal.hide('events-update-modal');
            this.getEvents();
        },
        hideDeleteModal() {
            this.$bvModal.hide('events-delete-modal');
            this.getEvents();
        },
        openRegisterModal() {
            this.$bvModal.show('events-register-modal');
        },
        openUpdateModal(item) {
            this.selectedUser.id = item.user_id;
            this.selectedUser.email = item.email;
            this.$bvModal.show('events-update-modal');
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getEvents()
        },
        showFilterByTypes() {
            this.$refs['filter-by-types-modal'].show();
        },
        setSelectedEventTypes(value) {
            this.filterTypes = value;
        },
        refresh() {
            this.$refs['filter-by-types-modal'].hide();
            this.getEvents();
        },
        formatDate,
        convertUTCtoLocalDate,
    },
};
</script>
<style scoped>
.button-group {
    display: flex;
    justify-content: space-between;
}

::v-deep .action-column {
    min-width: 30px;
    vertical-align: middle !important;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }
}
</style>