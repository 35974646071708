<template>
    <div class="password-update-modal">
        <b-modal @submit.prevent="updatePassword" id="password-update-modal" @show="refresh">
            <template #modal-title>
                <h3>Update Password</h3>
            </template>
            <div class="row row-cols-1 g-5">
                <div class="col">
                    <b-form-group
                        id="password-input-group"
                        label="New Password"
                        label-for="password"
                    >
                        <b-form-input
                            id="password"
                            type="password"
                            v-model="user.password"
                            :state="passwordState"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="passwordState == false" class="error">
                        Password is required
                    </div>
                    <b-form-group
                        id="confirm-password-input-group"
                        label="Confirm Password"
                        label-for="confirmpassword"
                    >
                        <b-form-input
                            id="confirm-password"
                            type="password"
                            v-model="user.confirm_password"
                            :state="confirmPasswordState"
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="confirmPasswordState == false" class="error">
                        Confirm Password must match Password
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <a
                    id="password-modal-update-event"
                    @click="updatePassword()"
                    class="btn btn-primary btn-margin-right"
                    >Update</a
                >
            </template>
        </b-modal>
    </div>
</template>
<script>
import { UsersService } from '../../services';

export default {
    name: 'UpdatePasswordModal',
    components: {},
    data() {
        return {
            checkValid: false
        };
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    emits: ['changeComplete'],
    computed: {
        passwordState() {
            if (!this.checkValid) return null
            return this.user.password ? true : false
        },
        confirmPasswordState() {
            if (!this.checkValid) return null
            return this.user.confirm_password && this.user.password == this.user.confirm_password ? true : false
        },
        isFormValid() {
            return this.passwordState && this.confirmPasswordState
        }
    },
    methods: {
        refresh() {
            this.checkValid = false
        },
        updatePassword() {
            this.checkValid = true
            if (this.isFormValid) {
                UsersService.updatePassword(
                    this.user.id,
                    this.user.username,
                    this.user.password
                ).then((response) => {
                    if (response.data) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Update User Password Successful' : 'Update User Password Failed',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true,
                        });

                        if (response.data.success) {
                            this.$emit('changeComplete');
                        }
                    }
                });
            }
        },
    },
};
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>