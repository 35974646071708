<template>
    <div>
        <div class="mt-3">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-2">
                        Applicable Product
                    </div>
                    <b-form-select v-model="bulkSelectedProduct" :state="bulkProductState">
                        <option
                            v-for="prod in products"
                            :value="prod"
                            :key="prod.id"
                        >
                            {{ prod.description }}
                        </option>
                    </b-form-select>
                    <div class="error" :class="{ 'hidden' : bulkProductState != false }">
                        Product is required
                    </div>
                </div>
                <div class="col-md-6 mt-4 file-container">
                    <div class="w-100">
                        <b-form-file
                            v-model="qrcodeCSV"
                            placeholder="Select a CSV or drop it here"
                            drop-placeholder="Drop CSV Here"
                            accept=".csv"
                            :state="csvState"
                        />
                        <div class="error" :class="{ 'hidden' : csvState != false }">
                        CSV is required
                    </div>
                    </div>
                    
                </div>
            </div>
            <div class="mt-3 button-group">
                <div class="button-group">
                    <a href="/files/stores/ExampleQRCodeBulkEntry.csv" class="align-self-center">
                        Download Example CSV
                    </a>
                </div>
                <div>
                    <b-button variant="primary" @click="onSubmit">Import</b-button>
                </div>
            </div>
            <div class="mt-3">
                <div
                    v-for="(error, index) in qrcodeErrors"
                    v-bind:key="index"
                >
                    <div style="color: red">{{ error }}</div>
                </div>
                <div style="color: green" v-if="success">
                    QR Code CSV imported successfully
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { StoresService, ProductsService } from '../../services';

export default {
    name: 'BulkImportQRCodes',
    data() {
        return {
            qrcodeCSV: null,
            qrcodeErrors: [],
            success: false,
            checkValid: false,
            products: [],
            bulkSelectedProduct: {
                id: null,
            },
        };
    },
    computed: {
        bulkProductState() {
            if (!this.checkValid) return null;
            return this.bulkSelectedProduct.id ? true : false;
        },
        csvState() {
            if (!this.checkValid) return null
            return this.qrcodeCSV ? true : false
        },
        isFormValid() {
            return this.bulkProductState && this.csvState
        }
    },
    mounted() {
        this.getProducts();
    },
    methods: {
        onSubmit() {
            this.checkValid = true
            if (this.isFormValid) {
                var formData = new FormData();
                formData.append('QRCodeCSV', this.qrcodeCSV);
                StoresService.updateUrlCsv(
                    formData,
                    this.bulkSelectedProduct.id
                ).then((response) => {
                    this.qrcodeErrors = response.data.errors;
                    this.success = response.data.success;

                    if (this.success) {
                        this.$root.$bvToast.toast('QR Codes Added', {
                            title: 'Success',
                            variant: 'success',
                            solid: true,
                        });
                        this.qrcodeCSV = null;
                    } else {
                        this.$root.$bvToast.toast('Check Error Messages', {
                            title: 'QR Codes Not Added',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                });
            }
        },
        getProducts() {
            ProductsService.get()
                .then((response) => {
                    this.products = response.data;
                })
                .catch((error) => {
                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'ProductsService.get failed',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
    },
};
</script>

<style scoped>
.file-container {
    display: flex;
    align-items: flex-end;
}
.button-group {
    display: flex;
    justify-content: space-between;
}
.error {
    color: #dc3545;
}
.hidden {
    visibility: hidden;
}
</style>