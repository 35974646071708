<template>
    <div>
        <b-form-group id="field-name-input-group" label="Field Name" label-for="fieldName">
            <b-form-input 
                id="field-name-input"
                v-model="customField.fieldName"
                :state="fieldNameState"
            >
            </b-form-input>
        </b-form-group>
        <div v-if="fieldNameState == false" class="error">
            Field name is not unique to the type
        </div>
        <b-form-group id="type-select-group" label="Type" label-for="type-select">
            <b-form-select 
                id="type-select" 
                v-model="customField.typeId"
                :state="typeState"
                :disabled="isUpdate"
            >
                    <option v-for="type in types" :value="type.id" :key="type.id">
                        {{ type.name }}
                    </option>
            </b-form-select>
        </b-form-group>
        <div v-if="typeState == false" class="error">
            Type is required
        </div>
        <b-form-group id="data-type-select-group" label="Data Type" label-for="data-type-select">
            <b-form-select 
                id="data-type-select" 
                v-model="customField.dataTypeId"
                :state="dataTypeState" 
            >
                    <option v-for="dataType in dataTypes" :value="dataType.id" :key="dataType.id">
                        {{ dataType.name }}
                    </option>
            </b-form-select>
        </b-form-group>
        <div v-if="dataTypeState == false" class="error">
            Data Type is required
        </div>
        <div v-if="customField.dataTypeId == CUSTOM_FIELD_DATA_TYPE_ENUM.Numeric">
            <b-form-group id="min-num-input-group" label="Min Number" label-for="min-num">
                <b-form-input 
                    id="min-num-input"
                    v-model="customField.minNum"
                    :state="minNumState"
                    type="number"
                    :min="0"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="minNumState == false" class="error">
                Min Number must be 0 or greater and less than Max Number
            </div>
            <b-form-group id="max-num-input-group" label="Max Number" label-for="max-num">
                <b-form-input 
                    id="max-num-input"
                    v-model="customField.maxNum"
                    :state="maxNumState"
                    type="number"
                    :min="1"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="maxNumState == false" class="error">
                Max Number must be greater than Min Number
            </div>
        </div>
        <div v-if="customField.dataTypeId == CUSTOM_FIELD_DATA_TYPE_ENUM.Text">
            <b-form-group id="min-length-input-group" label="Min Length" label-for="min-length">
                <b-form-input 
                    id="min-length-input"
                    v-model="customField.minLength"
                    :state="minLengthState"
                    type="number"
                    :min="1"
                    :max="254"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="minLengthState == false" class="error">
                Min Length must range from 1 - 254 and less than Max Length
            </div>
            <b-form-group id="max-length-input-group" label="Max Length" label-for="max-length">
                <b-form-input 
                    id="max-length-input"
                    v-model="customField.maxLength"
                    :state="maxLengthState"
                    type="number"
                    :min="1"
                    :max="255"
                >
                </b-form-input>
            </b-form-group>
            <div v-if="maxLengthState == false" class="error">
                Max Length must be greater than Min Length
            </div>
        </div>
        <div v-if="customField.dataTypeId == CUSTOM_FIELD_DATA_TYPE_ENUM.Date">
            <b-form-group id="min-date-group" label="Min Date" label-for="min-date">
                <b-input-group>
                    <b-form-input
                        id="min-date-input"
                        v-model="customField.minDate"
                        placeholder="YYYY-MM-DD"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                        autocomplete="off"
                        :state="minDateState"
                        :disabled="true"
                        >
                    </b-form-input>
                    <b-input-group-append>
                        <b-form-datepicker
                            id="expires-date"
                            v-model="customField.minDate"
                            button-only
                            right
                            :min="minDate" 
                            :max="maxDate"
                        >
                        </b-form-datepicker>
                    </b-input-group-append>
                </b-input-group> 
            </b-form-group>
            <div v-if="minDateState == false" class="error">
                Min date must be less than Max Date
            </div>
            <b-form-group id="max-date-group" label="Max Date" label-for="max-date">
                <b-input-group>
                    <b-form-input
                        id="max-date-input"
                        v-model="customField.maxDate"
                        placeholder="YYYY-MM-DD"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                        autocomplete="off"
                        :state="maxDateState"
                        :disabled="true"
                        >
                    </b-form-input>
                    <b-input-group-append>
                        <b-form-datepicker
                            id="max-date"
                            v-model="customField.maxDate"
                            button-only
                            right
                            :min="minDate" 
                            :max="maxDate"
                        >
                        </b-form-datepicker>
                    </b-input-group-append>
                </b-input-group> 
            </b-form-group>
            <div v-if="maxDateState == false" class="error">
                Max Date must be greater than Min Date
            </div>
        </div>
        <div v-if="customField.dataTypeId == CUSTOM_FIELD_DATA_TYPE_ENUM.List">
            <CustomFieldListValues ref="customFieldListValues" :listValues="customField.listValues" @listValues="setListValues" @setValidity="setListValuesValidity"/>
        </div>
        <b-form-group id="required-input-group" label-for="required">
            <b-form-checkbox v-model="customField.required" name="toggle-override" size="md" switch>
                Required
            </b-form-checkbox>
        </b-form-group>
        <b-form-group id="export-input-group" label-for="export">
            <b-form-checkbox v-model="customField.export" name="toggle-override" size="md" switch>
                Include In Export
            </b-form-checkbox>
        </b-form-group>
        <div class="col">
            <button @click="addCustomField" class="btn btn-primary row row-cols-1 right">
                {{ isUpdate ? 'Update' : 'Add'}}
            </button>
        </div>
    </div>
</template>
<script>

import CustomFieldsService from "../../services/customfields.service" 
import CustomFieldListValues from "./CustomFieldListValues.vue" 
import CUSTOM_FIELD_DATA_TYPE_ENUM from "../../models/CustomFieldDataTypeEnum"
import { getDateOnly } from "../../utils/utils"

export default {
    name: "AddUpdateCustomField",
    components: {
        CustomFieldListValues
    },
    props: {
        id: {
            type: Number,
        }
    },
    data() {
        return {
            customField:{
                id: 0,
                fieldName: "",
                typeId: 0,
                dataTypeId: 0,
                minNum: 0,
                maxNum: 0,
                minLength: 0,
                maxLength: 0,
                minDate: "",
                maxDate: "",
                listValues: [],
                required: false,
                export: false                
            },
            types: [],
            dataTypes: [],
            checkValid: false,
            minDate: 0,
            maxDate: 0,
            fieldNameIsUnique: true,
            CUSTOM_FIELD_DATA_TYPE_ENUM,
            listValuesValidity: false
        }
    },
    computed:{
        isUpdate() {
            return this.id !== undefined
        },
        fieldNameState() {
            if (!this.checkValid) return null 
            return this.customField.fieldName.length > 0 && this.fieldNameIsUnique
        },
        typeState() {
            if (!this.checkValid) return null
            return this.customField.typeId > 0
        },
        dataTypeState() {
            if (!this.checkValid) return null
            return this.customField.dataTypeId > 0
        },
        minNumState() {
            if (!this.checkValid) return null
            if (this.customField.dataTypeId != CUSTOM_FIELD_DATA_TYPE_ENUM.Numeric) return true
            return this.customField.minNum >= 0 && this.customField.minNum < this.customField.maxNum
        },
        maxNumState() {
            if (!this.checkValid) return null
            if (this.customField.dataTypeId != CUSTOM_FIELD_DATA_TYPE_ENUM.Numeric) return true
            return this.customField.maxNum >= 1 && this.customField.maxNum > this.customField.minNum
        },
        minLengthState() {
            if (!this.checkValid) return null
            if (this.customField.dataTypeId != CUSTOM_FIELD_DATA_TYPE_ENUM.Text) return true
            return this.customField.minLength >= 1 && this.customField.minLength <= 255
            && this.customField.minLength < this.customField.maxLength
        },
        maxLengthState() {
            if (!this.checkValid) return null
            if (this.customField.dataTypeId != CUSTOM_FIELD_DATA_TYPE_ENUM.Text) return true
            return this.customField.maxLength >= 1 && this.customField.maxLength <= 255
            && this.customField.maxLength > this.customField.minLength
        },
        minDateState() {
            if (!this.checkValid) return null
            if (this.customField.dataTypeId != CUSTOM_FIELD_DATA_TYPE_ENUM.Date) return true
            return Date.parse(this.customField.minDate) < Date.parse(this.customField.maxDate)
        },
        maxDateState() {
            if (!this.checkValid) return null
            if (this.customField.dataTypeId != CUSTOM_FIELD_DATA_TYPE_ENUM.Date) return true
            return Date.parse(this.customField.maxDate) > Date.parse(this.customField.minDate)
        },
        listValuesState() {
            if (!this.checkValid) return null
            if (this.customField.dataTypeId != CUSTOM_FIELD_DATA_TYPE_ENUM.List) return true
            return this.$refs.customFieldListValues.validateAll()
        },
        isFormValid() {
            return this.fieldNameState && this.minLengthState && this.maxLengthState && this.minNumState && this.maxNumState && this.minDateState && this.maxDateState && this.listValuesState
        },
    },
    watch: {
        'customField.dataTypeId'(dataTypeId) {
            if (dataTypeId !== CUSTOM_FIELD_DATA_TYPE_ENUM.Numeric) {
                this.customField.minNum = 0
                this.customField.maxNum = 0
            }
            if (dataTypeId !== CUSTOM_FIELD_DATA_TYPE_ENUM.Text) {
                this.customField.minLength = 0
                this.customField.maxLength = 0
            }
            if (dataTypeId !== CUSTOM_FIELD_DATA_TYPE_ENUM.Date) {
                this.setUpDates()
            }
            if (dataTypeId !== CUSTOM_FIELD_DATA_TYPE_ENUM.List) {
                this.customField.listValues = []
            }
        },
        'customField.fieldName'() {
            this.isFieldNameUnique()
        },
        'customField.typeId'() {
            this.isFieldNameUnique()
        }       
    },
    created() {
        if (this.isUpdate) {
            this.getById()
        }

        this.setUpDates()
    },
    mounted() {
        this.getTypes()
        this.getDataTypes()
    },
    methods: {
        setUpDates() {
            const now = new Date()

            this.minDate = new Date(now.getFullYear() - 100, 0, 1)
            this.maxDate = new Date(now.getFullYear() + 100, 11, 31)

            this.customField.minDate = this.getDateOnly(now)
            this.customField.maxDate = this.getDateOnly(now)
        },
        getTypes(){
            CustomFieldsService.getTypes().then((response) => {
                if (response) {
                    this.types = response.data
                }
            })
        },
        getDataTypes(){
            CustomFieldsService.getDataTypes().then((response) => {
                if (response) {
                    this.dataTypes = response.data
                }
            })
        },
        isFieldNameUnique() {
            return CustomFieldsService.isFieldNameUnique(
                this.customField.id,
                this.customField.typeId, 
                this.customField.fieldName
                ).then((response) => {
                    if (response) {
                        this.fieldNameIsUnique = response.data
                    }
            })
        },
        setListValues(listValues){
            this.customField.listValues = listValues
        },
        addCustomField() {
            this.checkValid = true
            if (this.isFormValid) {
                CustomFieldsService.addUpdate(this.customField).then((response) => {
                    if (response) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success?  'success' : 'danger',
                            solid: true
                        })

                        this.$emit('hide')
                    }
                })
            }
        },
        setListValuesValidity(value) {
            this.listValuesValidity = value
        },
        getById() {
            CustomFieldsService.getById(this.id).then((response) => {
                if (response) {
                    this.customField.id = response.data.id
                    this.customField.fieldName = response.data.field_name
                    this.customField.typeId = response.data.type_id
                    this.customField.dataTypeId = response.data.data_type_id
                    this.customField.minNum = response.data.min_num
                    this.customField.maxNum = response.data.max_num
                    this.customField.minLength = response.data.min_length
                    this.customField.maxLength = response.data.max_length
                    this.customField.minDate = this.getDateOnly(response.data.min_date)
                    this.customField.maxDate = this.getDateOnly(response.data.max_date)
                    this.customField.listValues = response.data.list_values
                    this.customField.required = response.data.required
                    this.customField.export = response.data.include_in_export
                }
            })
        },
        getDateOnly
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
.right {
    float: right;
}
</style>