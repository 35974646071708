<template>
    <div class="tag-add-modal">
        <b-modal @submit.prevent="addTag" id="tag-add-modal" @shown="refresh">
            <template #modal-title>
                <h3>Add Firmware Tag</h3>
            </template>
                <b-form-group
                    id="name-input-group"
                    label="Name"
                    label-for="name"
                >
                    <b-form-input id="name" v-model="name" :state="nameState && nameUniqueState"/>
                </b-form-group>
                <div v-if='nameState == false' class="error">
                    Firmware Tag is required
                </div>
                <div v-if='nameUniqueState == false' class="error">
                    Firmware Tag already exists
                </div>
            <template #modal-footer>
                <SpinnerButton
                    @click="addTag"
                    :isLoading="isLoading"
                    text="Add"
                    class="btn-primary"
                />
            </template>
        </b-modal>
    </div>
</template>

<script>
import TagService from '../../services/tag.service';
import SpinnerButton from '../SpinnerButton.vue';

export default {
    name: 'UpdateEvent',
    components: {
        SpinnerButton
    },
    data(){
        return {
            isLoading: false,
            name: '',
            typeId: 1,
            existingTags: [],
            checkValid: false
        };
    },
    computed: {
        nameState() {
            if (!this.checkValid) return null;
            return this.name != ""
        },
        nameUniqueState() {
            if (!this.checkValid) return null;
            return !this.existingTags.some(tag => tag.name === this.name)
        },
        isFormValid() {
            return this.nameState && this.nameUniqueState
        }
    },
    methods: {
        refresh() {
            this.getTags()

            this.isLoading =  false,
            this.name = '',
            this.checkValid = false
        },
        addTag() {
            this.checkValid = true;
            if (this.isFormValid) {
                this.isLoading = true;
                TagService.addNewTag(
                    this.typeId,
                    this.name
                ).then((response) => {
                    if (response) {
                        this.isLoading = false;
                        if (response.data) {
                            this.$root.$bvToast.toast(response.data.message, {
                                title: response.data.success ? 'Success' : 'Error',
                                variant: response.data.success ? 'success' : 'danger',
                                solid: true
                            })
                        }
                        this.$emit('hide');
                    }
                },
                (error) => {
                    this.isLoading = false;
                    console.log(error)
                    this.$emit('hide');
                });
            }
        },
        getTags() {
            TagService.listTagsByType(
                this.typeId
            ).then((response) => {
                this.existingTags = response.data
            }).catch(error => {
                this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                    title: 'TagsService.getTags failed',
                    variant: 'danger',
                    solid: true
                })
            })
        },
    },
};
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
}
</style>