<template>
    <div>
        <div class="mt-3">
            <div>
                <b-form-group id="user-type-select-group" label="User Type" label-for="user-type-select">
                    <b-form-select
                        id="user-type-select"
                        v-model="selectedUserType"
                        :disabled="false">
                            <option v-for="userType in userTypes" :value="userType.id" :key="userType.id">
                            {{ userType.name }}
                            </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div v-if="selectedUserType == USER_TYPE_ENUM.PortalUser">
                <div class="row row-cols-1 row-cols-md-2 g-5"> 
                    <div class="col">
                        <b-form-group id="username-input-group" label="Username" label-for="username">
                            <b-form-input 
                                id="username"
                                v-model="user.username"
                                :state="usernameState"
                            ></b-form-input>
                        </b-form-group>
                        <div v-if="usernameState == false" class="error">
                            Username is required
                        </div>
                    </div>
                    <div class="col">
                        <b-form-group id="name-input-group" label="Name" label-for="name">
                            <b-form-input 
                                id="name"
                                v-model="user.name"
                                :state="nameState"
                            ></b-form-input>
                        </b-form-group>
                        <div v-if="nameState == false" class="error">
                            Name is required
                        </div>
                    </div>
                    <div class="col">
                        <b-form-group id="email-input-group" label="Email" label-for="email">
                            <b-form-input 
                                id="email"
                                v-model="user.email"
                                autocomplete="email"
                                :state="emailState"
                            ></b-form-input>
                        </b-form-group>
                        <div v-if="emailState == false" class="error">
                            Email is an invalid format
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-2 g-5"> 
                    <div class="col">
                        <b-form-group id="password-input-group" label="Password" label-for="password">
                            <b-form-input 
                                id="password"
                                v-model="password"
                                type="password"
                                autocomplete="password"
                                :state="passwordState"
                            ></b-form-input>
                        </b-form-group>
                        <div v-if="passwordState == false" class="error">
                            Password is required
                        </div>
                    </div>
                    <div class="col">
                        <b-form-group id="confirm-password-input-group" label="Confirm Password" label-for="password">
                            <b-form-input 
                                id="confirmPassword"
                                v-model="confirmPassword"
                                type="password"
                                autocomplete="off"
                                :state="confirmPasswordState"
                            ></b-form-input>
                        </b-form-group>
                        <div v-if="confirmPasswordState == false" class="error">
                            Confirm Password must match Password
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-md-1 g-5 pl-3 pr-3">
                    <ClaimRoleComponent v-model="selectedRole" :state="roleState"/>
                </div>
                <div v-if="roleState == false" class="error">
                    Role is required
                </div>
                <template v-if="selectedRole.is_technician">
                    <b-form-group id="portal-user-access-level-group" label="Access Level" label-for="portal-user-access-level-input">
                        <b-form-input
                            id="portal-user-access-level-input"
                            v-model="user.accessLevel"
                            type="number"
                            :min="minAccessLevel"
                            :max="maxAccessLevel"
                            :state="levelState"
                        ></b-form-input>
                    </b-form-group>
                    <div class="alert alert-info">
                        {{ accessLevelHelpMessage }}
                    </div>
                </template>
            </div>
            <div v-if="selectedUserType == USER_TYPE_ENUM.KioskUser">
                <b-form-group id="name-group" label="Name" label-for="name-input">
                    <b-form-input
                        id="name-input"
                        v-model="driverCode.name"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="email-group" label="Email" label-for="email-input">
                    <b-form-input
                        id="email-input"
                        v-model="driverCode.email"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="access-level-group" label="Access Level" label-for="access-level-input">
                    <b-form-input
                        id="access-level-input"
                        v-model="driverCode.accessLevel"
                        type="number"
                        :min="minAccessLevel"
                        :max="maxAccessLevel"
                        :state="levelState"
                        required
                    ></b-form-input>
                </b-form-group>
                <div class="alert alert-info">
                    {{ accessLevelHelpMessage }}
                </div>
            </div>
            <div class="form-group message">
                <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                </div>
            </div>
            <a id="users-modal-register-user" @click="register()" class="btn btn-primary btn-margin-right right">Add</a>
        </div>
    </div>
</template>

<script>

import { RolesService, UsersService, DriverCodesService } from '../../services'
import { ClaimRoleComponent } from "../../components"
import USER_TYPE_ENUM from '../../models/UserTypeEnum'

export default {
    name: "RegisterUserModal",
    components: { 
        ClaimRoleComponent
    },
    data() {
        return {
            userErrors: [],
            success: false,
            roles: [],
            emails: [],
            user: {},
            message: "",
            password: "",
            confirmPassword: "",
            selectedRole: {},
            selectedUserType: USER_TYPE_ENUM.PortalUser,
            driverCode: {
                userType: 0,
                username: "",
                name: "",
                email: "",
                accessLevel: 1
            },
            userTypes: [],
            USER_TYPE_ENUM,
            regex: new RegExp('[a-z0-9]+@[a-z0-9]+\\.[a-z]{2,3}'),
            checkValid: false
        }
    },
    watch: {
        selectedUserType() {
            this.driverCode.userType = this.selectedUserType
        }
    },
    emits: ['changeComplete'],
    mounted() {
        this.getRoles()
        this.getUserTypes()
    },
    computed: {
        security() {
            return this.$store.state.auth.security
        },
        usernameState() {
            if (!this.checkValid) return null;
            return this.user.username ? true : false;
        },
        nameState() {
            if (!this.checkValid) return null;
            return this.user.username ? true : false;
        },
        emailState(){
            if (!this.checkValid) return null;
            return this.regex.test(this.user.email)
        },
        passwordState() {
            if (!this.checkValid) return null;
            return this.password ? true : false;
        },
        confirmPasswordState() {
            if (!this.checkValid) return null;
            return this.confirmPassword && this.password == this.confirmPassword ? true : false;
        },
        roleState() {
            if (!this.checkValid) return null;
            return this.selectedRole ? true : false;
        },
        levelState() {
            if (!this.checkValid) return null
            return (this.user.accessLevel >= this.minAccessLevel && this.user.accessLevel <= this.maxAccessLevel)
        },
        isFormValid() {
            return this.usernameState && this.nameState && this.emailState && this.passwordState && this.confirmPasswordState && this.roleState
        },
        minAccessLevel() {
            if (this.selectedUserType == USER_TYPE_ENUM.PortalUser)
                return 30
            return 1
        },
        maxAccessLevel() {
            if (this.security.technician) {
                return 99
            }
            return 89
        },
        accessLevelHelpMessage() {
            if (this.security.technician) {
                if (this.selectedUserType == USER_TYPE_ENUM.PortalUser) {
                    return "Access level 30 to 99 is kiosk technician"
                }
                return "Access level 1-29 is kiosk restock and 30-99 is kiosk technician"
            } else {
                if (this.selectedUserType == USER_TYPE_ENUM.PortalUser) {
                    return "Access level 30 to 89 is kiosk technician"
                }
                return "Access level 1-29 is kiosk restock and 30-89 is kiosk technician"
            }
        }
    },
     methods: {
        refresh() {
            this.message = ""
            this.password = ""
            this.confirmPassword = ""
            this.selectedRole = {}
            this.selectedUserType = USER_TYPE_ENUM.PortalUser
            this.checkValid = false

            this.user = {}

            this.driverCode = {
                userType: 0,
                name: "",
                email: "",
                accessLevel: 1
            },
            this.getRoles()
            this.getUserTypes()
        },
        register() {
            if (this.selectedUserType == USER_TYPE_ENUM.PortalUser) { 
                this.registerUser()
            } else if (this.selectedUserType == USER_TYPE_ENUM.KioskUser) {
                this.addAccessCode()
            }
        },
        registerUser() {
            this.checkValid = true
            this.message = "";

            if (this.selectedRole.is_technician) {
                if (this.user.accessLevel < this.minAccessLevel || this.user.accessLevel > this.maxAccessLevel) {
                    this.message = `Please provide access level from ${this.minAccessLevel} to ${this.maxAccessLevel}`
                    return
                }
                else if (this.user.accessLevel === undefined) {
                    this.message = "Please provide an access level"
                    return
                }
            } else {
                // Reset access level to null if role is not a technician admin
                this.user.accessLevel = undefined
            }
            if (this.isFormValid) {
                UsersService.register(
                    this.user.username,
                    this.user.name,
                    this.user.email,
                    this.password,
                    this.selectedRole.description,
                    this.user.accessLevel
                ).then((response) => {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });

                    if (response.data.success) {
                        this.$emit('changeComplete');
                    }
                });
            }
        },
        getRoles() {
            RolesService.getRoles(this.security.technician).then(
                (response) => {
                    this.roles = response.data;
            });
        },
        getUserTypes() {
            UsersService.getUserTypes('c').then(
                (response)=> {
                    if (response) {
                        this.userTypes = response.data         
                    }
                }
            )
        },
        addAccessCode() {
            if(!this.driverCode.name) {
                this.message = "Please provide a name."
                return;
            }
            if(!this.regex.test(this.driverCode.email)) {
                this.message = "Please provide a valid email format."
                return;
            }
            if(this.driverCode.accessLevel < this.minAccessLevel || this.driverCode.accessLevel > this.maxAccessLevel) {
                if (this.security.technician) {
                    this.message = "Please provide access level from 1 to 99"
                } else {
                    this.message = "Please provide access level from 1 to 89"
                }
                return;
            }

            DriverCodesService.add(
                    this.driverCode.name,
                    this.driverCode.email,
                    this.driverCode.userType,
                    this.driverCode.accessLevel
                ).then(
                (response)=> {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })

                    if (response.data.success) {
                        this.$emit('changeComplete')
                    }
                }
            );
        },
    }
}
</script>
<style scoped>
.error {
    color: #dc3545;
    margin-top: -0.75rem;
    margin-bottom: 1rem;
}
</style>