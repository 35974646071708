<template>
    <div>
        <h3><font-awesome-icon icon="desktop" class="nav-icon fa" />  Programs</h3>
        <div class="button-group">
            <div class="button-col">
                <b-button @click="showAddPromoProgramModal" variant="primary" class="button" v-if="security.promoCreate" >Add</b-button>
            </div>
        </div>
        <DynamicSearchComponent
            ref="promoProgramSearchComponent"
            preferencesName="promo_program_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <div class="pb-3">
            <Table
                id="promoProgramTable"
                :fields="dynamicFields"
                :items="promoPrograms"
                sortBy="name"
                :sortDesc="false"
                :searchPagination="searchPagination"
                @getItems="listPromoPrograms"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="data">
                    <div class="icons">
                        <font-awesome-icon icon="pencil" class="nav-icon pointer mx-2" @click="showUpdatePromoProgramModal(data.item)" v-if="security.promoWrite" title="Edit"/>
                        <font-awesome-icon icon="trash-can" class="nav-icon pointer mx-2" style="padding-left: 10px" @click="showRemoveProgramModal(data.item)" v-if="security.promoDelete" title="Delete"/>
                    </div>
                </template>
                <template #cell(discount)="data">
                    {{
                        data.item.discountTypeId != 2
                            ? formatPrice(data.item.discount)
                            : `%${data.item.discount}`
                    }}
                </template>
            </Table>
        </div>
        <PromoProgramExportFileComponent
            :arguments="searchQuery"
            class="export"
        />
        <b-modal
            id="add-update-promo-program"
            ref="add-update-promo-program"
            hide-footer
        >
            <template #modal-title>
                {{
                    selectedPromoProgram == undefined
                        ? 'Program - New'
                        : 'Program - Update'
                }}
            </template>
            <AddUpdatePromoProgram
                @hide="hideAddPromoProgramModal"
                :program="selectedPromoProgram"
            />
        </b-modal>
        <b-modal id="remove-program" ref="remove-program">
            <template #modal-title> Remove Program </template>
            <div class="d-block">
                <p>You are about to remove item</p>
                <p>Do you want to proceed?</p>
            </div>
            <template #modal-footer>
                <button class="btn btn-danger" @click="removeProgram(selectedPromoProgram)">
                    Remove
                </button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import {
    Table,
    PromoProgramExportFileComponent,
    DynamicSearchComponent,
} from '../../components/';
import AddUpdatePromoProgram from './AddUpdatePromoProgram.vue';
import PromosService from '../../services/promos.service';
import PROMO_TYPE_ENUM from '../../models/PromoTypeEnum';
import { generateDynamicFields } from '../../utils/utils';

export default {
    name: 'PromoProgram',
    components: {
        Table,
        AddUpdatePromoProgram,
        PromoProgramExportFileComponent,
        DynamicSearchComponent,
    },
    data() {
        return {
            promoPrograms: [],
            selectedPromoProgram: {},
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'name',
                    label: 'Name',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'startDate',
                    label: 'Start',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'endDate',
                    label: 'End',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'discountType',
                    label: 'Discount Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4
                },
                {
                    key: 'discount',
                    label: 'Discount',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5
                },
                {
                    key: 'couponsGenerated',
                    label: 'Coupons Generated',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6
                },
                {
                    key: 'couponsUsed',
                    label: 'Coupons Used',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7
                },
            ],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: 1,
            PROMO_TYPE_ENUM,
            searchCriteria: [
                {
                    key: 'province',
                    name: this.getProvinceString(),
                    type: 'Province',
                    searchQueryName: 'province',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'store_num',
                    name: 'Store #',
                    type: 'Text',
                    searchQueryName: 'storeNum',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'display_active',
                    name: 'Only Display Active Programs',
                    type: 'Boolean',
                    searchQueryName: 'displayActive',
                    defaultValue: false,
                },
            ],
            searchQuery: {},
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        dynamicFields() {
            return generateDynamicFields(
                this.fields,
                'actions',
                !this.security.promoDelete && !this.security.promoWrite
            );
        },
    },
    mounted() {
        this.listPromoPrograms();
    },
    methods: {
        listPromoPrograms() {
            this.$refs.promoProgramSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.searchQuery = args.query;
            PromosService.listPromoPrograms(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                if (response) {
                    this.promoPrograms = response.data.items;
                    this.searchPagination.totalRows =
                        this.promoPrograms.length > 0
                            ? response.data.total_rows
                            : 0;
                    args.done();
                }
            });
        },
        showAddPromoProgramModal() {
            this.selectedPromoProgram = undefined;
            this.$refs['add-update-promo-program'].show();
        },
        hideAddPromoProgramModal() {
            this.$refs['add-update-promo-program'].hide();
            this.listPromoPrograms();
        },
        showUpdatePromoProgramModal(promoProgram) {
            this.selectedPromoProgram = promoProgram;
            this.$refs['add-update-promo-program'].show();
        },
        hideUpdatePromoProgramModal() {
            this.$refs['add-update-promo-program'].hide();
            this.listPromoPrograms();
        },
        showRemoveProgramModal(promoProgram) {
            this.selectedPromoProgram = promoProgram;
            this.$refs['remove-program'].show();
        },
        removeProgram() {
            PromosService.removePromoProgram(this.selectedPromoProgram.id).then(
                (response) => {
                    if (response) {
                       this.$root.$bvToast.toast(response.data.message, {
                            title: response.data.success ? 'Success' : 'Error',
                            variant: response.data.success ? 'success' : 'danger',
                            solid: true
                        })
                        this.hideRemoveProgramModal();
                        this.listPromoPrograms();
                    }
                }
            );
        },
        hideRemoveProgramModal() {
            this.$refs['remove-program'].hide();
            this.listPromoPrograms();
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.listPromoPrograms()
        },
        formatPrice(price) {
            return price.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
            });
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
    },
};
</script>
<style scoped>

::v-deep .action-column {
    min-width: 30px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 0.5rem;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    .icons { 
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>