<template>
    <div>
        <h3>
            <font-awesome-icon icon="earth-americas" class="nav-icon fa" /> Regions
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf1b')" class="help-icon nav-icon fa" title="Sleegers User Guide - Regions"/>
        </h3>
        <RegionsTreeViewComponent/>
    </div>
</template>

<script>
import RegionsTreeViewComponent from '../../components/regions/RegionsTreeViewComponent.vue'
import { openHelpWindow } from '../../utils/utils'

export default {
    name: "Regions",
    components: {
        RegionsTreeViewComponent
    },
    methods: {
        openHelpWindow
    }
}
</script>
<style scoped>
</style>