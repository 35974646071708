<template>
    <div>
        <b-modal @submit.prevent="reloadAddProduct" id="product-add-modal" hide-footer>
            <template #modal-title>
                <h3>Add Product</h3>
            </template>
            <div class="row row-cols-1"> 
                <div class="col">
                    <b-form-group id="description-input-group" label="Description" label-for="description">
                        <b-form-input 
                            id="description"
                            v-model="productDetails.description"
                            :state="descriptionState"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="descriptionState == false" class="error">
                        Description is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="receipt-message-input-group" label="Receipt Message" label-for="receiptMessage">
                        <b-form-textarea
                            id="receiptMessage"
                            v-model="productDetails.receipt_message"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="email-message-input-group" label="Email Message" label-for="emailMessage">
                        <b-form-textarea
                            id="emailMessage"
                            v-model="productDetails.email_message"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1">
                <b-form-checkbox
                id="effective-date-time-input" 
                class="ml-3 mb-3"
                v-model="flagEffectiveDateRange">
                    Message Effective Date Range
                </b-form-checkbox>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group v-if="flagEffectiveDateRange">
                        <label class="mt-3" for="example-datepicker">Message Effective Start Date</label>
                        <b-form-datepicker
                        id="message_effective_start_date"
                        v-model="productDetails.message_start_date"
                        ></b-form-datepicker>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group v-if="flagEffectiveDateRange">
                        <label class="mt-3" for="example-datepicker">Message Effective End Date</label>
                        <b-form-datepicker
                        id="message_effective_end_date"
                        v-model="productDetails.message_end_date"
                        ></b-form-datepicker>
                    </b-form-group>
                </div> 
            </div>
            <div class="row row-cols-1"> 
                <div class="col">
                    <b-form-group id="receipt-heading-input-group" label="Receipt Heading" label-for="receiptHeading">
                        <b-form-input 
                            id="receiptHeading"
                            v-model="productDetails.receipt_heading"
                            :state="receiptHeadingState"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="receiptHeadingState == false" class="error">
                        Receipt Heading is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="receipt-message-one-input-group" label="Receipt Message One" label-for="receiptMessageOne">
                        <b-form-textarea
                            id="receiptMessageOne"
                            v-model="productDetails.receipt_primary_message"
                            :state="receiptPrimaryMessageState"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                    <div v-if="receiptPrimaryMessageState == false" class="error">
                        Receipt Message One is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="receipt-message-two-input-group" label="Receipt Message Two" label-for="receiptMessageTwo">
                        <b-form-textarea
                            id="receiptMessageTwo"
                            v-model="productDetails.receipt_secondary_message"
                            :state="receiptSecondaryMessageState"
                            required
                        ></b-form-textarea>
                    </b-form-group>
                    <div v-if="receiptSecondaryMessageState == false" class="error">
                        Receipt Message Two is required
                    </div>
                </div>
            </div>
            <div class="row row-cols-1">
                <div class="col">
                    <b-form-group id="billing-location-two-input-group" label="Billing Location" label-for="billingLocation">
                        <b-form-textarea
                            id="billingLocation"
                            v-model="productDetails.billing_location"
                            :state="billingLocationState"
                        ></b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col">
                    <b-form-group id="receipt-phone-input-group" label="Receipt Phone #" label-for="receiptPhone">
                        <b-form-input
                        id="receiptPhone"
                        v-model="productDetails.receipt_phone_number"
                        :state="receiptPhoneState || phoneValid"
                        required
                        ></b-form-input>
                    </b-form-group>
                    <div v-if="(receiptPhoneState || phoneValid) == false" class="error">
                        Receipt Phone # is required
                    </div>
                </div>
                <div class="col" v-if="showGSTReg">
                    <b-form-group id="gst-input-group" label="GST/HST Reg" label-for="gstReg">
                        <b-form-input
                        id="gstReg"
                        v-model="productDetails.gst_reg"
                        ></b-form-input>
                    </b-form-group>
                </div> 
            </div>
            <CustomFieldsForm ref="customFieldsForm" :typeId="CUSTOM_FIELD_TYPE_ENUM.Product"/>
            <b-button id="product-modal-add-product" @click="add()" class="btn btn-primary right" variant="primary">
                Add
            </b-button>
        </b-modal>
    </div>
</template>

<script>

import ProductsService from "../../../services/products.service"
import CustomFieldsForm from "../../../views/customfields/CustomFieldsForm.vue" 
import CUSTOM_FIELD_TYPE_ENUM from "../../../models/CustomFieldTypeEnum"

export default {
    name: "AddProductModal",
    components: {
        CustomFieldsForm
    },
    data() {
        return {
            productDetails: {
                description: '',
                receipt_message: '',
                email_message: '',
                message_start_date: null,
                message_end_date: null,
                receipt_heading: '',
                receipt_primary_message: '',
                receipt_secondary_message: '',
                receipt_phone_number: '',
                billing_location: '',
                gst_reg: '',
            },
            isLoading: false,
            flagEffectiveDateRange: false,
            checkValid: false,
            phoneValid: null,
            CUSTOM_FIELD_TYPE_ENUM
        }
    },
    props: {
        billingLocationLength: {
            type: Number,
            required: true
        }
    },
    emits: ['changeComplete'],
    computed: {
        descriptionState() {
            if(!this.checkValid) return null
            return this.productDetails.description == '' ? false : true
        },
        receiptHeadingState() {
            if(!this.checkValid) return null
            return this.productDetails.receipt_heading == '' ? false : true
        },
        receiptPrimaryMessageState() {
            if(!this.checkValid) return null
            return this.productDetails.receipt_primary_message == '' ? false : true
        },
        receiptSecondaryMessageState() {
            if(!this.checkValid) return null
            return this.productDetails.receipt_secondary_message == '' ? false : true
        },
        receiptPhoneState() {
            if(!this.checkValid) return null
            return this.productDetails.receipt_phone_number == '' ? false : true
        },
        billingLocationState() {
            if (!this.checkValid) return null
            return (this.productDetails.billing_location.length < this.billingLocationLength)
        },
        isFormValid() {
            return this.descriptionState && this.receiptHeadingState && this.receiptPrimaryMessageState && this.receiptSecondaryMessageState 
                && this.receiptPhoneState && this.billingLocationState
        }
    },
     methods: {
        reloadAddProduct(){
            this.$emit('reloadAddProduct');
        },
        add() {  
            this.checkValid = true
            this.phoneValid = this.validatePhone();
            if(!this.phoneValid) return;  
            this.phoneValid = null;    
            

            if (this.isFormValid && this.$refs.customFieldsForm.isFormValid()) {                   
                this.productDetails.custom_field_values = this.$refs.customFieldsForm.getValues()

                ProductsService.add(this.productDetails).then((response) => {
                    if (response) {
                        this.isLoading = false;
                        if(response.data.success){
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Successful',
                                variant: 'success',
                                solid: true
                            })
                            this.checkValid = false;
                            this.$emit('changeComplete');
                            this.productDetails = {};
                            this.phoneValid = null;
                        }
                        else {
                            this.vmidDuplicate = false
                            this.$root.$bvToast.toast(response.data.message, {
                                title: 'Error',
                                variant: 'danger',
                                solid: true
                            })
                        }
                        
                    }
                });
            }
        },
        validatePhone() {
            if(this.productDetails.receiptPhone == '') return true;
            const regex = new  RegExp('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$');
            if(!regex.test(this.productDetails.receipt_phone_number)) {
                this.$root.$bvToast.toast('Please provide a valid phone format', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true
                })
                return false;
            }
            return true;
        },
        showGSTReg(){
            this.$store.state.auth.portal_configuration.showgstreg == "1"
        },
    }
}
</script>

<style scoped>
.new-search {
    text-align: right;
    margin-right: 12px;
    position: relative;
    top: 38px;    
}

.error {
    color: #dc3545;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
</style>