import Vue from 'vue'
import VueI18n from 'vue-i18n'
import english from './english'

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {
        "en": english
    }
});

export default i18n