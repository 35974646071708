import { render, staticRenderFns } from "./AssignedFirmwareComponent.vue?vue&type=template&id=33f1e1dc&scoped=true&"
import script from "./AssignedFirmwareComponent.vue?vue&type=script&lang=js&"
export * from "./AssignedFirmwareComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f1e1dc",
  null
  
)

export default component.exports