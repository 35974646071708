<template>
    <div>
        <b-modal id="stores-qr-code-modal" hide-footer>
            <template #modal-title>QR Code</template>
            <div class="d-block text-center">
                <template>
                    <qrcode-vue :value="storeUrl" :size="150" level="H" />
                    <a
                        :href="storeUrl"
                        target="_blank"
                        style="margin-top: 4%"
                        >{{ storeUrl }}</a
                    >
                </template>
            </div>
            <b-button
                class="mt-3"
                block
                @click="$bvModal.hide('stores-qr-code-modal')"
                >Close</b-button
            >
        </b-modal>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'QRCodeStore',
    components: {
        QrcodeVue,
    },
    props: {
        storeUrl: {
            type: String,
            default: '',
        },
    },
};
</script>

<style scoped>
</style>