import axios from "axios";
import authHeader from "./auth.header";
import { addTimezoneInformation } from "../utils/utils"

class FileArchiveService {
  listArchiveData(query, startIndex, pageSize) {
    return axios({
      method: 'post',
      url: '/api/file_archive/list',
      headers: authHeader(),
      data: {
        record_type_id: query.recordType,
        date_start: addTimezoneInformation(query.dateStart),
        date_end: addTimezoneInformation(query.dateEnd),
        start_index: startIndex,
        page_size: pageSize,
      }
    })
  }

  getRecordTypes() {
    return axios({
      method: 'get',
      url: '/api/file_archive/get_record_types',
      headers: authHeader()
    }
    );
  }

  downloadFile(id, filename) {
    return axios({
      method: 'get',
      responseType: "blob",
      url: '/api/file_archive/download_file',
      headers: authHeader(),
      params: {
        id, filename
      }
    })
  }
}
export default new FileArchiveService();