import axios from "axios";
import authHeader from "./auth.header";

class SoftwareService {
    list(hardwareTypeId, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'get',
            url: '/api/software/list',
            headers: authHeader(),
            params: {
                hardwareTypeId, startIndex, pageSize, sortOrder
            }
        })
    }

    getFirmwareById(firmwareId) {
        return axios({
            method: 'get',
            url: '/api/software/firmware_by_id',
            headers: authHeader(),
            params: {
                firmwareId
            }
        })
    }

    upload(form_data) {
        var headers = {}

        Object.assign(headers, authHeader())
        Object.assign(headers, { "Content-Type": "multipart/form-data" })
        return axios({
            method: 'post',
            url: '/api/software/upload',
            headers: headers,
            data: form_data,
        })
    }

    submit(firmwarePack) {
        return axios({
            method: 'post',
            url: '/api/software/submit',
            headers: authHeader(),
            data: firmwarePack
        })
    }

    deleteSoftware(id) {
        return axios({
            method: 'delete',
            url: '/api/software/delete',
            headers: authHeader(),
            params: { firmwareId: id }
        });
    }

    downloadSoftware(id) {
        return axios({
            method: 'get',
            responseType: 'arraybuffer',
            url: '/api/software/download',
            headers: authHeader(),
            params: { firmwareId: id }
        });
    }

    listAssignment(query, startIndex, pageSize, sortOrder) {
        return axios({
            method: 'post',
            url: '/api/software/list_assignment',
            headers: authHeader(),
            data: {
                hardware_type_id: query.hardwareType == undefined ? 0 : query.hardwareType,
                region_id: query.region == undefined ? 0 : query.region,
                installed: query.installed == undefined ? null : query.installed,
                enabled: query.enabled == undefined ? null : query.enabled,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder

            }
        })
    }

    downloadAssignmentCsv(query) {
        return axios({
            method: 'get',
            url: '/api/software/download_csv',
            headers: authHeader(),
            params: {
                hardwareTypeId: query.hardwareType == undefined ? 0 : query.hardwareType,
                regionId: query.region == undefined ? 0 : query.region,
                installed: query.installed == undefined ? null : query.installed,
                enabled: query.enabled == undefined ? null : query.enabled
            }
        })
    }

    downloadAssignmentXlsx(query) {
        return axios({
            method: 'get',
            responseType: "blob",
            url: '/api/software/download_xlsx',
            headers: authHeader(),
            params: {
                hardwareTypeId: query.hardwareType == undefined ? 0 : query.hardwareType,
                regionId: query.region == undefined ? 0 : query.region,
                installed: query.installed == undefined ? null : query.installed,
                enabled: query.enabled == undefined ? null : query.enabled
            }
        })
    }

    getPhases(firmwareId) {
        return axios({
            method: 'get',
            url: '/api/software/phases',
            headers: authHeader(),
            params: {
                firmwareId
            }
        })
    }

    setPhase(id, firmware_id, phase, update_on) {
        return axios({
            method: 'post',
            url: '/api/software/phase',
            headers: authHeader(),
            data: {
                id,
                firmware_id,
                phase,
                update_on,
            }
        })
    }

    getPhaseProgress(phaseId) {
        return axios({
            method: 'get',
            url: '/api/software/phase_progress',
            headers: authHeader(),
            params: {
                phaseId
            }
        })
    }

    deletePhase(phaseId) {
        return axios({
            method: 'post',
            url: '/api/software/delete_phase',
            headers: authHeader(),
            params: {
                phaseId
            }
        })
    }

    completeRelease(firmwareId) {
        return axios({
            method: 'post',
            url: '/api/software/complete_release',
            headers: authHeader(),
            params: {
                firmwareId
            }
        })
    }

    getReleaseProgress(hardwareTypeId, startIndex, pageSize, sortOrder, currentReleaseProgress) {
        return axios({
            method: 'post',
            url: '/api/software/list_release_progress',
            headers: authHeader(),
            data: {
                hardware_type_id: hardwareTypeId,
                start_index: startIndex,
                page_size: pageSize,
                sort_order: sortOrder,
                old_release_progress: currentReleaseProgress
            }
        })
    }
}
export default new SoftwareService();