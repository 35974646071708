<template>
    <div class="container-fluid mt-3">
        <div class="row row-cols-1 row-cols-md-2">
            <div class="col left-col">
                <b-form-group
                    id="price-input-group"
                    label="Purchase Price"
                    label-for="price-input"
                >
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <font-awesome-icon
                                icon="dollar-sign"
                                class="nav-icon fa"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                            id="purchase-price-input"
                            v-model="priceDetails.purchase_price"
                            :state="purchasePriceState"
                            required
                        ></b-form-input>
                    </b-input-group>
                    <div v-if="purchasePriceState == false" class="error">
                        Purchase Price must be greater or equal to 0
                    </div>
                </b-form-group>
            </div>
            <div class="col right-col">
                <b-form-group
                    id="price-input-group"
                    label="Exchange Price"
                    label-for="price-input"
                >
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <font-awesome-icon
                                icon="dollar-sign"
                                class="nav-icon fa"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                            id="exchange-price-input"
                            v-model="priceDetails.exchange_price"
                            :state="exchangePriceState"
                            required
                        ></b-form-input>
                    </b-input-group>
                    <div v-if="exchangePriceState == false" class="error">
                        Exchange Price must be greater or equal to 0
                    </div>
                </b-form-group>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-2">
            <div class="col left-col">
                <b-form-group
                    id="price-input-group"
                    label="Restock Purchase Cost"
                    label-for="price-input"
                >
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <font-awesome-icon
                                icon="dollar-sign"
                                class="nav-icon fa"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                            id="restock-purchase-cost-input"
                            v-model="priceDetails.restock_purchase_cost"
                            :state="restockPurchaseCostState"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
                <div v-if="restockPurchaseCostState == false" class="error">
                    Restock Purchase Cost must be greater or equal to 0
                </div>
            </div>
            <div class="col right-col">
                <b-form-group
                    id="price-input-group"
                    label="Restock Exchange Cost"
                    label-for="price-input"
                >
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <font-awesome-icon
                                icon="dollar-sign"
                                class="nav-icon fa"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                            id="restock-exchange-cost-input"
                            v-model="priceDetails.restock_exchange_cost"
                            :state="restockExchangeCostState"
                        ></b-form-input>
                    </b-input-group>
                </b-form-group>
                <div v-if="restockExchangeCostState == false" class="error">
                    Restock Exchange Cost must be greater or equal to 0
                </div>
            </div>
            <div class="col left-col">
                <b-form-group
                    id="price-input-group"
                    label="Effective Date"
                    label-for="effectiveDate"
                >
                    <b-form-datepicker
                        id="update-date-datepicker"
                        v-model="priceDetails.effective_date"
                        right
                        :min="minDate"
                        :date-format-options="{
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                        }"
                        :state="effectiveDateState"
                    />
                </b-form-group>
                <div v-if="effectiveDateState == false" class="error">
                    Effective Date is required
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpdatePriceTabValue',
    props: {
        priceDetails: {
            type: Object,
            required: true,
        },
        minDate: Date.now(),
        checkValid: {
            type: Boolean,
            required: true,
        },
        dateOffset: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            ignoreCheckValid: false
        }
    },
    computed: {
        purchasePriceState() {
            if (!this.ignoreCheckValid && !this.checkValid) return null
            return this.priceDetails.purchase_price !== "" && this.priceDetails.purchase_price >= 0
        },
        exchangePriceState() {
            if (!this.ignoreCheckValid && !this.checkValid) return null
            return this.priceDetails.exchange_price !== "" && this.priceDetails.exchange_price >= 0
        },
        restockPurchaseCostState() {
            if (!this.ignoreCheckValid && !this.checkValid) return null
            return this.priceDetails.restock_purchase_cost !== "" && this.priceDetails.restock_purchase_cost >= 0 
        },
        restockExchangeCostState() {
            if (!this.ignoreCheckValid && !this.checkValid) return null
            return this.priceDetails.restock_exchange_cost !== "" && this.priceDetails.restock_exchange_cost >= 0
        },
        effectiveDateState() {
            if (!this.ignoreCheckValid && !this.checkValid) return null
            return this.priceDetails.effective_date != ''
        },
    },
    mounted() {
        if (this.dateOffset > 0) {
            let date = new Date(this.priceDetails.effective_date)
            date.setDate(date.getDate() + this.dateOffset)

            this.priceDetails.effective_date = date.toISOString().split('T')[0]
        }
    },
    methods: {
        validate(ignoreCheckValid) {
            this.ignoreCheckValid = ignoreCheckValid
            return this.purchasePriceState && this.exchangePriceState && this.restockPurchaseCostState && this.restockExchangeCostState && this.effectiveDateState
        }
    }
};
</script>

<style scoped>
.left-col {
    padding-left: 0px;
}

.right-col {
    padding-right: 0px;
}

.error {
    color: #dc3545;
    margin-bottom: 1rem;
}
</style>