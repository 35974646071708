<template>
    <b-modal scrollable size="xl" id="transaction-log-modal">
        <template #modal-title>
            <h3>Transaction Logs</h3>
        </template>
        <div class="modal-body">
            <span>
                <div v-for="log in logs" :key="log">
                    {{ log }}
                </div>
            </span>
        </div>
        <template #modal-footer>
            <span style="position:absolute; left: 20px; bottom: 20px">{{modalFooterMessage}}</span>
            <a class="btn btn-primary" @click="copyToClipboard()">Copy to Clipboard</a>
        </template>
    </b-modal>
</template>
<script>
export default {
    name: "TransactionLogs",
    props: {
        logs: Array
    },
    data(){
        return {
            modalFooterMessage: ""
        }
    },
    mounted(){
        this.$root.$on('bv::modal::show', () => {
            this.modalFooterMessage = ""
        })
    },
    methods: {
        copyToClipboard() {
            var copyLogs = this.logs.join("")
            
            navigator.clipboard.writeText(copyLogs).then(() =>{
                this.modalFooterMessage = "Logs copied to clipboard"
            })

        }
    }
}
</script>