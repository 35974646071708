<template>
    <div>
        <h3>
            <font-awesome-icon icon="hourglass" class="nav-icon fa" /> Kiosk
            Uptime
        </h3>
        <DynamicSearchComponent
            ref="kioskUptimeSearchComponent"
            preferencesName="kiosk_uptime_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />
        <div class="pb-4">
            <Table
                id="kioskUptimeTable"
                :fields="fields"
                :items="uptimeReport"
                sortBy="uptime"
                :sortDesc="true"
                :searchPagination="searchPagination"
                :horizontalScroll="true"
                @getItems="getKioskUptime"
                @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(vm_id)="data">
                <a href="#" @click="kioskLink(data.item.kiosk_id)" class="underline">
                    {{ data.item.vm_id }}
                </a>
            </template>
                <template #cell(date)="data">
                    {{ formatDate(data.item.date) }}
                </template>
            </Table>
        </div>
        <ExportFileComponent
            :arguments="this.arguments.query"
            :searchPagination="this.searchPagination"
            :disabled="false"
            page="kioskUptime"
            class="export"
        />
    </div>
</template>

<script>
import { DynamicSearchComponent } from '../../components';
import { UptimeService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import { ExportFileComponent, Table } from '../../components/';
import { kioskLink } from "../../utils/utils"
export default {
    name: 'KioskUptime',
    components: {
        DynamicSearchComponent,
        Table,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'vm_id',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1
                },
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_no',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'store_province',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'hardware_type',
                    label: 'Hardware Type',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2
                },
                {
                    key: 'region',
                    label: 'Region',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'percentage_uptime_vending',
                    label: 'Store Vending Uptime %',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'percentage_uptime_vending_by_state',
                    label: 'State Vending Uptime %',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'percentage_uptime_vending_by_state',
                    label: 'State Vending Uptime %',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'percentage_uptime_inventory_by_store',
                    label: 'Store Inventory Uptime %',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'percentage_uptime_inventory_by_state',
                    label: 'State Inventory Uptime %',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'uptime',
                    label: 'Uptime',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3
                },
                {
                    key: 'minutes_offline',
                    label: 'Vending Minutes Offline',
                    tdClass: 'column',
                    sortable: false,
                },
                {
                    key: 'out_of_inventory_minutes_offline',
                    label: 'Minutes Out of Inventory',
                    tdClass: 'column',
                    sortable: false,
                },
            ],
            searchCriteria: [
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'province',
                    name: 'Province',
                    type: 'Province',
                    searchQueryName: 'province',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'datestart',
                    name: 'Date Start',
                    subtype: 'start-date',
                    type: 'Date',
                    searchQueryName: 'dateStart',
                    defaultValue: new Date().toISOString().slice(0, 10),
                    required: true,
                },
                {
                    key: 'dateend',
                    name: 'Date End',
                    subtype: 'end-date',
                    type: 'Date',
                    searchQueryName: 'dateEnd',
                    defaultValue: new Date().toISOString().slice(0, 10),
                    required: true,
                },
            ],
            uptimeReport: [],
            arguments: [],
            isLoading: false,
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -3,
        };
    },
    mounted() {
    },
    methods: {
        getKioskUptime() {
            this.$refs.kioskUptimeSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.arguments = args;
            this.isLoading = true;
            UptimeService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then(
                (response) => {
                    if (response && response.data.total_rows > -1) {
                        this.uptimeReport = response.data.items;
                        this.searchPagination.totalRows =
                            this.uptimeReport.length > 0
                                ? response.data.total_rows
                                : 0;
                        this.isLoading = false;
                        args.done();
                    }
                    else {
                        this.$root.$bvToast.toast("Query has timed out. Please try narrowing the field of your search criteria", {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                        this.isLoading = false;
                        args.done();
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getKioskUptime();
        },
        kioskLink,
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
    },
};
</script>
<style scoped>
.underline {
    text-decoration: underline;
}

::v-deep .export > div {
    margin-top: -2.5rem !important;
}

@media only screen and (max-width: 575.5px) {
    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>