<template>
    <div>
        <h3>
            <font-awesome-icon icon="cash-register" class="nav-icon fa" /> Kiosks
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf2a')" class="help-icon nav-icon fa" title="Sleegers User Guide - Kiosks"/>
        </h3>
        <div class="button-group">
            <div class="button-col">
                <b-button
                    @click="openAddKioskModal"
                    variant="primary"
                    class="button mt-2"
                    v-if="security.kioskCreate"
                    >
                    Add Kiosks
                </b-button>
            </div>
        </div>
        <DynamicSearchComponent
            ref="kioskSearchComponent"
            preferencesName="kiosk_search"
            :allCriteria="searchCriteria"
            @resetPagination="
                () => {
                    this.searchPagination.currentPage = 1;
                    this.searchPagination.startIndex = 1;
                }
            "
            @reloadDynamicSearch="reloadDynamicSearch"
        />

        <Table
            id="kioskTable"
            :fields="dynamicFields"
            :items="kiosks"
            sortBy="last_ping_desc"
            :sortDesc="false"
            :horizontalScroll="true"
            :searchPagination="searchPagination"
            @getItems="getKiosks"
            @sortOrderChanged="sortOrderChanged"
        >
            <template #head(favourite)>
                <font-awesome-icon
                    icon="star"
                    :color="favouriteAll ? 'gold' : 'black'"
                    class="nav-icon pointer mx-2"
                    @click="favouriteAllKiosk()"
                    title="Favourite / Unfavourite All"
                />
            </template>
            <template #cell(favourite)="row">
                <font-awesome-icon
                    icon="star"
                    :color="row.item.favourite ? 'gold' : 'black'"
                    class="nav-icon pointer mx-2"
                    @click="favouriteKiosk(row.item.id, !row.item.favourite)"
                    :title="row.item.favourite ? 'Unfavourite' : 'Favourite'"
                />
            </template>
            <template #cell(actions)="row">
                <div class="icons">
                    <font-awesome-icon
                        :icon="
                            !row.detailsShowing ? 'chevron-down' : 'chevron-up'
                        "
                        class="nav-icon pointer mx-2"
                        @click="row.toggleDetails"
                        title="Expand Info"
                        :class="{ hide: row.item.num_servants == 0 }"
                    />
                    <font-awesome-icon
                        icon="scroll"
                        class="nav-icon pointer mx-2"
                        @click="restoreData(row.item)"
                        title="Log Data"
                    />
                    <font-awesome-icon
                        icon="robot"
                        class="nav-icon pointer action-btn"
                        @click="row.toggleDetails"
                        title="Servants"
                        v-if="row.item.numServants > 0"
                    />
                    <font-awesome-icon
                        icon="trash-can"
                        class="nav-icon pointer mx-2"
                        @click="openDeleteModal(row.item)"
                        v-if="security.kioskDelete"
                        title="Remove Kiosk"
                        />
                </div>
            </template>
            <template #cell(vmid)="data">
                <b
                    class="pointer vmid"
                    :class="getVmidSeverityColor(data)"
                    @click="kioskLink(data.item.id)"
                    >
                    {{ data.item.vmid }}
                    </b>
            </template>
            <template #cell(date)="data">
                {{ formatDate(convertUTCtoLocalDate(data.item.date)) }}
            </template>
            <template #row-details="data">
                <b-card>
                    <h5>Servants</h5>
                    <KioskServantsComponent :kioskId="data.item.id" />
                </b-card>
            </template>
        </Table>
        <ExportFileComponent
            :arguments="this.currentQuery"
            :disabled="false"
            page="kiosks"
            class="export"
        />
        <b-modal id="delete-kiosk" ref="delete-kiosk" hide-footer>
            <template #modal-title> Kiosk - Delete </template>
            <DeleteKioskComponent
                :kiosk="selectedKiosk"
                @hide="hideDeleteModal"
            />
        </b-modal>
        <b-modal id="add-kiosk-modal" ref="add-kiosk-modal" size="lg" hide-footer>
            <template #modal-title> Add Kiosks </template>
            <AddKioskModalTabs @hide="hideAddKioskModal"/>
        </b-modal>
    </div>
</template>

<script>
import SearchPagination from '../../models/search.pagination';
import { Table } from '../../components';
import { DynamicSearchComponent } from '../../components';
import KiosksService from '../../services/kiosks.service';
import RolesService from '../../services/roles.service';
import EXCLUDED_COLUMN_TYPE_ENUM from '../../models/ExcludedColumnsTypes';
import { generateFieldsByRole, kioskLink, formatDate, convertUTCtoLocalDate, openHelpWindow } from '../../utils/utils';
import { DeleteKioskComponent, AddKioskModalTabs } from '../../components/kiosk';
import KioskServantsComponent from '../../components/kiosks/KioskServantsComponent.vue';
import ExportFileComponent from '../../components/ExportFileComponent.vue';

export default {
    name: 'Kiosks',
    components: {
        Table,
        DynamicSearchComponent,
        DeleteKioskComponent,
        ExportFileComponent,
        KioskServantsComponent,
        AddKioskModalTabs
    },
    data() {
        return {
            fields: [
                {
                    key: 'favourite',
                    tdClass: 'favourite-column',
                },
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'vmid',
                    label: 'VMID',
                    tdClass: 'column',
                    sortable: true,
                    visible: false,
                    columnId: 1,
                    sortOrder: 1
                },
                {
                    key: 'type',
                    label: 'Type',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 2,
                    sortOrder: 2
                },
                {
                    key: 'store',
                    label: 'Store',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 3,
                    sortOrder: 3
                },
                {
                    key: 'store_number',
                    label: 'Store #',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 4,
                    sortOrder: 4
                },
                {
                    key: 'address',
                    label: 'Address',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 5,
                },
                {
                    key: 'city',
                    label: 'City',
                    tdClass: 'column',
                    sortable: false,
                    columnId: 6,
                },
                {
                    key: 'province',
                    label: this.getProvinceString(),
                    tdClass: 'column',
                    sortable: false,
                    columnId: 7,
                },
                {
                    key: 'last_checkin_desc',
                    label: 'Last Check-in',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 8,
                    sortOrder: 12
                },
                {
                    key: 'last_ping_desc',
                    label: 'Last Ping',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 9,
                    sortOrder: 15
                },
                {
                    key: 'last_issue_type',
                    label: 'Issue',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 10,
                    sortOrder: 13
                },
                {
                    key: 'last_issue_date_dt',
                    label: 'Date',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 11,
                    sortOrder:  14
                },
                {
                    key: 'last_successful_transaction',
                    label: 'Last Successful Transaction',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 51,
                    sortOrder:  11
                },                
                {
                    key: 'full_cylinders',
                    label: 'Full Cylinders',
                    tdClass: 'column',
                    sortable: true,
                    columnId: 52,
                    sortOrder:  5
                },
            ],
            searchCriteria: [
                {
                    key: 'vmid',
                    name: 'VMID',
                    type: 'Text',
                    searchQueryName: 'vmid',
                    initial: true,
                },
                {
                    key: 'svmid',
                    name: 'SVMID',
                    type: 'Svmid',
                    searchQueryName: 'svmid',
                },
                {
                    key: 'store_number',
                    name: 'Store Number',
                    type: 'Text',
                    searchQueryName: 'storeNumber',
                    initial: true,
                },
                {
                    key: 'account_number',
                    name: 'Account Number',
                    type: 'Text',
                    searchQueryName: 'accountNumber',
                },
                {
                    key: 'region',
                    name: 'Region',
                    type: 'Region',
                    searchQueryName: 'region',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'hardware_type',
                    name: 'Hardware Type',
                    type: 'HardwareType',
                    searchQueryName: 'hardwareType',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'product',
                    name: 'Product',
                    type: 'Product',
                    searchQueryName: 'product',
                    searchQueryValueProperty: 'id',
                },
                {
                    key: 'enabled',
                    name: 'Enabled',
                    type: 'Boolean',
                    searchQueryName: 'enabled',
                },
                {
                    key: 'installed',
                    name: 'Installed',
                    type: 'Boolean',
                    searchQueryName: 'installed',
                    defaultValue: true, 
                    initial: true,
                },
                {
                    key: 'severity_level',
                    name: 'Severity Level',
                    type: 'SeverityLevel',
                    searchQueryName: 'severityLevel',
                },
                {
                    key: 'issue_type',
                    name: 'Issue Type',
                    type: 'IssueType',
                    searchQueryName: 'issue',
                },
                {
                    key: 'favourite',
                    name: 'Favourite',
                    type: 'Boolean',
                    searchQueryName: 'favourite',
                },
            ],
            kiosks: [],
            searchPagination: new SearchPagination('', 1, 10, 1, 0),
            sortOrder: -1,
            show: true,
            favouriteAll: false,
            currentQuery: {},
            selectedKiosk: {},
            excludedColumns: [],
        };
    },
    computed: {
        security() {
            return this.$store.state.auth.security;
        },
        roleId() {
            return this.$store.state.auth.user.role_id;
        },
        dynamicFields() {
            return generateFieldsByRole(this.fields, this.excludedColumns);
        },
    },
    mounted() {
        this.getExcludedColumnsByRoleAndType();
    },
    methods: {
        getKiosks() {
            this.$refs.kioskSearchComponent.reloadDynamicSearch();
        },
        reloadDynamicSearch(args) {
            this.currentQuery = args.query;
            KiosksService.searchQuery(
                args.query,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            )
                .then((response) => {
                    if (response) {
                        if (this.currentQuery.svmid != null) {
                            this.kiosks = response.data.items.map((v) => ({
                                ...v,
                                _showDetails: true,
                            }));
                        } else {
                            this.kiosks = response.data.items;
                        }

                        for (var i = 0; i < this.kiosks.length; ++i) {
                            let dt = new Date(
                                Date.parse(this.kiosks[i].last_issue_date)
                            );
                            if (dt.getFullYear() < 1900) {
                                this.kiosks[i].last_issue_date_dt = '';
                            } else {
                                this.kiosks[i].last_issue_date_dt =
                                formatDate(convertUTCtoLocalDate(dt));
                            }

                            let transaction_dt = new Date(
                                Date.parse(this.kiosks[i].last_successful_transaction)
                            );
                            if (transaction_dt.getFullYear() < 1900) {
                                this.kiosks[i].last_successful_transaction = '';
                            } else {
                                this.kiosks[i].last_successful_transaction =
                                formatDate(convertUTCtoLocalDate(transaction_dt));
                            }
                        }
                        this.searchPagination.totalRows =
                            this.kiosks.length > 0
                                ? response.data.total_rows
                                : 0;
                    }
                    args.done();
            })
                .catch(() => {
                    args.done();
                });
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder
            this.getKiosks()
        },
        getVmidSeverityColor(data) {
            switch (data.item.severity_level_id) {
                case 0:
                    return 'text-success';
                case 1:
                    return 'text-info';
                case 2:
                    return 'text-warning';
                case 3:
                    return 'text-danger';
                default:
                    return '';
            }
        },
        restoreData(item) {
            var portalId = this.$store.state.auth.portal.id;
            var routeData = this.$router.resolve({
                path: '/restoreData/',
                query: { kiosk_id: item.id, portal_id: portalId },
            });
            window.open(routeData.href, 'kiosk_' + item.id);
        },
        favouriteKiosk(kioskId, favourite) {
            KiosksService.favouriteKiosk(kioskId, favourite).then(
                (response) => {
                if (response) {
                    if (!response.data.success) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: 'Error',
                            variant: 'danger',
                            solid: true,
                        });
                    }
                    this.getKiosks();
                }
                }
            );
        },
        favouriteAllKiosk() {
            KiosksService.favouriteAllKiosk(
                this.currentQuery,
                0,
                0,
                0,
                !this.favouriteAll
                ).then((response) => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? 'Success' : 'Error',
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true,
                    });

                    this.favouriteAll = !this.favouriteAll;
                    this.getKiosks();
                }
            });
        },
        getProvinceString() {
            return this.$store.state.auth.portal_configuration?.province ?? "Province";
        },
        getExcludedColumnsByRoleAndType() {
            RolesService.getExcludedColumnsByRoleAndType(
                this.roleId,
                EXCLUDED_COLUMN_TYPE_ENUM.Kiosk
            ).then((response) => {
                    if (response) {
                    this.excludedColumns = response.data;
                    }
            });
        },
        openDeleteModal(kiosk) {
            this.selectedKiosk = kiosk;
            this.$refs['delete-kiosk'].show();
        },
        hideDeleteModal(kiosk) {
            this.selectedKiosk = kiosk;
            this.$refs['delete-kiosk'].hide();
            this.getKiosks();
        },
        kioskLink,
        formatDate,
        convertUTCtoLocalDate,
        openHelpWindow,
        openAddKioskModal() {
            this.$bvModal.show('add-kiosk-modal');
        },
        hideAddKioskModal() {
            this.$bvModal.hide('add-kiosk-modal');
            this.getStores();
        },
    },
};
</script>

<style scoped>
.vmid {
    text-decoration: underline;
}

::v-deep .favourite-column {
    vertical-align: middle !important;
}

::v-deep .action-column {
    min-width: 130px;
    vertical-align: middle !important;
}

.hide {
    visibility: hidden;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 575.5px) {
    .icons {
        display: flex;
        justify-content: space-around;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }
}
</style>