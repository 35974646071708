<template>
    <b-modal id="software-delete-modal" @show="refresh">
        <template #modal-title>
            <h3>Delete Firmware</h3>
        </template>
        <div class="modal-body">
            <div class="row-fluid">
                <div class="control-group span4">
                    <p>
                        <label
                            >You are about to remove a firmware package.</label
                        >
                        <label>Any associated releases and phases will also be deleted</label>
                    </p>
                </div>
                <div class="control-group span8">
                    <p>
                        <label>Do you want to proceed?</label>
                    </p>
                </div>
            </div>
        </div>
        <template #modal-footer>
            <SpinnerButton @click="deleteSoftware" :isLoading="isLoading" text="Delete" class="btn btn-danger" />
        </template>
    </b-modal>
</template>

<script>
import SoftwareService from '../../services/software.service';
import { SpinnerButton } from '../../components';

export default {
    name: 'DeleteSoftwareModal',
    props: {
        softwareId: Number,
    },
    components: {
        SpinnerButton,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    emits: ['changeComplete'],
    methods: {
        refresh() {
            this.isLoading = false
        },
        deleteSoftware() {
            this.isLoading = true;
            SoftwareService.deleteSoftware(this.softwareId).then(response => {
                if (response) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: response.data.success ? "Success" : "Error",
                        variant: response.data.success ? 'success' : 'danger',
                        solid: true
                    })

                    this.$emit('changeComplete');
                    this.isLoading = false
                }
            })
        }
    }
}
</script>