<template>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <UnitConfigurationItemComponent
            v-for="config in form"
            :key="config.name"
            :config="config"
            :overridden="config.is_overridden"
            :disabled="disabled"
        />
        <div class="row row-cols-2 row-cols-sm-2 g-4">
            <div class="col">
                <b-button
                    class="details-btn"
                    @click="disable()"
                    v-if="disabled"
                    :disabled="!disabled"
                    variant="secondary"
                    >Edit</b-button
                >
                <b-button
                    type="reset"
                    class="details-btn"
                    v-if="!disabled"
                    :disabled="disabled"
                    variant="secondary"
                    >Cancel</b-button
                >
            </div>
            <div class="col">
                <b-button
                    type="submit"
                    class="details-btn submit-btn"
                    v-if="!disabled"
                    variant="primary"
                    >Save</b-button
                >
            </div>
        </div>
    </b-form>
</template>

<script>
import StoresService from '../../services/stores.service';
import UnitConfigurationItemComponent from '../kiosk/UnitConfigurationItemComponent.vue';

export default {
    name: 'ConfigStore',
    components: { UnitConfigurationItemComponent },
    data() {
        return {
            form: [],
            configurations: {},
            isLoading: false,
            selected: [],
            show: true,
            disabled: true,
        };
    },
    props: {
        storeId: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    mounted() {
        this.getConfiguration();
    },
    methods: {
        getConfiguration() {
            this.loading = true;
            StoresService.getConfiguration(this.storeId).then((response) => {
                if (response) {
                    this.configurations = response.data;
                    this.setForm(this.configurations);
                    this.loading = false;
                }
            });
        },
        onSubmit(event) {
            event.preventDefault();
            StoresService.updateConfiguration(this.storeId, this.form)
                .then((response) => {
                    this.configurations = response.data;
                    this.setForm(this.configurations);
                    this.loading = false;
                    this.disabled = true;

                    this.$root.$bvToast.toast('Successful', {
                        title: 'Update Store Configurations',
                        variant: 'success',
                        solid: true,
                    });
                })
                .catch((error) => {
                    this.setForm(this.configurations);
                    this.disabled = true;

                    this.$root.$bvToast.toast(`Error: ${JSON.stringify(error)}`, {
                        title: 'Update Store Configurations',
                        variant: 'danger',
                        solid: true,
                    });
                });
        },
        onReset(event) {
            event.preventDefault();
            this.disabled = true;
            this.setForm(this.configurations);
            this.show = false;

            this.$nextTick(() => {
                this.show = true;
            });
        },
        disable() {
            setTimeout(() => {
                this.disabled = !this.disabled;
            }, 150);
        },
        setForm(configs) {
            this.form = [];
            configs.items.forEach((config) => {
                this.form.push({ ...config });
            });
        },
    },
};
</script>
<style scoped>
</style>