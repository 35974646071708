<template>
    <b-modal id="dlg-alert" ref="dlg-alert" hide-footer>
            <template #modal-title>
                {{ title }}
            </template> 
            <span>{{ message }}</span>
            <b-button class="mt-3" block @click="$bvModal.hide('dlg-alert')">Close</b-button>
    </b-modal>      
</template>

<script>
    export default {
        name: "AlertDialog",
        data() {
            return {
                title: '',
                message: '',
            }
        },
        methods: {
            show(title, message) {
                this.title = title
                this.message = message
                this.$refs["dlg-alert"].show()
            }
        }
    }
</script>