import { render, staticRenderFns } from "./TagListComponent.vue?vue&type=template&id=05f43e41&scoped=true&"
import script from "./TagListComponent.vue?vue&type=script&lang=js&"
export * from "./TagListComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f43e41",
  null
  
)

export default component.exports