export const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_MSAL_CONFIG_CLIENTID,
        authority: 'https://login.microsoftonline.com/common/',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
}