<template>
    <div>
        <h3>
            <font-awesome-icon icon="coins" class="nav-icon fa" />  Prices
            <!-- TODO: Add back user help guide with split HTML -->
            <font-awesome-icon v-if="false" icon="question-circle" @click="openHelpWindow('#pf27')" class="help-icon nav-icon fa" title="Sleegers User Guide - Prices"/>
        </h3>
        <div class="button-group">
            <div class="button-col">
                <button
                    @click="openAddModal"
                    :disabled="!productId > 0"
                    class="btn btn-primary button"
                >
                    New Prices
                </button>
            </div>
        </div>
        <ProductsComponent
            v-model="productId"
            @input="getPrices"
            :state="null"
            :hideOptionAll="true"
            class="mt-3"
        />
        <div class="pb-3">
            <Table
              id="pricesTable"
              :fields="fields"
              :items="prices"
              sortBy="description"
              :sortDesc="false"
              :searchPagination="searchPagination"
              @getItems="getPrices"
              @sortOrderChanged="sortOrderChanged"
            >
                <template #cell(actions)="row">
                    <div v-if="row.item.typeId != 4" class="icons">
                        <font-awesome-icon
                            icon="pencil"
                            class="nav-icon pointer mx-2"
                            @click="openUpdateModal(row.item)"
                            title="Edit"
                        />
                        <font-awesome-icon
                            icon="trash-can"
                            class="nav-icon pointer mx-2"
                            @click="openDeleteModal(row.item)"
                            title="Delete"
                        />
                    </div>
                </template>
                <template #cell(effective_date)="data">
                    {{data.item.effective_date != null ? formatDateOnly(data.item.effective_date) : '' }}
                </template>
            </Table>
            <ExportFileComponent
                :arguments="currentQuery"
                :disabled="false"
                page="prices"
                class="export mb-3"
            />

            <DeletePriceModal
                @deleteComplete="deleteComplete"
                :priceId="selectedPriceId"
                :effectiveDateId="selectedDateId"
            />
            <AddPriceModal
                :productId="productId"
                @changeComplete="changeComplete"
            />
            <UpdatePriceModal
                @changeComplete="changeComplete"
                :priceId="selectedPriceId"
                :productId="productId"
            />
        </div>
    </div>
</template>

<script>
import { ProductsService } from '../../services';
import SearchPagination from '../../models/search.pagination';
import { Table, ProductsComponent } from '../../components/';
import {
    DeletePriceModal,
    AddPriceModal,
    UpdatePriceModal,
} from '../../components/kiosk/prices';
import ExportFileComponent from '../../components/ExportFileComponent.vue';
import { formatDateOnly, openHelpWindow } from '../../utils/utils'
export default {
    name: 'Prices',
    components: {
        Table,
        DeletePriceModal,
        AddPriceModal,
        UpdatePriceModal,
        ProductsComponent,
        ExportFileComponent,
    },
    data() {
        return {
            fields: [
                {
                    key: 'actions',
                    tdClass: 'action-column',
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 1,
                },
                {
                    key: 'purchase_price',
                    label: 'Purchase',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 2,
                },
                {
                    key: 'exchange_price',
                    label: 'Exchange',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 3,
                },
                {
                    key: 'cost_of_purchase',
                    label: 'Cost of Purchase',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 4,
                },
                {
                    key: 'cost_of_exchange',
                    label: 'Cost of Exchange',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 5,
                },
                {
                    key: 'include_in_output_generation',
                    label: 'Included in Output Generation',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 6,
                },
                {
                    key: 'effective_date',
                    label: 'Effective Date',
                    tdClass: 'column',
                    sortable: true,
                    sortOrder: 7,
                },
          ],
          prices: [],
          productId: 0,
          isLoading: false,
          selectedPriceId: 0,
          selectedDateId: 0,
          searchPagination: new SearchPagination('', 1, 10, 1, 0),
          priceDetails: {},
          sortOrder: 1,
            currentQuery: {},
        };
    },
    created() {
        this.getPrices();
    },
    methods: {
        getPrices() {
            this.currentQuery.productId = this.productId;
            this.isLoading = true;
            ProductsService.getPricesPaged(
                this.productId,
                this.searchPagination.startIndex,
                this.searchPagination.pageSize,
                this.sortOrder
            ).then((response) => {
                if (response) {
                    this.prices = response.data.items;
                    this.searchPagination.totalRows =
                    this.prices.length > 0 ? response.data.total_rows : 0;
                    this.isLoading = false;
                }
            });
        },
        openDeleteModal(item) {
            this.selectedPriceId = item.id;
            this.selectedDateId = item.effective_date_id;
            this.$bvModal.show('price-delete-modal');
        },
        deleteComplete() {
            this.$bvModal.hide('price-delete-modal');
            this.getPrices();
        },
        changeComplete() {
            this.$bvModal.hide('price-add-modal');
            this.$bvModal.hide('price-update-modal');
            this.getPrices();
        },
        openAddModal() {
            this.$bvModal.show('price-add-modal');
        },
        openUpdateModal(item) {
            this.selectedPriceId = item.id;
            this.selectedDateId = item.effective_date_id;
            this.$bvModal.show('price-update-modal');
        },
        sortOrderChanged(sortOrder) {
            this.sortOrder = sortOrder;
            this.getPrices();
        },
        formatDateOnly,
        openHelpWindow,
    }
}
</script>
<style scoped>
::v-deep .action-column {
    min-width: 100px;
    vertical-align: middle !important;
}

.button-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
}

.button-col {
    display: flex;
    flex-direction: row;
}

.button {
    margin-right: 0.5rem;
}

::v-deep .export > div {
    margin-top: 0rem !important;
}

@media only screen and (max-width: 575.5px) {
    .icons {
        display: flex;
        justify-content: space-around;
    }

    .button-group {
        flex-direction: column;
    }

    .button-col {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .button {
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 1rem;
    }

    ::v-deep .export > div {
        width: 100%;
        margin-top: 0rem !important;
    }
}
</style>